import { useForm } from "react-hook-form";
import {
  Order,
  OrderHotelItem,
  OrderHotelOfflineOption,
  OrderStatus,
} from "~/application/types";
import {
  DateFormats,
  diffBetweenDates,
  format,
} from "~/application/utils/date-functions";
import { Card, CardBody } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { DataListItemProps } from "~/components/List";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { getNameRoomTypes } from "../../utils";
import { OptionListItem } from "./components/OptionListItem";
import { OrderItem } from "~/services/resources/OrderApproval/IOrderApprovalService";

export interface OrderHotelOfflineProps extends DataListItemProps {
  item?: OrderHotelItem;
  order?: Order;
  onItemExpand: (room: OrderHotelItem) => void;
  onOpenOptionDetails: (
    item: OrderHotelItem,
    option: OrderHotelOfflineOption
  ) => void;
  canShowApprovalButtons?: boolean;
  approveItem: (item: OrderItem) => void;
  reproveItem: (item: OrderItem) => void;
}

export function OrderHotelOffline({
  item,
  canShowApprovalButtons,
  order,
  onItemExpand,
  onOpenOptionDetails,
  approveItem,
  reproveItem,
}: OrderHotelOfflineProps) {
  const options = item?.options;
  const offline = item?.obsOffline;
  const orderStatus = order?.status as OrderStatus;

  const { control, setValue, watch } = useForm<{ optionId?: string }>();

  const formData = watch();

  const adultNumber = (item?.guests || []).reduce(
    (accumulator, { passengerType }) => {
      return passengerType === "ADT" ? (accumulator += 1) : accumulator;
    },
    0
  );

  const roomTypes = getNameRoomTypes(item);

  if (options?.length && orderStatus !== OrderStatus.QUOTING) {
    return (
      <OptionListItem
        setValue={setValue}
        order={order}
        options={options}
        formData={formData}
        roomTypes={roomTypes}
        adultNumber={adultNumber}
        control={control}
        item={item}
        onItemExpand={onItemExpand}
        onOpenOptionDetails={onOpenOptionDetails}
      />
    );
  }

  return (
    <Flex direction="column" gap="8">
      <Card css={{ border: "none" }}>
        <CardBody css={{ p: "0" }}>
          <Flex
            direction={{ "@mxlg": "column" }}
            css={{ width: "100%", p: "0" }}
            justify="between"
          >
            <Flex css={{ width: "100%", p: "$6" }} direction="column" gap="6">
              <Flex direction="column" gap="4">
                <Text as="h1" size={{ "@mxlg": "6" }}>
                  Cotação de hotel
                </Text>
                <Text as="h2" size={{ "@mxlg": "4" }}>
                  {roomTypes?.map((roomType, index) => (
                    <Text key={index}>
                      <Text>{`Quarto ${roomType}`}</Text>{" "}
                      {!!roomTypes[index + 1] && <Text> e </Text>}
                    </Text>
                  ))}
                </Text>
              </Flex>

              <Flex direction="column" gap="8">
                <Flex>
                  <Text>
                    <Text size={{ "@mxlg": "3" }} variant="darkest">
                      {item?.cityName}
                    </Text>
                  </Text>
                </Flex>

                <Flex>
                  <Text css={{ flex: "0 0 50%" }}>
                    Check-in:{" "}
                    <Text size={{ "@mxlg": "3" }} variant="darkest">
                      {format(item?.checkIn as Date, DateFormats.SMALL_DATE)}
                    </Text>
                  </Text>

                  <Text size={{ "@mxlg": "3" }}>
                    Check-out:{" "}
                    {
                      <Text variant="darkest">
                        {format(item?.checkOut as Date, DateFormats.SMALL_DATE)}
                      </Text>
                    }
                  </Text>
                </Flex>

                <Flex>
                  <Text size={{ "@mxlg": "3" }}>
                    Regime de hospedagem:{" "}
                    <Text variant="darkest">{item?.roomRegimen}</Text>
                  </Text>
                </Flex>

                <Flex>
                  <Text size={{ "@mxlg": "3" }}>
                    Observações:{" "}
                    <Text variant="darkest">
                      {item?.obsOffline?.obs || "Sem observações."}
                    </Text>
                  </Text>
                </Flex>

                <Flex gap="2">
                  {offline?.amenities?.map((amenity) => (
                    <Tag
                      css={{
                        "@mxlg": {
                          border: "0",
                        },
                      }}
                      key={amenity}
                      variant="info-light"
                    >
                      {amenity}
                    </Tag>
                  ))}
                </Flex>
              </Flex>
            </Flex>

            <Flex
              justify={{ "@initial": "center", "@mxlg": "start" }}
              css={{
                width: "35%",
                border: "1px $neutrals-light",
                borderStyle: "none none none dotted",
                p: "$6",
                "@mxlg": {
                  px: "0",
                  ml: "$4",
                  borderStyle: "none",
                  width: "100%",
                  overflow: "hidden",
                },
              }}
            >
              <Text>
                <Text variant="darkest">{`${diffBetweenDates(
                  item?.checkOut,
                  item?.checkIn
                )} diárias `}</Text>
                {!!adultNumber && (
                  <Text variant="darkest">{`, ${adultNumber} Adulto${adultNumber > 1 ? "s" : ""
                    }`}</Text>
                )}
              </Text>
            </Flex>
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
}
