import { Vehicle, VehicleQueryDetail } from "~/application/types";
import { VehicleDTO, VehicleQueryDetailDTO } from "../dtos";
import { toDate } from "~/utils/date.utils";
import { mapViolatedPoliciesDTO } from "./mapViolatedPoliciesDTO";

export function mapVehicleDTO(data: VehicleDTO, searchKey: string): Vehicle {
  return {
    searchKey,
    id: data.id,
    vehicle: {
      description: data.vehicle_description,
      group: data.vehicle_group,
      image: data.vehicle_image,
      airCondition: data.vehicle_air_condition,
      transmissionType: data.vehicle_transmission_type,
      numberDoors: data.vehicle_number_doors,
      details: data.vehicle_details,
    },
    companyLogo: data.company_logo,
    destination: data.destination,
    isAvailable: data.is_available,
    origin: data.origin,
    rentalCompany: data.rental_company,
    totalFees: data.total_fees,
    totalPrice: data.total_price,
    violatedPolicies: mapViolatedPoliciesDTO(data.violated_policies),
    returnFeeAnotherCity: data.return_fee_another_city,
  };
}

export const mapVehicleDetailDTO = (data: VehicleQueryDetailDTO): VehicleQueryDetail => {
  return {
    address: {
      city: data?.address?.city,
      complement: data?.address?.complement,
      country: data?.address?.country,
      number: Number(data?.address?.number),
      quarter: data?.address?.quarter,
      street: data?.address?.street,
      state: data?.address?.state,
      zipCode: data?.address?.zip_code,
    },
    deadLineDatetime: toDate(data?.dead_line_datetime),
    deadLineDescription: data?.dead_line_description,
    email: data?.email,
    localization: data?.localization,
    maximumAge: Number(data?.maximum_age),
    minimumAge: Number(data?.minimum_age),
    phone: data?.phone,
    rentalPolicy: data?.rental_policy,
  };
};
