import { FC } from "react";
import { Hotel } from "~/application/types";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgMap } from "~/components/Icon/icons";
import { Text } from "~/components/Text";

export type ButtonMapProps = {
  disabled?: boolean;
  onSelect: (item: Hotel) => void;
  onMapView: (props: {
    onSelect: (item: Hotel) => void;
    description: string;
  }) => void;
  hotelDailyGuestText: string;
};

export const ButtonMap: FC<ButtonMapProps> = ({
  disabled,
  hotelDailyGuestText,
  onMapView,
  onSelect,
}) => {
  return (
    <Flex
      css={{
        border: "1px solid $primary-base",
        cursor: "pointer",
        fill: "$primary-base",
        color: "$primary-base",
        transition: "all 0.2s ease-in-out",
        ["&:hover"]: {
          fill: "$primary-dark",
          borderColor: "$primary-dark",
          color: "$primary-dark",
        },
        ["&:active"]: {
          fill: "$neutrals-white",
          borderColor: "$primary-base",
          color: "$neutrals-white",
          backgroundColor: "$primary-base",
        },
        px: "$3",
        py: "$2",
        borderRadius: "$sm",
        pointerEvents: disabled ? "none" : "auto",
        opacity: disabled ? 0.4 : 1,
      }}
      justify="center"
      align="center"
      gap="2"
      onClick={() => onMapView({ onSelect, description: hotelDailyGuestText })}
    >
      <Icon as={SvgMap} />
      <Text size="3">Ver mapa</Text>
    </Flex>
  );
};
