import { Order, OrderItemStatus, OrderItems, OrderStatus } from "~/application/types";
import { Button } from "~/components/Button";
import { dialogService } from "~/components/DialogStack";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgClose, SvgTicket } from "~/components/Icon/icons";
import { Text } from "~/components/Text";
import { CancelItemDialog } from "~/presentation/ManageOrder/pages/ManageOrderPage/components/CancelItemDialog";

export type ManualEmissionButtonsProps<T> = {
  data: Order;
  item: T;
  onCancelItem: ({
    orderItemId,
    orderItemType,
  }: {
    orderItemId: string;
    orderItemType: OrderItems;
  }) => void;
  onIssueItem: (item: T) => void;
  itemType: OrderItems;
};

export function ManualEmissionButtons<T extends { uuid: string; status: OrderItemStatus }>({
  data,
  item,
  onCancelItem,
  onIssueItem,
  itemType,
}: ManualEmissionButtonsProps<T>) {
  if (data.status === OrderStatus.CANCELING) return null;

  return (
    <Flex align="center" justify="center" gap="3">
      <Button
        variant="secondary"
        css={{
          "@mxlg": {
            height: "$10",
          },
        }}
        disabled={
          data.status === OrderStatus.ISSUED ||
          item.status === OrderItemStatus.DONE ||
          item.status === OrderItemStatus.CANCELED
        }
        onClick={() =>
          dialogService.showDialog(
            <CancelItemDialog
              title="Seu pedido será cancelado"
              onConfirm={() =>
                onCancelItem({
                  orderItemId: item.uuid,
                  orderItemType: itemType,
                })
              }
              textCancelation="Cancelar pedido"
            >
              <Text
                size="3"
                css={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "$2",
                }}
              >
                Após confirmar não poderá ser desfeito.
                <Text>Deseja continuar?</Text>
              </Text>
            </CancelItemDialog>
          )
        }
      >
        <Icon as={SvgClose} />
        <Text size={{ "@mxlg": "2" }}>Cancelar item</Text>
      </Button>

      <Button
        css={{
          "@mxlg": {
            height: "$10",
          },
        }}
        onClick={() => onIssueItem(item)}
        variant={item.status === OrderItemStatus.DONE ? "secondary" : "primary"}
      >
        <Icon as={SvgTicket} />
        <Text size={{ "@mxlg": "2" }}>
          {item.status === OrderItemStatus.DONE ? "Editar dados" : "Emitir item"}
        </Text>
      </Button>
    </Flex>
  );
}
