import { FC, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DropdownMenuIndicator } from "~/application/components/DropdownMenu";
import {
  expenseMenuItems,
  orderMenuItems,
  searchMenuItems,
  settingsMenuItems,
} from "~/application/data/menuItems";
import { routes } from "~/application/theme/routes";
import { NavMenuModule, filterMenuItems } from "~/application/utils/menu-functions";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgChevronDown, SvgGear, SvgOrder, SvgSearch, SvgTicket } from "~/components/Icon/icons";
import { Text } from "~/components/Text";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { Actions, UserContext } from "~/application/types";
import { NavMenuItem } from "./NavMenuItem";
import SvgAnalytics from "~/components/Icon/icons/Analytics";
import { AgencyEmployeeType } from "~/application/types";
import { useVerifyActions } from "~/presentation/shared/hooks/useVerifyActions";

export const NavMenu: FC = () => {
  const { user, userActions, profile, contexts } = useUser();
  const location = useLocation();
  const navigate = useNavigate();

  const activeMenu = useMemo<NavMenuModule>(() => {
    if (location.pathname.startsWith("/busca")) return "search";
    if (location.pathname.startsWith("/pedidos")) return "order";
    if (location.pathname.startsWith("/despesas")) return "expenses";
    if (location.pathname.startsWith("/configuracoes")) return "settings";
    if (location.pathname.startsWith("/dashboard")) return "dashboard";
  }, [location.pathname]);

  const searchMenus = useMemo(() => {
    const items = filterMenuItems(searchMenuItems, userActions, contexts);

    if (items.length === 0) return;

    return (
      <NavMenuItem isActive={activeMenu === "search"} menuItems={items}>
        <Icon as={SvgSearch} />
        <Text>Busca</Text>
        <DropdownMenuIndicator>
          <Icon as={SvgChevronDown} />
        </DropdownMenuIndicator>
      </NavMenuItem>
    );
  }, [activeMenu, userActions, contexts]);

  const orderMenus = useMemo(() => {
    const items = filterMenuItems(orderMenuItems, userActions, contexts, user);

    if (items.length === 0) return;

    return (
      <NavMenuItem isActive={activeMenu === "order"} menuItems={items}>
        <Icon as={SvgTicket} />
        <Text>Pedidos</Text>
        <DropdownMenuIndicator>
          <Icon as={SvgChevronDown} />
        </DropdownMenuIndicator>
      </NavMenuItem>
    );
  }, [activeMenu, userActions, contexts, user]);

  const expenseMenus = useMemo(() => {
    const items = filterMenuItems(expenseMenuItems, userActions, contexts);

    if (items.length === 0 || profile?.isExpenseApprover) return;

    return (
      <NavMenuItem isActive={activeMenu === "expenses"} menuItems={items}>
        <Icon as={SvgOrder} />
        <Text>Despesas</Text>
        <DropdownMenuIndicator>
          <Icon as={SvgChevronDown} />
        </DropdownMenuIndicator>
      </NavMenuItem>
    );
  }, [activeMenu, userActions, profile, contexts]);

  const settingsMenus = useMemo(() => {
    const items = filterMenuItems(settingsMenuItems, userActions, contexts);
    const isManager = user.type === AgencyEmployeeType.MANAGER;
    const isValidAgencyEmployee = isManager && user.context === UserContext.Agency;
    const isValidCustomerEmployee = user.context === UserContext.Customer;

    if (items.length && (isValidAgencyEmployee || isValidCustomerEmployee)) {
      return (
        <NavMenuItem isActive={activeMenu === "settings"} menuItems={items}>
          <Icon as={SvgGear} />
          <Text>Configurações</Text>
          <DropdownMenuIndicator>
            <Icon as={SvgChevronDown} />
          </DropdownMenuIndicator>
        </NavMenuItem>
      );
    }
  }, [activeMenu, userActions, contexts, user]);

  const dashboardMenus = useMemo(() => {
    const dashboardLink = user.customer?.dashboardLink || contexts?.customer?.dashboardLink;
    const canShowDashboard = useVerifyActions({
      actions: [Actions.ViewDashboard],
      contexts,
      profile,
    });

    if (!dashboardLink || !canShowDashboard) return;

    return (
      <Flex onClick={() => navigate(routes.Dashboard.root)}>
        <NavMenuItem isActive={activeMenu === "dashboard"} menuItems={[]}>
          <Icon as={SvgAnalytics} />
          <Text>Dashboard</Text>
        </NavMenuItem>
      </Flex>
    );
  }, [activeMenu, userActions, contexts, user, navigate]);

  return (
    <Flex as="nav" gap="4">
      {searchMenus}
      {orderMenus}
      {expenseMenus}
      {settingsMenus}
      {dashboardMenus}
    </Flex>
  );
};

NavMenu.displayName = "NavMenu";
