import { useQuery } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import { pendingOrderService } from "~/application/usecases";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants/queryKeys";
import { QueryTimes } from "~/constants/queryTimes";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { log } from "~/utils/log";
import { IPendingOrdersFilters, PendingOrdersContainer } from "./PendingOrdersContainer";
import { usePendingOrdersTab } from "./hooks/usePendingOrdersTab";
import { useDebounce } from "use-debounce";
import { PendingOrder } from "~/application/types";
import { filterOrders } from "./utils";

type ISelectOption = {
  value: string;
  label: string;
};

const LOG_TAG = "Order/PendingOrdersPage";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao listar pedidos pendentes",
} as const;

const EMPTY_PENDING_ORDERS_FILTERS: IPendingOrdersFilters = {
  consultants: [],
  customers: [],
  status: [],
  travelers: [],
  issuers: [],
  orderItem: null,
  orderNumber: "",
};

export function PendingOrdersPage() {
  const { user } = useUser();
  const tabValue = usePendingOrdersTab();

  const [selectedFilters, setSelectedFilters] = useState(EMPTY_PENDING_ORDERS_FILTERS);

  const [selected] = useDebounce(selectedFilters, 700);

  const { data, isFetching } = useQuery(
    [QueryKeys.AGENCY_PENDING_ORDERS, user.agency.uuid],
    () => {
      return pendingOrderService.find({});
    },
    {
      staleTime: QueryTimes.NORMAL,
      refetchOnWindowFocus: true,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE, "error");
      },
    }
  );
  
  const filteredData = useMemo(() => filterOrders(data?.orders, selected), [data, selected])

 
  const availableFilters = useMemo<IPendingOrdersFilters>(
    () => ({
      consultants:
        data?.filters.consultants.map((i) => ({
          label: i.name,
          value: i.uuid,
        })) || [],
      customers:
        data?.filters.customers.map((i) => ({
          label: i.tradingName,
          value: i.uuid,
        })) || [],
      status:
        data?.filters.status.map((i) => ({
          label: {
            approved: "Pendente de emissão",
            quoting: "Em cotação",
            canceling: "Cancelando",
            changing: "Emitido",
          }[i],
          value: i,
        })) || [],
      travelers:
        data?.filters.travelers.map((i) => ({
          label: i.fullName,
          value: i.uuid,
        })) || [],
      orderNumber: data?.filters.orderNumber || "",
      orderItem: data?.filters.orderItem || { label: "", value: "" },
      issuers:
        data?.filters.issuers.map((i) => ({
          label: i.name,
          value: i.uuid,
        })) || [],
    }),
    [data?.filters]
  );

  const handleFilterCustomer = useCallback((data: ISelectOption[]) => {
    setSelectedFilters((old) => ({ ...old, customers: data }));
  }, []);

  const handleFilterTravelers = useCallback((data: ISelectOption[]) => {
    setSelectedFilters((old) => ({ ...old, travelers: data }));
  }, []);

  const handleFilterConsultant = useCallback((data: ISelectOption[]) => {
    setSelectedFilters((old) => ({ ...old, consultants: data }));
  }, []);

  const handleFilterOrderItem = useCallback((item: ISelectOption) => {
    setSelectedFilters((old) => ({ ...old, orderItem: item }));
  }, []);

  const handleFilterStatus = useCallback((data: ISelectOption[]) => {
    setSelectedFilters((old) => ({ ...old, status: data }));
  }, []);

  const handleOrderNumber = useCallback((search: string) => {
    setSelectedFilters((old) => ({ ...old, orderNumber: search }));
  }, []);

  const handleIssuer = useCallback((data: ISelectOption[]) => {
    setSelectedFilters((old) => ({ ...old, issuers: data }));
  }, []);

  const handleCleanFilters = useCallback(() => {
    setSelectedFilters(EMPTY_PENDING_ORDERS_FILTERS);
  }, []);

  return (
    <PendingOrdersContainer
      isLoading={isFetching}
      pendingOrdersList={filteredData}
      activeTab={tabValue}
      filters={availableFilters}
      selectedFilters={selectedFilters}
      onFilterCustomer={handleFilterCustomer}
      onFilterTravelers={handleFilterTravelers}
      onFilterConsultant={handleFilterConsultant}
      onFilterOrderItem={handleFilterOrderItem}
      onFilterStatus={handleFilterStatus}
      onCleanFilters={handleCleanFilters}
      onOrderNumber={handleOrderNumber}
      onIssuer={handleIssuer}
    />
  );
}
