import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import { Traveler } from "~/application/types";
import {
  customerEmployeeService,
  travelerService,
} from "~/application/usecases";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Flex } from "~/components/Flex";
import { Col, Grid } from "~/components/Grid";
import { Text } from "~/components/Text";
import { H4 } from "~/components/Typography";
import {
  FormControl,
  FormControlLabel,
} from "~/core/shared/components/FormControl";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { AsyncSelect } from "~/presentation/shared/components/AsyncSelect";
import { useCustomerEmployeeDialog } from "~/presentation/shared/hooks/useCustomerEmployeeDialog";
import { useBooking } from "../../../../Booking/contexts/BookingContext";
import { Person } from "../../../types";
import { VehicleBookingCart } from "../components/VehicleBookingCart";
import { VehicleBookingContext } from "../contexts/VehicleBookingContext";

type VehicleTravelerSectionProps = {
  cartIsOpen: boolean;
  setNextStep: VoidFunction;
  currentActiveStep?: string;
  setCartIsOpen: Dispatch<SetStateAction<boolean>>;
};

export function VehicleTravelerSection({
  setNextStep,
  cartIsOpen,
  setCartIsOpen,
  currentActiveStep,
}: VehicleTravelerSectionProps) {
  const { user, contexts } = useUser();
  const { order } = useBooking();
  const { actions, bookingInfo } = useContext(VehicleBookingContext);

  const personsCache = useRef<Person[]>();

  const handleSelectPerson = useCallback(
    (person: Traveler) => {
      actions.setTraveler(person);
    },
    [actions, bookingInfo.traveler]
  );

  useEffect(() => {
    if (cartIsOpen) {
      setCartIsOpen(false);
    }
  }, []);

  useEffect(() => {
    if (bookingInfo.traveler) {
      setCartIsOpen(true);
    }
  }, [bookingInfo.traveler]);

  const searchPerson = useCallback(
    async (searchString: string) => {
      searchString = searchString.toLowerCase();

      if (!order) {
        return await customerEmployeeService
          .find({
            customerId: user?.customer?.uuid || contexts?.customer?.uuid,
            page: 1,
          })
          .then((data) => {
            personsCache.current = data.data;
            return personsCache.current.filter(
              (p) =>
                p.name.toLowerCase().includes(searchString) ||
                p.description?.toLowerCase().includes(searchString)
            );
          });
      }

      return order.travelers
        .filter((p) => p.fullName.toLowerCase().includes(searchString))
        .map((p) => ({
          uuid: p.uuid,
          name: p.fullName,
          description: `${p.cpf} (${p.email})`,
        }));
    },
    [personsCache.current]
  );

  useEffect(() => {
    searchPerson("");
  }, []);

  const getTravelerLabel = useCallback((item: Traveler) => item.fullName, []);

  const getTravelerValue = useCallback((item: Traveler) => item.uuid, []);

  const fetchTravelers = useCallback(
    async (name: string) => {
      return (
        order?.travelers ??
        travelerService.find(user?.customer || contexts?.customer, {
          name,
        })
      );
    },
    [order, user]
  );

  const { handleOpenCreateModal } = useCustomerEmployeeDialog({});

  return (
    <Grid
      gap="6"
      css={{
        gridTemplateColumns: "2fr 1fr",
        "@mxlg": {
          gridTemplateColumns: "1fr",
        },
      }}
    >
      <Box>
        <Flex
          align="center"
          direction={{ "@mxlg": "column" }}
          css={{ mb: "$10" }}
        >
          <Col
            css={{
              "@mxlg": {
                mb: "$5",
              },
            }}
          >
            <H4>Condutor</H4>
          </Col>

          <Button
            css={{
              "@mxlg": {
                width: "100%",
              },
            }}
            onClick={handleOpenCreateModal}
            variant="secondary"
          >
            <Text>Novo funcionário</Text>
          </Button>
        </Flex>

        <Flex direction="column" gap="8">
          <FormControl>
            <FormControlLabel>Funcionário</FormControlLabel>

            <AsyncSelect
              placeholder="Selecione o condutor"
              fetchOptions={fetchTravelers}
              defaultValue={bookingInfo.traveler}
              defaultOptions={order?.travelers}
              getOptionValue={getTravelerValue}
              getOptionLabel={getTravelerLabel}
              onChange={handleSelectPerson}
            />
          </FormControl>
        </Flex>
      </Box>
      <Box
        css={{
          "@mxlg": {
            width: "0",
          },
        }}
      >
        <Box
          css={{
            top: "116px",
            "@mxlg": {
              top: "0",
            },
          }}
        >
          <VehicleBookingCart
            setNextStep={setNextStep}
            cartIsOpen={cartIsOpen}
            setCarIsOpen={setCartIsOpen}
            currentActiveStep={currentActiveStep}
            bookingInfo={bookingInfo}
            onRemoveVehicle={() => actions.setVehicle(null as any)}
            vehicleEditable
          />
        </Box>
      </Box>
    </Grid>
  );
}
