import { useQueries, useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { OrderItems, PolicyParamsSlug, type Hotel, type HotelQuery } from "~/application/types";
import { hotelDetailsService, hotelQueryService, policyService } from "~/application/usecases";
import { dialogService } from "~/components/DialogStack";
import { QueryKeys } from "~/constants/queryKeys";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { useFilters } from "../../../../hooks/useFilters";
import { MapViewDialog } from "../../components/MapViewDialog";
import { useBookingHotel } from "../../contexts/BookingHotelContext";
import { HotelsContainer } from "./HotelsContainer";
import { STATIC_HOTEL_FILTERS } from "./utils";
import { useHotelBudget } from "../HotelDetailsPage/hooks/useHotelBudget/useHotelBudget";
import { usePoliciesFormats } from "~/presentation/shared/hooks/usePoliciesFormats";
import { compressString } from "~/presentation/shared/utils/string-functions/functions";
import { useBooking } from "~/presentation/Booking/contexts/BookingContext";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG_TAG = "Booking/BookingHotel/HotelsPage";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SNACKBAR_MESSAGES = {} as const;

export function HotelsPage() {
  const { hotelQuery, hotelReducer, isLoadingLinks, hotelQueryLinks, onSearchHotels, order } =
    useBookingHotel();

  const { contexts, user } = useUser();

  const [searchText, setSearchText] = useState<string>("");

  const [search] = useDebounce<string>(searchText, 700);

  const [rangeValeu, setRangeValue] = useState({
    min: 0,
    max: 0,
    providersFinished: 0,
  });
  const [range] = useDebounce<{
    min: number;
    max: number;
    providersFinished: number;
  }>(rangeValeu, 600);

  const navigate = useNavigate();

  const { search: locationSearch } = useLocation();
  
  const hotelQueries = useQueries({
    queries:
      hotelQueryLinks?.links.map((link) => ({
        queryKey: [QueryKeys.HOTEL, link],
        retry: 20,
        retryDelay: hotelQueryLinks.waitTime,
        enabled: !isLoadingLinks,
        queryFn: () => hotelQueryService.find(link),
      })) ?? [],
  });

  const hotelsList = useMemo(() => {
    return hotelQueries
      .reduce<Hotel[]>((p, c) => [...p, ...(c.data ?? [])], [])
      .sort((a, b) => (a?.bestValueTotal || 0) - (b?.bestValueTotal || 0))
  }, [hotelQueries]);

  const isLoadingHotels = useMemo(() => hotelQueries.some((q) => q.isFetching), [hotelQueries]);

  const countProvidersFinished = useMemo(
    () => hotelQueries.reduce((ac, q) => (!q.isFetching ? ac + 1 : ac), 0),
    [hotelQueries]
  );
  const customerId = user?.customer?.uuid || contexts?.customer?.uuid 

  const isSomeSettled = useMemo(() => hotelQueries.some((q) => !q.isFetching), [hotelQueries]);

  const hotels = usePoliciesFormats({customerId, data: hotelsList, itemType: OrderItems.HOTEL })

  const { filteredData, filters, onFilterChange, onClearFilters } = useFilters({
    data: hotels as Hotel[],
    filters: STATIC_HOTEL_FILTERS,
  });

  
  const searchedData = useMemo(() => {
    return filteredData.filter((item) => {
      return new RegExp(search, "i").test(item?.name || "");
    });
  }, [filteredData, search]);

  useEffect(() => {
    if (countProvidersFinished !== rangeValeu.providersFinished) {
      setRangeValue({
        min: 0,
        max: 0,
        providersFinished: countProvidersFinished,
      });
    }
  }, [countProvidersFinished, rangeValeu]);

  useEffect(() => {
    if (searchedData.length && countProvidersFinished !== rangeValeu.providersFinished) {
      setRangeValue({
        min: searchedData[0]?.bestValueTotal || 0,
        max: searchedData[searchedData.length - 1]?.bestValueTotal || 0,
        providersFinished: countProvidersFinished,
      });
    }
  }, [searchedData, rangeValeu, countProvidersFinished]);

  const filtredValeuData = useMemo(() => {
    return searchedData.filter((hotel) => {
      return (
        (hotel?.bestValueTotal || 0) >= (range.min || 0) &&
        (hotel?.bestValueTotal || 0) <= (range.max || 0)
      );
    });
  }, [range, searchedData]);

  const handleSearchHotels = useCallback(
    (data: HotelQuery) => {
      hotelReducer.dispatch({
        type: "RESET_BOOKING",
      });

      onClearFilters();

      onSearchHotels(data);
    },
    [hotelReducer, onClearFilters, onSearchHotels]
  );

  const minPriceRoomAll = (hotelsList|| []).sort((a, b) => (a?.bestRoomValue || 0) - (b?.bestRoomValue || 0))?.at(0)

  const handleSelectHotel = useCallback(
    (hotel: Hotel) => {
      hotelReducer.dispatch({
        type: "SET_HOTEL",
        payload: hotel,
      });
      const customerId = contexts.customer?.uuid;
      const url = `/busca/hospedagens/detalhes${locationSearch}&customerId=${customerId}&hotel=${compressString(JSON.stringify(
        hotel,
      ))}&policy=${minPriceRoomAll?.bestRoomValue}`;

      window.open(url, "_blank", "noopener,noreferrer");
    },
    [navigate, hotelReducer, user, contexts, minPriceRoomAll]
  );

  const handleMapView = useCallback(
    ({
      onSelect,
      data,
      description,
    }: {
      description?: string;
      data?: Hotel;
      onSelect: (data: Hotel) => void;
    }) => {
      dialogService.showDialog(
        <MapViewDialog
          height={600}
          hotelsList={filtredValeuData}
          data={data}
          onSelect={onSelect}
          description={description}
        />
      );
    },
    [filtredValeuData]
  );

  const hotelBudget = useHotelBudget();

  return (
    <HotelsContainer
      filters={filters}
      data={filtredValeuData}
      dataDefault={searchedData}
      hotelBudget={hotelBudget}
      onRangeValue={setRangeValue}
      countProvidersFinished={countProvidersFinished}
      rangeValue={rangeValeu}
      hotelQuery={hotelQuery!}
      isLoading={isLoadingHotels}
      isSomeSettled={isSomeSettled}
      searchText={searchText}
      setSearchText={setSearchText}
      onFilterChange={onFilterChange}
      onMapView={handleMapView}
      onSelect={handleSelectHotel}
      onSearchHotels={handleSearchHotels}
      order={order}
    />
  );
}
