import { Flex } from "~/components/Flex";
import { Form } from "~/components/Form";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { useVerifyParameter } from "../../hooks/useVerifyParameter";
import { SettingParameterSlug } from "~/application/types/entities/SettingParameter.type";
import { useReasonTrips } from "~/core/modules/Customer/pages/CustomerPage/hooks/useReasonTrips";
import { RoadQueryForm, RoadQueryFormData, TravelRoadQueryForm } from "../RoadQueryForm";
import { useFieldArray, useForm } from "react-hook-form";
import { AsyncSelect } from "../AsyncSelect";
import { FieldLabel, FormControl as NewFormControl } from "~/components/FormControl";
import { useCallback, useEffect, useState } from "react";
import { useRoadBudgetResult } from "~/presentation/Booking/BookingRoad/pages/RoadsPage/hooks/useRoadBudget/type";
import { Actions, Order } from "~/application/types";
import { AirwayBudgetDispatchType } from "~/presentation/Booking/BookingAirway/pages/FlightsPage/hooks/useAirwayBudget/type";
import { useFlightsPage } from "~/presentation/Booking/BookingAirway/pages/FlightsPage/contexts/FlightsPageContext";
import { SvgPlus, SvgSearch } from "~/components/Icon/icons";
import { Button } from "~/components/Button";
import { Caption } from "~/components/Typography";
import { Switch } from "~/components/Input";
import { Icon } from "~/components/Icon";
import { Text } from "~/components/Text";
import { useVerifyActions } from "../../hooks/useVerifyActions";
import useMobile from "../../hooks/useMobile";

export function RoadQueryContainer({
  defaultData,
  onSubmit,
  order,
}: {
  defaultData?: Partial<RoadQueryFormData>;
  onSubmit: (data: RoadQueryFormData) => void;
  roadBudget?: useRoadBudgetResult;
  order?: Order;
}) {
  const [isManyStretch, onIsManyStretch] = useState((defaultData?.stretches?.length || 0) > 1);
  const isMobile = useMobile();

  const formState = useForm<RoadQueryFormData>({
    defaultValues: defaultData,
  });

  const { setValue, watch, control, handleSubmit, resetField, getValues } = formState;

  const { fields, append, remove, replace } = useFieldArray({
    name: "stretches",
    control,
  });

  useEffect(() => {
    if (!isManyStretch && fields.length > 1) {
      replace([{} as TravelRoadQueryForm]);
    }
  }, [isManyStretch, remove, fields, resetField]);

  const data = watch();

  const { airwayBudget } = useFlightsPage();

  const { contexts, user, profile } = useUser();

  const currentReasonTrip = order?.reasonTrip;

  useEffect(() => {
    if (currentReasonTrip) {
      setValue("reasonTrip", currentReasonTrip);
    }
  }, [currentReasonTrip]);

  const customerId = user?.customer?.uuid || contexts?.customer?.uuid;

  const canShowReasonTrip =
    useVerifyParameter({
      customer: contexts?.customer,
      parameter: SettingParameterSlug.POLICY_BY_REASON_TRIP,
    }) &&
    useVerifyActions({ actions: [Actions.CreateOrder, Actions.ViewReasonTrip], contexts, profile });

  const canCreateOrder = useVerifyActions({ actions: [Actions.CreateOrder], contexts, profile });

  const { data: reasonTrips, fetchReasonTrip } = useReasonTrips({
    customerId,
    enabled: canShowReasonTrip,
  });

  function onFormSubmit(data: RoadQueryFormData) {
    if (onSubmit) {
      onSubmit(data);
    }
  }

  const handleAddField = useCallback(() => {
    const lastDestination = getValues(`stretches.${fields.length - 1}.destinationSelected`);
    append({ originSelected: lastDestination } as TravelRoadQueryForm);
  }, [getValues, fields]);

  return (
    <Form onSubmit={handleSubmit(onFormSubmit)}>
      <Flex
        direction="column"
        gap={{ "@initial": "4", "@mxlg": "6" }}
        css={{
          p: "$6",
          "@mxlg": {
            width: "80%",
            margin: "$15 auto 0",
            px: "0",
          }
        }}
      >

        {isMobile && (
          <Flex css={{ flex: "1" }} justify="end">
            <Switch checked={isManyStretch} onClick={() => onIsManyStretch((old) => !old)}>
              <Caption css={{ "@mxlg": { color: "#fff" }}}>Vários trechos</Caption>
            </Switch>
          </Flex>
        )}

        {canShowReasonTrip && (
          <Flex direction={{ "@mxlg": "column" }}>
            <NewFormControl name="reasonTrip" control={control} required>
              <FieldLabel css={{ "@mxlg": { color: "White" } }}>Motivo de viagem</FieldLabel>
              <AsyncSelect
                placeholder="Digite o motivo da viagem"
                defaultOptions={reasonTrips?.filter((reason) => reason.isActive)}
                defaultValue={data?.reasonTrip || currentReasonTrip}
                disabled={!!currentReasonTrip?.uuid}
                fetchOptions={fetchReasonTrip}
                getOptionLabel={(reason) => reason.reason}
                getOptionValue={(reason) => reason.uuid}
              />
            </NewFormControl>
          </Flex>
        )}
        
        <Flex gap="6" direction="column">
          {fields.map((field, index) => (
            <RoadQueryForm
              formState={formState}
              index={index}
              key={field.id}
              isManyStretch={isManyStretch}
              onRemoveField={() => remove(index)}
            />
          ))}
        </Flex>

        <Flex justify={{ "@initial": "between", "@mxlg": "center" }}>
          {!isMobile && (
            <Flex  css={{ flex: "1" }} justify="start">
              <Switch checked={isManyStretch} onClick={() => onIsManyStretch((old) => !old)}>
                <Caption css={{ "@mxlg": { color: "#fff" }}}>Vários trechos</Caption>
              </Switch>
            </Flex>
          )}

          {isManyStretch && canCreateOrder && (
            <Flex 
              direction={{ "@mxlg": "column" }}
              align="center" 
              gap={{ "@initial": "2", "@mxlg": "4" }} 
            >
              <Button 
                css={{ 
                  "@mxlg": {
                    width: "$50",
                    height: "$11",
                    backgroundColor: "#F0F2F5",
                    color: "$primary-base",
                  },
                }} 
                disabled={!(fields.length < 4)} 
                onClick={handleAddField}
              >
                <Icon as={SvgPlus} variant={{ "@initial": "white", "@mxlg": "primary" }} /> 
                Adicionar trecho
              </Button>

              <Button
                size="sm"
                type="submit"
                css={{
                  "@mxlg": {
                    width: "$50",
                    height: "$11",
                    backgroundColor: "#F0F2F5",
                    color: "$primary-base",
                  },
                }}
                onClick={() => airwayBudget?.dispatch?.({ type: AirwayBudgetDispatchType.CLEAR })}
              >
                <Icon as={SvgSearch} variant={{ "@mxlg": "primary" }} />
                <Text
                  css={{
                    "@lg": {
                      display: "none",
                    },
                  }}
                >
                  Buscar
                </Text>
              </Button>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Form>
  );
}
