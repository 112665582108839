import { Fragment } from "react";
import { CustomerEmployee, Traveler } from "~/application/types";
import { Container } from "~/components/Container";
import { Stepper, StepperItem } from "~/components/Stepper";
import { TabContent, Tabs } from "~/components/Tabs";
import { Text } from "~/components/Text";
import { SelectIssuer } from "~/presentation/shared/components/SelectIssuerModal";
import { BOOKING_MENU_STEPS, BookingHotelSteps } from "./utils";
import {
  TabSelectGuests,
  TabSelectGuestsProps,
} from "./views/SelectGuests/TabSelectGuests";
import {
  TabSelectRooms,
  TabSelectRoomsProps,
} from "./views/SelectRooms/TabSelectRooms";
import { Card } from "~/components/Card";
import { Box } from "~/components/Box";
import { SvgArrowBack } from "~/components/Icon/icons";
import { Icon } from "~/components/Icon";
import { Flex } from "~/components/Flex";
import { useBookingHotel } from "../../contexts/BookingHotelContext";
import { useNavigate } from "react-router-dom";
import { Dispatch, SetStateAction } from "react";

export interface HotelDetailsContainerProps {
  activeStep: BookingHotelSteps;
  selectRoomsState: TabSelectRoomsProps;
  selectGuestsState: TabSelectGuestsProps;
  canSelectIssuer?: boolean;
  fetchTravelers: (name: string) => Promise<Traveler[]>;
  onSetActiveStep: (item: BookingHotelSteps) => void;
  onSelectIssuer: (issuer: CustomerEmployee) => void;
}

export function HotelDetailsContainer({
  activeStep,
  selectRoomsState,
  selectGuestsState,
  canSelectIssuer,
  onSelectIssuer,
  fetchTravelers,
  onSetActiveStep,
}: HotelDetailsContainerProps) {
  const { onSearchHotels } = useBookingHotel();
  const navigate = useNavigate();

  const goBack = () => {
    if (activeStep === "select-guests")
      return onSetActiveStep(BookingHotelSteps.SELECT_ROOMS);
    if (activeStep === "select-rooms")
      return onSearchHotels(selectRoomsState.hotelQuery);
    else return navigate("/");
  };

  const headerTitle =
    activeStep === "select-rooms" ? "Escolha o quarto" : "Hóspedes";

  return (
    <Fragment>
      <Flex
        justify={{ "@mxlg": "around" }}
        align={{ "@mxlg": "center" }}
        css={{
          display: "none",
          "@mxlg": {
            backgroundColor: "#0064C5",
            height: "$18",
            color: "#FFF",
            display: "flex",
          },
        }}
      >
        <Box>
          <Icon onClick={() => goBack()} fill="#FFF" as={SvgArrowBack} />
        </Box>
        <Text>{headerTitle}</Text>
        <Box></Box>
      </Flex>
      <Container
        css={{
          py: "$10",
          "@mxlg": {
            p: "$5",
          },
        }}
      >
        <Stepper
          activeValue={activeStep}
          setActiveValue={onSetActiveStep}
          css={{
            mb: "$6",
            "@mxlg": {
              display: "none",
            },
          }}
        >
          {BOOKING_MENU_STEPS.map((item) => (
            <StepperItem value={item.id} key={item.id}>
              <Text>{item.title}</Text>
            </StepperItem>
          ))}
        </Stepper>

        <Tabs value={activeStep}>
          <TabContent value={BookingHotelSteps.SELECT_ROOMS}>
            <TabSelectRooms {...selectRoomsState} activeStep={activeStep} />
          </TabContent>

          <TabContent value={BookingHotelSteps.SELECT_GUESTS}>
            <TabSelectGuests {...selectGuestsState} activeStep={activeStep} />

            {canSelectIssuer && (
              <SelectIssuer
                fetchTravelers={fetchTravelers}
                css={{ marginTop: "-6rem", width: "66%" }}
                onChange={onSelectIssuer}
              />
            )}
          </TabContent>
        </Tabs>
      </Container>
    </Fragment>
  );
}
