import { Fee } from "~/application/types";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgEdit } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Switch } from "~/components/Input";
import {
  DataColItem,
  DataListItem,
  DataListItemProps,
} from "~/components/List/DataListItem";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { Caption } from "~/components/Typography";
import * as OrderUtils from "~/core/shared/utils/order.utils";
import * as MaskUtils from "~/utils/mask.utils";

export interface FeeListItemProps extends DataListItemProps {
  data: Fee;
  onEditClick: (item: Fee) => void;
  onToggleState: (item: Fee) => void;
}

export function FeeListItem({
  data,
  onEditClick,
  onToggleState,
  ...props
}: FeeListItemProps) {
  return (
    <DataListItem data-active={data.isActive} size="sm" {...props}>
      <DataColItem headerTitle="Nome" data={data.name} />
      <DataColItem
        headerTitle="Serviços"
        data={
          <Flex gap="2">
            {data.items.map((item) => (
              <Flex align="center" gap="2" key={item.uuid}>
                <IconButton size="md">
                  <Icon as={OrderUtils.getServiceIcon(item.serviceType)} />
                </IconButton>
                <Text css={{ fw: "600" }}>
                  {item.type === "fixed"
                    ? MaskUtils.asCurrency(item.value)
                    : `${item.value}%`}
                </Text>
              </Flex>
            ))}
          </Flex>
        }
      />
      <Tooltip content={<TooltipLabel>Editar taxa</TooltipLabel>}>
        <IconButton size="md" onClick={() => onEditClick(data)}>
          <Icon as={SvgEdit} />
        </IconButton>
      </Tooltip>
      <Switch checked={data.isActive} onClick={() => onToggleState(data)}>
        <Caption>{data.isActive ? "Ativo" : "Inativo"}</Caption>
      </Switch>
    </DataListItem>
  );
}

FeeListItem.displayName = "FeeListItem";
