import { FC, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "~/application/theme/routes";
import { path } from "~/application/utils/navigator-functions";
import { dialogService } from "~/components/DialogStack";
import { LoadingDialog } from "~/core/shared/components/LoadingDialog";
import { useRemakeSearch } from "~/core/shared/contexts/remakeSearchContext";
import { useCreateRoadBooking } from "~/presentation/Booking/BookingRoad/hooks/useCreateRoadBooking";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { BookingRoadSteps } from "../../../constants";
import { useRoadsPage } from "../../../contexts/RoadsPageContext";
import { RoadCartUI } from "./RoadCartUI";
import { calculateRoadBookingCartPrice } from "./utils";
import { PolicyParamsSlug, Road, RoadQuery, RoadSeat } from "~/application/types";

export const RoadCartSection: FC = () => {
  const navigate = useNavigate();

  const { contexts } = useUser();
  const customerId = contexts.customer?.uuid;

  const { roadReducer, bookingStep, roadQuery, isOneWay, deleteOrderRoad } = useRoadsPage();
  const { bookingInfo } = roadReducer;
  const {
    data: remakeSearchData,
    setState: setRemakeSearchData,
    currentStep: currentStepRemakeSearchData,
  } = useRemakeSearch();
  const roadTravelersQuantity = bookingInfo.travelers.length;

  const isLastStep = bookingStep.currentIndex === bookingStep.steps.length - 1;

  const lowerFareViolated = {
    departure: Boolean(
      bookingInfo.goRoadSelected?.road.violatedPolicies?.some(
        (policy) => policy.policyType === PolicyParamsSlug.LOWER_FARE
      )
    ),
    arrival: Boolean(
      bookingInfo.returnRoadSelected?.road.violatedPolicies?.some(
        (policy) => policy.policyType === PolicyParamsSlug.LOWER_FARE
      )
    ),
  };
  
  const { create: createRoadBooking, isCreating: isCreateRoadBooking } = useCreateRoadBooking({
    customerId,
    bookingInfo,
    lowerFareViolated,
    roadQuery: roadQuery?.data as RoadQuery,
  });

  const isProceedDisabled = useMemo(() => {
    if (isCreateRoadBooking) return true;

    const currentStep = bookingStep.currentIndex;

    switch (bookingStep.current) {
      case BookingRoadSteps.SELECT_GO_TICKET:
        return !bookingInfo.goRoadSelected;
      case BookingRoadSteps.SELECT_RETURN_TICKET:
        return !isOneWay && !bookingInfo.returnRoadSelected;
      case `${currentStep}`:
        return !bookingInfo.stretches.at(currentStep);
      case BookingRoadSteps.SELECT_TRAVELERS: {
        if (!bookingInfo.issuerId) {
          return true;
        }
        
        const noRepeatTravelers = bookingInfo.travelers.every((traveler, index) => {
          const isTravelerRepeated = bookingInfo.travelers.some(
            (t, i) => t?.uuid === traveler?.uuid && i !== index
          );
          return !isTravelerRepeated;
        });

        return (
          !noRepeatTravelers ||
          bookingInfo.travelers.length === 0 ||
          bookingInfo.travelers.length !== bookingInfo.seatsQuantity ||
          !bookingInfo.travelers.every(Boolean)
        );
      }
      default:
        return false;
    }
  }, [bookingInfo, isOneWay, bookingStep, isCreateRoadBooking]);

  const { individualValue, feesValue, totalValue, totalTaxesValue } = useMemo(() => {
    const goRoadSelected = bookingInfo.goRoadSelected?.road ?? null;

    const returnRoadSelected = bookingInfo.returnRoadSelected?.road ?? null;

    return calculateRoadBookingCartPrice(
      goRoadSelected,
      returnRoadSelected,
      bookingInfo.seatsQuantity,
      bookingInfo.stretches
    );
  }, [bookingInfo, roadQuery]);

  const isConfirmationEnabled = useMemo(
    () => bookingStep.current === BookingRoadSteps.CONFIRMATION,
    [bookingStep.current]
  );

  const onNextStep = useCallback(async () => {
    if (isConfirmationEnabled) return;
    if (bookingStep.currentIndex === 0 && !remakeSearchData) {
      roadReducer.dispatch({ type: "SET_TRAVELERS", payload: [] });
    }
    bookingStep.setStep(1 + bookingStep.currentIndex);
  }, [bookingStep, isConfirmationEnabled]);

  const onCreateRoadBooking = useCallback(async () => {
    if (!bookingInfo.goRoadSelected && !bookingInfo.stretches.length) return;

    dialogService.showDialog(
      <LoadingDialog message="Efetuando sua reserva, isto pode demorar um pouco" />
    );

    const orderId = await createRoadBooking({
      goRoad: {
        road: bookingInfo.goRoadSelected?.road as Road,
        seats: bookingInfo.goRoadSelected?.seats as RoadSeat[],
        seatsConnection: bookingInfo.goRoadSelected?.seatsConnection as RoadSeat[],
      },
      returnRoad: bookingInfo.returnRoadSelected && {
        road: bookingInfo.returnRoadSelected.road,
        seats: bookingInfo.returnRoadSelected.seats,
        seatsConnection: bookingInfo.returnRoadSelected.seatsConnection,
      },
      travelers: bookingInfo.travelers,
      lowerFareViolated,
      stretches: bookingInfo.stretches,
    });

    if (!orderId) return;

    dialogService.popAll();

    if (remakeSearchData.road) {
      const oldId = remakeSearchData.road[currentStepRemakeSearchData].roadOrderUuid;

      deleteOrderRoad({ itemId: oldId });
      setRemakeSearchData({
        currentStep: currentStepRemakeSearchData + 1,
        orderId,
      });

      if (currentStepRemakeSearchData + 1 !== remakeSearchData.road.length) {
        bookingStep.setStep(0);
        roadReducer.dispatch({ type: "CLEAR" });
        return;
      }
    }
    roadReducer.dispatch({ type: "SET_TRAVELERS", payload: [] });
    roadReducer.dispatch({ type: "CLEAR" });

    setRemakeSearchData({
      currentStep: 0,
      data: {},
      finishStep: {
        road: true,
      },
      orderId,
    });
    navigate(path(routes.Orders.Order.root, { orderId }));
  }, [
    bookingInfo,
    createRoadBooking,
    remakeSearchData,
    currentStepRemakeSearchData,
    setRemakeSearchData,
    navigate,
    lowerFareViolated,
  ]);

  return (
    <RoadCartUI
      totalValue={totalValue}
      individualValue={individualValue}
      feesValue={feesValue}
      isLastStep={isLastStep}
      totalTaxesValue={totalTaxesValue}
      seatsQuantity={bookingInfo.seatsQuantity}
      roadTravelersQuantity={roadTravelersQuantity}
      isProceedDisabled={isProceedDisabled}
      isConfirmationEnabled={isConfirmationEnabled}
      goRoad={bookingInfo.goRoadSelected?.road}
      returnRoad={bookingInfo.returnRoadSelected?.road}
      stretches={bookingInfo.stretches}
      onNextStep={onNextStep}
      onCreateRoadBooking={onCreateRoadBooking}
    />
  );
};
