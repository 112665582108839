import type { CostCenter } from "~/application/types";

export type EditableCostCenter = CostCenter;

const DEFAULT_FORM_DATA = {
  name: "",
  costCenterCode: "",
} as EditableCostCenter;

export function createCostCenter(editable: EditableCostCenter): CostCenter {
  return {
    ...editable,
  };
}

export function editCostCenter(data?: CostCenter): EditableCostCenter {
  if (!data) return DEFAULT_FORM_DATA;

  return {
    ...data,
  };
}
