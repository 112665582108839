import { Card } from "~/components/Card";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Flex } from "~/components/Flex";
import { FormDialog } from "~/components/FormDialog";
import { Text } from "~/components/Text";
import { decodeBase64 } from "~/core/modules/Customer/pages/CustomerPage/views/Crm/utils";
import { getServiceLabel } from "~/core/shared/utils/order.utils";

export interface AdditionalInfoProps {
  label: string;
  obs: string
}

export function AdditionalInfo({
  label,
  obs,
}: AdditionalInfoProps) {
  const htmlText = decodeBase64(obs).replaceAll('<p>', '').replace('</p>', '');

  return (
    <Container size="8" fixed>
    <FormDialog
      title={`Obeservações ${getServiceLabel(label)}`}
    >
      <DialogBody css={{ p: "$6", background: "$neutrals-lightest" }}>
        <Card css={{ p: "$5" }}>
          <Text css={{ mb: "$5" }}>Observações</Text>
          <Card css={{ p: "$5", height: "$64" }}>
            <div dangerouslySetInnerHTML={{ __html: htmlText }} style={{ wordBreak: "break-all" }} />
          </Card>
        </Card>
      </DialogBody>
    </FormDialog>
  </Container>
  );
}

AdditionalInfo.displayName = "AdditionalInfo";
