import { Fragment, useCallback, useMemo } from "react";
import {
  Order,
  OrderAirwayItemType,
  OrderHotelItem,
  OrderHotelItemType,
  OrderHotelOfflineOption,
  OrderRoadItem as OrderItemRoad,
  OrderItemStatus,
  OrderItems,
  OrderStatus,
  OrderVehicleItem as OrderVehicleType,
  OrderVehicleItemType,
  OrderItemsWithChangedPrice,
} from "~/application/types";
import { QuoteOfflineHotelProps } from "~/application/usecases/OfflineHotel";
import { Box } from "~/components/Box";
import { Container } from "~/components/Container";
import { Flex } from "~/components/Flex";
import { Col, Row } from "~/components/Grid";
import { Skeleton } from "~/components/Skeleton";
import { H4 } from "~/components/Typography";
import { OrderItemListItem } from "~/core/modules/Agency/pages/AgencyOrderPage/components/OrderItemListItem";
import { OrderAirwayItem } from "~/presentation/ManageOrder/pages/ManageOrderPage/components/OrderAirwayItem";
import { OrderHotelItem as OrderHotelItemComponent } from "~/presentation/ManageOrder/pages/ManageOrderPage/components/OrderHotelItem";
import { OrderRoadItem } from "~/presentation/ManageOrder/pages/ManageOrderPage/components/OrderRoadItem";
import { OrderSummaryCard } from "~/presentation/ManageOrder/pages/ManageOrderPage/components/OrderSummaryCard";
import { OrderVehicleItem } from "~/presentation/ManageOrder/pages/ManageOrderPage/components/OrderVehicleItem";
import { OrderItemsMenuItem } from "~/presentation/ManageOrder/pages/ManageOrderPage/types";

export interface TabOrderItemsProps {
  order?: Order;
  orderId: string;
  isLoading: boolean;
  canSelfAssign: boolean;
  isAirwayItemExpanded: boolean;
  isIssuingOrder: boolean;
  isHotelItemExpanded: boolean;
  isRoadItemExpanded: boolean;
  isVehicleItemExpanded: boolean;
  orderItems?: OrderItemsMenuItem[];
  onConsultOrderItemsPrice: (orderUuid?: string | undefined) => Promise<OrderItemsWithChangedPrice>;
  toggleVehicleItemVisible: () => void;
  toggleRoadItemVisible: () => void;
  toggleAirwayItemVisible: () => void;
  toggleHotelItemVisible: () => void;
  onCancelOrder: () => void;
  onIssueOrder: () => void;
  onCopyText: (text: string) => void;
  onAssignConsultant: () => void;
  onScrollIntoSection: (id: string) => void;
  onCheckUserMessage: (id: string) => boolean;
  onIssueAirway: () => void;
  onIssueRoad: (order: OrderItemRoad) => void;
  onIssueVehicle: (orderData: OrderVehicleType) => void;
  onIssueHotel: (orderData: OrderHotelItem) => void;
  onQuote: (data: QuoteOfflineHotelProps) => void;
  onSendOfflineQuote: (orderId: string) => void;
  onRejectCancelling: () => void;
  onManualCancelOrder: () => void;
  onDeleteOfflineQuote: (orderId: string) => void;
  onOpenHotelOptionDetails: (item: OrderHotelItem, option: OrderHotelOfflineOption) => void;
  onCancelItem: ({
    orderItemId,
    orderItemType,
  }: {
    orderItemId: string;
    orderItemType: OrderItems;
  }) => void;
}

export function TabOrderItems({
  order,
  orderId,
  isLoading,
  isIssuingOrder,
  isAirwayItemExpanded,
  isHotelItemExpanded,
  isRoadItemExpanded,
  isVehicleItemExpanded,
  toggleRoadItemVisible,
  toggleAirwayItemVisible,
  toggleHotelItemVisible,
  toggleVehicleItemVisible,
  onRejectCancelling,
  onCancelOrder,
  onIssueOrder,
  onIssueAirway,
  onIssueRoad,
  onIssueVehicle,
  onIssueHotel,
  onCopyText,
  onOpenHotelOptionDetails,
  onSendOfflineQuote,
  onDeleteOfflineQuote,
  onManualCancelOrder,
  onCancelItem,
  onQuote,
}: TabOrderItemsProps) {
  const orderStatus = order?.status as OrderStatus;

  const isQuotingStatus = orderStatus === OrderStatus.QUOTING;

  const isHotelOffline = order?.items.hotel?.rooms.some((room) => room.isOffline);

  const canSendQuotation = order?.items.hotel?.rooms?.every((room) => {
    const options = room?.options ?? [];

    return options.length > 0;
  });

  const everyItemIssuedOrCanceled = useMemo(() => {
    const everyRoomsIssuedOrCanceled = (order?.items.hotel?.rooms || []).every(
      (room) => [OrderItemStatus.DONE, OrderItemStatus.CANCELED].includes(room.status)
    )
    const everyRoadIssuedOrCanceled = (order?.items.road?.travels || []).every(
      (road) => [OrderItemStatus.DONE, OrderItemStatus.CANCELED].includes(road.status)
    )

    const everyVehicleIssuedOrCanceled = (order?.items.vehicle?.vehicles || []).every(
      (vehicle) => [OrderItemStatus.DONE, OrderItemStatus.CANCELED].includes(vehicle.status)
    )

    const everyAirwayIssuedOrCanceled = (order?.items.vehicle?.vehicles || []).every(
      (airway) => [OrderItemStatus.DONE, OrderItemStatus.CANCELED].includes(airway.status)
    )

    return everyRoomsIssuedOrCanceled && everyRoadIssuedOrCanceled && everyVehicleIssuedOrCanceled && everyAirwayIssuedOrCanceled

  }, [order]);
  

  const isIssued = orderStatus === OrderStatus.ISSUED || orderStatus === OrderStatus.EXPIRED_TIME;

  const renderAirwayItem = useCallback(
    () => (
      <OrderItemListItem
        isOpen={isAirwayItemExpanded}
        onItemExpand={toggleAirwayItemVisible}
        item={{
          type: OrderItems.AIRWAY,
        }}
        orderId={orderId}
        key={OrderItems.AIRWAY}
      >
        <Fragment key={OrderItems.AIRWAY}>
          <OrderAirwayItem
            order={order as Order}
            data={order?.items.airway as OrderAirwayItemType}
            isLoading={isLoading}
            onCopyText={onCopyText}
            onCancelItem={onCancelItem}
            onIssueOrder={onIssueOrder}
            onIssueAirway={onIssueAirway}
          />
        </Fragment>
      </OrderItemListItem>
    ),
    [order, isLoading, onCopyText, onIssueAirway, isAirwayItemExpanded]
  );

  const renderHotelItem = useCallback(
    () => (
      <OrderItemListItem
        isOpen={isHotelItemExpanded}
        onItemExpand={toggleHotelItemVisible}
        item={{
          type: OrderItems.HOTEL,
        }}
        orderId={orderId}
        key={OrderItems.HOTEL}
      >
        <Fragment key={OrderItems.HOTEL}>
          <OrderHotelItemComponent
            data={order?.items.hotel as OrderHotelItemType}
            isLoading={isLoading}
            onCopyText={onCopyText}
            onOpenHotelOptionDetails={onOpenHotelOptionDetails}
            onQuote={onQuote}
            order={order}
            onDeleteQuoteOffline={onDeleteOfflineQuote}
            onCancelItem={onCancelItem}
            onIssueOrder={onIssueOrder}
            onIssueHotel={onIssueHotel}
          />
        </Fragment>
      </OrderItemListItem>
    ),
    [order, isLoading, onCopyText, onIssueHotel, isHotelItemExpanded]
  );

  const renderVehicleItem = useCallback(
    () => (
      <OrderItemListItem
        isOpen={isVehicleItemExpanded}
        onItemExpand={toggleVehicleItemVisible}
        item={{
          type: OrderItems.VEHICLE,
        }}
        orderId={orderId}
        key={OrderItems.VEHICLE}
      >
        <Fragment key={OrderItems.VEHICLE}>
          <OrderVehicleItem
            data={order?.items.vehicle as OrderVehicleItemType}
            isLoading={isLoading}
            onIssueOrder={onIssueOrder}
            order={order}
            onCopyText={onCopyText}
            onIssueVehicle={onIssueVehicle}
            onCancelItem={onCancelItem}
          />
        </Fragment>
      </OrderItemListItem>
    ),
    [order, isLoading, onCopyText, onIssueVehicle, isVehicleItemExpanded, onCancelItem]
  );

  const renderRoadItem = useCallback(
    () => (
      <OrderItemListItem
        isOpen={isRoadItemExpanded}
        onItemExpand={toggleRoadItemVisible}
        item={{
          type: OrderItems.ROAD,
        }}
        orderId={orderId}
        key={OrderItems.ROAD}
      >
        <Fragment key={OrderItems.ROAD}>
          <OrderRoadItem
            data={order as Order}
            isLoading={isLoading}
            onIssueRoad={onIssueRoad}
            onIssueOrder={onIssueOrder}
            onCancelItem={onCancelItem}
          />
        </Fragment>
      </OrderItemListItem>
    ),
    [order, isLoading, onIssueRoad, isRoadItemExpanded]
  );

  return (
    <Container
      css={{
        mt: "$10",
        p: 0,
        "@mxlg": {
          overflow: "hidden",
        },
      }}
    >
      {isLoading ? (
        <Fragment>
          <Skeleton style={{ height: 20 }} />

          <Skeleton style={{ height: 200 }} />
        </Fragment>
      ) : (
        <Col
          sz="11"
          css={{
            margin: "0 auto",
            "@mxlg": {
              ml: "$4",
            },
          }}
        >
          <Row gapX="8">
            <Flex justify="around" gap={{ "@initial": "8", "@mxlg": "1" }}>
              <Box css={{ width: "98%" }}>
                <Col>
                  {order?.itemsIncluded && order?.itemsIncluded.length > 0 && (
                    <>
                      <Box css={{ mb: "$10" }}>
                        <H4
                          css={{
                            fw: "600",
                            "@mxlg": {
                              ta: "center",
                            },
                          }}
                        >
                          Itens do pedido
                        </H4>
                      </Box>

                      <Flex direction="column" gap="8">
                        {order?.itemsIncluded.map(
                          (item) =>
                            ({
                              [OrderItems.AIRWAY]: renderAirwayItem(),
                              [OrderItems.HOTEL]: renderHotelItem(),
                              [OrderItems.VEHICLE]: renderVehicleItem(),
                              [OrderItems.ROAD]: renderRoadItem(),
                              [OrderItems.ADVANCE]: null,
                              [OrderItems.HOTEL_OFFLINE]: null,
                            }[item])
                        )}
                      </Flex>
                    </>
                  )}
                </Col>
              </Box>

              <Box css={{ mb: "$10" }}>
                {!order || isLoading ? (
                  <Fragment>
                    <Skeleton style={{ height: 20 }} />

                    <Skeleton style={{ height: 200 }} />
                  </Fragment>
                ) : (
                  <Fragment>
                    <OrderSummaryCard
                      isHotelOffline={isHotelOffline}
                      isIssued={isIssued}
                      isQuotingStatus={isQuotingStatus}
                      canIssuePendingOrder={everyItemIssuedOrCanceled}
                      isIssuingOrder={isIssuingOrder}
                      onIssueOrder={onIssueOrder}
                      onCancelOrder={onCancelOrder}
                      canSendQuotation={canSendQuotation}
                      onManualCancelOrder={onManualCancelOrder}
                      title="Informações"
                      onRejectCancelling={onRejectCancelling}
                      onSendOfflineQuote={() => {
                        onSendOfflineQuote(order?.uuid as string);
                      }}
                      data={order}
                    />
                  </Fragment>
                )}
              </Box>
            </Flex>
          </Row>
        </Col>
      )}
    </Container>
  );
}
