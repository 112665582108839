import { OrderAirwaySegment } from "~/application/types";
import { toDate } from "~/application/utils/date-functions";
import { OrderAirwaySegmentDTO } from "../dtos";
import { mapViolatedPoliciesDTO } from "./mapViolatedPoliciesDTO";

export function mapOrderAirwaySegmentDTO(data: OrderAirwaySegmentDTO): OrderAirwaySegment {
  return {
    id: data.id,
    uuid: data.uuid,
    airline: data.airline,
    departureAirport: data.departure_airport,
    arrivalAirport: data.arrival_airport,
    departureDate: toDate(data.departure_date),
    arrivalDate: toDate(data.arrival_date),
    servicesIncluded: data.services_included ? {
      baggage: data.services_included.baggage,
      checkedBaggage: data.services_included.checked_baggage,
      comfortSeat: data.services_included.comfort_seat,
      refund: data.services_included.refund,
      refundDescription: data.services_included.refund_description,
      rescheduling: data.services_included.rescheduling,
      seatAssignment: data.services_included.seat_assignment,
    } : undefined,
    segments: data.segments.map?.((segment) => ({
      airline: data.airline,
      flightNumber: segment.flightNumber,
      departureFlightInfo: {
        shortAirport: segment.departureInfo.short_airport,
        airportIata: segment.departureInfo.airport_iata,
        airport: segment.departureInfo.airport,
        dateTime: toDate(segment.departureInfo.dateTime),
      },
      arrivalFlightInfo: {
        shortAirport: segment.arrivalInfo.short_airport,
        airportIata: segment.departureInfo.airport_iata,
        airport: segment.arrivalInfo.airport,
        dateTime: toDate(segment.arrivalInfo.dateTime),
      },
    })),
    availableSeatMap: data.avaliabel_seat_map,
    isTheCheapestFlight: data.is_the_cheapest_flight,
    familyFare: data.family_fare,
    flightNumber: data.flight_number,
    flightType: data.flight_type,
    value: parseFloat(data.value),
    tracker: data.tracker,
    expiresAt: toDate(data.expires_at),
    ticket: data.ticket,
    passengers: data.passengers,
    justification: data.justification,
    reasonRejected: data.reason_rejected,
    isRejected: Boolean(data.rejected),
    violatedPolicies: mapViolatedPoliciesDTO(data.violated_policies),
  };
}
