import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { QueryKeys, QueryTimes } from "~/application/constants";
import { orderService, roadQueryService } from "~/application/usecases";
import { log } from "~/application/utils/log";
import { snackbarService } from "~/components/SnackbarStack";
import { queryClient } from "~/services/queryClient";
import { UseRoadQueryProps, UseRoadQueryResult } from "./types";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao procurar passagens",
} as const;

export const useRoadQuery = ({
  logTag,
  contexts,
  roadParams,
}: UseRoadQueryProps): UseRoadQueryResult => {
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");

  const { data: order, isFetching: isFetchingOrder } = useQuery(
    [QueryKeys.ORDERS, orderId],
    () => orderService.findById(orderId!),
    {
      staleTime: QueryTimes.SMALL,
      refetchOnWindowFocus: false,
      enabled: !!orderId,
      onError: (error) => {
        if (logTag) log.e(logTag, error);
      },
    }
  );

  const {
    data: roadQueryLinks,
    isFetching: isLoadingLinks,
    refetch: refetchRoadQueryLinks,
  } = useQuery(
    [QueryKeys.QUERY_LINKS_ROADS, contexts?.customer?.uuid, roadParams],
    () => roadQueryService.query(contexts!.customer!.uuid, roadParams!),
    {
      staleTime: QueryTimes.LONGEST,
      enabled: !isFetchingOrder && !!roadParams && !!contexts?.customer?.uuid,
      onError: (error) => {
        if (logTag) log.e(logTag, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  useEffect(() => {
    return () => {
      // TODO: Group queries by search key
      queryClient.invalidateQueries([QueryKeys.QUERY_ROADS_GO]);
      queryClient.invalidateQueries([QueryKeys.QUERY_ROADS_RETURN]);
    };
  }, []);

  return {
    order,
    roadQueryLinks,
    isLoadingLinks,
    refetchRoadQueryLinks,
  };
};
