import { FC, useCallback } from "react";
import { Traveler } from "~/application/types";
import { formatNumber } from "~/application/utils/string-functions";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Flex } from "~/components/Flex";
import { FieldLabel } from "~/components/FormControl";
import { Col, Row } from "~/components/Grid";
import { Text } from "~/components/Text";
import { H4 } from "~/components/Typography";
import { AsyncSelect } from "~/presentation/shared/components/AsyncSelect";
import { TravelersSectionUIProps } from "./types";

export const TravelersSectionUI: FC<TravelersSectionUIProps> = ({
  data,
  selectedTravelers,
  travelersPlaceholder,
  fetchTravelers,
  onCreateCustomerEmployee,
  onSelectTraveler,
}) => {
  const getTravelerLabel = useCallback((item: Traveler) => item.fullName, []);

  const getTravelerValue = useCallback((item: Traveler) => {
    return item.uuid;
  }, []);

  return (
    <Box>
      <Flex
        align="center"
        direction={{ "@mxlg": "column" }}
        css={{
          mb: "$6",
          "@mxlg": {
            width: "100%",
          },
        }}
      >
        <Col>
          <H4>Adicione passageiros</H4>
        </Col>

        <Button
          css={{
            "@mxlg": {
              mt: "$6",
              width: "90%",
            },
          }}
          onClick={onCreateCustomerEmployee}
          variant="secondary"
        >
          <Text>Novo funcionário</Text>
        </Button>
      </Flex>

      <Flex direction="column" gap="8">
        {travelersPlaceholder.map((placeholder, index) => {
          const controlLabel = `${
            placeholder.type === "ADT" ? "Adulto" : "Criança"
          } ${formatNumber(1 + placeholder.index, 2)}`;

          const defaultValue = selectedTravelers[index] || null;

          return (
            <Row gap="6" key={`traveler_${index}`}>
              <Col sz="12">
                <FieldLabel>{controlLabel}</FieldLabel>

                <AsyncSelect
                  defaultOptions={data}
                  value={defaultValue}
                  fetchOptions={fetchTravelers}
                  selectedOptions={selectedTravelers}
                  placeholder="Digite o passageiro"
                  getOptionValue={getTravelerValue}
                  getOptionLabel={getTravelerLabel}
                  onChange={(value) => onSelectTraveler(value, index)}
                />
              </Col>
            </Row>
          );
        })}
      </Flex>
    </Box>
  );
};
