import { Customer } from "~/application/types";
import { SettingParameterSlug } from "~/application/types/entities/SettingParameter.type";

export const useVerifyParameter = ({
  customer,
  parameter,
}: {
  customer: Customer;
  parameter: SettingParameterSlug;
}) => {
  return customer?.settingsParameters?.some((settings) => {
    return settings.slug === parameter && settings.value === "true";
  });
};
