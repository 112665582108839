import { FC, Fragment } from "react";
import { Outlet } from "react-router";
import { Box } from "~/components/Box";
import { Head } from "~/presentation/shared/components/Document";
import { NavBar } from "./components/NavBar";
import { RemakeSearchProvider } from "~/core/shared/contexts/remakeSearchContext";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { Flex } from "~/components/Flex";
import { IconButton } from "~/components/IconButton";
import { Icon } from "~/components/Icon";
import { SvgWhatsapp } from "~/components/Icon/icons";
import { useUser } from "../../contexts/UserContext";

export const AppContainer: FC = () => {
  const { contexts } = useUser();
  const onSendMensagemInWhatsapp = () => {
    window.open(
      `https://api.whatsapp.com/send/?phone=${contexts.customer.agency.phone}&text=Ol%C3%A1%2C+gostaria+de+tirar+uma+dúvida.&type=phone_number&app_absent=0`,
      "_blank"
    );
  };
  return (
    <RemakeSearchProvider>
      <Fragment>
        <Head colorScheme="light" />
        {!contexts.agency && (
          <Tooltip
            variant="white"
            content={
              <Flex direction="column" align="center">
                <TooltipLabel>Duvidas? </TooltipLabel>
                <TooltipLabel>Fale conosco!</TooltipLabel>
              </Flex>
            }
          >
            <IconButton
              size="xl"
              css={{
                borderRadius: "$circular",
                position: "fixed",
                bottom: "90px",
                right: "$3",
                zIndex: 100,
                backgroundColor: "$success-base",
                "@mxlg": {
                  display: "none",
                },
                "&:hover": {
                  backgroundColor: "$success-dark",
                },
              }}
              onClick={onSendMensagemInWhatsapp}
            >
              <Icon as={SvgWhatsapp} size="sm" />
            </IconButton>
          </Tooltip>
        )}

        <Box
          as="header"
          css={{
            position: "sticky",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: "$navigationBar",
          }}
        >
          <NavBar />
        </Box>

        <Box
          css={{
            pb: location.pathname.includes('dashboard') ? '0' : "$10", // Used to prevent content to be at very low bottom of the page
          }}
        >
          <Outlet />
        </Box>
      </Fragment>
    </RemakeSearchProvider>
  );
};

AppContainer.displayName = "AppContainer";
