import { components, ControlProps } from "react-select";
import { styled } from "~/application/theme";

const StyledControl = styled(components.Control, {
  // Reset
  boxSizing: "border-box",
  display: "flex",
  userSelect: "none",

  // Custom
  position: "relative",
  transition: "$fast",
  outline: "none",
  gap: "$2",
  fontSize: "$md",
  fontWeight: 500,
  flexWrap: "wrap",
  width: "100%",
});

const SelectControlRoot = styled("div", {
  // Reset
  boxSizing: "border-box",
  display: "flex",
  userSelect: "none",

  // Custom
  position: "relative",
  transition: "$fast",
  outline: "none",
  gap: "$2",
  fontSize: "$md",
  fontWeight: 500,
  flexWrap: "wrap",
  border: "none",
  borderRadius: "$md",
  backgroundColor: "$neutrals-white",
  color: "$neutrals-darkest",
  fill: "$neutrals-darkest",

  "&::after": {
    content: "",
    pointerEvents: "none",
    position: "absolute",
    margin: 0,
    inset: 0,
    borderColor: "$neutrals-light",
    borderRadius: "inherit",
    borderStyle: "solid",
    borderWidth: "1px",
    transition: "$faster",
  },

  "&:hover": {
    cursor: "pointer",
    color: "$neutrals-black",
    fill: "$neutrals-black",
  },

  "&[data-dirty=true]": {
    backgroundColor: "$error-light",
    "&::after": {
      borderWidth: "2px",
      borderColor: "$error-base",
    },
  },

  "&[data-focused=true]": {
    boxShadow: "$focus-sm",
  },

  "&[data-disabled=true]": {
    pointerEvents: "none",
    opacity: "$disabled",
  },

  variants: {
    size: {
      sm: {
        [`& > ${StyledControl}`]: {
          padding: "$2 $4",
          height: "$14",
        },
      },
      md: {
        [`& > ${StyledControl}`]: {
          padding: "$4 $6",
          height: "$18",
        },
      },
    },
  },

  defaultVariants: {
    size: "sm",
  },
});

export const SelectControl = (props: ControlProps) => {
  const { size, "data-dirty": isDirty } = props.selectProps as Record<
    string,
    any
  >;

  return (
    <SelectControlRoot
      size={size}
      data-dirty={isDirty}
      data-open={props.menuIsOpen}
      data-disabled={props.isDisabled}
      data-focused={props.isFocused}
    >
      <StyledControl {...props} />
    </SelectControlRoot>
  );
};

SelectControl.displayName = "SelectControl";
