import { useFieldArray, useForm } from "react-hook-form";
import { Flex } from "~/components/Flex";
import { Form } from "~/components/Form";
import { useReasonTrips } from "~/core/modules/Customer/pages/CustomerPage/hooks/useReasonTrips";
import { useUser } from "~/presentation/core/contexts/UserContext";
import useMobile from "../../hooks/useMobile";
import { SettingParameterSlug } from "~/application/types/entities/SettingParameter.type";
import { useVerifyParameter } from "../../hooks/useVerifyParameter";
import { Actions, Airport, Order } from "~/application/types";
import {
  AirwayQueryForm,
  AirwayQueryFormData,
  AirwayQueryFormDataRules,
  TravelAirwayQueryForm,
} from "../AirwayQueryForm";
import { useFlightsPage } from "~/presentation/Booking/BookingAirway/pages/FlightsPage/contexts/FlightsPageContext";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FieldLabel, FormControl as NewFormControl } from "~/components/FormControl";
import { AsyncSelect } from "../AsyncSelect";
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from "~/core/shared/components/Popover";
import { SelectButton } from "~/core/shared/components/SelectButton";
import { Container } from "~/components/Container";
import { Card, CardBody } from "~/components/Card";
import { CardSectionTitle } from "~/core/modules/DeprecatedBooking/components/Card";
import { CounterListItem } from "~/core/shared/components/CounterListItem";
import { Counter } from "~/core/shared/components/Counter";
import { Button } from "~/components/Button";
import { Icon } from "~/components/Icon";
import { SvgPlus, SvgSearch } from "~/components/Icon/icons";
import { Switch } from "~/components/Input";
import { Caption } from "~/components/Typography";
import { AirwayBudgetDispatchType } from "~/presentation/Booking/BookingAirway/pages/FlightsPage/hooks/useAirwayBudget/type";
import { Text } from "~/components/Text";
import { useVerifyActions } from "../../hooks/useVerifyActions";

export type AirwayQueryContainerProp = {
  order?: Order;
  rules?: Partial<AirwayQueryFormDataRules>;
  defaultData?: Partial<AirwayQueryFormData>;
  onSubmit?: (data: Partial<AirwayQueryFormData>) => Promise<void>;
};

export function displayTravelers({
  adultQuantity,
  childrenQuantity,
}: {
  adultQuantity: number;
  childrenQuantity: number;
}): string {
  let label = `${adultQuantity} adulto`;
  if (adultQuantity > 1) {
    label += "s";
  }
  if (childrenQuantity > 0) {
    label += `, ${childrenQuantity} criança`;

    if (childrenQuantity > 1) {
      label += "s";
    }
  }

  return label;
}

export function AirwayQueryContainer({
  defaultData,
  onSubmit,
  order,
  rules: rulesProps,
}: AirwayQueryContainerProp) {
  const [isManyStretch, onIsManyStretch] = useState((defaultData?.stretch?.length || 0) > 1);

  const formState = useForm({
    defaultValues: defaultData,
  });

  const { control, setValue, watch, register, handleSubmit, resetField } = formState;

  const { profile } = useUser();

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "stretch",
  });
  const isMobile = useMobile();

  const { contexts, user } = useUser();

  const customerId = user?.customer?.uuid || contexts?.customer?.uuid;

  const showReasonTrip =
    useVerifyParameter({
      customer: contexts.customer,
      parameter: SettingParameterSlug.POLICY_BY_REASON_TRIP,
    }) &&
    useVerifyActions({ actions: [Actions.CreateOrder, Actions.ViewReasonTrip], contexts, profile });

  const { data: reasonTrips, fetchReasonTrip } = useReasonTrips({
    customerId,
    enabled: showReasonTrip && !!customerId,
  });

  const data = watch();
  const currentReasonTrip = order?.reasonTrip;
  useEffect(() => {
    if (currentReasonTrip) {
      setValue("reasonTrip", currentReasonTrip);
    }
  }, [currentReasonTrip]);

  const onFormSubmit = useCallback(
    (data: Partial<AirwayQueryFormData>) => {
      onSubmit?.(data);
    },
    [onSubmit]
  );

  const { airwayBudget } = useFlightsPage();

  const canCreateOrder = useVerifyActions({ actions: [Actions.CreateOrder], contexts, profile });

  useEffect(() => {
    if (!isManyStretch && fields.length > 1) {
      replace([{} as TravelAirwayQueryForm]);
    }
  }, [isManyStretch, remove, fields, resetField]);

  const rules = useMemo<AirwayQueryFormDataRules>(() => {
    const maxTravelers = rulesProps?.maxTravelers || 0;
    let maxAdultQuantity = rulesProps?.maxAdultQuantity || 99;
    let maxChildrenQuantity = rulesProps?.maxChildrenQuantity || 99;

    const travelersQuantity = (data.adultQuantity || 0) + (data.childrenQuantity || 0);

    if (maxTravelers) {
      maxAdultQuantity = 1 + maxTravelers - travelersQuantity;
      maxChildrenQuantity = maxTravelers - (data.adultQuantity || 0);
    }

    return {
      maxTravelers,
      maxAdultQuantity,
      maxChildrenQuantity,
    };
  }, [data, rulesProps]);

  return (
    <Form onSubmit={handleSubmit(onFormSubmit)}>
      <Flex
        direction="column"
        gap={{ "@initial": "4", "@mxlg": "6" }}
        css={{
          p: "$6",
          "@mxlg": {
            width: "80%",
            margin: "$15 auto 0",
            px: "0",
          }
        }}
      >
        {isMobile && (
          <Flex css={{ flex: "1" }} justify="end">
            <Switch checked={isManyStretch} onClick={() => onIsManyStretch((old) => !old)}>
              <Caption css={{ "@mxlg": { color: "#fff" } }}>Vários trechos</Caption>
            </Switch>
          </Flex>
        )}

        <Flex direction={{ "@mxlg": "column" }} gap={{ "@initial": "2", "@mxlg": "6" }}>
          {showReasonTrip && (
            <Flex direction={{ "@mxlg": "column" }}>
              <NewFormControl name="reasonTrip" control={control} required>
                <FieldLabel css={{ "@mxlg": { color: "White" } }}>Motivo de viagem</FieldLabel>
                <AsyncSelect
                  size="sm"
                  placeholder="Digite o motivo de viagem"
                  defaultOptions={reasonTrips}
                  defaultValue={data?.reasonTrip || currentReasonTrip}
                  disabled={!!currentReasonTrip?.uuid}
                  fetchOptions={fetchReasonTrip}
                  getOptionLabel={(reason) => reason.reason}
                  getOptionValue={(reason) => reason.uuid}
                />
              </NewFormControl>
            </Flex>
          )}

          <NewFormControl control={control} name="adultQuantity">
            <FieldLabel css={{
              "@mxlg": {
                color: "#fff"
              }
            }}>Passageiros</FieldLabel>

            <Popover>
              <PopoverTrigger asChild>
                <SelectButton>
                  {displayTravelers({
                    adultQuantity: data?.adultQuantity || 0,
                    childrenQuantity: data?.childrenQuantity || 0,
                  })}
                </SelectButton>
              </PopoverTrigger>

              <PopoverContent align={isMobile ? "center" : "start"} sideOffset={8}>
                <Container size="5">
                  <Card>
                    <CardSectionTitle>Quantidade de passageiros</CardSectionTitle>
                    <CounterListItem title="Adultos" description="A partir de 18 anos de idade">
                      <Counter
                        {...register("adultQuantity", {
                          required: true,
                          min: 1,
                          max: 99,
                        })}
                        minValue={1}
                        maxValue={order?.travelers.length ?? rules.maxAdultQuantity}
                        defaultValue={data?.adultQuantity}
                      />
                    </CounterListItem>
                    <CardBody>
                      <PopoverClose asChild>
                        <Button variant="secondary">Aplicar</Button>
                      </PopoverClose>
                    </CardBody>
                  </Card>
                </Container>
              </PopoverContent>
            </Popover>
          </NewFormControl>
        </Flex>

        {fields?.map((field, index) => (
          <AirwayQueryForm
            formState={formState}
            index={index}
            key={field.id}
            onRemoveField={() => remove(index)}
            isManyStretch={isManyStretch}
          />
        ))}
        
        <Flex justify={{ "@initial": "between", "@mxlg": "center" }}>
          {!isMobile && (
            <Flex  css={{ flex: "1" }} justify="start">
              <Switch checked={isManyStretch} onClick={() => onIsManyStretch((old) => !old)}>
                <Caption>Vários trechos</Caption>
              </Switch>
            </Flex>
          )}
          
          {isManyStretch && canCreateOrder && (
            <Flex 
              direction={{ "@mxlg": "column" }}
              align="center" 
              gap={{ "@initial": "2", "@mxlg": "4" }} 
            >
              <Button
                disabled={!(fields.length < 4)}
                css={{
                  "@mxlg": {
                    width: "$50",
                    height: "$11",
                    backgroundColor: "#F0F2F5",
                    color: "$primary-base",
                  },
                }}
                onClick={() => {
                  append({
                    origin: data.stretch?.at(data.stretch.length - 1)?.destination as Airport,
                  } as any);
                }}
              >
                <Icon as={SvgPlus} variant={{ "@initial": "white", "@mxlg": "primary" }} /> 
                Adicionar trecho
              </Button>

              <Button
                size="sm"
                type="submit"
                css={{
                  "@mxlg": {
                    width: "$50",
                    height: "$11",
                    backgroundColor: "#F0F2F5",
                    color: "$primary-base",
                  },
                }}
                onClick={() => airwayBudget?.dispatch?.({ type: AirwayBudgetDispatchType.CLEAR })}
              >
                <Icon as={SvgSearch} variant={{ "@mxlg": "primary" }} />
                <Text
                  css={{
                    "@lg": {
                      display: "none",
                    },
                  }}
                >
                  Buscar
                </Text>
              </Button>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Form>
  );
}
