import { FC, useCallback } from "react";
import { SubmitErrorHandler, useForm } from "react-hook-form";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col, Row } from "~/components/Grid";
import { SvgKey } from "~/components/Icon/icons";
import { NumberInput, TextInput } from "~/components/Input";
import { Text } from "~/components/Text";
import { H5 } from "~/components/Typography";
import { CostCenterDialogProps } from "./types";
import { EditableCostCenter, createCostCenter, editCostCenter } from "./utils";

export const CostCenterDialog: FC<CostCenterDialogProps> = ({
  data,
  onCloseClick,
  onSubmit: onSubmitProp,
}) => {
  const isNew = !data;

  const { control, formState, handleSubmit } = useForm<EditableCostCenter>({
    defaultValues: editCostCenter(data),
    reValidateMode: "onBlur",
    mode: "onSubmit",
  });

  const onSubmit = useCallback(
    (formData: EditableCostCenter) => {
      onSubmitProp?.(createCostCenter(formData));
    },
    [onSubmitProp]
  );

  const onError = useCallback<SubmitErrorHandler<EditableCostCenter>>(
    (formErrors) => {
      // eslint-disable-next-line no-console
      console.log(formErrors);
    },
    []
  );

  return (
    <Container size="8" fixed>
      <Form onSubmit={handleSubmit(onSubmit, onError)}>
        <FormDialog
          title={isNew ? "Novo centro de custo" : "Editar centro de custo"}
          negativeButton={
            <Button variant="tertiary" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button disabled={formState.isSubmitting} type="submit">
              <Text>{isNew ? "Adicionar" : "Aplicar"}</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ p: "$6" }}>
            <Box css={{ mb: "$10" }}>
              <H5>Informações básicas</H5>
            </Box>

            <Row gap="6">
              <Col sz="5">
                <FormControl name="costCenterCode" control={control} required>
                  <FieldLabel>Integração externa</FieldLabel>
                  <TextInput leftIcon={SvgKey} placeholder="Número do ID" />
                </FormControl>
              </Col>

              <Col sz="7">
                <FormControl name="name" control={control} required>
                  <FieldLabel>Nome do centro de custo</FieldLabel>
                  <TextInput placeholder="Digite o nome do centro de custo" />
                </FormControl>
              </Col>
            </Row>

            <Box css={{ my: "$10" }}>
              <H5>Orçamento</H5>
            </Box>

            <Row>
              <Col sz="12">
                <FormControl name="credit" control={control}>
                  <FieldLabel>Orçamento deste centro de custo</FieldLabel>
                  <NumberInput prefix="R$ " placeholder="R$ 5.000,00" />
                </FormControl>
              </Col>
            </Row>
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
};

CostCenterDialog.displayName = "CostCenterDialog";
