import queryString from "query-string";
import {
  AdvanceOrderStatus,
  CostCenter,
  OrderAdvance,
  PaginatedResource,
  Traveler,
  TravelerAdvance,
} from "~/application/types";
import { DateUtils } from "~/application/utils";
import { api } from "~/infrastructure/api";
import {
  OrderAdvanceDTO,
  TravelerAdvanceDTO,
  TravelerDTO,
} from "~/infrastructure/api/dtos";
import { mapTravelerAdvanceDTO } from "~/infrastructure/api/mappers";
import type {
  IFindByApproverParams,
  IOrderAdvanceService,
} from "./IOrderAdvanceService";

export function parseTravelerDTO(traveler: TravelerDTO): Traveler {
  return {
    uuid: traveler.uuid,
    fullName: traveler.full_name,
    cpf: traveler.cpf,
    email: traveler.email,
    rg: traveler.rg,
    dateBirth: DateUtils.toDate(traveler.date_birth),
    phone: traveler.phone,
    company: {
      tradingName: traveler.company?.trading_name,
    },
    seats: traveler.seats?.map((seat) => ({
      seatType: seat.seat_type,
      isConnection: seat.is_connection,
      seatNumber: seat.seat_number,
      ticket: seat.ticket ?? null,
      bpe: seat.bpe ?? null,
    })),
    avatarUrl: traveler.avatarUrl ?? undefined,
  };
}

export function parseTravelerAdvanceDTO(
  data: TravelerAdvanceDTO
): TravelerAdvance {
  return {
    uuid: data.uuid,
    order: {
      uuid: data.order?.uuid,
      travelers: (data.order?.travelers || []).map(parseTravelerDTO),
    },
    status: data.status as AdvanceOrderStatus,
    traveler: parseTravelerDTO(data.traveler),
    description: data.description,
    currentApprover: data.current_approver,
    approvedBy: data.approved_by,
    expenses: data.expenses,
    value: data.value,
    approvers: (data.approvers || []).map(({ uuid, name }) => ({
      id: uuid,
      name,
    })),
  };
}

export class OrderAdvanceService implements IOrderAdvanceService {
  async create(data: OrderAdvance): Promise<OrderAdvance> {
    const url = "/orders/travelers/advance-orders";
    return await api
      .post<
        { order_uuid: string; advance_orders: TravelerAdvanceDTO[] },
        OrderAdvanceDTO
      >(url, {
        number_days: data.numberOfDays,
        travelers: data.travelers.map((item) => ({
          status: item.status,
          traveler_uuid: item.traveler.uuid,
          description: item.description,
          value: item.value,
        })),
      })
      .then(({ data: response }) => ({
        orderId: response.order_uuid,
        numberOfDays: data.numberOfDays,
        travelers: response.advance_orders.map(mapTravelerAdvanceDTO),
      }));
  }

  async append(
    orderId: string,
    data: TravelerAdvance[]
  ): Promise<TravelerAdvance[]> {
    const url = `/orders/${orderId}/travelers/advance-orders`;
    return await api
      .post<{ data: TravelerAdvanceDTO[] }>(
        url,
        data.map((item) => ({
          traveler_uuid: item.traveler.uuid,
          description: item.description,
          value: item.value,
        }))
      )
      .then(({ data: response }) => response.data.map(mapTravelerAdvanceDTO));
  }

  async findByOrderId(orderId: string): Promise<TravelerAdvance[]> {
    const url = `/orders/${orderId}/travelers/advance-orders`;
    return await api
      .get<{ data: TravelerAdvanceDTO[] }>(url)
      .then(({ data: response }) => response.data.map(mapTravelerAdvanceDTO));
  }

  async findById(id: string): Promise<TravelerAdvance> {
    const url = `/orders/travelers/advance-orders/${id}`;
    return await api
      .get<TravelerAdvanceDTO>(url)
      .then(({ data }) => mapTravelerAdvanceDTO(data));
  }

  async findByApprover({
    statuses,
  }: IFindByApproverParams): Promise<PaginatedResource<TravelerAdvance[]>> {
    const url = queryString.stringifyUrl({
      url: "advance-orders",
      query: {
        statuses: statuses?.join(","),
      },
    });

    return await api
      .get<PaginatedResource<TravelerAdvanceDTO[]>>(url)
      .then(({ data: { data: response, ...rest } }) => ({
        ...rest,
        data: response.map(mapTravelerAdvanceDTO),
      }));
  }

  async requestApproval(
    data: TravelerAdvance & CostCenter & { costCenterId: string }
  ): Promise<void> {
    const url = `/orders/travelers/advance-orders/${data.uuid}/expenses/assessments`;
    return await api
      .post(url, {
        cost_center_uuid: data.costCenterId,
        approval_models: data.approvalModels.map((approvalModel) => ({
          approval_type: approvalModel.approvalType,
          approvers: approvalModel.approvers.map((approver) => ({
            uuid: approver.uuid,
            is_self_approver: approver.isSelfApprover,
          })),
        })),
        cost_center_code: data.costCenterCode,
        credit: data.credit,
        current_approver: data.currentApprover,
        customer_uuid: data.customerId,
        description: data.description,
        is_active: data.isActive,
        name: data.name,
        order: {
          uuid: data.order.uuid,
          travelers: data.order.travelers,
        },
        status: data.status,
        traveler: {
          uuid: data.traveler.uuid,
          cpf: data.traveler.cpf,
          date_birth: data.traveler.dateBirth,
          email: data.traveler.email,
          full_name: data.traveler.fullName,
          rg: data.traveler.rg,
        },
        uuid: data.uuid,
        value: data.value,
      })
      .then(({ data }) => data);
  }
}
