import React, { FC, useCallback, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { createAirwayQueryUrl } from "~/presentation/Booking/BookingAirway/utils";
import { AirwayQueryFormData } from "~/presentation/shared/components/AirwayQueryForm";
import { queryClient } from "~/services/queryClient";
import { useFlightsPage } from "../../../contexts/FlightsPageContext";
import { useAirwayParams } from "../../../hooks/useAirwayParams";
import { useAirwayReducer } from "../../../hooks/useAirwayReducer";
import { AirwayBookingBarUI } from "./AirwayBookingBarUI";
import { BookingAirwaySteps } from "../../../constants";

export const AirwayBookingBarSection: FC<{
  currentStepSection: BookingAirwaySteps | string;
  currentSteppter: number;
  setStep: (step: number) => void;
}> = ({ currentStepSection, currentSteppter, setStep }) => {
  const navigate = useNavigate();

  const { flightQuery, airwayReducer, bookingStep, order } = useFlightsPage();
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");
  const { dispatch } = useAirwayReducer();

  const [isEditable, setEditable] = useState<boolean>(false);

  const airwayParams = useAirwayParams();

  const onSubmit = useCallback(
    async (data: Partial<AirwayQueryFormData>) => {
      const queryUrl = createAirwayQueryUrl(data, orderId ?? "");

      await queryClient.invalidateQueries([airwayParams]);

      bookingStep.setStep(0);
      dispatch({ type: "CLEAR" });
      airwayReducer.dispatch({ type: "CLEAR" });
      navigate(queryUrl, {
        replace: true,
      });

      setEditable(false);
    },
    [navigate, orderId, airwayReducer]
  );

  const handleBarClick: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      // ignore clicks while editing
      if (!isEditable) {
        // Ignore clicks on the bar itself
        if (event.target !== event.currentTarget) {
          setEditable(true);
        }
      }
    },
    [isEditable]
  );

  return (
    <AirwayBookingBarUI
      flightQuery={flightQuery.data!}
      isEditable={isEditable}
      currentStepSection={currentStepSection}
      currentSteppter={currentSteppter}
      setStep={setStep}
      order={order}
      onBarClick={handleBarClick}
      onQuery={onSubmit}
    />
  );
};

AirwayBookingBarSection.displayName = "AirwayBookingBarSection";
