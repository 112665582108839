import { Markdown } from "~/application/types";
import {
  AppBarActions,
  AppBarContainer,
  AppBarHeading,
} from "~/components/AppBar";
import { AppBar } from "~/components/AppBar/AppBar";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { Footer } from "~/components/Document";
import { EmptyState } from "~/components/EmptyState";
import { Icon } from "~/components/Icon";
import { SvgPlus } from "~/components/Icon/icons";
import { SearchBar } from "~/components/Input";
import { LazyList } from "~/components/LazyList";
import { Pagination } from "~/components/Pagination";
import { Text } from "~/components/Text";
import { Fragment, useCallback } from "react";
import { MarkdownListItem } from "./components/MarkdownListItem";

export interface MarkdownsContainerProps {
  isLoading: boolean;
  markdownsList: Markdown[] | undefined;
  currentPage: number;
  lastPage: number;
  searchText: string;
  setSearchText: (searchO: string) => void;
  onGoToPage: (page: number) => void;
  onCreateMarkdown: () => void;
  onEditMarkdown: (item: Markdown) => void;
  onToggleState: (data: Markdown) => void;
}

export function MarkdownsContainer({
  markdownsList,
  currentPage,
  lastPage,
  searchText,
  setSearchText,
  onGoToPage,
  onCreateMarkdown,
  onEditMarkdown,
  onToggleState,
}: MarkdownsContainerProps) {
  const listRenderer = useCallback(
    (item: Markdown) => (
      <MarkdownListItem
        data={item}
        onToggleState={onToggleState}
        onEditClick={onEditMarkdown}
        key={item.uuid}
      />
    ),
    [onToggleState, onEditMarkdown]
  );

  return (
    <Fragment>
      <AppBar>
        <AppBarContainer>
          <AppBarHeading title="Markdowns" />

          <AppBarActions>
            <SearchBar
              search={searchText}
              onSearchChange={setSearchText}
              placeholder="Encontrar markdown"
            />

            <Button title="Novo markdown" onClick={onCreateMarkdown}>
              <Icon as={SvgPlus} />
              <Text>Novo markdown</Text>
            </Button>
          </AppBarActions>
        </AppBarContainer>
      </AppBar>

      <Container css={{ pt: "$10", pb: "$20" }}>
        <LazyList
          gap="6"
          items={markdownsList}
          render={listRenderer}
          skeletonQuantity={10}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              {searchText.length > 0 ? (
                <Text>Nenhum markdown corresponde aos dados informados</Text>
              ) : (
                <Text>Você não possui markdowns cadastrados</Text>
              )}
            </EmptyState>
          }
        />
      </Container>

      <Footer>
        <Container>
          <Pagination
            activePage={currentPage}
            pagesCount={lastPage}
            setActivePage={onGoToPage}
          />
        </Container>
      </Footer>
    </Fragment>
  );
}
