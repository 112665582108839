import { FC } from "react";
import { MaskUtils } from "~/application/utils";
import { Col, Row } from "~/components/Grid";
import { Text } from "~/components/Text";
import { asCurrency } from "~/utils/mask.utils";
import { AgencyRoadValueDetailsProps } from "./types";

export const AgencyRoadValueDetails: FC<AgencyRoadValueDetailsProps> = ({ item }) => {
  const roadValue = item.value + item.fee + item.providerFees;
  const oldValue = item.originalValue / item.travelers.length;
  
  return (
    <>
      <Row justify="center" css={{ mt: 40, mb: 40 }}>
        <Col css={{ textAlign: "start", mb: "$4" }}>
          <Text as="p" size="3" css={{ mb: "$2", color: "$neutrals-dark" }}>
            Tarifa
          </Text>

          <Text size="5" css={{ fw: "600" }}>
            {asCurrency(item.travelers.length === 0 ? 0 : item.value / item.travelers.length - item.markup)}
          </Text>
        </Col>

        <Col
          css={{
            textAlign: "start",
            "@mxlg": {
              ta: "end",
            },
          }}
        >
          <Text as="p" size="3" css={{ mb: "$3", color: "$neutrals-dark" }}>
            Taxas e encargos
          </Text>
          <Text size="5" css={{ fw: "600" }}>
            {asCurrency(item.travelers.length === 0 ? 0 : item.providerFees / item.travelers.length)}
          </Text>
        </Col>

        <Col css={{ textAlign: "start" }}>
          <Text as="p" size="3" css={{ mb: "$3", color: "$neutrals-dark" }}>
            Fee
          </Text>

          <Text size="5" css={{ fw: "600" }}>
            {asCurrency(item.travelers.length === 0 ? 0 : item.fee / item.travelers.length)}
          </Text>
        </Col>

        <Col
          css={{
            textAlign: "start",
            "@mxlg": {
              ta: "end",
              mt: "$1",
            },
          }}
        >
          <Text as="p" size="3" css={{ mb: "$2", color: "$neutrals-dark" }}>
            Markup
          </Text>
          <Text size="5" css={{ fw: "600" }}>
            {MaskUtils.asCurrency(item.markup)}
          </Text>
        </Col>
      </Row>

      <Row>
        <Col
          css={{
            textAlign: "start",
            "@mxlg": {
              mb: "$5",
            },
          }}
        >
          <Text as="p" size="3" css={{ mb: "$3", color: "$neutrals-dark" }}>
            Valor unitário
          </Text>

          <Text size="5" css={{ fw: "600" }}>
            {asCurrency(item.travelers.length === 0 ? 0 : roadValue / item.travelers.length)}
          </Text>
        </Col>

        <Col
          css={{
            textAlign: "start",
            "@mxlg": {
              ta: "end",
            },
          }}
        >
          <Text as="p" size="3" css={{ mb: "$3", color: "$neutrals-dark" }}>
            Valor total antigo
          </Text>
          <Text size="5" css={{ fw: "600", color: "$neutrals-dark" }}>
            {asCurrency(item.travelers.length === 0? 0 : oldValue * item.travelers.length)}
          </Text>
        </Col>

        <Col css={{ textAlign: "start" }}>
          <Text as="p" size="3" css={{ mb: "$3", color: "$neutrals-dark" }}>
            Valor antigo
          </Text>
          <Text size="5" css={{ fw: "600", color: "$neutrals-dark" }}>
            {asCurrency(item.travelers.length === 0 ? 0 : oldValue)}
          </Text>
        </Col>

        <Col
          css={{
            textAlign: "start",
            "@mxlg": {
              ta: "end",
            },
          }}
        >
          <Text as="p" size="3" css={{ mb: "$3", color: "$neutrals-dark" }}>
            Valor fornecedor
          </Text>
          <Text size="5" css={{ fw: "600", color: "$neutrals-dark" }}>
            {asCurrency(item.travelers.length === 0 ? 0 : (item.value + item.providerFees) / item.travelers.length - item.markup)}
          </Text>
        </Col>
      </Row>
    </>
  );
};
