import { importCustomerEmployeeService } from "~/application/usecases/ImportCustomerEmployee";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { useDebounce } from "use-debounce";
import {
  ApiError,
  CustomerEmployee,
  DeepPartial,
  PaginatedResource,
  Profile,
} from "~/application/types";
import { authService, customerEmployeeService } from "~/application/usecases";
import { fetchCostCenters } from "~/application/usecases/CostCenter/utils";
import { fetchProfile } from "~/application/usecases/Profile/utils";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants/queryKeys";
import { QueryTimes } from "~/constants/queryTimes";
import { InactivateDialog } from "~/core/shared/components/InactivateDialog";
import { useAuth } from "~/presentation/core/contexts/AuthContext";
import {
  CustomerEmployeeFilter,
  EMPTY_CUSTOMER_EMPLOYEE_FILTERS,
  ICustomerEmployeeFilters,
} from "~/presentation/shared/types";
import {
  CustomerEmployeeDialog,
  EditableCustomerEmployee,
} from "~/presentation/shared/views/CustomerEmployeeDialog";
import { queryClient } from "~/services/queryClient";
import { log } from "~/utils/log";
import { ChangePasswordDialog } from "../views/CustomerEmployee/components/ChangePasswordDialog";
import { FileUpload } from "~/presentation/shared/components/FileUpload";
import { logError } from "~/presentation/shared/utils/errors";
import { LoadingDialog } from "~/presentation/shared/views/LoadingDialog";
import { ImportCustomerEmployeeProps } from "~/application/usecases/ImportCustomerEmployee/ImportCustomerEmployeeServiceInterface";
import { Control, useForm } from "react-hook-form";

export interface UseEmployeesResult {
  profilesOptions?: CustomerEmployeeFilter[];
  control: Control<ICustomerEmployeeFilters, any>;
  activenessOptions?: CustomerEmployeeFilter[];
  data?: CustomerEmployee[];
  isLoading: boolean;
  currentPage: number;
  lastPage: number;
  searchText: string;
  hasUploadedFromSpreadsheet: boolean;
  setSearchText: (search: string) => void;
  onGoToPage: (value: number) => void;
  onCreateCustomerEmployee: () => void;
  onCleanFilters: () => void;
  onChangePassword: (customerEmployeeId: string) => void;
  onEditCustomerEmployee: (item: CustomerEmployee) => void;
  onToggleCustomerEmployeeState: (item: CustomerEmployee) => void;
  onCreateCustomerEmployeeBySpreadsheet: (customerId: string) => void;
}

export interface UseCustomerEmployeesOptions {
  customerId: string;
  enabled: boolean;
}

const LOG_TAG = "Customer/CustomerPage/useCustomerEmployees";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar funcionários da agência",
  CREATE_SUCCESS_MESSAGE: "Funcionário criado",
  CREATE_ERROR_MESSAGE: "Falha ao criar funcionário",
  UPLOAD_CUSTOMER_EMPLOYEES_BY_SPREADSHEET_SUCCESS_MESSAGE: "Planilha carregada com sucesso",
  UPLOAD_CUSTOMER_EMPLOYEES_BY_SPREADSHEET_ERROR_MESSAGE: "Falha ao carregar planilha",
  UPDATE_SUCCESS_MESSAGE: "Funcionário atualizado",
  UPDATE_ERROR_MESSAGE: "Falha ao atualizar funcionário",
  ACTIVATE_ERROR_MESSAGE: "Falha ao ativar funcionário",
  ACTIVATE_SUCCESS_MESSAGE: "Funcionário ativado",
  INACTIVATE_ERROR_MESSAGE: "Falha ao inativar funcionário",
  INACTIVATE_SUCCESS_MESSAGE: "Funcionário inativado",
  CHANGE_CUSTOMER_EMPLOYEE_PASSWORD_SUCCESS_MESSAGE:
    "Solicitação de alteração de senha feita com sucesso",
} as const;

export const DEFAULT_FORM_DATA: DeepPartial<CustomerEmployee> = {
  name: "",
  lastName: "",
  phone: "",
  email: "",
  cpf: "",
  rg: "",
  gender: "",
  birthDate: "",
  registration: "",
  position: "",
  passportNumber: "",
  passportExpiration: "",
  loyaltyLatam: "",
  loyaltyAzul: "",
  loyaltyGol: "",
  customerId: "",
  profile: {
    uuid: "",
  } as Profile,
  policyId: "",
  outsourced: false,
};

export function useCustomerEmployees({
  customerId,
  enabled,
}: UseCustomerEmployeesOptions): UseEmployeesResult {
  const { user } = useAuth();

  const [hasUploadedFromSpreadsheet, setHasUploadedFromSpreadsheet] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [search] = useDebounce<string>(searchText, 700);

  const [currentPage, setCurrentPage] = useState<number>(1);

  const { control, watch, setValue } = useForm({
    defaultValues: EMPTY_CUSTOMER_EMPLOYEE_FILTERS,
  });

  const dataFilter = watch();

  const { data, isFetching } = useQuery<PaginatedResource<CustomerEmployee[]>, ApiError>(
    [QueryKeys.CUSTOMER_EMPLOYEES, customerId, currentPage, search, dataFilter],
    () =>
      customerEmployeeService.find({
        page: currentPage,
        customerId,
        name: search,
        profile: dataFilter.profiles.value,
        isActive: dataFilter.activeness.value,
      }) as Promise<PaginatedResource<CustomerEmployee[]>>,
    {
      staleTime: QueryTimes.NORMAL,
      refetchOnWindowFocus: false,
      enabled: enabled,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE, "error");
      },
    }
  );

  const { mutateAsync: mutateCreateCustomerEmployee } = useMutation(
    async (item: EditableCustomerEmployee) =>
      await customerEmployeeService.create({
        ...item,
        customerId,
      }),
    {
      onMutate: () => {
        dialogService.showDialog(<LoadingDialog message="Criando funcionário" />);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.CUSTOMER_EMPLOYEES]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CREATE_SUCCESS_MESSAGE, "success");

        dialogService.popAll();
      },
      onError: (error: { message?: string }) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE,
        });

        dialogService.popDialog();
      },
    }
  );

  const { mutateAsync: mutateUploadCustomerEmployeesSpreadsheet } = useMutation(
    async (data: ImportCustomerEmployeeProps) => {
      return await importCustomerEmployeeService.import(data);
    },
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Carregando planilha" />);
      },
      onSuccess: () => {
        setHasUploadedFromSpreadsheet(true);
        queryClient.invalidateQueries([QueryKeys.CUSTOMER_EMPLOYEES]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.UPLOAD_CUSTOMER_EMPLOYEES_BY_SPREADSHEET_SUCCESS_MESSAGE,
          "success"
        );
      },
      onError: (error: { message?: string }) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage:
            SNACKBAR_MESSAGES.UPLOAD_CUSTOMER_EMPLOYEES_BY_SPREADSHEET_ERROR_MESSAGE,
        });
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const { mutateAsync: mutateUpdateCustomerEmployee } = useMutation(
    async (item: EditableCustomerEmployee) => customerEmployeeService.updateById(item),
    {
      onMutate: () => {
        dialogService.showDialog(<LoadingDialog message="Atualizando funcionário" />);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.CUSTOMER_EMPLOYEES]);
        dialogService.popAll();
        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE, "success");
      },
      onError: (error: { message?: string }) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE,
        });

        dialogService.popDialog();
      },
    }
  );

  const { mutate: mutateToggleState } = useMutation(
    (item: CustomerEmployee) => customerEmployeeService.toggleActive(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating CustomerEmployee(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated CustomerEmployee(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_EMPLOYEES]);

        snackbarService.showSnackbar(
          item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_SUCCESS_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_SUCCESS_MESSAGE,
          "success"
        );

        dialogService.popDialog();
      },
      onError: (error, item) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_ERROR_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_ERROR_MESSAGE,
          "error"
        );

        if (item.isActive) {
          dialogService.popDialog();
        }
      },
    }
  );

  const { mutate: mutateChangePassword } = useMutation(
    async (customerEmployeeId: string) => {
      return await authService.requestChangePassword(customerEmployeeId);
    },
    {
      onSuccess: () => {
        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.CHANGE_CUSTOMER_EMPLOYEE_PASSWORD_SUCCESS_MESSAGE,
          "success"
        );

        dialogService.popDialog();
      },
      onError: (error: { message: string }) => {
        log.e(LOG_TAG, error);
        snackbarService.showSnackbar(error.message, "error");
      },
    }
  );

  const handleChangePassword = useCallback((customerEmployeeId: string) => {
    dialogService.showDialog(
      <ChangePasswordDialog onSubmit={() => mutateChangePassword(customerEmployeeId)} />
    );
  }, []);

  const getCostCenters = useCallback(() => fetchCostCenters({ customerId }), [customerId]);

  const handleToggleState = useCallback((item: CustomerEmployee) => {
    if (!item.isActive) {
      mutateToggleState(item);
      return;
    }

    dialogService.showDialog(
      <InactivateDialog
        loadingMessage="Inativando funcionário"
        onConfirm={() => mutateToggleState(item)}
      />
    );
  }, []);

  const handleOpenCreateModal = useCallback(() => {
    dialogService.showDialog(
      <CustomerEmployeeDialog
        isNew
        fetchProfile={fetchProfile}
        user={user}
        onSubmit={mutateCreateCustomerEmployee}
        data={DEFAULT_FORM_DATA as CustomerEmployee}
        fetchCostCenters={getCostCenters}
        customerId={customerId}
      />
    );
  }, [mutateCreateCustomerEmployee, getCostCenters, customerId]);

  const handleOpenEditModal = useCallback(
    (item: CustomerEmployee) => {
      dialogService.showDialog(
        <CustomerEmployeeDialog
          data={item}
          user={user}
          fetchProfile={fetchProfile}
          onSubmit={mutateUpdateCustomerEmployee}
          fetchCostCenters={getCostCenters}
          customerId={customerId}
        />
      );
    },
    [mutateUpdateCustomerEmployee, getCostCenters, customerId]
  );

  const handleChangeSearch = useCallback((text: string) => {
    setSearchText(text);
    setCurrentPage(1);
  }, []);

  const handleCleanFilters = useCallback(() => {
    setValue("activeness", EMPTY_CUSTOMER_EMPLOYEE_FILTERS.activeness);
    setValue("profiles", EMPTY_CUSTOMER_EMPLOYEE_FILTERS.profiles);
  }, []);

  const handleOnCreateCustomerEmployeeBySpreadsheet = useCallback(
    (customerId: string) => {
      dialogService.showDialog(
        <FileUpload
          title="Arraste seus arquivos para adicioná-los"
          allowedExtensions={["xls", "xlsx"]}
          onUpload={(file: File) => {
            mutateUploadCustomerEmployeesSpreadsheet({ customerId, file });
          }}
        />
      );
    },
    [mutateUploadCustomerEmployeesSpreadsheet]
  );

  const profilesOptions = [{ label: "", value: "" },
     ...(data?.filters?.profile?.map((profile) => ({
    label: profile,
    value: profile,
  })) || [])];
  
  const activenessOptions = [{ label: "", value: "" },
     ...(data?.filters?.is_active?.map((profile) => ({
    label: profile,
    value: profile,
  })) || [])];
  

  return {
    profilesOptions,
    activenessOptions,
    data: data?.data,
    control,
    isLoading: isFetching,
    currentPage: currentPage,
    lastPage: data?.meta?.last_page || 0,
    searchText,
    hasUploadedFromSpreadsheet,
    setSearchText: handleChangeSearch,
    onGoToPage: setCurrentPage,
    onCleanFilters: handleCleanFilters,
    onCreateCustomerEmployee: handleOpenCreateModal,
    onEditCustomerEmployee: handleOpenEditModal,
    onToggleCustomerEmployeeState: handleToggleState,
    onChangePassword: handleChangePassword,
    onCreateCustomerEmployeeBySpreadsheet: handleOnCreateCustomerEmployeeBySpreadsheet,
  };
}
