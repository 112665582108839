import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form/Form";
import { FormDialog } from "~/components/FormDialog";
import { Text } from "~/components/Text";
import { HotelIssuanceDialogProps } from "./types";
import { HotelIssuanceForm } from "./HotelIssuanceForm";
import { HotelEmission, OrderItems } from "~/application/types";
import { createFormData } from "./utils";

export const HotelIssuanceDialog: FC<HotelIssuanceDialogProps> = ({
  orderHotel,
  onCloseClick,
  providers,
  isLoading,
  onSubmit: onSubmitProp,
}) => {
  const { control, formState, handleSubmit, watch, setValue } = useForm<HotelEmission>({
    defaultValues: createFormData({ data: orderHotel, providers }),
    reValidateMode: "onBlur",
    mode: "onSubmit",
  });

  const { value } = watch();

  const onSubmit = useCallback(
    (formData: HotelEmission) => {
      return onSubmitProp(formData);
    },
    [onSubmitProp]
  );
  const taxes = {
    fee: orderHotel?.fee || 0,
    markup: orderHotel?.markup || 0,
  };

  useEffect(() => {
    const { totalValue } = watch();

    if (!totalValue) {
      setValue("totalValue", value);
    }
  }, [value, setValue]);

  const totalValue = (value || 0) + taxes.markup * (value || 0) + taxes.fee * (value || 0);

  return (
    <Container
      size="8"
      fixed
      css={{
        overflow: "visible",
      }}
    >
      <Form
        css={{
          "@mxlg": {
            width: "98%",
            margin: "0 auto",
          },

          overflow: "visible",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormDialog
          css={{
            overflow: "visible",
          }}
          title="Emissão de item de hotel"
          negativeButton={
            <Button variant="tertiary" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button disabled={formState.isSubmitting} type="submit">
              <Text>Emitir</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody
            css={{
              overflow: "visible",
            }}
          >
            <HotelIssuanceForm
              control={control}
              providers={providers}
              totalValue={totalValue}
              value={value}
              isLoading={isLoading}
              isOffline={orderHotel.isOffline}
              taxes={taxes}
            />
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
};
