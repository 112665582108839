import {
  ApiError,
  Vehicle,
  VehicleQuery,
  VehicleQueryDetail,
  VehicleQueryLinks,
} from "~/application/types";
import { DateUtils } from "~/application/utils";
import { DateFormats } from "~/application/utils/date-functions";
import { api } from "~/infrastructure/api";
import { VehicleDTO, VehicleQueryDetailDTO, VehicleQueryLinksDTO } from "~/infrastructure/api/dtos";
import { mapVehicleDTO, mapVehicleDetailDTO } from "~/infrastructure/api/mappers";
import { IVehicleService } from "./IVehicleQueryService";

export class VehicleService implements IVehicleService {
  async query(
    customerId: string,
    {
      originSelected,
      destinationSelected,
      dateGetSelected,
      dateReturnSelected,
      timeReturnSelected,
      timeGetSelected,
      reasonTrip,
    }: VehicleQuery
  ): Promise<VehicleQueryLinks> {
    const checkInDate =
      DateUtils.format(dateGetSelected as Date, DateFormats.ISO_DATE) +
      "T" +
      timeGetSelected +
      ":00";

    const checkOutDate =
      DateUtils.format(dateReturnSelected as Date, DateUtils.DateFormats.ISO_DATE) +
      "T" +
      timeReturnSelected +
      ":00";

    return api
      .post<VehicleQueryLinksDTO>("/vehicles", {
        customer_uuid: customerId,
        origin_city: originSelected.uuid,
        destination_city: destinationSelected.uuid,
        checkin: checkInDate,
        checkout: checkOutDate,
        reason_trip_uuid: reasonTrip?.uuid,
      })
      .then(({ data }) => ({
        waitTime: data.wait_time,
        links: data.links,
      }));
  }

  async linkDetail({ searchKey, id }: { searchKey: string; id: number }): Promise<{
    wait_time: number;
    link: string;
  }> {
    const link = api
      .post<{
        wait_time: number;
        link: string;
      }>("/vehicles/detail", {
        search_key: searchKey,
        id,
      })
      .then(({ data }) => data);
    return link;
  }

  async findDetail(link: string): Promise<VehicleQueryDetail> {
    return api.get<VehicleQueryDetailDTO>(link).then(({ data }) => {
      if (ApiError.isAsyncError(data)) {
        throw new ApiError({ data, statusCode: 204 });
      }

      return mapVehicleDetailDTO(data);
    });
  }

  async find(url: string): Promise<Vehicle[]> {
    return api.get<{ search_key: string; vehicles: VehicleDTO[] }>(url).then(({ data }) => {
      if (!data.vehicles)
        throw new ApiError({
          data: data,
          statusCode: 204,
        });

      return data.vehicles.map((vehicle) => mapVehicleDTO(vehicle, data.search_key));
    });
  }
}
