import { PendingOrder, PendingOrderFilters } from "~/application/types";
import { toDate } from "~/application/utils/date-functions";
import { PendingOrderDTO } from "../dtos";

export function mapPendingOrderDTO(data: PendingOrderDTO): {
  orders: PendingOrder[];
  filters: PendingOrderFilters;
} {
  return {
    orders: data.orders.map(
      (order): PendingOrder => ({
        uuid: order.uuid,
        orderNumber: order.order_number,
        orderLink: order.order_link,
        expiresAt: toDate(order.expires_at),
        createdAt: toDate(order.created_at),
        isExpired: order.is_expired,
        nearestServiceDate: order.nearest_service_date,
        itemsIncluded: {
          ...(order.items_included.airway && {
            airway: {
              expiresAt: toDate(order.items_included.airway.expires_at),
              isExpired: !!order.items_included.airway.is_expired,
            },
          }),
          ...(order.items_included.offline_hotel && {
            offline_hotel: {
              expiresAt: toDate(order.items_included.offline_hotel.expires_at),
              isExpired: !!order.items_included.offline_hotel.is_expired,
            },
          }),
          ...(order.items_included.road && {
            road: {
              expiresAt: toDate(order.items_included.road.expires_at),
              isExpired: !!order.items_included.road.is_expired,
            },
          }),
          ...(order.items_included.hotel && {
            hotel: {
              expiresAt: toDate(order.items_included.hotel.expires_at),
              isExpired: !!order.items_included.hotel.is_expired,
            },
          }),
          ...(order.items_included.vehicle && {
            vehicle: {
              expiresAt: toDate(order.items_included.vehicle.expires_at),
              isExpired: !!order.items_included.vehicle.is_expired,
            },
          }),
        } as any,
        status: {
          status: order.status.status,
          createdAt: toDate(order.status.created_at),
        },
        travelers: order.travelers.map((item) => ({
          uuid: item.uuid,
          fullName: item.full_name,
          avatarUrl: item.avatar_url ?? undefined,
        })),
        customer: {
          uuid: order.customer.uuid,
          tradingName: order.customer.trading_name,
        },
        consultant: order.consultant
          ? {
              uuid: order.consultant.uuid,
              fullName: order.consultant.full_name,
            }
          : null,
        issuer: {
          uuid: order.issuer.uuid,
          name: order.issuer.name,
          email: order.issuer.email,
        },
      })
    ),
    filters: {
      consultants: data.filters.consultants,
      customers: data.filters.customers.map((customer) => ({
        uuid: customer.uuid,
        tradingName: customer.trading_name,
      })),
      status: data.filters.status,
      travelers: data.filters.travelers.map((item) => ({
        uuid: item.uuid,
        fullName: item.full_name,
        avatarUrl: item.avatar_url ?? undefined,
      })),
      issuers: data.filters.issuers.map((issuer) => ({
        uuid: issuer.uuid,
        name: issuer.name,
        email: issuer.email,
      })),
      orderNumber: data.filters.order_number,
    },
  };
}
