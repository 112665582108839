import { IGroupedData } from "./types";

export function rangedArray(length: number) {
  return Array.from(Array(length).keys());
}

export function groupArrayByKey<TData extends object>({
  data,
  keyExtract,
}: {
  data: TData[];
  keyExtract: (item: TData) => string;
}): IGroupedData<TData>[] {
  const groups = data.reduce<Record<string, TData[]>>((groups, item) => {
    const itemKey = keyExtract(item);

    if (!groups[itemKey]) {
      groups[itemKey] = [];
    }
    groups[itemKey].push(item);

    return groups;
  }, {});

  return Object.entries<TData[]>(groups).map(([key, value]) => ({
    groupKey: key,
    data: value,
  }));
}

export function arrayToObject<TData extends object>(
  data: TData[],
  keyExtract: (item: TData) => string
) {
  return Object.fromEntries(data.map((item) => [keyExtract(item), item]));
}
