import { Order, PolicyParamsSlug } from "~/application/types";
import { VehicleQuery } from "~/application/types";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { orderService } from "~/application/usecases";
import { useMemo, useReducer } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import { LoadingModal } from "../../components/LoadingModal";
import { ModalProvider } from "../../contexts/ModalContext";
import { useModal } from "../../hooks/useModal";
import { orderVehicleService } from "../../services/order-vehicle";
import { VehicleBookingProvider } from "./contexts/VehicleBookingContext";
import {
  buildVehicleBookingInfo,
  buildVehicleQuery,
  VehicleBookingActions,
  vehicleBookingReducer,
  VehicleBookingState,
} from "./utils/vehicle.utils";
import { VehicleQueryClient } from "./utils/vehicleQueryClient";
import { useVehicleBudget } from "./hooks/useVehicleBudget/useVehicleBudget";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "~/constants";

export const DEFAULT_VEHICLE_BOOKING_STATE = {
  issuerId: undefined,
  vehicleSelected: null,
  traveler: null,
} as unknown as VehicleBookingState;

export const VehicleBookingContainer = () => {
  const { user, contexts } = useUser();

  const [searchParams] = useSearchParams();
  const queryData = useMemo<VehicleQuery>(() => buildVehicleQuery(searchParams), [searchParams]);

  const orderId = searchParams.get("orderId");

  const { data: order } = useQuery(
    [QueryKeys.ORDERS, orderId],
    () => orderService.findById(orderId!),
    {
      enabled: !!orderId,
    }
  );

  // Control modals
  const { isOpen, data: modalData, actions: modalActions } = useModal();

  // Booking state variable
  const [state, dispatch] = useReducer(vehicleBookingReducer, DEFAULT_VEHICLE_BOOKING_STATE);

  // Booking state variable
  const queryClient = useMemo<VehicleQueryClient>(() => {
    return new VehicleQueryClient(queryData, {
      ...user,
      customer: contexts?.customer,
    });
  }, [queryData, contexts]);

  // Create booking information from "state" data
  const bookingInfo = useMemo(() => buildVehicleBookingInfo(state, queryData), [queryData, state]);

  // Handle booking events
  const actions = useMemo<VehicleBookingActions>(
    () => ({
      createBooking: async (issuerId?: string) => {
        modalActions.show({ message: "Efetuando sua reserva" });

        try {
          const customerId = contexts?.customer?.uuid;
          let orderId = searchParams.get("orderId");
          let order: Order;

          if (!orderId) {
            order = await orderService.create({
              customerId,
              issuerId,
              travelersIds: [bookingInfo.traveler.uuid],
              reasonTripId: queryData.reasonTrip?.uuid,
            });

            orderId = order.uuid;
          }

          await orderVehicleService.create({
            orderUuid: orderId,
            searchKey: bookingInfo?.vehicleSelected?.searchKey,
            vehicleId: bookingInfo?.vehicleSelected?.id,
            travelerUuid: bookingInfo.traveler.uuid,
            lowerFareViolated: bookingInfo.vehicleSelected?.violatedPolicies?.some(
              (policy) => policy.policyType === PolicyParamsSlug.LOWER_FARE
            ),
          });

          return orderId;
        } finally {
          modalActions.close();
        }
      },
      setVehicle: (vehicle) =>
        dispatch({
          type: "SET_GO_VEHICLE",
          payload: vehicle,
        }),
      setTraveler: (traveler) =>
        dispatch({
          type: "SET_TRAVELER",
          payload: traveler,
        }),
    }),
    [user, queryClient, bookingInfo, contexts, dispatch, modalActions]
  );
  const vehicleBudget = useVehicleBudget();

  return (
    <ModalProvider
      value={{
        isOpen,
        actions: modalActions,
      }}
    >
      <VehicleBookingProvider
        value={{
          bookingInfo,
          actions,
          queryData,
          queryClient,
          vehicleBudget,
          order,
        }}
      >
        <Outlet />
        <LoadingModal isOpen={isOpen} message={modalData.message} />
      </VehicleBookingProvider>
    </ModalProvider>
  );
};
