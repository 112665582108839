import {
  Dispatch,
  Fragment,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import type { HotelQuery, Traveler } from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Flex } from "~/components/Flex";
import { Col, Row } from "~/components/Grid";
import { Text } from "~/components/Text";
import { Caption, H4 } from "~/components/Typography";
import { AsyncSelect } from "~/presentation/shared/components/AsyncSelect";
import { formatNumber } from "~/utils/string.utils";
import { HotelBookingCart } from "../../../../../../../DeprecatedBooking/modules/BookingHotel/components/HotelBookingCart";
import { HotelRoomSelected } from "../../../../types";
import { BookingHotelSteps } from "../../utils";

export interface TabSelectGuestsProps {
  travelers?: Traveler[];
  roomsSelected?: HotelRoomSelected[];
  isShowCart: boolean;
  onSelectGuest: (guest: Traveler, roomIndex: number, position: number) => void;
  onCreateBooking: () => void;
  onCreateCustomerEmployee: () => void;
  setIsShowCart: Dispatch<SetStateAction<boolean>>;
  fetchGuests: (name: string) => Promise<Traveler[]>;
  activeStep?: BookingHotelSteps;
}

export function TabSelectGuests({
  travelers,
  roomsSelected = [],
  isShowCart,
  activeStep,
  fetchGuests,
  setIsShowCart,
  onSelectGuest,
  onCreateBooking,
  onCreateCustomerEmployee,
}: TabSelectGuestsProps) {
  const getTravelerLabel = useCallback((item: Traveler) => item.fullName, []);

  const getTravelerValue = useCallback((item: Traveler) => item.uuid, []);

  return (
    <Fragment>
      <Row>
        <Col
          sz="8"
          css={{
            "@mxlg": {
              width: "100%",
            },
          }}
        >
          <Flex
            align="center"
            direction={{ "@mxlg": "column" }}
            css={{ mb: "$10" }}
          >
            <Col>
              <H4>Adicione hóspedes</H4>
            </Col>

            <Button
              css={{
                "@mxlg": {
                  width: "80%",
                  mt: "$6",
                },
              }}
              onClick={onCreateCustomerEmployee}
              variant="secondary"
            >
              <Text>Novo funcionário</Text>
            </Button>
          </Flex>

          <Flex direction="column" gap="8">
            {roomsSelected.map(({ room, guests }, roomIndex) => (
              <Flex direction="column" gap="6" key={`room_${roomIndex}`}>
                <Text css={{ fw: "700" }}>{room.description}</Text>

                {guests.map((guest, guestIndex) => (
                  <Box key={`guest_${roomIndex}_${guestIndex}`}>
                    <Caption>{`Adulto ${formatNumber(
                      1 + guestIndex,
                      2
                    )}`}</Caption>

                    <AsyncSelect
                      fetchOptions={fetchGuests}
                      defaultOptions={travelers}
                      placeholder="Selecione um hóspede"
                      getOptionValue={getTravelerValue}
                      getOptionLabel={getTravelerLabel}
                      onChange={(value) => {
                        onSelectGuest(value, roomIndex, guestIndex);
                      }}
                    />
                  </Box>
                ))}
              </Flex>
            ))}
          </Flex>
        </Col>

        <Col
          sz="4"
          css={{
            "@mxlg": {
              width: "0",
              p: 0,
            },
          }}
        >
          <HotelBookingCart
            rooms={roomsSelected.map(({ room }) => room)}
            isShowCart={isShowCart}
            setIsShowCart={setIsShowCart}
            onGoNextStep={onCreateBooking}
            activeStep={activeStep}
          />
        </Col>
      </Row>
    </Fragment>
  );
}
