import type {
  AirwayEmission,
  DeepPartial,
  OrderAirwayItemType,
} from "~/application/types";
import { AirwayIssuanceFormData } from "./types";

export function createFormData(
  orderAirway: OrderAirwayItemType
): DeepPartial<AirwayIssuanceFormData> {
  return {
    issuances: orderAirway.flights.map((i) => ({
      uuid: i.uuid,
      tracker: i.tracker,
      travelers: i.passengers.map((p) => ({
        uuid: p.uuid,
        ticket: p.ticket,
      })),
    })),
  };
}

export function parseFormData(
  formData: AirwayIssuanceFormData
): AirwayEmission[] {
  return formData.issuances.map((i) => ({
    tracker: i.tracker,
    uuid: i.uuid,
    travelers: i.travelers,
  }));
}
