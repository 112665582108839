import { FC } from "react";
import { OrderStatus } from "~/application/types";
import { Button } from "~/components/Button";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { SvgDownload } from "~/components/Icon/icons";
import { DataColItem } from "~/components/List";
import { Text } from "~/components/Text";
import { RoadTravelerBpeInfoProps } from "./types";

export const RoadTravelerBpeInfo: FC<RoadTravelerBpeInfoProps> = ({
  seatNumber,
  travelName,
  enabled,
  ticket,
  bpe,
}) => {
  if (!enabled) return null;

  return (
    <Flex justify="between" align="center" css={{ p: "$6" }}>
      <Col sz="2">
        <DataColItem
          headerTitle="Nº do Bilhete"
          css={{ mb: "$8" }}
          boxCss={{ mt: "$2" }}
          data={<Text fw="600">{ticket}</Text>}
        />
      </Col>

      <Col sz="4">
        <DataColItem
          headerTitle={`Trecho: ${travelName}`}
          boxCss={{ mt: "$2" }}
          data={
            <Button
              css={{ width: "100%" }}
              disabled={!bpe}
              onClick={() => {
                if (bpe) window.open(bpe);
              }}
            >
              {bpe && <SvgDownload />}
              {bpe ? "Bilhete Eletrônico" : "Não Possui Bilhete Eletrônico"}
            </Button>
          }
        />
      </Col>

      <Col sz="2">
        <DataColItem
          headerTitle="Nº do assento"
          css={{ mb: "$6" }}
          boxCss={{ mt: "$2" }}
          data={<Text fw="600">{seatNumber}</Text>}
        />
      </Col>
    </Flex>
  );
};
