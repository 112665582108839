import type { Hotel } from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Col, Grid } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgChevronLeft, SvgChevronRight } from "~/components/Icon/icons";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { H4 } from "~/components/Typography";
import { StarRating } from "~/core/shared/components/StarsRating";
import { useCarousel } from "~/hooks/useCarousel";
import * as MaskUtils from "~/utils/mask.utils";
import { formatHotelAddress } from "../../../utils";
import { Checkbox } from "~/components/Input";
import { useHotelBudgetResult } from "../../HotelDetailsPage/hooks/useHotelBudget/type";
import { ViolatedPoliciesButton } from "~/presentation/shared/components/ViolatedPoliciesButton/ViolatedPoliciesButton";

interface OnMapViewData {
  description?: string;
  data?: Hotel;
  onSelect: (data: Hotel) => void;
}

export interface HotelListItemProps {
  data: Hotel;
  description?: string;
  onMapView: (data: OnMapViewData) => void;
  onSelect: (data: Hotel) => void;
  hotelBudget: useHotelBudgetResult;
}

export function HotelListItem({
  data,
  hotelBudget,
  description,
  onMapView,
  onSelect,
}: HotelListItemProps) {
  const { currentItem, toNextItem, toPreviousItem } = useCarousel({
    size: data?.hotelImages.length || 0,
  });

  const { activeBudget, onSelectHotelBudget, state } = hotelBudget;

  const isCheckedBudget = state.some((hotel) => hotel?.uuid === data?.uuid);

  const disabledButton = !((data?.hotelImages.length as number) - 1);

  return (
    <Card
      css={{
        overflow: "visible",
      }}
    >
      <Flex direction={{ "@mxlg": "column" }} align={{ "@mxlg": "center" }}>
        <Grid
          align="end"
          css={{
            p: "$8",
            width: "320px",
            height: "312px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            "@mxlg": {
              width: "100%",
              height: "$40",
            },
          }}
          style={{
            backgroundImage: `url(${data?.hotelImages[currentItem]}), url(/images/broken-image.png)`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          <Flex align="center" justify="between" gap="4">
            <Button
              variant="tertiary"
              rounded
              onClick={toNextItem}
              css={{
                "@mxlg": {
                  p: 10,
                  height: "$10",
                },
              }}
              disabled={disabledButton}
            >
              <Icon as={SvgChevronLeft} />
            </Button>

            <Button
              variant="tertiary"
              rounded
              css={{
                "@mxlg": {
                  p: 10,
                  height: "$10",
                },
              }}
              onClick={toPreviousItem}
              disabled={disabledButton}
            >
              <Icon as={SvgChevronRight} />
            </Button>
          </Flex>
        </Grid>

        <Col
          css={{
            borderRight: "1px solid $colors$neutrals-lightest",
          }}
        >
          <Flex
            align="start"
            css={{
              "@mxlg": {
                p: 15,
              },
            }}
            direction="column"
            as={CardBody}
            gap="6"
          >
            <Flex
              css={{
                "@mxlg": {
                  width: "100%",
                },
              }}
              gap="4"
              justify={{ "@mxlg": "between" }}
              align={{ "@mxlg": "center" }}
            >
              {activeBudget && (
                <Checkbox
                  checked={isCheckedBudget}
                  onChange={() => onSelectHotelBudget({ data })}
                />
              )}
              <H4
                css={{
                  "@mxlg": {
                    fontSize: "$md",
                  },
                }}
              >
                {data?.name}
              </H4>
              <Button
                css={{
                  display: "none",
                  "@mxlg": {
                    display: "flex",
                    height: "$2",
                  },
                }}
                variant="tertiary"
                onClick={() => onMapView({ data, onSelect, description })}
              >
                <Text
                  css={{
                    "@mxlg": {
                      fontSize: "12px",
                    },
                  }}
                >
                  Veja no mapa
                </Text>
              </Button>
            </Flex>

            <StarRating stars={data?.stars || 0} />

            <Text variant="dark" size="3" css={{ fw: "500" }}>
              {formatHotelAddress(data)}.
            </Text>
            {!!data?.violatedPolicies?.length && (
              <ViolatedPoliciesButton data={data.violatedPolicies} />
            )}
            <Flex gap="2" align="center">
              <Button
                css={{
                  "@mxlg": {
                    display: "none",
                  },
                }}
                variant="tertiary"
                onClick={() => onMapView({ data, onSelect, description })}
              >
                <Text>Veja no mapa</Text>
              </Button>

              <Tag variant="neutral-light">{data?.provider.name}</Tag>
            </Flex>
          </Flex>
        </Col>

        <Flex
          direction="column"
          align={{ "@initial": "end", "@mxlg": "center" }}
          as={CardBody}
          gap="6"
          css={{
            "@mxlg": {
              mb: "$5",
              width: "100%",
            },
          }}
        >
          {description && (
            <Text as="p" size="2" variant="dark">
              {description}
            </Text>
          )}

          <H4>{MaskUtils.asCurrency(data?.bestValueTotal || 0)}</H4>

          <Box
            css={{
              mt: "auto",
              "@mxlg": {
                width: "90%",
              },
            }}
          >
            <Button
              css={{
                "@mxlg": {
                  width: "100%",
                },
              }}
              disabled={activeBudget}
              onClick={() => onSelect(data)}
            >
              <Text>Ver opções</Text>
            </Button>
          </Box>
        </Flex>
      </Flex>
    </Card>
  );
}

HotelListItem.displayName = "HotelListItem";
