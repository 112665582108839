import { Flex } from "~/components/Flex";
import { IOrderAirwayItemSegment } from "../OrderAirwayItemSegment";
import { Card, CardBody } from "~/components/Card";
import { Col } from "~/components/Grid";
import { Text } from "~/components/Text";
import { LazyList } from "~/components/LazyList";
import { PassengerListItem } from "~/presentation/shared/components/PassengerListItem";
import { ReasonRejectedItem } from "~/presentation/shared/components/ReasonRejectedItem";
import { ReprovalMessage } from "../../ApprovalMessage";
import { OrderApprovalItem, canMarkAsRejected } from "~/presentation/shared/utils";
import { useOrder } from "~/presentation/shared/hooks/useOrder";
import { RemoveItemButton } from "../../RemoveItemButton";
import { ApprovalOrderContext } from "../../../hooks/ApprovalOrderContext";
import { useContext } from "react";
import { CombinedOrderAirwayItemCard } from "./CombinedOrderAirwayItemCard";
import { Button } from "~/components/Button";
import { OrderStatus } from "~/application/types";

export function CombinedOrderAirwayItemSegment({
  item,
  onOpenDetails,
  onDeleteItem,
  onOpenAirwaySeats,
  onRemoveSeat,
  itemIndex
}: IOrderAirwayItemSegment) {
  const { items } = useContext(ApprovalOrderContext);

  const { order } = useOrder();
  const reasonRejected = item.reasonRejected;
  const shouldMarkAsRejected = canMarkAsRejected({
    reasonRejected: reasonRejected,
    order,
  });

  const canShowReprovalMessage =
    items?.rejected && items.rejected.some(({ itemOrderId }) => itemOrderId === item.uuid);

  const airwayItem = {
    item: OrderApprovalItem.AIRWAY_ORDERS,
    itemOrderId: item.uuid,
  };

  const orderStatus = order?.status;

  return (
    <Flex direction="column" gap="4">
      <Card
        css={{
          overflow: "visible",
          border: shouldMarkAsRejected ? "1px solid red" : "none",
          backgroundColor: "transparent",
        }}
      >
        <CardBody
          css={{
            p: "0",
          }}
        >
          <Flex direction="column" gap="6">
            <Flex>
              <Col>
                <Text size="5">Passagem</Text>
              </Col>
            </Flex>

            <CombinedOrderAirwayItemCard
              data={item}
              itemIndex={itemIndex}
              onOpenDetails={onOpenDetails}
              onDeleteItem={onDeleteItem}
              canShowReprovalMessage={false}
            />

            {item.passengers.length > 0 && <Text size="5">Passageiros</Text>}

            <LazyList
              items={item.passengers}
              render={(traveler) => (
                <PassengerListItem
                  data={traveler}
                  item={item}
                  key={traveler.uuid}
                  onRemoveSeat={onRemoveSeat}
                />
              )}
              skeletonHeight={88}
              skeletonQuantity={2}
            />
          </Flex>

          {shouldMarkAsRejected && (
            <ReasonRejectedItem css={{ mt: "$6" }} reasonRejected={reasonRejected} />
          )}

          {canShowReprovalMessage && <ReprovalMessage itemIndex={itemIndex} item={airwayItem} />}
        </CardBody>
      </Card>
      
      <Flex
        justify="end"
        css={{
          width: "100%",
        }}
        gap="2"
      >
        {item.availableSeatMap && orderStatus === OrderStatus.OPEN && !order?.isExpired && (
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              onOpenAirwaySeats?.({
                itemId: item.uuid,
                passengers: item.passengers,
                item,
              });
            }}
          >
            <Text size="3">Marcar assento</Text>
          </Button>
        )}
        <RemoveItemButton onRemove={() => onDeleteItem?.(item.uuid)} />
      </Flex>
    </Flex>
  );
}
