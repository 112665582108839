import * as React from "react";
import { SVGProps } from "react";

const SvgMap = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24px" height="24px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M2.5 15.625h-.625a.625.625 0 0 0 .777.606l-.152-.606Zm0-11.25-.152-.606a.625.625 0 0 0-.473.606H2.5Zm5-1.25.28-.559a.625.625 0 0 0-.432-.047l.152.606Zm5 13.75-.28.559a.625.625 0 0 0 .432.047l-.152-.606Zm5-12.5h.625a.625.625 0 0 0-.777-.606l.152.606Zm0 11.25.152.606a.625.625 0 0 0 .473-.606H17.5ZM7.348 13.769l-5 1.25.304 1.212 5-1.25-.304-1.212Zm-4.223 1.856V4.375h-1.25v11.25h1.25ZM2.652 4.981l5-1.25-.304-1.212-5 1.25.304 1.212Zm10.127 11.335-5-2.5-.559 1.118 5 2.5.56-1.118Zm-4.654-1.941V3.125h-1.25v11.25h1.25ZM7.22 3.684l5 2.5.56-1.118-5-2.5-.56 1.118Zm4.655 1.941v11.25h1.25V5.625h-1.25Zm.777.606 5-1.25-.304-1.212-5 1.25.304 1.212Zm4.223-1.856v11.25h1.25V4.375h-1.25Zm.473 10.644-5 1.25.304 1.212 5-1.25-.304-1.212Z" />
  </svg>
);

export default SvgMap;
