import { api } from "~/infrastructure/api";
import { IProviderService } from "./IProviderService";
import { mapProviderDTO } from "~/infrastructure/api/mappers/mapProviderDTO";
import { Provider } from "~/application/types";

export class OrderProvider implements IProviderService {
  async find(): Promise<Provider[]> {
    return await api
      .get(`/providers`)
      .then(({ data }) => mapProviderDTO(data));
  }
}
