import { PendingOrder } from "~/application/types";
import { IPendingOrdersFilters } from "../PendingOrdersContainer";

export enum PendingOrdersTab {
  PENDING = "pendentes",
  PROCESSED = "processados",
}

type OrderItems = 'hotel' | 'airway' | 'vehicle' |  'road';


export const PENDING_ORDERS_MENU_TABS = [
  { title: "Pendentes", id: PendingOrdersTab.PENDING },
  { title: "Processados", id: PendingOrdersTab.PROCESSED },
];

export function filterOrders(orders?: PendingOrder[], filters?: IPendingOrdersFilters) {
  return orders?.filter((order) => {
    const matchesConsultant =
      !filters?.consultants.length ||
      filters?.consultants.some(
        (filter) => filter.value === order?.consultant?.uuid
      );

    const matchesCustomer =
      !filters?.customers.length ||
      filters?.customers.some(
        (filter) => filter.value === order.customer.uuid
      );

    const matchesStatus =
      !filters?.status.length ||
      filters?.status.some((filter) => filter.value === order.status.status);

    const matchesTraveler =
      !filters?.travelers.length ||
      order.travelers.some((traveler) =>
        filters?.travelers.some(
          (filter) => filter.value === traveler.uuid
        )
      );

      const matchesOrderNumber =
      !filters?.orderNumber || order.orderNumber.toString() === filters.orderNumber;


      const matchesOrderItem =
      !filters?.orderItem?.value ||
      (order.itemsIncluded && order?.itemsIncluded[filters.orderItem.value as OrderItems]);

    return (
      matchesConsultant &&
      matchesCustomer &&
      matchesStatus &&
      matchesTraveler &&
      matchesOrderNumber &&
      matchesOrderItem
    );
  });
}