import { Passenger } from "~/application/types";
import { DateUtils } from "~/application/utils";
import { Card } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgTrash } from "~/components/Icon/icons";
import { Text } from "~/components/Text";
import { CombinedOrderAirwayItemSegmentType } from "~/core/modules/Order/pages/OrderPage/views/OrderItem/components/OrderAirwayItem";

export interface PassengerListItemProps {
  data: Passenger;
  item?: CombinedOrderAirwayItemSegmentType;
  onRemoveSeat?: ({ orderItemId, seatId }: { orderItemId: string; seatId: string }) => void;
}

export function PassengerListItem({ data, item, onRemoveSeat, ...props }: PassengerListItemProps) {
  const baggage = item?.servicesIncluded?.baggage;
  const customerDateOfBirth = DateUtils.smallDateFormat(data.dateBirth);

  return (
    <Card css={{ overflow: "visible" }}>
      <Flex
        css={{
          borderRadius: "$md",
          backgroundColor: "$neutrals-white",
          width: "100%",
          p: "$6",
        }}
      >
        <Flex gap="4" direction="column" css={{ width: "100%" }}>
          <Flex justify="between" direction={{ "@mxmd": "column" }} gap="4">
            <Col
              sz="2"
              css={{
                "@mxmd": {
                  width: "100%",
                },
              }}
            >
              <Flex
                direction={{ "@initial": "column", "@mxmd": "row" }}
                align={{ "@mxmd": "center" }}
              >
                <Text
                  size="2"
                  variant="dark"
                  css={{
                    mb: "1rem",
                    fw: "500",
                    display: "block",
                    "@mxmd": {
                      mr: "$3",
                      display: "none",
                    },
                  }}
                >
                  Nome
                </Text>

                <Text size={{ "@initial": "2", "@mxmd": "3" }} css={{ fw: "600" }}>
                  {data.fullName}
                </Text>
              </Flex>
            </Col>

            <Col
              sz="4"
              css={{
                "@mxmd": {
                  width: "100%",
                  p: 0,
                  height: 5,
                },
              }}
            >
              <Flex direction={{ "@initial": "column", "@mxmd": "row" }}>
                <Text
                  size="2"
                  variant="dark"
                  css={{
                    mb: "1rem",
                    fw: "500",
                    display: "block",
                    "@mxmd": {
                      mr: "$3",
                    },
                  }}
                >
                  Ticket
                </Text>

                <Text
                  size="2"
                  css={{
                    fw: "600",
                    "@mxmd": {
                      color: "#727272",
                    },
                  }}
                >
                  {data.ticket}
                </Text>
              </Flex>
            </Col>

            <Col
              sz="2"
              css={{
                "@mxmd": {
                  width: "100%",
                },
              }}
            >
              <Flex direction={{ "@initial": "column", "@mxmd": "row" }}>
                <Text
                  size="2"
                  variant="dark"
                  css={{
                    mb: "1rem",
                    fw: "500",
                    display: "block",
                    "@mxmd": {
                      mr: "$3",
                    },
                  }}
                >
                  Quantidade de bagagem
                </Text>

                <Text size="2" css={{ fw: "600", wordBreak: "break-all", color: "#727272" }}>
                  {baggage}
                </Text>
              </Flex>
            </Col>
            <Col
              sz="2"
              css={{
                "@mxmd": {
                  width: "100%",
                },
              }}
            >
              <Flex direction={{ "@initial": "column", "@mxmd": "row" }}>
                <Text
                  size="2"
                  variant="dark"
                  css={{
                    mb: "1rem",
                    fw: "500",
                    display: "block",
                    "@mxmd": {
                      mr: "$3",
                    },
                  }}
                >
                  Data de nascimento
                </Text>

                <Text size="2" css={{ fw: "600", wordBreak: "break-all", color: "#727272" }}>
                  {customerDateOfBirth}
                </Text>
              </Flex>
            </Col>
          </Flex>

          {!!data.seats?.length && (
            <Flex gap="4">
              {data.seats?.map((seat, index) => {
                return (
                  <Flex direction="row" gap="1" align="center" key={seat.uuid}>
                    <Flex
                      justify="between"
                      css={{
                        cursor: "pointer",
                        backgroundColor: "$primary-light",
                        borderRadius: "$md",

                        p: "$3",
                        ["&:hover"]: {
                          backgroundColor: "$error-light",
                          [`& ${Text}`]: {
                            color: "$error-base",
                          },
                          [`& ${Icon}`]: {
                            fill: "$error-base",
                          },
                        },
                      }}
                      gap="2"
                      align="center"
                      onClick={() =>
                        onRemoveSeat?.({
                          orderItemId: item?.uuid || "",
                          seatId: data?.seats?.at(index)?.uuid || "",
                        })
                      }
                    >
                      <Flex gap="1">
                        <Text variant="primary" size="2" fw="600">
                          {seat.seatNumber}
                        </Text>
                      </Flex>
                      <Icon as={SvgTrash} variant="primary" />
                    </Flex>

                    <Flex direction="column" gap="2">
                      <Text variant="primary" size="1" fw="600">
                        {
                          item?.segments
                            .concat(item.combined || [])
                            .find(
                              (seg) =>
                                seg.arrivalFlightInfo.shortAirport === seat.arrival &&
                                seg.departureFlightInfo.shortAirport === seat.departure
                            )?.departureFlightInfo.shortAirport
                        }
                      </Text>
                      <Text variant="primary" size="1" fw="600">
                        {
                          item?.segments
                            .concat(item.combined || [])
                            .find(
                              (seg) =>
                                seg.arrivalFlightInfo.shortAirport === seat.arrival &&
                                seg.departureFlightInfo.shortAirport === seat.departure
                            )?.arrivalFlightInfo.shortAirport
                        }
                      </Text>
                    </Flex>
                  </Flex>
                );
              })}
            </Flex>
          )}
        </Flex>
      </Flex>
    </Card>
  );
}
