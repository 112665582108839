import { FC, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BiztripLogo } from "~/components/BiztripLogo";
import { Box } from "~/components/Box";
import { Container } from "~/components/Container";
import { NavMenu } from "../NavMenu";
import { ProfileMenu } from "../ProfileMenu";
import { Styled } from "./styled";
import { useRemakeSearch } from "~/core/shared/contexts/remakeSearchContext";
import {
  SvgChevronDown,
  SvgChevronUp,
  SvgExpenses,
  SvgMenu,
} from "~/components/Icon/icons";

import Close from "~/components/Icon/icons/Close";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { OrderItems } from "~/application/types";
import { Col } from "~/components/Grid/Col";
import { Text } from "~/components/Text";
import { MenuItemList, MenuItems } from "../NavMenuMobile/NavMenuMobile";
import { routes } from "~/application/theme/routes";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { asCurrency } from "~/utils/mask.utils";
import { Collapse } from "react-collapse";
import { Card, CardBody } from "~/components/Card";
import { Button } from "~/components/Button";
import { CreditLimit } from "../CreditLimits/CreditLimit";

export const NavBar: FC = () => {
  const navigate = useNavigate();
  const { data, currentStep } = useRemakeSearch();
  const { contexts, user } = useUser();
  const location = useLocation();

  const [searchIsOpen, setSearchIsOpen] = useState(true);
  const [requestsIsOpen, setRequestsIsOpen] = useState(false);
  const [expanseIsOpen, setExpanseIsOpen] = useState(false);
  const [configIsOpen, setConfigIsOpen] = useState(false);
  const [orderIsOpen, setOrdersIsOpen] = useState(false);
  const [openInvoiceLimit, setOpenInvoiceLimit] = useState(false);

  const handleWhatsappMessage = () => {
    const redirectUrl =
      "https://api.whatsapp.com/send/?phone=5599985442224&text=Ol%C3%A1+Kennedy+Viagens%2C+gostaria+de+falar+com+o+setor+financeiro.&type=phone_number&app_absent=0";
    window.open(redirectUrl);
  };

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const isMobile = useMobile();

  return (
    <Styled.Container css={{ position: "relative", p: "$5" }} as={Container}>
      <Box>
        {isMobile ? <SvgMenu onClick={() => setMenuIsOpen(true)} /> : null}
      </Box>

      {menuIsOpen ? (
        <Box
          css={{
            "@mxxl": {
              width: "80%",
              height: "100vh",
              position: "absolute",
              transition: "ease-in-out",
              zIndex: "$overlay",
              top: "0",
              left: "0",
              backgroundColor: "White",
            },
          }}
        >
          <Box
            css={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "$10",
              height: "$20",
            }}
          >
            <BiztripLogo size="lg" />
            <Close onClick={() => setMenuIsOpen(false)} />
          </Box>
          <Box>
            <MenuItemList
              css={{}}
              data-active={location.pathname.startsWith("/busca")}
              isOpen={searchIsOpen}
              onItemExpand={() => setSearchIsOpen((old) => !old)}
              item={{
                type: MenuItems.SEARCH,
              }}
              orderId={"orderId"}
              key={OrderItems.ROAD}
            >
              <Col css={{ ml: "$18" }}>
                <Text
                  onClick={() => {
                    setMenuIsOpen(false);
                    navigate(routes.Booking.Airway.root);
                  }}
                  as="p"
                  css={{ fw: "500" }}
                >
                  Aéreo
                </Text>{" "}
                <br />
                <Text
                  onClick={() => {
                    navigate(routes.Booking.Hotel.root);
                    setMenuIsOpen(false);
                  }}
                  as="p"
                  css={{ fw: "500" }}
                >
                  Hotéis
                </Text>{" "}
                <br />
                <Text
                  onClick={() => {
                    navigate(routes.Booking.Road.root);
                    setMenuIsOpen(false);
                  }}
                  as="p"
                  css={{ fw: "500" }}
                >
                  Ônibus
                </Text>{" "}
                <br />
                <Text
                  onClick={() => {
                    setMenuIsOpen(false);
                    navigate(routes.Booking.Vehicle.root);
                  }}
                  as="p"
                  css={{ fw: "500" }}
                >
                  Carros
                </Text>{" "}
                <br />
              </Col>
            </MenuItemList>
          </Box>
          <Box>
            <MenuItemList
              isOpen={requestsIsOpen}
              data-active={location.pathname.startsWith("/pedidos")}
              onItemExpand={() => setRequestsIsOpen((old) => !old)}
              item={{
                type: MenuItems.ORDER,
              }}
              orderId={"orderId"}
              key={OrderItems.ROAD}
            >
              <Col css={{ ml: "$18" }}>
                <Text
                  onClick={() => {
                    setMenuIsOpen(false);
                    navigate(routes.Orders.CustomerOrders.root);
                  }}
                  as="p"
                  css={{ fw: "500" }}
                >
                  Meus pedidos
                </Text>{" "}
                <br />
                {/* <Text
                  onClick={() => {
                    navigate(routes.Orders.Advances.root);
                    setMenuIsOpen(false);
                  }}
                  as="p"
                  css={{ fw: "500" }}
                >
                  Adiantamentos
                </Text>{" "}
                <br /> */}
              </Col>
            </MenuItemList>
          </Box>
          <Box>
            <MenuItemList
              css={{}}
              isOpen={expanseIsOpen}
              data-active={location.pathname.startsWith("/despesas")}
              onItemExpand={() => setExpanseIsOpen((old) => !old)}
              item={{
                type: MenuItems.EXPENSE,
              }}
              orderId={"orderId"}
              key={OrderItems.ROAD}
            >
              <Col css={{ ml: "$18" }}>
                <Text
                  onClick={() => {
                    setMenuIsOpen(false);
                    navigate("/despesas");
                  }}
                  as="p"
                  css={{ fw: "500" }}
                >
                  Despesas
                </Text>{" "}
                <br />
              </Col>
            </MenuItemList>
          </Box>
          <Box>
            <MenuItemList
              css={{
                hover: {
                  outline: "none",
                },
              }}
              isOpen={configIsOpen}
              onItemExpand={() => setConfigIsOpen((old) => !old)}
              item={{
                type: MenuItems.CONFIG,
              }}
              orderId={"orderId"}
              key={OrderItems.ROAD}
            >
              <Col css={{ ml: "$18" }}>
                <Text
                  onClick={() => {
                    navigate(routes.Configuration.Parameters.Customers.root);
                    setMenuIsOpen(false);
                  }}
                  as="p"
                  css={{ fw: "500" }}
                >
                  Parâmetros
                </Text>{" "}
                <br />
                <Text
                  onClick={() => {
                    navigate(routes.Configuration.CostCenters.root);
                    setMenuIsOpen(false);
                  }}
                  as="p"
                  css={{ fw: "500" }}
                >
                  Centros de custo
                </Text>{" "}
                <br />
                <Text
                  onClick={() => {
                    navigate(routes.Configuration.CustomerEmployees.root);
                    setMenuIsOpen(false);
                  }}
                  as="p"
                  css={{ fw: "500" }}
                >
                  Funcionários
                </Text>
              </Col>
            </MenuItemList>
          </Box>
        </Box>
      ) : null}

      <Box
        css={{
          flexBasis: "16%",
        }}
      >
        <BiztripLogo
          size="lg"
          onClick={() => {
            if (data.road && currentStep !== data.road.length) return;
            navigate("/");
          }}
        />
      </Box>

      {isMobile && contexts.agency && (
        <Box
          css={{
            "@mxxl": {
              width: "80%",
              height: "100vh",
              position: "absolute",
              transition: "ease-in-out",
              display: menuIsOpen ? "block" : "none",
              zIndex: "$overlay",
              top: "0",
              left: "0",
              backgroundColor: "White",
            },
          }}
        >
          <Box
            css={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "$10",
              height: "$20",
            }}
          >
            <BiztripLogo size="lg" />
            <Close onClick={() => setMenuIsOpen(false)} />
          </Box>
          <Box>
            <MenuItemList
              css={{
                hover: {
                  outline: "none",
                },
              }}
              isOpen={orderIsOpen}
              onItemExpand={() => setOrdersIsOpen((old) => !old)}
              item={{
                type: MenuItems.ORDER,
              }}
              orderId={"orderId"}
              key={OrderItems.ROAD}
            >
              <Col css={{ ml: "$18" }}>
                <Text
                  onClick={() => {
                    navigate(routes.Agencies.Orders.root);
                    setMenuIsOpen(false);
                  }}
                  as="p"
                  css={{ fw: "500" }}
                >
                  Ver pedidos
                </Text>{" "}
                <br />
                <Text
                  onClick={() => {
                    navigate(routes.Orders.PendingOrders.root);
                    setMenuIsOpen(false);
                  }}
                  as="p"
                  css={{ fw: "500" }}
                >
                  Pedidos pendentes
                </Text>{" "}
              </Col>
            </MenuItemList>
          </Box>
          <Box>
            <MenuItemList
              css={{
                hover: {
                  outline: "none",
                },
              }}
              isOpen={configIsOpen}
              onItemExpand={() => setConfigIsOpen((old) => !old)}
              item={{
                type: MenuItems.CONFIG,
              }}
              orderId={"orderId"}
              key={OrderItems.ROAD}
            >
              <Col css={{ ml: "$18" }}>
                <Text
                  onClick={() => {
                    navigate(routes.Configuration.Customers.root);
                    setMenuIsOpen(false);
                  }}
                  as="p"
                  css={{ fw: "500" }}
                >
                  Empresas
                </Text>{" "}
                <br />
                <Text
                  onClick={() => {
                    navigate(routes.Configuration.Fees.root);
                    setMenuIsOpen(false);
                  }}
                  as="p"
                  css={{ fw: "500" }}
                >
                  Taxas
                </Text>{" "}
                <br />
                <Text
                  onClick={() => {
                    navigate(routes.Configuration.Markups.root);
                    setMenuIsOpen(false);
                  }}
                  as="p"
                  css={{ fw: "500" }}
                >
                  Markups
                </Text>
                <br />
                <Text
                  onClick={() => {
                    navigate(routes.Configuration.Markdowns.root);
                    setMenuIsOpen(false);
                  }}
                  as="p"
                  css={{ fw: "500" }}
                >
                  Markdowns
                </Text>
                <br />
              </Col>
            </MenuItemList>
          </Box>
        </Box>
      )}

      <Box
        css={{
          flex: 1,
          "@mxlg": {
            display: "none",
          },
        }}
      >
        <NavMenu />
      </Box>

      {user.customer && !isMobile && <CreditLimit />}

      <ProfileMenu />
    </Styled.Container>
  );
};

NavBar.displayName = "NavBar";
