export const mask = "dD/mM/YYYY";

export const formatChars = {
  Y: "[0-9]",
  d: "[0-3]",
  D: "[0-9]",
  m: "[0-1]",
  M: "[1-9]",
};

export const controlValueMask = (value: string) => {
  const dateParts = value.split("/");
  const dayPart = dateParts[0];
  const monthPart = dateParts[1];

  if (!dayPart || !monthPart) return value;

  // Conditional mask for the 2nd digit of day based on the first digit
  if (dayPart.startsWith("3")) formatChars["D"] = "[0-1]";
  // To block 39, 32, etc.
  else if (dayPart.startsWith("0")) formatChars["D"] = "[1-9]";
  // To block 00.
  else formatChars["D"] = "[0-9]"; // To allow 05, 15, 25  etc.

  // Conditional mask for the 2nd digit of month based on the first digit
  if (monthPart.startsWith("1")) formatChars["M"] = "[0-2]";
  // To block 15, 16, etc.
  else formatChars["M"] = "[1-9]"; // To allow 05, 06  etc - but blocking 00.

  return value;
};
