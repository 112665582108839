import { searchMenuItems } from "~/application/data";
import { AgencyEmployeeType, User, UserActions, UserContexts } from "~/application/types";

import { Actions, UserContext } from "~/application/types";
import { useUser } from "~/presentation/core/contexts/UserContext";

export type NavMenuModule = "search" | "settings" | "expenses" | "order" | "dashboard" | undefined;

export type NavMenuItemType = {
  title: string;
  href: string;
  requiredContext?: UserContext;
  requiredActions: Actions[];
};

export const filterMenuItems = (
  items: NavMenuItemType[],
  actions: UserActions,
  contexts: UserContexts,
  user?: User
): NavMenuItemType[] => {
  const searchMenuTitles = searchMenuItems.map((menuItem) => menuItem.title);
  return items
    .filter((item) => {
      if (
        item.title.includes("Relatórios") &&
        !!contexts.customer &&
        item.requiredContext === UserContext.Agency
      ) {
        return false;
      }

      if (!!contexts?.agency && !!contexts.customer && searchMenuTitles.includes(item.title)) {
        return true;
      }
      if (user?.agency && !item.title.includes("Meus pedidos") && !!contexts.customer) {
        return true;
      }
      if (!!contexts.customer && item.title === "Ver pedidos") {
        return false;
      }

      if (
        item.title.includes("Relatórios") &&
        user?.type !== AgencyEmployeeType.MANAGER &&
        !contexts.customer
      ) {
        return false;
      }

      if (!item.requiredContext) {
        return true;
      }

      return (
        item.requiredActions.every((action) => actions[action]) && contexts[item.requiredContext]
      );
    })
    .map((item) => {
      if (!item.requiredContext) {
        return item;
      }

      const isAgencyAsCustomerEmployee = contexts.agency && contexts.customer;
      const isSeeOrdersMenu = item.title.includes("Ver pedidos");

      if (
        isAgencyAsCustomerEmployee &&
        isSeeOrdersMenu &&
        item.requiredContext.includes(UserContext.Agency) &&
        !item.title.includes("(Agência)")
      ) {
        item.title += " (Agência)";
      } else if (
        isAgencyAsCustomerEmployee &&
        isSeeOrdersMenu &&
        item.requiredContext.includes(UserContext.Customer) &&
        !item.title.includes("(Empresa)")
      ) {
        item.title += " (Empresa)";
      }

      return item;
    });
};
