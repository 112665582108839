// Used for create Modals

import React, { useMemo, useState } from "react";
import { Dialog, DialogContent, DialogOverlay, DialogPortal } from "./Dialog";

export type ModalPortalController = {
  setRender: (render: React.ReactNode) => void;
  render: () => React.ReactNode;
  show: VoidFunction;
  hide: VoidFunction;
};

export type ModalPortalProps = {
  isOpen?: boolean;
  children?: React.ReactNode;
};

export type UseModalPortalReturn = {
  isOpen: boolean;
  controller: ModalPortalController;
};

/**
 * @deprecated
 */
export const useModalPortal = (): UseModalPortalReturn => {
  const [isOpen, setOpen] = useState(false);
  const [renderer, setRenderer] = useState<React.ReactNode>();

  const controller = useMemo(
    () => ({
      setRender: (render: React.ReactNode) => setRenderer(render),
      render: () => renderer,
      show: () => setOpen(true),
      hide: () => setOpen(false),
    }),
    [setOpen, setRenderer, renderer]
  );

  return { isOpen, controller };
};

/**
 * @deprecated
 */
export const ModalPortal = ({ isOpen, children }: ModalPortalProps) => {
  return (
    <Dialog open={isOpen} modal>
      <DialogPortal>
        <DialogOverlay />
        <DialogContent>{children}</DialogContent>
      </DialogPortal>
    </Dialog>
  );
};

ModalPortal.displayName = "ModalPortal";
