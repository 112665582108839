import { CredentialsContainer } from "./CredentialContainer";
import { useCallback, useState } from "react";
import { dialogService } from "~/components/DialogStack";
import { CredentialDialog, CredentialDialogForm } from "./components/CredentialDialog";
import { useMutation, useQuery } from "@tanstack/react-query";
import { QueryKeys, QueryTimes } from "~/constants";
import { snackbarService } from "~/components/SnackbarStack";
import { log } from "~/application/utils/log";
import { LOG_TAG } from "../AgenciesOrdersPage/types";
import { credentialService } from "~/application/usecases/Credentials";
import { Provider } from "~/application/types";
import { LoadingDialog } from "~/core/shared/components/LoadingDialog";
import { queryClient } from "~/services/queryClient";
import { OrderProviderService } from "~/application/usecases/Provider";
import { SubmissionData } from "~/application/usecases/Credentials/ICredentialService";
import { useDebounce } from "use-debounce";

const DEFAULT_FORM_DATA: CredentialDialogForm = {
  provider: {},
} as CredentialDialogForm;

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Erro ao buscar fornecedores",
  LOAD_SEARCH_ERROR_MESSAGE: "Erro ao buscar credencial",
  UPDATE_SUCCESS_MESSAGE: "Credencial atualizada com sucesso.",
  UPDATE_ERROR_MESSAGE: "Erro ao atualizar credencial",
  CREATE_SUCCESS_MESSAGE: "Credencial cadastrada com sucesso",
  CREATE_ERROR_MESSAGE: "Erro ao criar a credencial",
  ACTIVE_SUCCESS_MESSAGE: "Credencial ativada",
  ACTIVE_CUSTOMER_SUCCESS_MESSAGE: "Empresa ativada",
  INACTIVE_CUSTOMER_CREDENTIAL_MESSAGE: "Empresa inativada",
  INACTIVE_CUSTOMER_CREDENTIAL_ERROR_MESSAGE: "Falha ao inativar empresa",
  ACTIVE_ERROR_MESSAGE: "Falha ao ativar credencial",
  ACTIVE_CUSTOMER_ERROR_MESSAGE: "Falha ao ativar empresa",
  INACTIVE_ERROR_MESSAGE: "Falha ao inativar credencial",
  INACTIVE_SUCCESS_MESSAGE: "Credencial inativada",
};

export function CredentialsPage() {
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [debouncedText] = useDebounce(searchText, 700);
  const handleChangeSearch = useCallback((text: string) => {
    setSearchText(text);
    setCurrentPage(1);
  }, []);

  const { mutate: handleCreateCredential } = useMutation(
    (data: SubmissionData) => credentialService.create(data),
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Criando credencial" />);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.CREDENTIALS]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CREATE_SUCCESS_MESSAGE, "success");
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE, "error");
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const fetchCredentialCustomerOnClick = ({
    credentialId,
    currentPage,
    searchText,
  }: {
    credentialId: string;
    currentPage: number;
    searchText: string;
  }) => {
    return useQuery(
      [QueryKeys.CUSTOMER_CREDENTIAL, credentialId, currentPage, searchText],
      () =>
        credentialService.customers({ credentialId, page: currentPage, trading_name: searchText }),
      {
        enabled: false,
      }
    );
  };

  const { mutate: handleToggleCustomer } = useMutation(
    ({ agreementId, isActive }: { agreementId: string; refetch: () => void; isActive: boolean }) =>
      credentialService.toggleAgreement({ agreementId, isActive }),
    {
      onMutate: ({ isActive }) => {
        dialogService.popDialog();
        dialogService.showDialog(
          <LoadingDialog message={`${isActive ? "Inativando a empresa" : "Ativando a empresa"}`} />
        );
      },
      onSuccess: (_, vars) => {
        const { refetch, isActive } = vars;

        refetch();
        snackbarService.showSnackbar(
          isActive
            ? SNACKBAR_MESSAGES.INACTIVE_CUSTOMER_CREDENTIAL_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVE_CUSTOMER_SUCCESS_MESSAGE,
          "success"
        );
      },
      onError: (error, vars) => {
        const { isActive } = vars;
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          isActive
            ? SNACKBAR_MESSAGES.INACTIVE_CUSTOMER_CREDENTIAL_ERROR_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVE_CUSTOMER_ERROR_MESSAGE,
          "error"
        );
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const { mutate: handleActiveCredential } = useMutation(
    (credentialId: string) => credentialService.active(credentialId),
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Ativando credencial" />);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.CREDENTIALS]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.ACTIVE_CUSTOMER_SUCCESS_MESSAGE, "success");
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.ACTIVE_CUSTOMER_ERROR_MESSAGE, "error");
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const { mutate: handleInactiveCredential } = useMutation(
    (credentialId: string) => credentialService.inactive(credentialId),
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Inativando credencial" />);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.CREDENTIALS]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.INACTIVE_SUCCESS_MESSAGE, "success");
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.INACTIVE_ERROR_MESSAGE, "error");
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const { mutate: handleUpdateCredential } = useMutation(
    (data: SubmissionData) => credentialService.update(data),
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Atualizando credencial" />);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.CREDENTIALS]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE, "success");
      },
      onError: (error) => {
        log.e(LOG_TAG, error);
        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE, "error");
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const { data: credentials, isLoading: isLoadingCredential } = useQuery(
    [QueryKeys.CREDENTIALS, currentPage, debouncedText],
    () => credentialService.find({ provider: debouncedText, page: currentPage }),
    {
      staleTime: QueryTimes.LONG,
      refetchOnWindowFocus: true,

      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.LOAD_SEARCH_ERROR_MESSAGE, "error");
      },
    }
  );

  const fetchParameter = useCallback(
    (provider: Provider) => credentialService.findByProvider(provider?.uuid),
    []
  );

  const fetchProviders = useCallback(() => OrderProviderService.find(), []);

  const onOpenCreateDialog = useCallback(() => {
    dialogService.showDialog(
      <CredentialDialog
        isNew
        defaultFormData={DEFAULT_FORM_DATA}
        onCloseClick={() => dialogService.popDialog()}
        fetchCredentials={fetchParameter}
        fetchProviders={fetchProviders}
        onSubmitProp={handleCreateCredential}
      />
    );
  }, [fetchParameter, handleCreateCredential]);

  const onOpenEditDialog = useCallback(
    (item: CredentialDialogForm) => {
      dialogService.showDialog(
        <CredentialDialog
          defaultFormData={item}
          onCloseClick={() => dialogService.popDialog()}
          fetchCredentials={fetchParameter}
          fetchProviders={fetchProviders}
          onSubmitProp={handleUpdateCredential}
        />
      );
    },
    [fetchParameter, handleCreateCredential]
  );

  return (
    <CredentialsContainer
      credentials={credentials}
      searchText={searchText}
      currentPage={currentPage}
      handleToggleCustomer={handleToggleCustomer}
      lastPage={credentials?.meta.last_page || 1}
      isLoading={isLoadingCredential}
      onGoToPage={setCurrentPage}
      setSearchText={handleChangeSearch}
      fetchCredentialCustomerOnClick={fetchCredentialCustomerOnClick}
      onOpenCreateDialog={onOpenCreateDialog}
      onActive={handleActiveCredential}
      onInactive={handleInactiveCredential}
      onOpenEditDialog={onOpenEditDialog}
    />
  );
}
