import React from "react";
import { BoxProps } from "../Box";
import { Flex } from "../Flex";

export type AppBarActionsProps = {
  children: React.ReactNode;
};

export const AppBarActions = ({ children }: BoxProps) => (
  <Flex gap="6" justify="end">
    {children}
  </Flex>
);

AppBarActions.displayName = "AppBarActions";
