import queryString from "query-string";
import { Fragment, useCallback, useMemo, useState } from "react";
import { routes } from "~/application/theme/routes";
import type { Hotel, HotelQuery, Order } from "~/application/types";
import { toISOString } from "~/application/utils/date-functions";
import { Alert } from "~/components/Alert";
import { AppBar } from "~/components/AppBar";
import { Box } from "~/components/Box";
import { Card, CardBody, CardSectionHeader } from "~/components/Card";
import { CartHeader } from "~/components/Cart";
import { Container } from "~/components/Container";
import { Flex } from "~/components/Flex";
import { Col, Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgClose, SvgFilter } from "~/components/Icon/icons";
import { Checkbox, SearchBar } from "~/components/Input";
import { Spinner } from "~/components/Spinner";
import { Text } from "~/components/Text";
import { H3, H5 } from "~/components/Typography";
import { FilterListItem } from "~/core/modules/Booking/components/FilterListItem";
import { OfflineQuote } from "~/presentation/shared/components/OfflineQuote";
import { pluralizeWord } from "~/utils/string.utils";
import { HotelBookingBar } from "../../../../../DeprecatedBooking/modules/BookingHotel/components/HotelBookingBar";
import { Filter } from "../../../../utils";
import { formatDailyGuestText } from "../../utils";
import { HotelListItem } from "./components/HotelListItem";
import { HotelsLazyList } from "./components/HotelsLazyList";
import { DateFormats, displayDate } from "~/utils/date.utils";
import MapImage from "~/assets/images/map.png";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { ButtonMap } from "./components/ButtonMap";
import { Slider } from "~/components/Slider";
import { asCurrency } from "~/utils/mask.utils";
import { Button } from "~/components/Button";
import {
  HotelBudgetActionType,
  useHotelBudgetResult,
} from "../HotelDetailsPage/hooks/useHotelBudget/type";
import { dialogService } from "~/components/DialogStack";
import { HotelBudgetDialog } from "../HotelDetailsPage/hooks/useHotelBudget/components/HotelBudgetDialog";
import { useUser } from "~/presentation/core/contexts/UserContext";

export interface HotelsContainer {
  data: Hotel[];
  dataDefault: Hotel[];
  filters: Filter[];
  hotelQuery: HotelQuery;
  isLoading: boolean;
  isSomeSettled: boolean;
  searchText: string;
  order?: Order;
  setSearchText: (searchO: string) => void;
  onFilterChange: (groupKey: string, optionKey: string) => void;
  onMapView: ({
    onSelect,
    data,
    description,
  }: {
    description?: string;
    data?: Hotel;
    onSelect: (data: Hotel) => void;
  }) => void;
  onSelect: (item: Hotel) => void;
  onSearchHotels: (data: HotelQuery) => void;
  rangeValue: {
    max?: number;
    min?: number;
    providersFinished: number;
  };
  onRangeValue: ({
    max,
    min,
    providersFinished,
  }: {
    max: number;
    min: number;
    providersFinished: number;
  }) => void;
  countProvidersFinished: number;
  hotelBudget: useHotelBudgetResult;
}

export function HotelsContainer({
  data,
  dataDefault,
  filters,
  isLoading,
  hotelQuery,
  searchText,
  rangeValue,
  isSomeSettled,
  hotelBudget,
  onRangeValue,
  setSearchText,
  onFilterChange,
  onMapView,
  onSelect,
  onSearchHotels,
  countProvidersFinished,
  order,
}: HotelsContainer) {
  const hotelDailyGuestText = useMemo<string>(() => formatDailyGuestText(hotelQuery), [hotelQuery]);

  const { activeBudget, onActiveBudget, state, dispatch } = hotelBudget;
  const { contexts, user } = useUser();

  const isMobile = useMobile();

  const [isShowFilter, setIsShowFilter] = useState(false);

  const displayText = useMemo(() => {
    return `Encontramos ${data.length} ${pluralizeWord("hotel", "hotéis", data.length)} para ${[
      hotelQuery?.city?.name,
      hotelQuery?.city?.state,
      hotelQuery?.city?.country,
    ]
      .filter(Boolean)
      .join(", ")}`;
  }, [data, hotelQuery]);

  const listRenderer = useCallback(
    (item: Hotel) => (
      <HotelListItem
        data={item}
        description={hotelDailyGuestText}
        onMapView={onMapView}
        onSelect={onSelect}
        hotelBudget={hotelBudget}
        key={item?.uuid}
      />
    ),
    [hotelDailyGuestText, onMapView, onSelect, hotelBudget]
  );

  const filterRenderer = useCallback(
    (item: Filter) => (
      <FilterListItem
        data={item}
        onFilterChange={onFilterChange}
        key={item.key}
        isLoading={false}
      />
    ),
    [onFilterChange, isLoading]
  );

  const handleGeneratePdf = useCallback(
    () =>
      dialogService.showDialog(
        <HotelBudgetDialog
          data={state}
          customer={contexts.customer}
          user={user}
          hotelParams={hotelQuery}
        />
      ),
    [state, contexts.customer, user]
  );

  const orderId = window.location.search.split("&orderId=")[1];

  const quoteUrl = queryString.stringifyUrl({
    url: routes.Booking.OfflineHotel.root,
    query: {
      city: JSON.stringify(hotelQuery?.city),
      accommodations: JSON.stringify(hotelQuery?.accommodations),
      checkIn: toISOString(hotelQuery?.checkInDate as Date),
      checkOut: toISOString(hotelQuery?.checkOutDate as Date),
      orderId,
    },
  });

  const allHotelBudget = state?.length === data?.length;

  const handleSelectallVehicleBudget = useCallback(() => {
    if (allHotelBudget) {
      dispatch?.({
        paylod: [],
        type: HotelBudgetActionType.ADD,
      });
      return;
    }
    dispatch?.({
      paylod: data,
      type: HotelBudgetActionType.ADD,
    });
  }, [allHotelBudget, data]);

  return (
    <Fragment>
      <AppBar>
        <HotelBookingBar
          data={hotelQuery}
          onSubmit={onSearchHotels}
          hotelBudget={hotelBudget}
          order={order}
        />
      </AppBar>

      <Container
        css={{
          "@mxlg": {
            p: 15,
          },
        }}
      >
        <Flex
          justify="center"
          css={{
            "@lg": {
              display: "none",
            },
          }}
        >
          <Box css={{ py: "$1", width: "100%" }}>
            <Box
              css={{
                textAlign: "center",
                backgroundColor: "#0064C5",
                p: "$3",
                color: "#FFF",
                fw: "500",
                borderRadius: "$md",
                borderTopRightRadius: "0",
                borderBottomRightRadius: "0",
              }}
            >
              <Text>Check-In</Text>
              <br />
              <Text css={{ fontSize: "10px" }}>
                {displayDate(hotelQuery?.checkInDate as Date, DateFormats.BASIC_DATE)}
              </Text>
            </Box>
          </Box>
          <Box css={{ py: "$1", width: "100%" }}>
            <Box
              css={{
                textAlign: "center",
                backgroundColor: "#FFF",
                p: "$3",
                fw: "500",
                borderRadius: "$md",
                borderTopLeftRadius: "0",
                borderBottomLeftRadius: "0",
              }}
            >
              <Text>Check-Out</Text>
              <br />
              <Text css={{ fontSize: "10px" }}>
                {displayDate(hotelQuery?.checkOutDate as Date, DateFormats.BASIC_DATE)}
              </Text>
            </Box>
          </Box>
        </Flex>
        <Box
          css={{
            my: "$20",
            display: "flex",
            height: "$10",
            "@mxlg": {
              my: "$8",
              justifyContent: "space-around",
              direction: "column",
            },
          }}
        >
          <Flex gap="8">
            <H3
              css={{
                "@mxlg": {
                  display: "none",
                },
              }}
            >
              {displayText}
            </H3>
            {isMobile && (
              <ButtonMap
                hotelDailyGuestText={hotelDailyGuestText}
                disabled={!data.length}
                onSelect={onSelect}
                onMapView={onMapView}
              />
            )}
          </Flex>

          <Col
            sz="3"
            css={{
              "@mxlg": {
                width: "50%",
                display: "flex",
                justifyContent: "end",
                overflow: "hidden",
              },
            }}
          >
            <Card
              css={{
                width: "130px",
                border: "1px solid #166DB1",
                "@lg": {
                  display: "none",
                },
              }}
            >
              <CartHeader
                css={{
                  height: "38.5px",
                  background: "$neutrals-lightest",
                }}
                onClick={() => setIsShowFilter(true)}
              >
                <Icon fill="#0064C5" as={SvgFilter} />
                <Text css={{ fontSize: "12px", color: "#0064C5" }}>Filtros</Text>
              </CartHeader>
            </Card>
          </Col>
        </Box>

        <Row
          gap="8"
          css={{
            "@mxlg": {
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            },
          }}
        >
          <Col
            sz="3"
            css={{
              "@mxlg": {
                display: "none",
              },
            }}
          >
            <Flex direction="column" gap="3" align="center">
              <Flex
                align="center"
                justify="center"
                css={{
                  width: "100%",
                  height: "150px",
                  backgroundImage: `url(${MapImage})`,
                  borderRadius: "$md",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <ButtonMap
                  hotelDailyGuestText={hotelDailyGuestText}
                  disabled={!data.length}
                  onSelect={onSelect}
                  onMapView={onMapView}
                />
              </Flex>
              <Card
                css={{
                  width: "100%",
                }}
              >
                <CartHeader>
                  <Icon as={SvgFilter} />
                  <Text>Filtrar resultados</Text>
                </CartHeader>

                <>
                  <CardBody css={{ p: "$4", width: "100%" }}>
                    <Flex
                      direction="column"
                      align="center"
                      gap="2"
                      css={{
                        width: "100%",
                      }}
                    >
                      <SearchBar
                        style={{
                          width: "100%",
                        }}
                        search={searchText}
                        onSearchChange={setSearchText}
                        placeholder="Nome do hotel"
                      />
                    </Flex>
                  </CardBody>
                  <CardSectionHeader>Valor total</CardSectionHeader>
                  {rangeValue.providersFinished === countProvidersFinished && (
                    <CardBody css={{ p: "$4" }}>
                      <Flex align="center" gap="1" justify="center" direction="column">
                        <Flex
                          justify="between"
                          align="center"
                          css={{
                            width: "80%",
                          }}
                        >
                          <Flex direction="column" align="center">
                            <Text size="2" fw="600">
                              Min
                            </Text>
                            <Text size="1">{asCurrency(rangeValue.min || 0)}</Text>
                          </Flex>
                          <Flex direction="column" align="center">
                            <Text size="2" fw="600">
                              Max
                            </Text>
                            <Text size="1">{asCurrency(rangeValue.max || 0)}</Text>
                          </Flex>
                        </Flex>
                        <Slider
                          css={{
                            width: "70%",
                          }}
                          max={dataDefault[(dataDefault.length || 0) - 1]?.bestValueTotal}
                          min={dataDefault[0]?.bestValueTotal}
                          onValueChange={([min, max]) =>
                            onRangeValue({
                              min,
                              max,
                              providersFinished: countProvidersFinished,
                            })
                          }
                          defaultValue={[
                            dataDefault[0]?.bestValueTotal || 0,
                            dataDefault[(dataDefault.length || 0) - 1]?.bestValueTotal || 0,
                          ]}
                        />
                      </Flex>
                    </CardBody>
                  )}
                  {filters.map(filterRenderer)}
                </>
              </Card>
            </Flex>
          </Col>

          <Col
            sz="9"
            css={{
              "@mxlg": {
                width: "100%",
              },
            }}
          >
            <Flex direction="column" gap="6">
              <Flex
                justify="between"
                gap="2"
                css={{
                  width: "100%",
                }}
              >
                {activeBudget && (
                  <Flex align="center" gap="2">
                    <Checkbox checked={allHotelBudget} onChange={handleSelectallVehicleBudget} />
                    <Text size="3">Selecionar todos desta página</Text>
                  </Flex>
                )}
                <Flex></Flex>

                <Flex gap="2">
                  {activeBudget && (
                    <Button onClick={handleGeneratePdf} disabled={!state.length}>
                      {" "}
                      Gerar PDF
                    </Button>
                  )}
                  <Button
                    variant="secondary-light"
                    css={{
                      backgroundColor: activeBudget ? "$neutrals-black" : "transparent",
                      color: activeBudget ? "$neutrals-white" : "$neutrals-darkest",
                    }}
                    disabled={!data.length}
                    onClick={() => onActiveBudget(!activeBudget)}
                  >
                    Cotação eletrônica
                  </Button>
                </Flex>
              </Flex>

              {isLoading && (
                <Box>
                  <Alert variant="info">
                    <Spinner size="sm" />
                    <Text css={{ lineHeight: "$6" }}>
                      Aguarde, estamos procurando as melhores condições para sua hospedagem
                    </Text>
                  </Alert>
                </Box>
              )}

              <HotelsLazyList
                quoteUrl={quoteUrl}
                isLoading={!isSomeSettled}
                items={data}
                gap="6"
                render={listRenderer}
                skeletonQuantity={10}
                skeletonHeight={312}
              />

              {isSomeSettled && (!data || !data.length) && <OfflineQuote quoteUrl={quoteUrl} />}
            </Flex>
          </Col>
        </Row>
        <Card
          css={{
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            borderRadius: "0",
            width: isShowFilter ? "95%" : "0",
            transition: "$slow",
            border: "0",
            zIndex: "999",
          }}
        >
          <Flex align="center" justify="between">
            <H5 css={{ ml: "$5", mt: "$5" }}>Filtrar resultados</H5>
            <Icon as={SvgClose} onClick={() => setIsShowFilter(false)} />
          </Flex>
          <CardBody
            css={{
              width: "90%",
              margin: "0 auto",
              mt: "$10",
            }}
          >
            {filters.map(filterRenderer)}
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
}
