import type { Assessment } from "~/application/types";
import { api } from "~/infrastructure/api";
import { AssessmentDTO } from "~/infrastructure/api/dtos";
import { mapAssessmentDTO } from "~/infrastructure/api/mappers";
import { mapOrderApprovalRejectedItems } from "~/services/resources/OrderApproval/OrderApprovalService";
import type { CreateAssessmentParams, IAssessmentService } from "./IAssessmentService";

export class AssessmentService implements IAssessmentService {
  async create({
    orderId,
    costCenter,
    observation,
    reasonTrip,
    branchId,
    approvers,
    canApproveInstantly,
    rejectedItems,
    project,
    agencyObs,
  }: CreateAssessmentParams): Promise<Assessment> {
    const url = `/orders/${orderId}/assessments`;
    const data: Record<string, any> = {
      branch_uuid: branchId,
      cost_center_uuid: costCenter.uuid,
      approvers_uuid: approvers,
      reason_approval_agency: agencyObs,
      reason_trip_uuid: reasonTrip?.uuid,
      approve_instantly: canApproveInstantly,
      rejected_items: [],
      project_uuid: project?.uuid,
    };

    if (!canApproveInstantly) {
      data.rejected_items = mapOrderApprovalRejectedItems(rejectedItems);
    }

    if (observation) {
      data.obs = observation;
    }

    return await api
      .post<{ assessment: AssessmentDTO }>(url, data)
      .then(({ data }) => mapAssessmentDTO(data.assessment));
  }
}
