import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col, Row } from "~/components/Grid";
import { Select, Switch, TextInput } from "~/components/Input";
import { Text } from "~/components/Text";
import { QueryKeys } from "~/constants";
import { Agreement } from "~/application/types/entities/BindCredential.type";
import { AsyncSelect } from "~/presentation/shared/components/AsyncSelect";
import { AgreementData } from "~/application/usecases/Credentials/ICredentialService";
import { Credential } from "~/application/types/entities/Credential.type";
import { Spinner } from "~/components/Spinner";

const credentialSchema = yup.object().shape({
  credential: yup.object().shape({
    uuid: yup.string().required("A credencial é obrigatória"),
    description: yup.string().nullable(),
  }),
  codeAgreement: yup.string().required("A descrição é obrigatória"),
  agreementComparation: yup.object().shape({
    uuid: yup.string(),
    codeAgreement: yup.string(),
  }).nullable(),
});

type CredentialSchema = yup.InferType<typeof credentialSchema>;

export interface CredentialDialogProps {
  isNew?: boolean;
  defaultData?: Agreement;
  onCloseClick?: () => void;
  onSubmitProp: (data: AgreementData) => void;
  fetchCredential: (name: string) => Promise<Credential[]>;
  fetchAgreements: () => Promise<Agreement[]>;
}

export function BindCredentialDialog({
  isNew = false,
  defaultData,
  onCloseClick,
  fetchAgreements,
  fetchCredential,
  onSubmitProp,
}: CredentialDialogProps) {
  const { control, handleSubmit, setValue, formState } = useForm<CredentialSchema>({
    defaultValues: defaultData,
    resolver: yupResolver(credentialSchema),
  });

  const isComparatableState =
    isNew || !defaultData?.agreementComparation ? false : true;

  const [isComparable, setIsComparable] = useState<boolean>(isComparatableState);

  const descriptionAgreementDefaultValue =
    !isComparable || !defaultData?.agreementComparation
      ? { uuid: "", codeAgreement: "" }
      : defaultData.agreementComparation;

  const { data: credentialData, isLoading } = useQuery(
    [QueryKeys.CREDENTIALS],
    () => fetchCredential("")
  );

  const { data: agreementData, isLoading: isLoadingAgreement } = useQuery<
    Agreement[]
  >([QueryKeys.AGREEMENTS], () => fetchAgreements());

  useEffect(() => {
    if (!isComparable || !isNew) {
      setValue("agreementComparation", descriptionAgreementDefaultValue);
    }
  }, [isComparable, isNew]);

  const agreementDataFiltered = isNew
    ? agreementData
    : agreementData?.filter(
        (agreement) => agreement.uuid !== defaultData?.uuid
      );

  const parseFormData = (data: CredentialSchema) => {
    return {
      credentialId: data.credential?.uuid || "",
      agreementId: defaultData?.uuid,
      agreementComparationId: !isComparable
        ? null
        : data.agreementComparation?.uuid,
      codeAgreement: data.codeAgreement,
    };
  };

  const onSubmit = (data: CredentialSchema) => {
    const formData = parseFormData(data as CredentialSchema);
    onSubmitProp(formData);
  };

  return (
    <Container size="8" fixed>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormDialog
          title="Vincular credencial"
          negativeButton={
            <Button variant="tertiary" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button type="submit" disabled={formState.isSubmitting || isLoading || isLoadingAgreement}>
              {formState.isSubmitting && (
                <Spinner 
                  css={{ 
                    borderLeftColor: "$neutrals-white", 
                    width: "$4", 
                    height: "$4", 
                    borderWidth: "2px" 
                  }} 
                />
              )}
              <Text>{isNew ? "Adicionar" : "Aplicar"}</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ p: "$6" }}>
            <Row css={{ mb: "$5" }}>
              <Col sz="12">
                <FormControl name="credential" control={control} required>
                  <FieldLabel>Credencial</FieldLabel>
                  <AsyncSelect
                    isLoading={isLoading}
                    placeholder="Selecione o fornecedor"
                    defaultOptions={credentialData}
                    fetchOptions={fetchCredential}
                    getOptionValue={(credential) => credential.uuid}
                    getOptionLabel={(credential) => credential.description}
                  />
                  {formState.errors.credential?.uuid && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.credential.uuid.message}
                    </Text>
                  )}
                </FormControl>
              </Col>
            </Row>

            <Row>
              <Col sz="12">
                <FormControl name="codeAgreement" control={control} required>
                  <FieldLabel>Descrição</FieldLabel>
                  <TextInput />
                  {formState.errors.codeAgreement && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.codeAgreement.message}
                    </Text>
                  )}
                </FormControl>
              </Col>
            </Row>

            <Row css={{ mt: "$4" }}>
              <FieldLabel>Compara com outra credencial?</FieldLabel>
              <Switch
                checked={isComparable}
                onChange={() => setIsComparable((old) => !old)}
                css={{ width: "fit-content" }}
              />
            </Row>

            <Row css={{ mt: "$4" }}>
              <Col sz="12">
                <FormControl
                  name="agreementComparation"
                  disabled={!isComparable}
                  control={control}
                >
                  <FieldLabel>Compara com:</FieldLabel>
                  <Select
                    isLoading={isLoadingAgreement}
                    placeholder="Selecione o fornecedor"
                    options={agreementDataFiltered}
                    getOptionValue={(agreement) => agreement.uuid}
                    getOptionLabel={(agreement) => agreement.codeAgreement}
                  />
                  {formState.errors.agreementComparation?.uuid && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.agreementComparation.uuid.message}
                    </Text>
                  )}
                </FormControl>
              </Col>
            </Row>
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
}
