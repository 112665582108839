import { FC, Fragment, useCallback, useEffect } from "react";
import dayjs from "dayjs";

import { MaskUtils } from "~/application/utils";
import { Container } from "~/components/Container";
import { Flex } from "~/components/Flex";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { Col, Grid, Row } from "~/components/Grid";
import { SvgCalendar, SvgExpenses, SvgMail, SvgPix } from "~/components/Icon/icons";
import { MaskedInput, NumberInput, Select, TextInput } from "~/components/Input";
import { MultiSelect } from "~/components/Input/MultiSelect";
import { Pagination } from "~/components/Pagination/Pagination";
import { REGIME_OPTIONS } from "../../types";
import { OfflineHotelOptionProps } from "./types";
import { Label } from "~/components/Typography";
import {
  Popover,
  PopoverAnchor,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from "~/core/shared/components/Popover";
import { Card, CardBody } from "~/components/Card";
import { DoubleCalendar } from "~/core/shared/components/DoubleCalendar";
import { DateFormats, displayDate } from "~/utils/date.utils";
import { DialogFooter } from "~/components/Dialog";
import { Button } from "~/components/Button";
import { Text } from "~/components/Text";
import { rangedArray } from "~/utils/array.utils";
import { formatNumber } from "~/utils/string.utils";
import {
  SelectButton,
  SelectContent,
  SelectItem,
  SelectItemText,
  SelectTrigger,
  SelectValue,
  Select as SelectHours,
} from "~/core/shared/components/SelectButton/Select";

export const OfflineHotelOption: FC<OfflineHotelOptionProps> = ({
  item,
  index,
  control,
  currentRoomOptionIndex,
  optionsToReplicate,
  isCopy,
  isEditing,
  amenities,
  isLoadingAmenities,
  isSameAmenity,
  isSameRoomRegimen,
  hasAmenitiesSelected,
  hasRegimenValue,
  currentPage,
  fields,
  field,
  hotelFee,
  hotelMarkup,
  providerValue,
  roomTypeOptions,
  setValue,
  setHotelFee,
  setHotelMarkup,
  setProviderValue,
  setCurrentPage,
  hotelFeeType,
  options,
}) => {
  const hotelOtherTaxes = options?.at(index)?.otherTaxes || 0;
  const currentOption = options?.at(index);
  const checkIn = new Date(currentOption?.checkIn as Date);
  const checkOut = new Date(currentOption?.checkOut as Date);
  checkIn.setHours(0, 0, 0, 0);
  checkOut.setHours(0, 0, 0, 0);
  const hotelDaily = dayjs(checkOut).diff(dayjs(checkIn), "day");
  const hotelDailyValue = providerValue * (hotelMarkup / 100 + 1) + hotelFee || 0;
  const hotelTotalValue = hotelDailyValue * hotelDaily + hotelOtherTaxes;

  const handleDateClick = useCallback(
    (date: Date) => {
      const checkIn = options.at(index)?.checkIn
        ? new Date(options.at(index)?.checkIn as Date)
        : null;
      const checkOut = options.at(index)?.checkOut
        ? new Date(options.at(index)?.checkOut as Date)
        : null;

      if (!checkIn) {
        setValue(`options.${index}.checkIn`, date);
      } else if (!checkOut) {
        if (date.getTime() > checkIn.getTime()) {
          setValue(`options.${index}.checkOut`, date);
        } else {
          setValue(`options.${index}.checkOut`, checkIn);
          setValue(`options.${index}.checkIn`, date);
        }
      } else if (checkIn && checkOut) {
        setValue(`options.${index}.checkIn`, date);
        setValue(`options.${index}.checkOut`, undefined);
      } else {
        setValue(`options.${index}.checkIn`, date);
      }
    },
    [options, setValue, index]
  );

  const excludeDates = useCallback(() => {
    setValue(`options.${index}.checkIn`, undefined);
    setValue(`options.${index}.checkOut`, undefined);
  }, []);

  useEffect(() => {
    if (isCopy) {
      setValue(`options.${index}.replicateRoom`, {
        uuid: optionsToReplicate?.at(currentRoomOptionIndex ?? 0)?.uuid as string,
        name: optionsToReplicate?.at(currentRoomOptionIndex ?? 0)?.name as string,
      });
    }
  }, [isCopy]);
  return (
    <Flex key={`field-${field.id}`} direction="column" gap="4">
      <Row gap="2">
        <Popover
          css={{
            position: "relative",
          }}
        >
          <Flex
            gap="2"
            css={{
              "@mxlg": {
                flexDirection: "column",
              },
            }}
          >
            <Col>
              <FormControl name={`options.${index}.checkIn`} control={control} required>
                <FieldLabel>CheckIn</FieldLabel>

                <PopoverTrigger asChild>
                  <TextInput
                    size="sm"
                    onBlur={(e) => e.preventDefault()}
                    placeholder="Data de check-in"
                    leftIcon={SvgCalendar}
                    readOnly
                    value={checkIn ? displayDate(checkIn, DateFormats.LONG_DATE) : ""}
                  />
                </PopoverTrigger>

                <PopoverAnchor />
              </FormControl>
            </Col>
            <Col>
              <FormControl name={`options.${index}.checkOut`} control={control} required>
                <FieldLabel>Check-out</FieldLabel>

                <PopoverTrigger asChild>
                  <TextInput
                    data-size="sm"
                    onBlur={(e) => e.preventDefault()}
                    size="sm"
                    placeholder="Data da check-out"
                    leftIcon={SvgCalendar}
                    readOnly
                    value={checkOut ? displayDate(checkOut, DateFormats.LONG_DATE) : ""}
                  />
                </PopoverTrigger>
              </FormControl>
            </Col>
          </Flex>

          <PopoverContent>
            <Card
              css={{
                position: "absolute",
                width: "40vw",
                "@mxlg": {
                  width: "80vw",
                },
              }}
            >
              <DoubleCalendar
                date={checkIn || new Date()}
                activeMinDate={checkIn as Date}
                activeMaxDate={checkOut as Date}
                onDateClick={handleDateClick}
              />

              <DialogFooter>
                <CardBody>
                  <Flex gap="4" css={{ justifyContent: "flex-end" }}>
                    <PopoverClose asChild>
                      <Button variant="tertiary" onClick={excludeDates}>
                        <Text>Excluir</Text>
                      </Button>
                    </PopoverClose>

                    <PopoverClose asChild>
                      <Button variant="secondary">
                        <Text>Aplicar</Text>
                      </Button>
                    </PopoverClose>
                  </Flex>
                </CardBody>
              </DialogFooter>
            </Card>
          </PopoverContent>
        </Popover>
      </Row>
      <Row
        gap="6"
        css={{
          "@mxlg": {
            flexDirection: "column",
          },
        }}
      >
        <Col>
          <FormControl name={`options.${index}.description`} control={control} required>
            <FieldLabel>Nome do Hotel</FieldLabel>
            <TextInput placeholder="Insira o nome do hotel" />
          </FormControl>
        </Col>

        <Col>
          <FormControl name={`options.${index}.address`} control={control} required>
            <FieldLabel>Endereço do hotel</FieldLabel>
            <TextInput placeholder="Insira o endereço do hotel" />
          </FormControl>
        </Col>

        <Col>
          <FormControl name={`options.${index}.phone`} control={control}>
            <FieldLabel>Telefone</FieldLabel>
            <MaskedInput
              placeholder="(11) 9 1234-5678"
              type="tel"
              mask={MaskUtils.pickPhoneNumberMask(field?.phone || "")}
            />
          </FormControl>
        </Col>
      </Row>

      <Row
        gap="6"
        css={{
          "@mxlg": {
            flexDirection: "column",
          },
        }}
      >
        <Col>
          <FormControl name={`options.${index}.email`} control={control}>
            <FieldLabel>Email de contato do hotel</FieldLabel>
            <TextInput placeholder="Insira o email de contato do hotel" leftIcon={SvgMail} />
          </FormControl>
        </Col>

        <Col>
          <FormControl name={`options.${index}.roomType`} control={control} required>
            <FieldLabel>Tipo de quarto</FieldLabel>
            <Select
              placeholder="Selecione o tipo de quarto"
              options={roomTypeOptions}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.name}
            />
          </FormControl>
        </Col>

        <Col>
          <FormControl name={`options.${index}.regimen`} control={control} required>
            <FieldLabel>Opções de regime</FieldLabel>
            <Select
              placeholder="Selecione o regime"
              options={REGIME_OPTIONS}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.name}
            />
          </FormControl>
          <Col css={{ color: "red" }}>
            {hasRegimenValue && !isSameRoomRegimen && (
              <Label css={{ color: "red" }}>Regime diferente da opção cadastrada no pedido</Label>
            )}
          </Col>
        </Col>
      </Row>

      {isCopy && (
        <Col>
          <FormControl name={`options.${index}.replicateRoom`} control={control} required>
            <FieldLabel>Replicar para qual quarto</FieldLabel>
            <Select
              placeholder="Selecione o quarto"
              options={optionsToReplicate}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.name}
            />
          </FormControl>
        </Col>
      )}

      <Row gap="6">
        <Col>
          <FormControl name={`options.${index}.amenities`} control={control} required>
            <FieldLabel>Necessidades mínimas</FieldLabel>
            <MultiSelect
              placeholder="Selecione as necessidades mínimas"
              options={amenities}
              isLoading={isLoadingAmenities}
              getOptionValue={(option) => option.uuid}
              getOptionLabel={(option) => option.name}
            />
          </FormControl>

          <Col>
            {hasAmenitiesSelected && !isSameAmenity && (
              <Label css={{ color: "red" }}>
                Necessidade mínima diferente da opção cadastrada no pedido
              </Label>
            )}
          </Col>
        </Col>

        <Flex align="center">
          <FieldLabel>Métodos de Pagamento</FieldLabel>
        </Flex>
      </Row>

      <Row
        gap="6"
        css={{
          "@mxlg": {
            flexDirection: "column",
          },
        }}
      >
        <Col>
          <FormControl name={`options.${index}.paymentPix`} control={control}>
            <FieldLabel>Chave Pix</FieldLabel>
            <TextInput placeholder="Insira a chave pix" leftIcon={SvgPix} />
          </FormControl>
        </Col>

        <Col>
          <FormControl name={`options.${index}.paymentCreditCard`} control={control}>
            <FieldLabel>Número do cartão</FieldLabel>
            <TextInput placeholder="Insira o número do cartão" />
          </FormControl>
        </Col>
      </Row>

      <Flex
        gap="2"
        css={{
          "@mxlg": {
            flexDirection: "column",
          },
        }}
      >
        <Col>
          <FormControl name={`options.${index}.providerValue`} control={control}>
            <FieldLabel>Valor diária (Hotel)</FieldLabel>
            <NumberInput
              placeholder="0,00 R$"
              allowNegative={false}
              leftIcon={SvgExpenses}
              onChange={({ target }) => {
                const providerValue = parseFloat(target.value) || 0;
                setValue(`options.${index}.providerValue`, providerValue);
                setProviderValue(providerValue);
              }}
            />
          </FormControl>
        </Col>

        <Col>
          <FormControl name={`options.${index}.hotelMarkup`} control={control}>
            <FieldLabel>Markup</FieldLabel>
            <NumberInput
              placeholder="% Markup"
              suffix="%"
              allowNegative={false}
              defaultValue={hotelMarkup}
              onChange={({ target }) => {
                const markup = parseFloat(target.value) || 0;

                setHotelMarkup(markup);
                setValue(`options.${index}.hotelMarkup`, markup);
              }}
            />
          </FormControl>
        </Col>

        <Col>
          <FormControl name={`options.${index}.hotelFee`} control={control}>
            <FieldLabel>Fee</FieldLabel>
            {hotelFeeType === "percent" ? (
              <NumberInput
                placeholder="% Fee"
                suffix="%"
                allowNegative={false}
                defaultValue={hotelFee}
                onChange={({ target }) => {
                  const hotelFee = parseFloat(target.value) || 0;
                  setValue(`options.${index}.hotelFee`, hotelFee);
                  setHotelFee(hotelFee);
                }}
              />
            ) : (
              <NumberInput
                placeholder="Fee"
                allowNegative={false}
                defaultValue={hotelFee}
                leftIcon={SvgExpenses}
                onChange={({ target }) => {
                  const hotelFee = parseFloat(target.value) || 0;
                  setValue(`options.${index}.hotelFee`, hotelFee);
                  setHotelFee(hotelFee);
                }}
              />
            )}
          </FormControl>
        </Col>
        <Col>
          <FormControl name={`options.${index}.otherTaxes`} control={control}>
            <FieldLabel>Outras taxas</FieldLabel>
            <NumberInput placeholder="Outras taxas" allowNegative={false} leftIcon={SvgExpenses} />
          </FormControl>
        </Col>

        <Col>
          <FormControl name={`options.${index}.customerValue`} control={control}>
            <FieldLabel>Valor total</FieldLabel>
            <NumberInput
              value={hotelTotalValue}
              prefix=" R$: "
              style={{ color: "#636363", background: "#F0F2F5" }}
              disabled
            />
          </FormControl>
        </Col>
      </Flex>

      <Row gap="6">
        <Col>
          <FormControl name={`options.${index}.obsAgency`} control={control}>
            <FieldLabel>Observações ao emissor (Consultor)</FieldLabel>
            <TextInput placeholder="Observações ao consultor" />
          </FormControl>
        </Col>
      </Row>

      <Row gap="6">
        <Col>
          <FormControl name={`options.${index}.obsIssuer`} control={control}>
            <FieldLabel>Observações ao cliente (Solicitante)</FieldLabel>
            <TextInput placeholder="Observações ao solicitante" />
          </FormControl>
        </Col>

        {!isEditing && (
          <Container>
            <Pagination
              activePage={currentPage}
              pagesCount={fields.length || 1}
              setActivePage={setCurrentPage}
            />
          </Container>
        )}
      </Row>
    </Flex>
  );
};
