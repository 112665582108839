import { Order, Traveler } from "~/application/types";
import { Vehicle } from "~/application/types";
import { VehicleQuery, VehicleQueryLinks } from "~/application/types";
import { Person } from "../../../types";
import { AppError } from "~/application/types";
import { toDate } from "~/utils/date.utils";
import { vehicleQueryService } from "~/application/usecases";

export type VehicleBookingState = {
  issuerId?: string;
  vehicleSelected?: Vehicle;
  traveler: Traveler;
};

export type VehicleBookingInfo = VehicleBookingState & {
  query: VehicleQuery;
  price: {
    totalValue: number;
    totalTaxesValue: number;
    individualValue: number;
  };
};

export type VehicleBookingActions = {
  createBooking: (issuerId?: string) => Promise<Order["uuid"]>;
  setVehicle: (vehicle?: Vehicle) => void;
  setTraveler: (traveler: Traveler) => void;
};

export type VehicleBookingDispatchAction =
  | {
      type: "SET_GO_VEHICLE";
      payload?: Vehicle;
    }
  | {
      type: "SET_TRAVELER";
      payload: Traveler;
    }
  | {
      type: "SET_ISSUER";
      payload: Traveler;
    };

export type VehicleBookingDispatch = (data: VehicleBookingDispatchAction) => void;

export function vehicleBookingReducer(
  state: VehicleBookingState,
  action: VehicleBookingDispatchAction
): VehicleBookingState {
  switch (action.type) {
    case "SET_GO_VEHICLE":
      return {
        ...state,
        vehicleSelected: action.payload,
      };
    case "SET_TRAVELER":
      return { ...state, traveler: action.payload };
    case "SET_ISSUER":
      return { ...state, issuerId: action.payload.uuid };
    default:
      return state;
  }
}

export function buildVehicleQuery(searchParams: URLSearchParams): VehicleQuery {
  const paramsObj = Object.fromEntries(searchParams);

  try {
    return {
      dateGetSelected: toDate(paramsObj.dateGetSelected),
      dateReturnSelected: toDate(paramsObj.dateReturnSelected),
      originSelected: JSON.parse(paramsObj.originSelected),
      destinationSelected: JSON.parse(paramsObj.destinationSelected),
      timeGetSelected: paramsObj.timeGetSelected,
      timeReturnSelected: paramsObj.timeReturnSelected,
      reasonTrip: paramsObj?.reasonTrip ? JSON.parse(paramsObj?.reasonTrip) : null,
    } as VehicleQuery;
  } catch (error) {
    throw AppError.badVehicleQuery();
  }
}

export function buildVehicleBookingInfo(
  state: VehicleBookingState,
  query: VehicleQuery
): VehicleBookingInfo {
  // Price related data calculation
  let totalValue = 0;
  let totalTaxesValue = 0;
  let individualValue = 0;

  if (state.vehicleSelected) {
    totalValue += state.vehicleSelected.totalPrice;
    totalTaxesValue += state.vehicleSelected.totalFees;
  }

  individualValue = totalValue;

  return {
    ...state,
    query,
    price: {
      totalValue,
      totalTaxesValue,
      individualValue,
    },
  };
}

export class VehicleQueryClient {
  private customerId: string;
  private queryData: VehicleQuery;

  private vehicleLinks?: VehicleQueryLinks;

  constructor(data: { customerId: string; queryData: VehicleQuery }) {
    this.customerId = data.customerId;
    this.queryData = data.queryData;
  }

  requestVehicleLinks = async () => {
    if (this.vehicleLinks) return this.vehicleLinks;

    return await vehicleQueryService
      .query(this.customerId, {
        originSelected: this.queryData.originSelected,
        destinationSelected: this.queryData.destinationSelected,
        dateGetSelected: this.queryData.dateGetSelected,
        dateReturnSelected: this.queryData.dateReturnSelected,
      } as VehicleQuery)
      .then((value) => {
        this.vehicleLinks = value;
        return this.vehicleLinks;
      });
  };
}
