import { DateUtils } from "~/application/utils";
import { api } from "~/infrastructure/api";
import { OfflineHotelQueryDTO } from "~/infrastructure/api/dtos";
import {
  IOfflineHotelService,
  QuoteOfflineHotelProps,
  RequestOfflineHotelProps,
} from "./IOfflineHotelService";
import { displayDate } from "~/utils/date.utils";

export class OfflineHotelService implements IOfflineHotelService {
  async request({ orderId, data }: RequestOfflineHotelProps): Promise<void> {
    const accommodations = data.accommodations.map(({ travelers, adultQuantity }) => ({
      guests: travelers.map((t) => t.uuid),
      adult_quantity: adultQuantity,
    }));

    await api
      .post<void, OfflineHotelQueryDTO>(`/orders/${orderId}/hotel/offline`, {
        accommodations,
        amenities: data.amenities.map(({ uuid }) => uuid),
        city_uuid: data.city.uuid,
        checkIn: displayDate(data.checkInDate as Date, DateUtils.DateFormats.ISO_DATE_TIME),
        checkOut: displayDate(data.checkOutDate as Date, DateUtils.DateFormats.ISO_DATE_TIME),
        customer_uuid: data.customerId,
        suggestion: data.suggestion,
        regimen: data.regime?.name,
        obs: data.obs || "",
      })
      .then(({ data }) => data);
  }

  async quote({ room, options }: QuoteOfflineHotelProps): Promise<void> {
    return await api
      .post(`orders/hotel/offline/${room?.uuid}/options`, {
        options: options.map((option) => ({
          uuid: option.uuid || undefined,
          description: option.description,
          address: option.address,
          phone: option.phone,
          email: option.email,
          room_type: option.roomType.name,
          regimen: option.regimen.name,
          provider_value: option.providerValue,
          customer_value: option.customerValue,
          hotel_fee: option.hotelFee,
          hotel_markup: option.hotelMarkup,
          obs_issuer: option.obsIssuer,
          obs_agency: option.obsAgency,
          payment_pix: option.paymentPix,
          payment_credit_card: option.paymentCreditCard,
          amenities: option?.amenities?.map(({ uuid }) => uuid),
          note: option.note,
          check_in: displayDate(
            new Date(option.checkIn as Date),
            DateUtils.DateFormats.ISO_DATE_TIME
          ),
          check_out: displayDate(
            new Date(option.checkOut as Date),
            DateUtils.DateFormats.ISO_DATE_TIME
          ),
          other_taxes: option.otherTaxes,
        })),
      })
      .then(({ data }) => data);
  }

  async selectOption(optionId: string): Promise<void> {
    await api.patch(`orders/hotel/offline/options/${optionId}`).then(({ data }) => data);
  }

  async deleteOption(optionId: string): Promise<void> {
    await api.delete(`orders/hotel/offline/options/${optionId}`).then(({ data }) => data);
  }
}
