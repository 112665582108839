import { useForm } from "react-hook-form";
import { Project } from "~/application/types";
import { FormControl } from "~/components/FormControl";
import { Icon } from "~/components/Icon";
import { SvgEdit } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Switch } from "~/components/Input";
import { DataColItem, DataListItem, DataListItemProps } from "~/components/List/DataListItem";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { Caption } from "~/components/Typography";

export interface ProjectListItemProps extends DataListItemProps {
  data: Project;
  onEditClick: (item: Project) => void;
  onToggleState: (item: Project) => void;
}

export function ProjectListItem({
  data,
  onEditClick,
  onToggleState,
  ...props
}: ProjectListItemProps) {
  return (
    <DataListItem data-active={data.active} {...props}>
      <DataColItem headerTitle="Projeto" data={data.name} />
      <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
        <IconButton size="md" onClick={() => onEditClick(data)}>
          <Icon as={SvgEdit} />
        </IconButton>
      </Tooltip>
      <Switch checked={data.active} onClick={() => onToggleState(data)}>
        <Caption>{data.active ? "Ativo" : "Inativo"}</Caption>
      </Switch>
    </DataListItem>
  );
}
