import { CSS } from "@stitches/react";
import { useNavigate, useParams } from "react-router-dom";

import { routes } from "~/application/theme/routes";
import { OrderRoadSegment } from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { CompanyLogo } from "~/components/CompanyLogo";
import { Flex } from "~/components/Flex";
import { Col, Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgCheck, SvgRemove } from "~/components/Icon/icons";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { H6, Label } from "~/components/Typography";
import { DefaultCompanyLogo } from "~/presentation/shared/components/DefaultCompanyLogo";
import { TrackDots } from "~/presentation/shared/components/Track";
import { ViolatedPoliciesButton } from "~/presentation/shared/components/ViolatedPoliciesButton/ViolatedPoliciesButton";
import useMobile from "~/presentation/shared/hooks/useMobile";
import * as DateUtils from "~/utils/date.utils";

export interface OrderRoadCardProps {
  data: OrderRoadSegment;
  css?: CSS;
  onOpenDetails: (data: OrderRoadSegment) => void;
}

export function OrderRoadCard({ data, css, onOpenDetails }: OrderRoadCardProps) {
  const navigate = useNavigate();
  const { orderId } = useParams() as { orderId: string };

  function navigateToCheapestRoadsPage() {
    const url = routes.Orders.Order.CheapestRoads.root
      .replace(":orderId", orderId)
      .concat(`?ticketId=${data.uuid}`);
    
    navigate(url);
  }
  const isMobile = useMobile()
  const handleOpenDetails = () => onOpenDetails(data);

  return (
    <Card
      css={{
        overflow: "visible",
        ...css,
        "@mxmd": {
          border: "0",
        },
      }}
    >
      <Flex
        as={CardBody}
        align="center"
        justify="between"
        css={{
          pb: "0",
          "@mxmd": {
            width: "100%",
            margin: "0 auto",
          },
        }}
      >
        
        <Flex direction="column" gap="4" align="start" css={{ width: "100%" }}>
          <Flex justify="between" align="center" css={{ width: "100%" }} wrap="wrap" gap="2">
            {data.companyImage ? (
              <CompanyLogo src={data.companyImage} alt={data.companyName} size="lg" />
            ) : (
              <DefaultCompanyLogo companyName={data.companyName} />
            )}
             {isMobile && (
               <Button
                variant="tertiary"
                onClick={handleOpenDetails}
                css={{ "@mxlg": {
                  p: "$4",
                  height: "$4"
                }}}
              >
               Ver detalhes
             </Button>
             )}
            <Flex align="center" direction={{ "@mxlg": "column" }} gap="2" wrap="wrap" css={{ "@mxlg": { width: "100%" }}}>
              {!!data.violatedPolicies?.length && (
                <ViolatedPoliciesButton data={data.violatedPolicies} />
              )}

              {data.isTheCheapestRoad ? (
                <Button variant="success-light" onClick={navigateToCheapestRoadsPage} css={{ "@mxlg": { width: "100%" }}}>
                  <Icon as={SvgCheck} />
                  <Text>Fez a melhor escolha</Text>
                </Button>
              ) : (
                <Button variant="error-light" onClick={navigateToCheapestRoadsPage} css={{ "@mxlg": { width: "100%" }}}>
                  <Icon as={SvgRemove} />
                  <Text>Melhor opção disponível</Text>
                </Button>
              )}
              
              {!isMobile && (
                <Button
                variant="tertiary"
                onClick={handleOpenDetails}
              >
                Ver detalhes
              </Button>
              )}
            </Flex>
          </Flex>

          <Flex
            css={{
              gap: "$4",
              "@mxmd": {
                display: "none",
              },
            }}
          >
            <Text size="4">{data.companyName}</Text>
            {data.providerIdentification && (
              <Tag variant="neutral-light" css={{ p: "5px" }}>
                <Text css={{ fontSize: "8px" }}>{data.providerIdentification}</Text>
              </Tag>
            )}
          </Flex>
        </Flex>

        <Flex gap="3">
          {data.bpeTicket && (
            <Tag variant="error-light">
              <Text>Passagem no celular</Text>
            </Tag>
          )}
        </Flex>
      </Flex>

      <CardBody
        css={{
          "@mxlg": {
            px: 0,
            width: "100%",
          },
        }}
      >
        <Row
          gap="6"
          css={{
            "@mxmd": {
              width: "95%",
              margin: "0 auto",
              p: "0",
              flexDirection: "column",
            },
          }}
        >
          <Col
            css={{
              "@mxlg": {
                p: 0,
              },
            }}
          >
            <Flex align="start" direction="column" gap="4" css={{ "@mxlg": { px: "$4" }}}>
              <Label
                size="2"
                css={{
                  "@mxmd": {
                    display: "none",
                  },
                }}
              >
                Saída
              </Label>
              <Label>{DateUtils.toHour12(data.departureDate)}</Label>

              <H6>{data.departure}</H6>

              <Text
                css={{
                  "@mxmd": {
                    display: "none",
                  },
                }}
                size="3"
              >
                {DateUtils.displayDate(data.departureDate)}
              </Text>
            </Flex>
          </Col>

          <Col
            css={{
              "@mxlg": {
                p: 0,
              },
            }}
          >
            <Flex align="start" direction="column" gap="4" css={{ "@mxlg": { px: "$4" }}}>
              <Label
                size="2"
                css={{
                  "@mxmd": {
                    display: "none",
                  },
                }}
              >
                Chegada
              </Label>
              <Label>{DateUtils.toHour12(data.arrivalDate)}</Label>

              <H6>{data.arrival}</H6>

              <Text
                css={{
                  "@mxmd": {
                    display: "none",
                  },
                }}
                size="3"
              >
                {DateUtils.displayDate(data.arrivalDate)}
              </Text>
            </Flex>
          </Col>

          <Col
            css={{
              "@mxmd": {
                display: "none",
              },
            }}
          >
            <Flex align="start" direction="column" gap="4">
              <Label>Duração</Label>

              <Box css={{ width: "40%" }}>
                <TrackDots numberOfDots={1} />
              </Box>

              <Label>{DateUtils.displayDurationInSeconds(data.duration)}</Label>
              <a
                rel="stylesheet"
                onClick={handleOpenDetails}
                style={{
                  color: "#0064C5",
                  fontWeight: "600",
                  fontSize: "12px",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                {data.connection ? "2 Trechos" : "1 Trecho"}
              </a>
            </Flex>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

OrderRoadCard.displayName = "OrderRoadCard";
