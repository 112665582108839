import {
  OrderRoadSegment,
  OrderStatus,
  RoadTraveler,
} from "~/application/types";
import { Alert } from "~/components/Alert";
import { CardProps } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { Text } from "~/components/Text";
import { asCPF } from "~/utils/mask.utils";
import { RoadTravelerBpeInfo } from "./components/RoadTravelerBpeInfo";
import { DateUtils } from "~/application/utils";
import { toDate } from "~/application/utils/date-functions";

export interface RoadTravelerListItemProps extends CardProps {
  item: Pick<OrderRoadSegment, "connection" | "departure" | "arrival">;
  traveler: RoadTraveler;
  orderStatus: OrderStatus;
}

export function RoadTravelerListItem({
  item,
  traveler,
  orderStatus,
  ...props
}: RoadTravelerListItemProps) {
  const doestNotHaveAnyBpe = traveler.seats.every(({ bpe }) => !bpe);
  const customerDateOfBirth = DateUtils.smallDateFormat(traveler.dateBirth)
  
  return (
    <Flex direction="column" {...props}>
      <Flex
        css={{
          borderRadius: "$md",
          backgroundColor: "$neutrals-white",
          width: "100%",
          p: "$3 $6 $3 $6",
          "@mxmd": {
            p: 0,
          },
        }}
      >
        <Flex gap="4" direction="column" css={{ width: "100%" }}>
          <Flex
            justify="between"
            direction={{ "@mxmd": "column" }}
            gap="4"
            css={{
              p: "$6",
            }}
          >
            <Col
              sz="2"
              css={{
                "@mxmd": {
                  width: "100%",
                },
              }}
            >
              <Flex
                direction={{ "@initial": "column", "@mxmd": "row" }}
                align={{ "@mxmd": "center" }}
              >
                <Text
                  size="2"
                  variant="dark"
                  css={{
                    mb: "1rem",
                    fw: "500",
                    display: "block",
                    "@mxmd": {
                      mr: "$3",
                      display: "none",
                    },
                  }}
                >
                  Nome
                </Text>

                <Text
                  size={{ "@initial": "2", "@mxmd": "3" }}
                  css={{ fw: "600" }}
                >
                  {traveler.fullName}
                </Text>
              </Flex>
            </Col>

            <Col
              sz="4"
              css={{
                "@mxmd": {
                  width: "100%",
                  p: 0,
                  height: 5,
                },
              }}
            >
              <Flex direction={{ "@initial": "column", "@mxmd": "row" }}>
                <Text
                  size="2"
                  variant="dark"
                  css={{
                    mb: "1rem",
                    fw: "500",
                    display: "block",
                    "@mxmd": {
                      mr: "$3",
                    },
                  }}
                >
                  CPF
                </Text>

                <Text
                  size="2"
                  css={{
                    fw: "600",
                    "@mxmd": {
                      color: "#727272",
                    },
                  }}
                >
                  {asCPF(traveler.cpf)}
                </Text>
              </Flex>
            </Col>

            <Col
              sz="2"
              css={{
                "@mxmd": {
                  width: "100%",
                },
              }}
            >
              <Flex direction={{ "@initial": "column", "@mxmd": "row" }}>
                <Text
                  size="2"
                  variant="dark"
                  css={{
                    mb: "1rem",
                    fw: "600",
                    display: "block",
                    "@mxmd": {
                      mr: "$3",
                    },
                  }}
                >
                  RG
                </Text>

                <Text size="2" css={{ fw: "600", wordBreak: "break-all" }}>
                  {traveler.rg}
                </Text>
              </Flex>
            </Col>

            <Col
              sz="2"
              css={{
                "@mxmd": {
                  width: "100%",
                },
              }}
            >
              <Flex direction={{ "@initial": "column", "@mxmd": "row" }}>
                <Text
                  size="2"
                  variant="dark"
                  css={{
                    mb: "1rem",
                    fw: "600",
                    display: "block",
                    "@mxmd": {
                      mr: "$3",
                    },
                  }}
                >
                  Data de nascimento
                </Text>

                <Text size="2" css={{ fw: "600", wordBreak: "break-all" }}>
                  {customerDateOfBirth}
                </Text>
              </Flex>
            </Col>
          </Flex>
        </Flex>

        {doestNotHaveAnyBpe && orderStatus === OrderStatus.ISSUED && (
          <Alert variant="info" css={{ ml: "$4", mr: "$4", mb: "$7" }}>
            <Text fw={500} variant="primary-dark">
              Para esta viagem, não há bilhete eletrônico disponível. Por favor,
              dirija-se ao guichê da viação para retirar sua passagem
              rodoviária.
            </Text>
          </Alert>
        )}
      </Flex>

      {!doestNotHaveAnyBpe && (
        <Flex
          css={{
            boxSizing: "border-box",
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            transition: "$fast",
            borderRadius: "$md",
          }}
        >
          <Flex
            css={{
              backgroundColor: "$neutrals-white",
              p: "0 $2 $6 0",
              borderRadius: " $md 0 0 $md ",
            }}
          ></Flex>
          <Flex
            css={{
              backgroundColor: "$neutrals-white",
              border: "2px $neutrals-light",
              borderStyle: "dashed none none none",
              width: "100%",
              p: "$3 $6 $3 $6",
              gap: "$1",
            }}
            direction="column"
          >
            {traveler.seats.map(
              ({ seatNumber, ticket, bpe, isConnection }, index) => {
                const travelName = isConnection
                  ? `${item.connection?.name} -> ${item.arrival}`
                  : `${item.departure} -> ${
                      item.connection ? item.connection.name : item.arrival
                    }`;

                const key = `${travelName}-${ticket}-${bpe}-${index}`;

                return (
                  <RoadTravelerBpeInfo
                    key={key}
                    seatNumber={seatNumber}
                    ticket={ticket}
                    enabled={!!bpe}
                    bpe={bpe}
                    travelName={travelName}
                  />
                );
              }
            )}
          </Flex>
          <Flex
            css={{
              backgroundColor: "$neutrals-white",
              p: "0 $2 $6 0",
              borderRadius: " 0 $md $md 0 ",
            }}
          ></Flex>
        </Flex>
      )}
    </Flex>
  );
}

RoadTravelerListItem.displayName = "RoadTravelerListItem";
