import { Box } from "~/components/Box";
import { Button, ButtonProps } from "~/components/Button";
import { CompanyLogo } from "~/components/CompanyLogo";
import { Flex } from "~/components/Flex";
import { Image } from "~/components/Image";
import { Text } from "~/components/Text";
import { asCurrency } from "~/utils/mask.utils";
import { ComponentProps, useCallback } from "react";
import { styled } from "~/application/theme";
import { dialogService } from "~/components/DialogStack";
import { VehicleDetailsDialog } from "./VehicleDetailsDialog";
import { Checkbox } from "~/components/Input";
import { Vehicle } from "~/application/types";
import { useVehicleBudgetResult } from "../hooks/useVehicleBudget/type";
import { ViolatedPoliciesButton } from "~/presentation/shared/components/ViolatedPoliciesButton/ViolatedPoliciesButton";

export type VehicleCardProps = ComponentProps<typeof StyledVehicleCard> & {
  data: Vehicle;
  buttonProps?: ButtonProps;
  readOnly?: boolean;
  vehicleBudget: useVehicleBudgetResult;
};

export type AccommodationRulesDialogProps = {
  data: {
    searchKey: string;
    hotelId: string;
    roomId: number;
  };
};

const StyledVehicleCard = styled(Box, {
  position: "relative",
  display: "flex",
  background: "$neutrals-white",
  borderWidth: "2px",
  borderStyle: "solid",
  borderRadius: "$md",
  borderColor: "transparent",
  color: "$neutrals-black",
  transition: "$normal",
  height: "375px",

  "&:hover": {
    boxShadow: "$md",
  },

  [`&[data-active=true]`]: {
    borderColor: "$primary-base",
  },
});

export const VehicleCard = ({
  data,
  buttonProps,
  vehicleBudget,
  ...props
}: VehicleCardProps) => {
  const handleOpenVehicleDialog = useCallback(() => {
    dialogService.showDialog(<VehicleDetailsDialog data={data} />);
  }, []);
  const { onSelectVehicleBudget, state, activeBudget } = vehicleBudget;

  const isCheckedVehicleBudget = state.some((sv) => sv.id === data.id);

  return (
    <StyledVehicleCard
      css={{
        "@media(max-width: 1480px)": {
          height: "auto",
        },
      }}
      {...props}
    >
      {activeBudget && (
        <Checkbox
          onChange={() => onSelectVehicleBudget({ data })}
          checked={isCheckedVehicleBudget}
          css={{
            position: "absolute",
            top: "$6",
            left: "$6",
          }}
        />
      )}

      <Flex
        gap="6"
        direction={{ "@mxxxl": "column" }}
        css={{
          gap: "$10",
          p: "$6",
          height: "auto",
          flex: "1 0 0%",
          "@xxl": {
            borderRight: "1px solid $colors$neutrals-lightest",
          }
        }}
      >
        <Flex direction="column" justify="between" gap="2">
          <Flex direction={{ "@mxxxl": "column" }} gap="4" css={{ minWidth: "168px" }}>
            <Flex justify="center" css={{ flexShrink: 0 }}>
              <Image src={data.vehicle.image} css={{ height: "152px", width: "168px" }} />
            </Flex>

            <Flex direction="column" gap="3">
              <Text size={{ "@initial": "6", "@mxlg": "4" }} css={{ fw: "600" }}>
                {data.vehicle.group}
              </Text>

              <Text variant="dark" size={{ "@mxlg": "3" }} css={{ lineHeight: 1.25 }}>
                {data.vehicle.description}
              </Text>

              <Text size="2" variant="darkest" css={{ lineHeight: 1.25 }}>
                {data.vehicle.details}
              </Text>

              <Text size="2" css={{ fw: "600", mt: "$4", lineHeight: 1.25  }}>
                {`LOCAL DE RETIRADA: ${data.origin}`}
              </Text>
            </Flex>
          </Flex>

          <Flex 
            align="center" 
            justify={{ "@initial": "between", "@xxl": "start" }} 
            gap="2" wrap="wrap"
          >
            <CompanyLogo src={data.companyLogo} size="lg" />
            {!!data.violatedPolicies?.length && (
              <ViolatedPoliciesButton data={data.violatedPolicies} full={false} />
            )}
          </Flex>
        </Flex>
        

        <Flex
          direction="column"
          gap="6"
          css={{
            mt: "auto",
            "@xxl": {
              display: "none",
            },
          }}
        >
          <Text as="h4" size="6" css={{ fw: "600", textAlign: "center" }}>
            {asCurrency(data.totalPrice)}
          </Text>

          <Flex direction="column" gap="2">
            <Button {...buttonProps}>Selecionar</Button>
            <Button onClick={handleOpenVehicleDialog} variant="tertiary">Ver detalhes</Button>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        direction="column"
        gap="4"
        css={{
          p: "$8",
          "@mxxxl": {
            display: "none",
          },
        }}
        align="end"
      >
        <Button css={{ width: "100%" }} variant="tertiary" onClick={handleOpenVehicleDialog}>
          Ver detalhes
        </Button>
        <Flex direction="column" gap="6" css={{ mt: "auto" }}>
          <Text as="h4" size="6" css={{ fw: "600" }}>
            {asCurrency(data.totalPrice)}
          </Text>

          <Button {...buttonProps} disabled={activeBudget}>
            Selecionar
          </Button>
        </Flex>
      </Flex>
    </StyledVehicleCard>
  );
};

VehicleCard.displayName = "VehicleCard";
