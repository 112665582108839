import { FC, Fragment } from "react";
import { formatNumber } from "~/application/utils/string-functions";
import { Box } from "~/components/Box";
import { Flex } from "~/components/Flex";
import { Text } from "~/components/Text";
import { H3, Label } from "~/components/Typography";
import { FlightReadListItem } from "~/presentation/shared/components/FlightReadListItem";
import { TravelerListItem } from "~/presentation/shared/components/TravelerListItem";
import { ConfirmationUIProps } from "./types";

export const ConfirmationUI: FC<ConfirmationUIProps> = ({ bookingState, onOpenDetails }) => {
  const { goFlightSelected, returnFlightSelected, travelers, stretch } = bookingState;
  return (
    <Fragment>
      <Box css={{ mb: "$6" }}>
        <H3
          css={{
            "@mxlg": {
              textAlign: "center",
              fw: "bold",
              fontSize: "$lg",
            },
          }}
        >
          Confirme as informações de sua reserva
        </H3>
      </Box>

      <Flex direction="column" gap="4">
        {goFlightSelected && (
          <>
            <Text size="5" css={{ fw: 600, mt: "$6" }}>
              Ida
            </Text>

            <FlightReadListItem data={goFlightSelected.flight} onOpenDetails={onOpenDetails} />
          </>
        )}
        {stretch.length > 1 &&
          stretch?.map((stre, index) => (
            <Flex direction="column" gap="2">
              <Text size="5" css={{ fw: 600, mt: "$6" }}>
                {`Trecho ${index + 1}`}
              </Text>

              <FlightReadListItem data={stre.flight} onOpenDetails={onOpenDetails} />
            </Flex>
          ))}

        {returnFlightSelected && (
          <>
            <Text size="5" css={{ fw: 600 }}>
              Volta
            </Text>

            <FlightReadListItem data={returnFlightSelected.flight} onOpenDetails={onOpenDetails} />
          </>
        )}
      </Flex>

      <Flex direction="column" gap="6" css={{ mt: "$6" }}>
        <Text size="5" css={{ fw: 600 }}>
          Passageiros
        </Text>

        {travelers.map(({ type, index, traveler }) => (
          <Flex direction="column" gap="2" key={`traveler-${index}-${traveler.uuid}`}>
            <Label>
              {type === "ADT" ? "Adulto" : "Criança"} {formatNumber(1 + index, 2)}{" "}
            </Label>
            <TravelerListItem key={traveler.uuid} data={traveler} />
          </Flex>
        ))}
      </Flex>
    </Fragment>
  );
};

ConfirmationUI.displayName = "ConfirmationUI";
