import { FC, Fragment } from "react";
import { Box } from "~/components/Box";
import { Flex } from "~/components/Flex";
import { Text } from "~/components/Text";
import { H3 } from "~/components/Typography";
import { RoadReadListItem } from "~/presentation/shared/components/RoadReadListItem";
import { TravelerListItem } from "~/presentation/shared/components/TravelerListItem";
import { ConfirmationUIProps } from "./types";

export const ConfirmationUI: FC<ConfirmationUIProps> = ({ bookingInfo, onOpenDetails }) => {
  const { goRoadSelected, returnRoadSelected, travelers, stretches } = bookingInfo;

  return (
    <Fragment>
      <Box css={{ mb: "$6" }}>
        <H3
          css={{
            "@mxmd": {
              fontSize: "$lg",
              fw: "bolder",
              textAlign: "center",
            },
          }}
        >
          Confirme as informações de sua reserva
        </H3>
      </Box>

      <Flex direction="column" gap="4">
        {!!stretches.length &&
          stretches.map((stretch, index) => (
            <Flex direction="column" gap="5" key={stretch.road.uuid}>
              <Text
                size="5"
                css={{
                  fw: 600,
                }}
              >
                {`Trecho ${index + 1}`}
              </Text>

              <RoadReadListItem
                data={stretch.road}
                seats={stretch.seats}
                seatsConnection={stretch.seatsConnection}
                onOpenDetails={onOpenDetails}
              />
            </Flex>
          ))}
        {goRoadSelected && (
          <>
            <Text
              size="5"
              css={{
                fw: 600,
                mt: "$6",
                "@mxmd": {
                  mt: "$2",
                },
              }}
            >
              Ida
            </Text>

            <RoadReadListItem
              data={goRoadSelected.road}
              seats={goRoadSelected.seats}
              seatsConnection={goRoadSelected.seatsConnection}
              onOpenDetails={onOpenDetails}
            />
          </>
        )}

        {returnRoadSelected && (
          <>
            <Text size="5" css={{ fw: 600 }}>
              Volta
            </Text>

            <RoadReadListItem
              data={returnRoadSelected.road}
              seats={returnRoadSelected.seats}
              seatsConnection={returnRoadSelected.seatsConnection}
              onOpenDetails={onOpenDetails}
            />
          </>
        )}

        <Flex
          direction="column"
          gap="6"
          css={{
            mt: "$6",
            "@mxlg": {
              mt: "$0",
            },
          }}
        >
          <Text size="4" css={{ fw: 600 }}>
            Passageiros
          </Text>

          {travelers.map((traveler) => (
            <TravelerListItem key={traveler.uuid} data={traveler} />
          ))}
        </Flex>
      </Flex>
    </Fragment>
  );
};

ConfirmationUI.displayName = "ConfirmationUI";
