import type { OrderRoad, OrderRoadSegment } from "~/application/types";
import { api } from "~/infrastructure/api";
import { OrderRoadDTO } from "~/infrastructure/api/dtos";
import { mapOrderRoadDTO, mapOrderRoadSegmentDTO } from "~/infrastructure/api/mappers";
import type { IOrderRoadService } from "./IOrderRoadService";
import { ICreateOrderRoadData } from "./types";

export class OrderRoadService implements IOrderRoadService {
  async create({
    orderId,
    goRoad,
    returnRoad,
    travelers,
    lowerFareViolated,
  }: ICreateOrderRoadData): Promise<OrderRoadSegment[]> {
    const departure = {
      search_key: goRoad.road.searchKey,
      travel_id: goRoad.road.id,
      seats: goRoad.seats.map((seat, index) => ({
        seat_number: parseInt(seat.seat),
        passenger_uuid: travelers[index].uuid,
        seats_number_connection:
          goRoad.seatsConnection.length > 0 ? [parseInt(goRoad.seatsConnection[index].seat)] : [],
      })),
      lower_fare_violated: lowerFareViolated.departure,
    };

    const arrival = {
      search_key: returnRoad?.road.searchKey,
      travel_id: returnRoad?.road.id,
      seats: returnRoad?.seats.map((seat, index) => ({
        seat_number: parseInt(seat.seat),
        passenger_uuid: travelers?.[index].uuid,
        seats_number_connection:
          returnRoad?.seatsConnection.length > 0
            ? [parseInt(returnRoad.seatsConnection[index].seat)]
            : [],
      })),
      lower_fare_violated: lowerFareViolated.arrival,
    };

    const body = {
      departure: departure,
      arrival: returnRoad ? arrival : undefined,
    };

    return await api
      .post<{ data: OrderRoadDTO }>(`/orders/${orderId}/road`, body)
      .then(({ data: response }) => response.data.map(mapOrderRoadSegmentDTO));
  }

  async findByOrderId(orderId: string): Promise<OrderRoad> {
    return await api
      .get<{ data: OrderRoadDTO }>(`/orders/${orderId}/road`)
      .then(({ data }) => mapOrderRoadDTO(data.data));
  }

  async delete(itemId: string): Promise<void> {
    await api.post<void>(`/orders/road/${itemId}/cancel`);
  }
}
