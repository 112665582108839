import type { OrderHotel } from "~/application/types";
import { api } from "~/infrastructure/api";
import { OrderHotelDTO } from "~/infrastructure/api/dtos";
import { mapOrderHotelDTO } from "~/infrastructure/api/mappers";
import * as DateUtils from "~/utils/date.utils";
import type { ICreateOrderHotelParams, IOrderHotelService } from "./IOrderHotelService";

export class OrderHotelService implements IOrderHotelService {
  async create({ orderId, hotel, hotelQuery, rooms }: ICreateOrderHotelParams): Promise<void> {
    return await api
      .post(`/orders/${orderId}/hotel`, {
        search_key: hotel?.searchKey,
        hotel_uuid: hotel?.uuid,
        checkin: DateUtils.toISOString(hotelQuery?.checkInDate as Date),
        checkout: DateUtils.toISOString(hotelQuery?.checkOutDate as Date),
        rooms: rooms.map(({ room, guests, lowerFareViolated }) => ({
          lower_fare_violated: lowerFareViolated,
          room_id: room.roomId.toString(),
          guests: guests.map((i) => i.uuid),
        })),
      })
      .then(() => undefined);
  }

  async findByOrderId(orderId: string): Promise<OrderHotel[]> {
    return await api
      .get<OrderHotelDTO[]>(`/orders/${orderId}/hotel`)
      .then(({ data }) => data.map(mapOrderHotelDTO));
  }

  async delete(itemId: string): Promise<void> {
    await api.post<void>(`/orders/hotel/${itemId}/cancel`);
  }
}
