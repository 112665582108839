import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { OrderItemStatus, OrderItems, OrderStatus, PendingOrder } from "~/application/types";
import { Box } from "~/components/Box";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgExternalLink } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { ExternalLink, Link } from "~/components/Link";
import { DataColItem, DataListItem, DataListItemProps } from "~/components/List/DataListItem";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import * as OrderUtils from "~/core/shared/utils/order.utils";
import { OrderStatusTag } from "~/presentation/shared/components/OrderStatusTag";
import useMobile from "~/presentation/shared/hooks/useMobile";
import * as DateUtils from "~/utils/date.utils";

export interface PendingOrderListItemProps extends DataListItemProps {
  data: PendingOrder;
}

export function PendingOrderListItem({ data, ...props }: PendingOrderListItemProps) {
  const navigate = useNavigate();
  const orderItems = useMemo(() => Object.keys(data.itemsIncluded) as OrderItems[], [data]);

  const expiredItems = useMemo(
    () => orderItems.filter((i) => data.itemsIncluded[i].isExpired),
    [data, orderItems]
  );

  const statusBadge = useMemo(() => {
    if (expiredItems?.length > 0) return "error";
    if (
      Math.abs(Date.now() - data.expiresAt.getTime()) <
      1000 * 60 * 60 * 5 // 5 hours
    )
      return "warning";
    return "";
  }, [expiredItems]);

  const customLabels = {
    [OrderStatus.APPROVED]: "Pendente de emissão",
  } as Partial<Record<OrderStatus | OrderItemStatus, string>>;

  const isMobile = useMobile();
  const status = data.status.status;

  const handleOpenOrder = () => {
    navigate(`/pedidos/pedidos-pendentes/${data.uuid}`);
  };

  return (
    <Flex
      onClick={handleOpenOrder}
      direction="column"
      css={{
        cursor: "pointer",
        "&:hover": {
          ":after": {
            boxShadow: "$md",
            borderWidth: "2px",
            borderColor: "$primary-base",
          },
        },
      }}
    >
      <DataListItem
        css={{
          "@mxlg": {
            display: "flex",
            flexDirection: "column",
          },
        }}
        {...props}
        data-list={true}
      >
        <Box
          css={{
            ml: "-$6",
            my: "-$6",
            width: "$2",
            alignSelf: "stretch",
            borderRadius: "$md 0 0 $md",
            backgroundColor: {
              error: "$error-base",
              warning: "$warning-base",
              "": undefined,
            }[statusBadge],
          }}
        />
        {!isMobile ? (
          <>
            <DataColItem
              headerTitle="Pedido"
              data={
                <Tooltip content={<TooltipLabel>Ver em pedidos</TooltipLabel>}>
                  <ExternalLink
                    to={`/pedidos/${data.uuid}`}
                    onClick={(event) => event.stopPropagation()}
                    as={Link}
                  >
                    <Text css={{ fw: "600", mr: "$2" }}>{data.orderNumber}</Text>
                    <Icon as={SvgExternalLink} size="sm" />
                  </ExternalLink>
                </Tooltip>
              }
              css={{ fixedWidth: 120 }}
            />
            <DataColItem
              headerTitle="Status"
              data={
                <OrderStatusTag
                  data={status === OrderStatus.APPROVED ? OrderStatus.PENDING_ISSUE : status}
                  customLabels={customLabels}
                />
              }
              css={{ fixedWidth: 152 }}
            />
          </>
        ) : (
          <Flex justify="between" css={{ width: "100%", pt: "$4" }}>
            <DataColItem
              headerTitle="Pedido"
              data={
                <Tooltip content={<TooltipLabel>Ver em pedidos</TooltipLabel>}>
                  <ExternalLink to={`/pedidos/${data.uuid}`} as={Link}>
                    <Text css={{ fw: "600", mr: "$2" }}>{data.orderNumber}</Text>
                    <Icon as={SvgExternalLink} size="sm" />
                  </ExternalLink>
                </Tooltip>
              }
              css={{ fixedWidth: 120 }}
            />
            <DataColItem
              headerTitle=""
              data={
                <OrderStatusTag
                  data={status === OrderStatus.APPROVED ? OrderStatus.PENDING_ISSUE : status}
                  customLabels={customLabels}
                />
              }
              css={{ fixedWidth: 152, ta: "end" }}
            />
          </Flex>
        )}
        <DataColItem
          headerTitle="Expiração"
          css={{ color: data.isExpired ? "$error-base" : "$warning-dark" }}
          data={DateUtils.displayDate(data.expiresAt, DateUtils.DateFormats.SMALL_DATE_TIME)}
        />

        <DataColItem headerTitle="Consultor" data={data.consultant?.fullName} />

        <DataColItem headerTitle="Empresa" data={data.customer.tradingName} />

        <DataColItem
          headerTitle="Data de criação"
          data={DateUtils.displayDate(data.createdAt, DateUtils.DateFormats.SMALL_DATE)}
        />

        <DataColItem
          headerTitle="Início de serviço"
          data={DateUtils.displayDate(data.nearestServiceDate, DateUtils.DateFormats.SMALL_DATE)}
        />

        <DataColItem
          headerTitle="Itens do pedido"
          data={
            <Flex gap="1">
              {orderItems.map((service) => (
                <Tooltip
                  key={`${data.uuid}-${service}`}
                  content={<TooltipLabel>{OrderUtils.getServiceLabel(service)}</TooltipLabel>}
                >
                  <IconButton size="md">
                    <Icon variant="error" as={OrderUtils.getServiceIcon(service)} />
                  </IconButton>
                </Tooltip>
              ))}
            </Flex>
          }
          css={{ fixedWidth: 216 }}
        />
      </DataListItem>
      <Flex
        className="test"
        css={{
          boxSizing: "border-box",
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
          transition: "$fast",
          borderRadius: "$md",

          "&::after": {
            content: "",
            pointerEvents: "none",
            position: "absolute",
            inset: 0,
            margin: 0,
            borderRadius: "inherit",
            borderStyle: "none solid solid solid",
            borderColor: "transparent",
            transition: "inherit",
          },
          "&[data-active=true]::after": {
            borderWidth: "2px",
            borderColor: "$primary-base",
          },
        }}
      >
        <Flex
          css={{
            backgroundColor: "$neutrals-white",
            p: "0 $2 $6 0",
            borderRadius: " $md 0 0 $md ",
          }}
        ></Flex>
        <Flex
          css={{
            backgroundColor: "$neutrals-white",
            border: "2px $neutrals-light",
            borderStyle: "dotted none none none",
            width: "100%",
            p: "$3 $6 $3 $6",
            gap: "$1",
          }}
          direction="column"
        >
          <Text
            css={{
              color: "$neutrals-dark",
              fw: "600",
              fontSize: "$sm",
            }}
          >
            Passageiros
          </Text>
          <Flex
            gap={{ "@initial": "8", "@mxlg": "2" }}
            css={{
              width: "100%",
              "@mxlg": {
                mt: "$3",
              },
            }}
            direction={{ "@mxlg": "column" }}
            justify="center"
          >
            {data.travelers.map((traveler) => (
              <Text
                key={traveler.uuid}
                css={{
                  color: "$neutrals-darkest",
                  fw: "500",
                  fontSize: "clamp(10px, 3vw, 16px)",
                }}
              >
                {traveler.fullName}
              </Text>
            ))}
          </Flex>
        </Flex>
        <Flex
          css={{
            backgroundColor: "$neutrals-white",
            p: "0 $2 $6 0",
            borderRadius: " 0 $md $md 0 ",
          }}
        ></Flex>
      </Flex>
    </Flex>
  );
}

PendingOrderListItem.displayName = "PendingOrderListItem";
