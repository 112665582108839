import { AirwayEmission, HotelEmission, OrderItems, VehicleEmission } from "~/application/types";
import { api } from "~/infrastructure/api";
import type { IEmissionService } from "./IEmissionService";
import { RoadEmissionSubmit } from "~/presentation/shared/views/RoadIssuance";

export class EmissionService implements IEmissionService {
  async airway(orderId: string, data: AirwayEmission[]): Promise<void> {
    return await api.post(`/orders/${orderId}/emissions/airway`, data).then(({ data }) => data);
  }

  async road(orderId: string, data: RoadEmissionSubmit): Promise<void> {
    const body = {
      ...data,
      travelers: data.travelers.map((traveler) => ({
        uuid: traveler.uuid,
        value: traveler.value,
        provider_uuid: traveler.providerId,
        provider_fees: traveler.providerFees,
        seats: traveler.seats.map((seat) => ({
          is_connection: seat.isConnection,
          ticket: seat.ticket,
          seat_number: seat.seatNumber,
          bpe: seat.bpe,
          seat_type: seat.seatType,
        })),
      })),
    };

    return await api.post(`/orders/${orderId}/emissions/road`, [body]).then(({ data }) => data);
  }

  async cancelItem({
    orderItemType,
    orderItemId,
  }: {
    orderItemType: OrderItems;
    orderItemId: string;
  }): Promise<void> {
    return await api
      .post(`/orders/${orderItemType}/${orderItemId}/cancel`)
      .then(({ data }) => data);
  }

  async hotel(orderId: string, data: HotelEmission): Promise<void> {
    const body = {
      uuid: data.uuid,
      tracker: data.tracker,
      provider_uuid: data.provider?.uuid,
      fare_value: data.totalValue,
      total_value: data.value,
    };

    return await api.post(`/orders/${orderId}/emissions/hotel`, [body]).then(({ data }) => data);
  }

  async vehicle(orderId: string, data: VehicleEmission): Promise<void> {
    return await api.post(`/orders/${orderId}/emissions/vehicle`, [data]).then(({ data }) => data);
  }
}
