import { CustomerSettingParameter } from "~/application/types";
import { SettingParameterTypeValue } from "~/application/types/entities/SettingParameter.type";
import { CustomerSettingParameterDTO } from "../dtos";

export function mapCustomerSettingParameterDTO(
  data: CustomerSettingParameterDTO
): CustomerSettingParameter {
  const parseValue = ({ type_value, value }: CustomerSettingParameterDTO) => {
    if (value === null) {
      return value;
    }

    if (type_value === SettingParameterTypeValue.BOOLEAN) {
      return value === "true";
    }

    return Number(value);
  };

  return {
    uuid: data.uuid,
    customerId: data.customer_uuid,
    description: data.description,
    slug: data.slug,
    value: parseValue(data),
    type: data.type,
    typeValue: data.type_value,
  };
}
