import { useEffect, useState } from "react";
import { GoogleMap, Marker, useLoadScript, InfoWindow } from "@react-google-maps/api";

import { ViolatedPoliciesButton } from "~/presentation/shared/components/ViolatedPoliciesButton/ViolatedPoliciesButton";
import { Box } from "~/components/Box";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { FormDialog } from "~/components/FormDialog";
import { Hotel } from "~/application/types";
import { Flex } from "~/components/Flex";
import { Carrossel } from "./components/Carrossel";
import { Text } from "~/components/Text";
import { StarRating } from "~/core/shared/components/StarsRating";
import { asCurrency } from "~/application/utils/mask-functions";
import { dialogService } from "~/components/DialogStack";
import { Spinner } from "~/components/Spinner";
import markerCheck from "~/assets/icons/marker_check.svg";
import markerWarn from "~/assets/icons/marker_warn.svg";
import marker from "~/assets/icons/marker.svg";

export interface MapViewDialogProps {
  height: number;
  onCloseClick?: () => void;
  hotelsList?: Hotel[];
  onSelect?: (data: Hotel) => void;
  data?: Hotel;
  description?: string;
}

export function MapViewDialog({
  height,
  onCloseClick,
  hotelsList,
  onSelect,
  data,
  description,
}: MapViewDialogProps) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_CLIENT_ID || "",
  });

  const [mapRef, setMapRef] = useState<google.maps.Map>();
  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] =
    useState<{
      index: number;
    }>();

  const onMapLoad = (map: google.maps.Map) => {
    setMapRef(map);
    const bounds = new google.maps.LatLngBounds();
    if (data) {
      map.setZoom(17);
      map.setCenter({
        lat: data?.address.latitude || 0,
        lng: data?.address.longitude || 0,
      });
      return;
    }
    hotelsList?.forEach((hotel) =>
      bounds.extend({
        lat: hotel?.address.latitude || 0,
        lng: hotel?.address.longitude || 0,
      })
    );
    map.fitBounds(bounds);
  };

  const handleMarkerClick = ({ index, lat, lng }: { index: number; lat: number; lng: number }) => {
    mapRef?.panTo({ lat, lng });
    setInfoWindowData({ index });
    setIsOpen(true);
  };

  const handleSelectHotel = (hotel: Hotel) => {
    onSelect?.(hotel);
    dialogService.popAll();
  };
  useEffect(() => {
    if (data) {
      handleMarkerClick({
        index: hotelsList?.findIndex((hotel) => hotel?.uuid === data?.uuid) || 0,
        lat: data?.address.latitude || 0,
        lng: data?.address.longitude || 0,
      });
    }
  }, [data]);

  return (
    <Container size="12" fixed>
      <FormDialog
        title="Veja a acomodação no mapa"
        css={{
          "@mxmd": {
            width: "90%",
            margin: "0 auto",
            height: "90vh",
          },
        }}
        onClickDismissButton={onCloseClick}
      >
        <DialogBody>
          <Box css={{ width: "100%" }}>
            {!isLoaded && (
              <Flex align="center" justify="center" css={{ height: `${height}px` }}>
                <Spinner size="lg" />
              </Flex>
            )}

            {isLoaded && (
              <GoogleMap
                mapContainerStyle={{
                  width: "100%",
                  height: `${height}px`,
                }}
                onLoad={onMapLoad}
                onClick={() => setIsOpen(false)}
                options={{
                  styles: [
                    {
                      featureType: "poi",
                      elementType: "labels",
                      stylers: [
                        {
                          visibility: "off",
                        },
                      ],
                    },
                  ],
                }}
              >
                {hotelsList?.map((hotel, index) => {
                  const hasViolatedPolicy = Boolean(hotel?.violatedPolicies?.length);

                  const markerIcon = isOpen && infoWindowData?.index === index
                    ? markerCheck
                    : (hasViolatedPolicy ? markerWarn : marker);

                  return (
                    <Marker
                      label={{
                        text: `${asCurrency(hotel?.bestValueTotal || 0)}`,
                        fontSize: "12px",
                        color: "#fff",
                        fontWeight: "700",
                      }}
                      key={hotel?.uuid}
                      position={{
                        lat: hotel?.address.latitude || 0,
                        lng: hotel?.address.longitude || 0,
                      }}
                      onClick={() => {
                        handleMarkerClick({
                          index,
                          lat: hotel?.address.latitude || 0,
                          lng: hotel?.address.longitude || 0,
                        });
                      }}
                      icon={{
                        url: markerIcon,
                        scaledSize: new google.maps.Size(80, 70),
                      }}
                    >
                      {isOpen && infoWindowData?.index === index && (
                        <InfoWindow
                          onCloseClick={() => {
                            setIsOpen(false);
                          }}
                          onDomReady={() => {
                            const infoWindow = document.querySelector(
                              ".gm-style-iw-c"
                            ) as HTMLElement;

                            const infoWindowIcon = document.querySelector(
                              ".gm-ui-hover-effect>span"
                            ) as HTMLElement;

                            const infoWindowContent = document.querySelector(
                              ".gm-style-iw-d"
                            ) as HTMLElement;

                            const tooltipArrow = document.querySelector(
                              ".gm-style-iw-tc"
                            ) as HTMLElement;

                            infoWindow.style.borderRadius = "20px";
                            infoWindow.style.padding = "16px";
                            infoWindow.style.boxShadow = "0px 0px 10px 0px rgba(0,0,0,0.5)";
                            infoWindow.style.height = "auto";
                            infoWindow.style.overflow = "visible";

                            infoWindowIcon.style.width = "20px";
                            infoWindowIcon.style.height = "20px";

                            infoWindowContent.style.overflow = "visible";

                            tooltipArrow.style.zIndex = "-1";
                          }}
                        >
                          <Flex direction="column" gap="4">
                            <Carrossel
                              images={hotel?.hotelImages || []}
                              size={hotel?.hotelImages?.length || 0}
                            />

                            <Flex direction="column" gap="2">
                              <Text
                                as="h2"
                                fw="600"
                                css={{
                                  "@mxlg": {
                                    fontSize: "$sm",
                                  },
                                }}
                              >
                                {hotel?.name.split("(")[0]}
                              </Text>
                              <StarRating stars={hotel?.stars as number} />
                              <Flex gap="2" align="center">
                                <Text size="2" css={{ fw: "500" }}>
                                  {hotel?.address?.cityName}{" "}
                                </Text>
                                <Text size="1" css={{ fw: "200" }}>
                                  {hotel?.address?.street}{" "}
                                </Text>
                              </Flex>
                            </Flex>

                            <Flex direction="column" align="center" gap="2">
                              <Text variant="darkest" size="2">
                                {description}
                              </Text>
                              <Text
                                as="h1"
                                css={{
                                  "@mxlg": {
                                    fontSize: "$sm",
                                  },
                                }}
                              >
                                {asCurrency(hotel?.bestValueTotal || "")}
                              </Text>
                            </Flex>

                            <Flex direction="column" gap="2">
                              {hasViolatedPolicy && (
                                <Flex align="center" justify="center">
                                  <ViolatedPoliciesButton data={hotel?.violatedPolicies} />
                                </Flex>
                              )}

                              <Flex
                                css={{
                                  width: "100%",
                                  borderRadius: "8px",
                                  backgroundColor: "$primary-base",
                                  p: "$3",
                                  cursor: "pointer",
                                  transition: "background-color 0.2s ease-in-out",
                                  ["&:hover"]: {
                                    backgroundColor: "$primary-dark",
                                  },
                                }}
                                justify="center"
                                onClick={() => handleSelectHotel(hotel)}
                              >
                                <Text as="p" variant="white" fw="600" size="3">
                                  Ver Hotel
                                </Text>
                              </Flex>
                            </Flex>
                          </Flex>
                        </InfoWindow>
                      )
                      }
                    </Marker>
                  );
                })}
              </GoogleMap>
            )}
          </Box>
        </DialogBody>
      </FormDialog>
    </Container >
  );
}
