import { Fragment } from "react";
import {
  GroupedOrderHistory,
  Order,
  OrderItemsWithChangedPrice,
  OrderMessage,
} from "~/application/types";
import { AppBar, AppBarContainer, AppBarHeading } from "~/components/AppBar";
import { Container } from "~/components/Container";
import { Flex } from "~/components/Flex";
import { Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgChevronLeft } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Link } from "~/components/Link";
import { Skeleton } from "~/components/Skeleton";
import { CounterTab, CounterTabBadge, TabBar, TabContent, Tabs } from "~/components/Tabs";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { TabOrderMessages } from "~/presentation/shared/components/TabOrderMessages";
import { TabOrderHistory } from "../OrderPage/views/OrderItem/components/TabOrderHistory";
import { ORDER_MENU_TABS, OrderTab } from "./utils";
import { TabOrderAdvances } from "./views/OrderAdvance/TabOrderAdvances";
import { TabOrderItems, TabOrderItemsProps } from "./views/OrderItems/TabOrderItems";
import useMobile from "~/presentation/shared/hooks/useMobile";

export interface ManageOrderContainerProps {
  isLoading: boolean;
  activeTab: OrderTab;
  order?: Order;
  orderItemsState: TabOrderItemsProps;
  isLoadingOrderMessages: boolean;
  orderMessages: OrderMessage[];
  orderHistory?: GroupedOrderHistory[];
  isLoadingOrderHistory: boolean;
  onConsultOrderItemsPrice: (orderUuid?: string | undefined) => Promise<OrderItemsWithChangedPrice>;
}

export function ManageOrderContainer({
  isLoading,
  activeTab,
  order,
  orderItemsState,
  isLoadingOrderMessages,
  orderMessages,
  isLoadingOrderHistory,
  orderHistory,
  onConsultOrderItemsPrice,
}: ManageOrderContainerProps) {
  const tabOrderItemsProps = {
    ...orderItemsState,
    isLoading,
    onConsultOrderItemsPrice,
  };
  const isMobile = useMobile();

  const tabOrderMessagesProps = {
    isLoadingOrderMessages,
    orderMessages,
  };

  const tabOrderHistoryProps = {
    order,
    isLoadingOrderHistory,
    orderHistory: orderHistory || [],
  };

  return (
    <Fragment>
      <Tabs value={activeTab}>
        <AppBar>
          <AppBarContainer>
            <Tooltip content={<TooltipLabel>Voltar</TooltipLabel>}>
              <Link to="/pedidos/pedidos-pendentes">
                <IconButton size="md">
                  <Icon as={SvgChevronLeft} css={{ fill: isMobile ? "#FFF" : "" }} />
                </IconButton>
              </Link>
            </Tooltip>

            <AppBarHeading
              title={
                isLoading ? (
                  <Skeleton variant="text-6" style={{ maxWidth: 360 }} />
                ) : (
                  `Pedido #${order?.orderNumber}`
                )
              }
            />
          </AppBarContainer>

          <Row
            css={{
              "@mxlg": {
                width: "103%",
              },
            }}
          >
            <Container
              css={{
                "@mxlg": {
                  overflowX: "scroll",
                  p: 0,
                },
              }}
            >
              <TabBar>
                {isLoading || !order ? (
                  <Flex gap="8" css={{ p: "$4" }}>
                    <Skeleton variant="text-4" style={{ width: 96 }} />
                    <Skeleton variant="text-4" style={{ width: 96 }} />
                  </Flex>
                ) : (
                  ORDER_MENU_TABS.map(({ title, id }) => (
                    <Link to={`#${id}`} title={title} key={id}>
                      <CounterTab id={id} value={id}>
                        <Text>{title}</Text>

                        {id === OrderTab.ITEMS && (
                          <CounterTabBadge>
                            <Text>{order.itemsIncluded.length}</Text>
                          </CounterTabBadge>
                        )}

                        {id === OrderTab.OBSERVATIONS && (
                          <CounterTabBadge>
                            <Text>{orderMessages.length}</Text>
                          </CounterTabBadge>
                        )}

                        {id === OrderTab.HISTORY && (
                          <CounterTabBadge>
                            <Text>
                              {Object.values(orderHistory || {}).reduce(
                                (acc, { history }) => acc + history.length,
                                0
                              )}
                            </Text>
                          </CounterTabBadge>
                        )}
                      </CounterTab>
                    </Link>
                  ))
                )}
              </TabBar>
            </Container>
          </Row>
        </AppBar>

        {!isLoading && (
          <Container css={{ px: 5 }}>
            <TabContent value={OrderTab.ITEMS}>
              <TabOrderItems {...tabOrderItemsProps} />
            </TabContent>

            <TabContent value={OrderTab.VOUCHERS}>
              <TabOrderAdvances />
            </TabContent>

            <TabContent value={OrderTab.OBSERVATIONS}>
              <TabOrderMessages {...tabOrderMessagesProps} />
            </TabContent>

            <TabContent value={OrderTab.HISTORY}>
              <TabOrderHistory {...tabOrderHistoryProps} />
            </TabContent>
          </Container>
        )}
      </Tabs>
    </Fragment>
  );
}
