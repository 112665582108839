import { Fragment, useCallback, useState } from "react";
import { Order, OrderStatus, RemakeSearchData } from "~/application/types";
import { AppBar } from "~/components/AppBar";
import { Box } from "~/components/Box";
import { Card, CardBody } from "~/components/Card";
import { Container } from "~/components/Container";
import { Footer } from "~/components/Document";
import { EmptyState } from "~/components/EmptyState";
import { Flex } from "~/components/Flex";
import { Col, Row } from "~/components/Grid";
import { LazyList } from "~/components/LazyList";
import { Link } from "~/components/Link";
import { Pagination } from "~/components/Pagination";
import { Skeleton } from "~/components/Skeleton";
import { CounterTab, CounterTabBadge, TabBar, Tabs } from "~/components/Tabs";
import { Text } from "~/components/Text";
import { H4, H5 } from "~/components/Typography";
import { LoadingModal } from "~/core/modules/DeprecatedBooking/components/LoadingModal";
import { OrderListItem } from "~/presentation/shared/components/OrderListItem";
import { OrderSearch } from "../../AgenciesOrdersPage";
import { GroupedAgencyOrder } from "../../types";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  UseMutationResult,
} from "@tanstack/react-query";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { SvgFilter } from "~/components/Icon/icons";
import { Icon } from "~/components/Icon";
import { OrderFilter } from "../OrderFilter";
import Close from "~/components/Icon/icons/Close";

export interface AgencyOrdersContainerProps {
  searches?: OrderSearch;
  isLoading: boolean;
  ordersGroupsList?: GroupedAgencyOrder[];
  activeStatusTab: OrderStatus;
  currentPage: number;
  lastPage: number;
  tabSummary?: {
    id: OrderStatus;
    total: number;
    title: string;
  }[];
  onClearFilters: () => void;
  setOrderNumber: (search: string) => void;
  setEmployeeName: (search: string) => void;
  setIssuerName: (search: string) => void;
  setApproverName: (search: string) => void;
  setCustomerName: (search: string) => void;
  setStartDate: (search: string) => void;
  setEndDate: (search: string) => void;
  onGoToPage: (page: number) => void;
  onIssuerNotify: () => UseMutationResult<void, unknown, string, void>;
  onRefetchRoadQuery: (orderUuid: string) => {
    refetch: <TPageData>(
      options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
    ) => Promise<QueryObserverResult<RemakeSearchData, unknown>>;
    isLoading: boolean;
  };
  onCancelOrder: (orderUuid: string) => void;
}

export function AgenciesOrdersContainer({
  currentPage,
  lastPage,
  tabSummary,
  isLoading,
  searches,
  ordersGroupsList,
  activeStatusTab,
  setStartDate,
  setEndDate,
  onClearFilters,
  setEmployeeName,
  setCustomerName,
  setIssuerName,
  setApproverName,
  setOrderNumber,
  onGoToPage,
  onIssuerNotify,
  onRefetchRoadQuery,
  onCancelOrder,
}: AgencyOrdersContainerProps) {
  const [isDownloadingVoucher, setIsDownloadingVoucher] = useState(false);
  const isMobile = useMobile();
  const [sideBarIsOpen, setSideBarIsOpen] = useState(false);

  const toggleIsDownloadingVoucher = () => {
    setIsDownloadingVoucher((old) => !old);
  };

  const orderListRenderer = useCallback(
    (item: Order) => (
      <OrderListItem
        detailOrderLink={`/agencias/pedidos/${item.uuid}#${item.status}`}
        voucherDownloadUrl={`${import.meta.env.VITE_API_URL}/orders/${item.uuid}/voucher/pdf`}
        onIconClick={toggleIsDownloadingVoucher}
        onDownloaded={toggleIsDownloadingVoucher}
        data={item}
        key={item.uuid}
        onIssuerNotify={onIssuerNotify}
        onRefetchRoadQuery={onRefetchRoadQuery}
        onCancelOrder={onCancelOrder}
      />
    ),
    []
  );

  const listRenderer = useCallback(
    (item: GroupedAgencyOrder) => (
      <Box key={item.timestamp}>
        <Box css={{ mb: "$6" }}>
          <H5 variant="darkest" css={{ fw: 600 }}>
            {item.label}
          </H5>
        </Box>

        <LazyList gap="6" items={item.items} render={orderListRenderer} skeletonHeight={114} />
      </Box>
    ),
    [orderListRenderer]
  );

  const setStartDateCallback = useCallback((date: string) => {
    setStartDate(String(date));
  }, []);

  const setEndDateCallback = (date: string) => {
    setEndDate(String(date));
  };

  return (
    <Fragment>
      {isMobile && (
        <Flex
          direction="column"
          css={{
            "@mxxl": {
              width: "80%",
              height: "100vh",
              position: "fixed",
              display: sideBarIsOpen ? "flex" : "none",
              transition: "ease-in-out",
              zIndex: "999",
              top: "0",
              left: "0",
              backgroundColor: "White",
            },
          }}
        >
          <Box
            css={{
              display: "flex",
              width: "90%",
              margin: "0 auto",
              alignItems: "center",
              justifyContent: "space-between",
              mt: "$5",
            }}
          >
            <H5>Filtrar pedido</H5>
            <Close onClick={() => setSideBarIsOpen(false)} />
          </Box>
          <Flex
            align="center"
            justify="center"
            direction="column"
            css={{ height: "$20", mt: "$64", ml: "$3", width: "100%" }}
          >
            <OrderFilter
              isLoading={isLoading}
              searches={searches}
              onClearFilters={onClearFilters}
              setCustomerName={setCustomerName}
              setEmployeeName={setEmployeeName}
              setIssuerName={setIssuerName}
              setApproverName={setApproverName}
              setEndDateCallback={setEndDateCallback}
              setStartDateCallback={setStartDateCallback}
              setOrderNumber={setOrderNumber}
              setStartDate={setStartDate}
            />
          </Flex>
        </Flex>
      )}

      <Tabs value={activeStatusTab}>
        <AppBar>
          <Card
            css={{
              px: "$14",
              border: "none",
              "@mxlg": {
                px: 0,
              },
            }}
          >
            <CardBody style={{ border: "none" }}>
              <Row gap="4" align="center" wrap="noWrap">
                <Col>
                  <H4>Pedidos</H4>
                </Col>

                {!isMobile ? (
                  <>
                    <OrderFilter
                      isLoading={isLoading}
                      onClearFilters={onClearFilters}
                      setCustomerName={setCustomerName}
                      setEmployeeName={setEmployeeName}
                      setIssuerName={setIssuerName}
                      setApproverName={setApproverName}
                      setEndDateCallback={setEndDateCallback}
                      setStartDateCallback={setStartDateCallback}
                      setOrderNumber={setOrderNumber}
                      setStartDate={setStartDate}
                      searches={searches}
                    />
                  </>
                ) : (
                  <Card
                    onClick={() => setSideBarIsOpen((old) => !old)}
                    css={{ width: "$0", p: "$2" }}
                  >
                    <Icon as={SvgFilter} />
                  </Card>
                )}
              </Row>
            </CardBody>
          </Card>

          <Container
            css={{
              "@mxlg": {
                overflow: "scroll",
                px: "0",
              },
            }}
          >
            <TabBar>
              {isLoading || !tabSummary ? (
                <Flex gap="8" css={{ p: "$4", pl: 0 }}>
                  <Skeleton variant="text-4" style={{ width: 96 }} />
                  <Skeleton variant="text-4" style={{ width: 96 }} />
                  <Skeleton variant="text-4" style={{ width: 96 }} />
                  <Skeleton variant="text-4" style={{ width: 96 }} />
                </Flex>
              ) : (
                tabSummary?.map(({ title, total, id }) => (
                  <Link to={`#${id}`} title={title} key={id}>
                    <CounterTab id={id} value={id}>
                      <Text>{title}</Text>
                      <CounterTabBadge>{total}</CounterTabBadge>
                    </CounterTab>
                  </Link>
                ))
              )}

              {isDownloadingVoucher && (
                <LoadingModal isOpen={isDownloadingVoucher} message="Baixando voucher" />
              )}
            </TabBar>
          </Container>
        </AppBar>

        <Container
          css={{
            pt: "$10",
            pb: "$20",
            "@mxlg": {
              px: "$6",
            },
          }}
        >
          <LazyList
            gap="6"
            items={ordersGroupsList}
            render={listRenderer}
            skeletonHeight={114}
            EmptyComponent={
              <EmptyState>
                <Text>Você não possui pedidos</Text>
              </EmptyState>
            }
          />
        </Container>
      </Tabs>

      <Footer>
        <Container>
          <Pagination activePage={currentPage} pagesCount={lastPage} setActivePage={onGoToPage} />
        </Container>
      </Footer>
    </Fragment>
  );
}
