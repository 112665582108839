import { CSS } from "@stitches/react";
import { ComponentProps } from "react";
import { styled } from "~/application/theme";
import { Box } from "../../Box";
import { Text } from "../../Text";
import { Flex } from "~/components/Flex";

const DataColItemRoot = styled("div", {
  // Reset
  display: "flex",
  boxSizing: "border-box",

  // Custom reset?
  flexDirection: "column",
  justifyContent: "space-between",

  // Custom
  alignSelf: "stretch",
  flex: "1 0 0%",
});

export type DataColItemProps = ComponentProps<typeof DataColItemRoot> & {
  headerTitle?: string;
  headerTitleSize?: "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9";
  data: React.ReactNode;
  boxCss?: CSS;
};

export const DataColItem = ({
  data,
  boxCss,
  headerTitle = "",
  headerTitleSize = "2",
  dangerouslySetInnerHTML,
  ...props
}: DataColItemProps & { dangerouslySetInnerHTML?: { __html: string } }) => {
  return (
    <DataColItemRoot {...props}>
      <Flex direction={{ "@initial": "column" }} gap="1">
        <Text
          size={headerTitleSize}
          variant="dark"
          css={{
            fw: "500",
            display: headerTitle === "" ? "none" : "flex",
          }}
        >
          {headerTitle}
        </Text>
        <Box
          css={{
            ...boxCss,
          }}
        >
          {dangerouslySetInnerHTML ? (
            <div dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
          ) : (
            <Text css={{ fw: "600" }}>{data}</Text>
          )}
        </Box>
      </Flex>
    </DataColItemRoot>
  );
};

DataColItem.displayName = "DataColItem";

