import { FC, useCallback, useEffect, useState } from "react";
import { Icon } from "~/components/Icon";
import { SvgChevronDown, SvgChevronUp, SvgEdit } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { SearchBar, Switch } from "~/components/Input";
import { DataColItem, DataListItem, DataListItemProps } from "~/components/List/DataListItem";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { Caption } from "~/components/Typography";
import { Credential, CredentialCustomer } from "~/application/types/entities/Credential.type";
import { getServiceIcon } from "~/presentation/shared/utils";
import { Flex } from "~/components/Flex";
import { Collapse } from "react-collapse";
import { Box } from "~/components/Box";
import { Card, CardSectionHeader } from "~/components/Card";
import { Spinner } from "~/components/Spinner";
import { Text } from "~/components/Text";
import { Col } from "~/components/Grid";
import { UseMutateFunction, UseQueryResult } from "@tanstack/react-query";
import { Container } from "~/components/Container";
import { Pagination } from "~/components/Pagination";
import { PaginatedResource } from "~/application/types";
import { useDebounce } from "use-debounce";

interface CredentialListItemProps extends DataListItemProps {
  data: Credential;
  isCollapsed: boolean;
  currentPage: number;
  handleToggleCustomer: UseMutateFunction<
    void,
    unknown,
    {
      agreementId: string;
      refetch: () => void;
      isActive: boolean;
    },
    void
  >;

  onToggleState: (id: string) => void;
  fetchCredentialCustomerOnClick: ({
    credentialId,
    currentPage,
    searchText,
  }: {
    credentialId: string;
    currentPage: number;
    searchText: string;
  }) => UseQueryResult<PaginatedResource<CredentialCustomer[]>, unknown>;

  onEditClick: (data: Credential) => void;
  onToggleCollapse: () => void;
}

export const CredentialListItem: FC<CredentialListItemProps> = ({
  data,
  isCollapsed,
  handleToggleCustomer,
  onToggleState,
  fetchCredentialCustomerOnClick,
  onEditClick,
  onToggleCollapse,
  ...props
}) => {
  const [itemPage, setItemPage] = useState<number>(1);
  const [searchCustomerText, setSearchCustomerText] = useState<string>("");
  const [debouncedText] = useDebounce<string>(searchCustomerText, 700);

  const handlePageChange = useCallback(
    async (page: number) => {
      setItemPage(page);
    },
    [setItemPage]
  );

  const {
    isLoading,
    data: customers,
    refetch,
  } = fetchCredentialCustomerOnClick({
    credentialId: data.uuid,
    currentPage: itemPage ?? 1,
    searchText: debouncedText,
  });

  const onToggleCustomer = useCallback(
    (customer: CredentialCustomer) => {
      handleToggleCustomer({
        agreementId: customer.agreementId,
        refetch,
        isActive: customer.active,
      });
    },
    [handleToggleCustomer]
  );

  const onPageChange = useCallback(
    async (page: number) => {
      await handlePageChange(page);
      refetch();
    },
    [handlePageChange, refetch]
  );

  useEffect(() => {
    if (debouncedText) {
      refetch();
    }
  }, [debouncedText]);

  return (
    <>
      <DataListItem
        data-active={data.active || isCollapsed}
        data-list={isCollapsed}
        {...props}
        onClick={() => {
          refetch();
          onToggleCollapse();
        }}
        css={{
          cursor: "pointer",
          borderBottomLeftRadius: isCollapsed ? "$none" : undefined,
          borderBottomRightRadius: isCollapsed ? "$none" : undefined,
          "&:hover::after": {
            boxShadow: isCollapsed ? "$none" : undefined,
          },
        }}
      >
        <DataColItem headerTitle="Nome" data={data.description} />
        <DataColItem headerTitle="Fornecedor" data={data.provider.name} />
        <DataColItem
          headerTitle="Serviço"
          data={
            <Flex gap="2">
              <Flex align="center" gap="2">
                <IconButton size="md">
                  <Icon as={getServiceIcon(data.serviceType)} />
                </IconButton>
              </Flex>
            </Flex>
          }
        />
        <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
          <IconButton
            size="md"
            onClick={(event) => {
              event.stopPropagation();
              onEditClick(data);
            }}
          >
            <Icon as={SvgEdit} />
          </IconButton>
        </Tooltip>

        <Switch
          checked={data.active}
          onClick={(event) => {
            event.stopPropagation();
            onToggleState(data.uuid);
          }}
        >
          <Caption>{data.active ? "Ativo" : "Inativo"}</Caption>
        </Switch>
        <IconButton size="md">
          <Icon as={isCollapsed ? SvgChevronUp : SvgChevronDown} />
        </IconButton>
      </DataListItem>
      <Collapse isOpened={isCollapsed} style={{ padding: "10px", background: "#f0f0f0" }}>
        <Box
          css={{
            background: "#fff",
            borderWidth: "0 2px 2px 2px",
            borderStyle: "solid",
            borderColor: "$primary-base",
            borderBottomLeftRadius: "$md",
            borderBottomRightRadius: "$md",
            pb: "$5",
          }}
        >
          <Flex justify="end" css={{ px: "$4", py: "$3" }}>
            <SearchBar
              search={searchCustomerText}
              onSearchChange={(text) => setSearchCustomerText(text)}
              placeholder="Encontrar empresa"
            />
          </Flex>
          {isLoading ? (
            <Flex justify="center" css={{ p: "$5" }}>
              <Spinner />
            </Flex>
          ) : (
            <Card css={{ width: "98%", margin: "0 auto" }}>
              <CardSectionHeader
                css={{
                  p: "$5",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <DataColItem
                  data={
                    <Flex>
                      <Text>Nome Fantasia</Text>
                    </Flex>
                  }
                />

                <DataColItem
                  data={
                    <Flex>
                      <Text>Razão Social</Text>
                    </Flex>
                  }
                />

                <DataColItem
                  data={
                    <Flex>
                      <Text>CNPJ</Text>
                    </Flex>
                  }
                />

                <DataColItem css={{ ta: "end" }} data={<></>} />
              </CardSectionHeader>

              {(customers?.data?.length || 0) <= 0 && (
                <Text css={{ p: "$5" }}>Nenhuma empresa encontrada</Text>
              )}

              {customers?.data?.map((customer) => (
                <Flex
                  justify="between"
                  css={{
                    p: "$5",
                    gap: "$4",
                    wordBreak: "break-word",
                    borderBottom: "1px solid $colors$neutrals-light",
                    "&:last-child": {
                      borderBottom: "none",
                    },
                  }}
                >
                  <DataColItem
                    data={
                      <Col css={{ wordBreak: "break-all" }}>
                        <Text>{customer.companyName}</Text>
                      </Col>
                    }
                  />

                  <DataColItem
                    data={
                      <Col>
                        <Text>{customer.tradingName}</Text>
                      </Col>
                    }
                  />

                  <DataColItem
                    data={
                      <Col>
                        <Text>{customer.cnpj}</Text>
                      </Col>
                    }
                  />

                  <DataColItem
                    css={{ ta: "end" }}
                    data={
                      <Switch
                        checked={customer.active}
                        onClick={() => {
                          onToggleCustomer(customer);
                        }}
                      >
                        <Caption>{customer.active ? "Ativo" : "Inativo"}</Caption>
                      </Switch>
                    }
                  />
                </Flex>
              ))}
            </Card>
          )}
          <Container css={{ p: "$5" }}>
            <Pagination
              activePage={itemPage || 1}
              setActivePage={onPageChange}
              pagesCount={customers?.meta.last_page || 1}
            />
          </Container>
        </Box>
      </Collapse>
    </>
  );
};
