import { useCallback, useMemo, useState } from "react";
import {
  createFilterGroups,
  Filter,
  IFilterGenerator,
  IFilterGroup,
} from "../utils";

export interface UseFiltersReturn<TData> {
  filteredData: TData[];
  filters: Filter[];
  onFilterChange: (groupKey: string, optionKey: string) => void;
  onClearFilters: () => void;
}

export interface UseFiltersOptions<TData> {
  data: TData[];
  filters: IFilterGroup<TData>[];
  filtersGenerator?: IFilterGenerator<TData>[];
}

type SelectedFilter = {
  [groupKey: string]: Set<string>;
};

export function useFilters<TData>({
  data,
  filters: filtersOption,
  filtersGenerator = [],
}: UseFiltersOptions<TData>): UseFiltersReturn<TData> {
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter>({});

  const generatedFilters = useMemo<IFilterGroup<TData>[]>(
    () => createFilterGroups(data, filtersGenerator),
    [data, filtersGenerator]
  );

  const filterGroups = useMemo<IFilterGroup<TData>[]>(
    () =>
      [...filtersOption, ...generatedFilters].sort((a, b) =>
        a.label > b.label ? 1 : -1
      ),
    [generatedFilters, filtersOption]
  );

  const memoizedFilters = useMemo(
    () =>
      filterGroups.map((group) => ({
        key: group.key,
        label: group.label,
        options: group.options.map((item) => ({
          key: item.key,
          label: item.label,
          quantity: data.filter((i) => group.isValid(i, item.value)).length,
        })),
      })),
    [data, filterGroups]
  );

  const filters = useMemo<Filter[]>(
    () =>
      memoizedFilters.map((group) => ({
        ...group,
        options: group.options.map((item) => ({
          ...item,
          selected: !!selectedFilters[group.key]?.has(item.key),
        })),
      })),
    [selectedFilters, memoizedFilters]
  );

  const filteredData = useMemo<TData[]>(
    () =>
      data.filter((item) =>
        filterGroups.every((group) => {
          const selected = group.options.filter(
            (option) => !!selectedFilters[group.key]?.has(option.key)
          );

          if (!selected.length) return true;

          return selected.some((option) => group.isValid(item, option.value));
        })
      ),
    [data, selectedFilters, filterGroups]
  );

  const onFilterChange = useCallback((groupKey: string, optionKey: string) => {
    setSelectedFilters((old) => {
      const filtersSet = new Set(old[groupKey]);

      if (!filtersSet.has(optionKey)) {
        filtersSet.add(optionKey);
      } else {
        filtersSet.delete(optionKey);
      }

      return {
        ...old,
        [groupKey]: filtersSet,
      };
    });
  }, []);

  const onClearFilters = useCallback(() => {
    setSelectedFilters({});
  }, []);

  return {
    filters,
    filteredData,
    onFilterChange,
    onClearFilters,
  };
}
