import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { ApiError, CustomerEmployee, PaginatedResource } from "~/application/types";
import { customerEmployeeService } from "~/application/usecases";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants/queryKeys";
import { QueryTimes } from "~/constants/queryTimes";
import { useUser } from "~/presentation/core/contexts/UserContext";
import {
  EMPTY_CUSTOMER_EMPLOYEE_FILTERS,
  ICustomerEmployeeFilters,
} from "~/presentation/shared/types";
import { InactivateDialog } from "~/presentation/shared/views/InactivateDialog";
import { queryClient } from "~/services/queryClient";
import { log } from "~/utils/log";
import { LOG_TAG, UseCustomerEmployeesPageResult } from "./types";
import { useCustomerEmployeeDialog } from "~/presentation/shared/hooks/useCustomerEmployeeDialog";
import { useForm } from "react-hook-form";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar funcionários da agência",
  CREATE_SUCCESS_MESSAGE: "Funcionário criado",
  CREATE_ERROR_MESSAGE: "Falha ao criar funcionário",
  UPDATE_SUCCESS_MESSAGE: "Funcionário atualizado",
  UPDATE_ERROR_MESSAGE: "Falha ao atualizar funcionário",
  ACTIVATE_ERROR_MESSAGE: "Falha ao ativar funcionário",
  ACTIVATE_SUCCESS_MESSAGE: "Funcionário ativado",
  INACTIVATE_ERROR_MESSAGE: "Falha ao inativar funcionário",
  INACTIVATE_SUCCESS_MESSAGE: "Funcionário inativado",
} as const;

export function useCustomerEmployeesPage(): UseCustomerEmployeesPageResult {
  const { handleOpenCreateModal, handleOpenEditModal } = useCustomerEmployeeDialog({});

  const { contexts } = useUser();

  const customerId = contexts.customer.uuid;

  const { control, watch, reset } = useForm({
    defaultValues: EMPTY_CUSTOMER_EMPLOYEE_FILTERS,
  });
  const dataFilter = watch();

  const [searchText, setSearchText] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [search] = useDebounce<string>(searchText, 700);

  const { data, isFetching } = useQuery<PaginatedResource<CustomerEmployee[]>, ApiError>(
    [QueryKeys.CUSTOMER_EMPLOYEES, customerId, currentPage, search, dataFilter],
    () =>
      customerEmployeeService.find({
        customerId,
        profile: dataFilter.profiles.value,
        page: currentPage,
        name: search,
        approversOnly: dataFilter.profiles.value,
        isActive: dataFilter.activeness.value,
      }),
    {
      staleTime: QueryTimes.NORMAL,
      refetchOnWindowFocus: false,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE, "error");
      },
    }
  );

  const { mutate: mutateToggleState } = useMutation(
    (item: CustomerEmployee) => customerEmployeeService.toggleActive(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating CustomerEmployee(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated CustomerEmployee(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_EMPLOYEES]);

        snackbarService.showSnackbar(
          item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_SUCCESS_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_SUCCESS_MESSAGE,
          "success"
        );

        dialogService.popDialog();
      },
      onError: (error, item) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_ERROR_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_ERROR_MESSAGE,
          "error"
        );

        if (item.isActive) {
          dialogService.popDialog();
        }
      },
    }
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [dataFilter]);

  const handleToggleState = useCallback((item: CustomerEmployee) => {
    if (!item.isActive) {
      mutateToggleState(item);
      return;
    }

    dialogService.showDialog(<InactivateDialog onConfirm={() => mutateToggleState(item)} />);
  }, []);

  const handleChangeSearch = useCallback((text: string) => {
    setSearchText(text);
    setCurrentPage(1);
  }, []);

  const handleCleanFilters = useCallback(() => {
    reset();
    setCurrentPage(1);
  }, []);

  const profilesOptions = data?.filters?.profile?.map((profile) => ({
    label: profile,
    value: profile,
  }));

  const activenessOptions = data?.filters?.is_active?.map((profile) => ({
    label: profile,
    value: profile,
  }));

  return {
    profilesOptions,
    activenessOptions,
    control,
    data: data?.data,
    isLoading: isFetching,
    currentPage: currentPage,
    lastPage: data?.meta.last_page ?? 0,
    searchText: searchText,
    setSearchText: handleChangeSearch,
    onGoToPage: setCurrentPage,
    onCreateCustomerEmployee: handleOpenCreateModal,
    onEditCustomerEmployee: handleOpenEditModal,
    onToggleCustomerEmployeeState: handleToggleState,
    onCleanFilters: handleCleanFilters,
  };
}
