import { Fragment, useCallback, useState } from "react";
import {
  Customer,
  GroupedOrderHistory,
  OrderMessage,
  UserContext,
} from "~/application/types";
import { AppBar, AppBarContainer, AppBarHeading } from "~/components/AppBar";
import { Container } from "~/components/Container";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgChevronLeft, SvgPerson } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Link } from "~/components/Link";
import { Skeleton } from "~/components/Skeleton";
import { CounterTab, CounterTabBadge, TabBar, TabContent, Tabs } from "~/components/Tabs";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { H4 } from "~/components/Typography";
import { LoadingModal } from "~/core/modules/DeprecatedBooking/components/LoadingModal";
import { TabOrderHistory } from "~/core/modules/Order/pages/OrderPage/views/OrderItem/components/TabOrderHistory";
import { useAuth } from "~/presentation/core/contexts/AuthContext";
import { useOrder } from "~/presentation/shared/hooks/useOrder";
import { ORDER_MENU_TABS, OrderTab } from "~/presentation/shared/utils";
import { TabOrderMessages } from "../../../../../presentation/shared/components/TabOrderMessages/TabOrderMessages";
import { TabOrderItems } from "./components/TabOrderItems";
import { UseOrderItemsResult } from "./hooks/types";
import { getServiceIcon } from "~/core/shared/utils/order.utils";
import { dialogService } from "~/components/DialogStack";
import { AdditionalInfo } from "./components/AdditionalInfoDialog";
import { CustomerInfoDialog } from "./components/CustomerInfoDialog";
import { Approvers, Issuers } from "~/infrastructure/api/dtos";

export interface AgencyOrderContainerProps {
  isLoading: boolean;
  activeTab: OrderTab;
  additionalInfo: Customer | undefined;
  orderItemsState: UseOrderItemsResult;
  isLoadingOrderMessages: boolean;
  orderMessages: OrderMessage[];
  orderHistory?: GroupedOrderHistory[];
  isLoadingOrderHistory: boolean;
  isLoadingAdditionalInfo: boolean;
  onSendVoucherInWhatsapp: () => void;
}

export type CustomerInfoProps = { approvers: Approvers, issuers: Issuers }
export type AdditionalInfoProps = { label: string, obs: string }

export function AgencyOrderContainer({
  isLoading,
  activeTab,
  orderItemsState,
  isLoadingAdditionalInfo,
  orderMessages,
  additionalInfo,
  isLoadingOrderMessages,
  isLoadingOrderHistory,
  orderHistory,
  onSendVoucherInWhatsapp,
}: AgencyOrderContainerProps) {
  const { user } = useAuth();
  const { order } = useOrder();
  const [isDownloadingVoucher, setIsDownloadingVoucher] = useState(false);

  const toggleIsDownloadingVoucher = () => {
    setIsDownloadingVoucher((old) => !old);
  };

  const userIsAgencyEmployee = user.context === UserContext.Agency;

  const menuTabs = ({ id, title }: { id: string; title: string }) => (
    <Link to={`#${id}`} title={title} key={id}>
      <CounterTab id={id} value={id}>
        <Text>{title}</Text>

        {id === OrderTab.ITEMS && (
          <CounterTabBadge>
            <Text>{order?.itemsIncluded.length}</Text>
          </CounterTabBadge>
        )}

        {id === OrderTab.OBSERVATIONS && (
          <CounterTabBadge>
            <Text>{orderMessages.length || 0}</Text>
          </CounterTabBadge>
        )}

        {id === OrderTab.HISTORY && (
          <CounterTabBadge>
            <Text>
              {Object.values(orderHistory || []).reduce(
                (acc, { history }) => acc + history.length,
                0
              )}
            </Text>
          </CounterTabBadge>
        )}
      </CounterTab>
    </Link>
  );

  const dialogAdditionalInfo = useCallback(({ label, obs }: AdditionalInfoProps) => {
    return dialogService.showDialog(<AdditionalInfo label={label} obs={obs} />)
  }, [])

  const customerInfoDialog = useCallback(() => {
    return dialogService.showDialog(<CustomerInfoDialog approvers={additionalInfo?.approvers} issuers={additionalInfo?.issuers} />)
  }, [additionalInfo])

  const hash = window.location.hash.slice(1);
  const orderMessagesState = {
    isLoadingOrderMessages,
    orderMessages,
  };

  const orderHistoryState = {
    isLoadingOrderHistory,
    orderHistory: orderHistory ?? [],
  };

  const tabOrderItemProps = {
    ...orderItemsState,
    onSendVoucherInWhatsapp,
    onVoucherClick: toggleIsDownloadingVoucher,
    onVoucherDownloaded: toggleIsDownloadingVoucher,
  };
  
  return (
    <Fragment>
      <Tabs value={activeTab}>
        <AppBar>
          <AppBarContainer>
            <Tooltip content={<TooltipLabel>Voltar</TooltipLabel>}>
              <Link
                to={`/agencias/pedidos${hash === OrderTab.OBSERVATIONS ? "" : `#${hash}`
                  }`}
              >
                <IconButton size="md">
                  <Icon as={SvgChevronLeft} css={{
                    "@mxlg": {
                      fill: "#fff"
                    }
                  }} />
                </IconButton>
              </Link>
            </Tooltip>

            <AppBarHeading
              title={
                isLoading ? (
                  <Skeleton variant="text-6" style={{ maxWidth: 360 }} />
                ) : (
                  <Flex justify={{ "@mxlg": "center" }}>
                    <H4 size={{ "@mxlg": "2" }}>
                      <Flex align="center">
                        Pedido #{order?.orderNumber}{" "}
                        {userIsAgencyEmployee ? ` - ${order?.customer.name}` : ""}
                        {isLoadingAdditionalInfo ? (
                          <Flex gap="8" css={{ p: "$4" }}>
                          <Skeleton variant="text-4" style={{ width: 100 }} />
                        </Flex>

                        ) : (
                          <Flex align="center" css={{ ml: "$5" }}>
                            <IconButton size="lg" onClick={() => customerInfoDialog()}>
                              <Icon size="md" as={SvgPerson} />
                            </IconButton>
                            {additionalInfo?.additionalInfo &&
                              Object.entries(additionalInfo.additionalInfo).map(([key, value]) => {
                                const ServiceIcon = getServiceIcon(key);
                                return (
                                  <IconButton css={{ p: "$5" }} key={key}>
                                    <Flex key={key} css={{ cursor: "pointer" }} onClick={() => dialogAdditionalInfo({ label: key, obs: value.obs })}>
                                      {ServiceIcon && <ServiceIcon />}
                                    </Flex>
                                  </IconButton>
                                );
                              })}
                          </Flex>
                        )}
                      </Flex>
                    </H4>
                  </Flex>
                )
              }
            />


          </AppBarContainer>

          <Container css={{ "@mxlg": { p: "0", overflow: "scroll" } }}>
            <TabBar>
              {isLoading || !order ? (
                <Flex gap="8" css={{ p: "$4" }}>
                  <Skeleton variant="text-4" style={{ width: 96 }} />
                  <Skeleton variant="text-4" style={{ width: 96 }} />
                </Flex>
              ) : (
                ORDER_MENU_TABS.filter(({ id }) => id !== OrderTab.ACCOUNTABILITY_EXPENSES).map(
                  ({ title, id }) => menuTabs({ id, title })
                )
              )}
            </TabBar>
          </Container>
        </AppBar>

        {!isLoading && (
          <Container css={{
            "@mxlg": {
              p: "$5"
            }
          }}>
            <TabContent value={OrderTab.ITEMS}>
              <TabOrderItems {...tabOrderItemProps} />
            </TabContent>

            <TabContent value={OrderTab.OBSERVATIONS}>
              <TabOrderMessages {...orderMessagesState} />
            </TabContent>

            <TabContent value={OrderTab.HISTORY}>
              <TabOrderHistory {...orderHistoryState} />
            </TabContent>
          </Container>
        )}

        <LoadingModal isOpen={isDownloadingVoucher} message="Baixando voucher do pedido" />
      </Tabs>
    </Fragment>
  );
}
