import {
  Order,
  OrderHotelItem,
  OrderHotelItemType,
  OrderHotelOfflineOption,
  OrderItemStatus,
  OrderStatus,
} from "~/application/types";
import { diffBetweenDates } from "~/application/utils/date-functions";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgCopy, SvgEdit, SvgExpenses, SvgTrash } from "~/components/Icon/icons";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { getNameRoomTypes } from "~/core/modules/Order/pages/OrderPage/views/OrderItem/utils";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { DateFormats, displayDate } from "~/utils/date.utils";
import { asCurrency } from "~/utils/mask.utils";

export type OrderHotelOfflineAgencyItemProps = {
  item?: OrderHotelItem;
  order?: Order;
  index?: number;
  onOpenOptionDetails: (item: OrderHotelItem, option: OrderHotelOfflineOption) => void;
  onOpenQuoteOffline?: (item: OrderHotelItem, options: OrderHotelOfflineOption[]) => void;
  onCopyQuoteOffline?: (item: OrderHotelItem, option: OrderHotelOfflineOption[]) => void;
  onDeleteQuoteOffline?: (optionId: string) => void;
  onItemExpand: (room: OrderHotelItem) => void;
};

export function OrderHotelOfflineAgencyItem({
  item,
  order,
  onOpenQuoteOffline,
  onDeleteQuoteOffline,
  onCopyQuoteOffline,
  onOpenOptionDetails,
}: OrderHotelOfflineAgencyItemProps) {
  const itemOffline = item?.obsOffline;
  const isMobile = useMobile();

  const check = {
    in: item?.checkIn,
    out: item?.checkOut,
  };

  const isApprovedStatus = [
    OrderStatus.APPROVED,
    OrderStatus.ISSUED,
    OrderStatus.PENDING_ISSUE,
  ].includes(order?.status as OrderStatus);
  const options = item?.options;

  const optionsFiltered = options?.filter((option) =>
    isApprovedStatus ? option.status === OrderItemStatus.QUOTED : true
  );

  const isQuotingStatus = order?.status === OrderStatus.QUOTING;

  const adultNumber = item?.guests.reduce((acc, guest) => {
    if (guest.passengerType === "ADT") {
      acc += 1;
    }
    return acc;
  }, 0) as number;

  const roomType = getNameRoomTypes(item);

  return (
    <Flex direction="column" gap="8">
      {!optionsFiltered?.length ? (
        <Card css={{ border: "3px solid $primary-base", overflowX: "hidden" }}>
          <CardBody css={{ p: "0" }}>
            <Flex css={{ width: "100%", p: "0" }} justify="between">
              <Flex css={{ width: "100%", p: "$6" }} direction="column" gap="6">
                <Flex direction="column" gap="4">
                  <Text as="h2" size={{ "@mxlg": "5" }}>
                    Hotel à Definir
                  </Text>
                  <Text as="h3" size={{ "@mxlg": "4" }}>{`Quarto ${roomType}`}</Text>
                </Flex>
                <Flex direction="column" gap="6">
                  <Flex>
                    <Text>
                      <Text variant="black" size="3">
                        {item?.cityName}
                      </Text>
                    </Text>
                  </Flex>

                  <Flex
                    css={{
                      "@mxlg": {
                        flexDirection: "column",
                      },
                    }}
                  >
                    <Text css={{ mr: "$10" }}>{`Check-in: ${displayDate(
                      check.in ?? "",
                      DateFormats.SMALL_DATE
                    )}`}</Text>
                    <Text
                      css={{
                        "@mxlg": {
                          mt: "$5",
                        },
                      }}
                    >{`Check-out: ${displayDate(check.out ?? "", DateFormats.SMALL_DATE)}`}</Text>
                  </Flex>
                  <Text>{`Regime de hospedagem: ${item?.roomRegimen}`}</Text>
                  {item?.obsOffline?.obs && (
                    <Text>
                      {`Observação:`}{" "}
                      <Text size="3" variant="warning-dark">
                        {" "}
                        {item?.obsOffline?.obs}
                      </Text>
                    </Text>
                  )}

                  {isMobile && (
                    <Flex direction="column" gap="4" align="start">
                      <Text size="4">
                        <Text variant="darkest">{`${diffBetweenDates(check.out, check.in)} diária${
                          diffBetweenDates(check.out, check.in) > 1 ? "s," : ","
                        }`}</Text>
                        <Text variant="darkest">{` ${adultNumber} Adulto${
                          adultNumber > 1 ? "s" : ""
                        }`}</Text>
                      </Text>
                      <Text fw="600">
                        R$ <Text>0,00</Text>
                      </Text>
                    </Flex>
                  )}

                  <Flex gap="2">
                    {itemOffline?.amenities?.map((amenity) => (
                      <Tag key={amenity} variant="info-light">
                        {amenity}
                      </Tag>
                    ))}
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                justify="between"
                align="center"
                direction="column"
                css={{
                  width: "35%",
                  border: "1px $neutrals-light",
                  borderStyle: "none none none dotted",
                  p: "$6",
                  "@mxlg": {
                    border: "0",
                  },
                }}
              >
                <Flex
                  direction="column"
                  gap="4"
                  align="center"
                  css={{
                    "@mxlg": {
                      display: "none",
                    },
                  }}
                >
                  <Text size="4">
                    <Text variant="darkest">{`${diffBetweenDates(check.out, check.in)} diária${
                      diffBetweenDates(check.out, check.in) > 1 ? "s," : ","
                    }`}</Text>
                    <Text variant="darkest">{` ${adultNumber} Adulto${
                      adultNumber > 1 ? "s" : ""
                    }`}</Text>
                  </Text>
                  <Text fw="600">
                    R$ <Text>0,00</Text>
                  </Text>
                </Flex>

                {!order?.isExpired && (
                  <Button
                    onClick={() => {
                      onOpenQuoteOffline?.(item as OrderHotelItem, []);
                    }}
                    css={{
                      "@mxlg": {
                        height: "$4",
                        px: "$3",
                        mr: "30px",
                        fontSize: "$xxs",
                      },
                    }}
                  >
                    <SvgExpenses />
                    <Text css={{ ml: "$2" }}>Cotizar Item</Text>
                  </Button>
                )}
              </Flex>
            </Flex>
          </CardBody>
        </Card>
      ) : (
        optionsFiltered?.map((option, index) => (
          <Card css={{ border: "none" }} key={option.uuid}>
            <CardBody css={{ p: "0" }}>
              <Flex
                css={{ width: "100%", p: "0" }}
                direction={{ "@mxlg": "column" }}
                justify="between"
              >
                <Flex css={{ width: "100%", p: "$6" }} direction="column" gap="6">
                  {isMobile && (
                    <Flex justify="between" css={{ width: "100%" }}>
                      <Text
                        as="h2"
                        size={{ "@mxlg": "3" }}
                        css={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          "@mxlg": {
                            alignItems: "start",
                          },
                        }}
                      >
                        {optionsFiltered?.length ? option.description : "Hotel à Definir"}

                        <Tag
                          css={{
                            "@mxlg": {
                              height: "$7",
                              width: "100px",
                              border: "0",
                              display: "none",
                            },
                          }}
                          variant="info-light"
                        >
                          Opção {index + 1}
                        </Tag>

                        {!isQuotingStatus && (
                          <Button
                            variant="tertiary"
                            onClick={() => {
                              onOpenOptionDetails(item as OrderHotelItem, option);
                            }}
                            css={{
                              "@mxlg": {
                                height: "$3",
                                p: "$3",
                                mb: "$5",
                                fontSize: "$sm",
                              },
                            }}
                          >
                            <Icon as={SvgEdit} />
                            Editar dados
                          </Button>
                        )}
                      </Text>
                    </Flex>
                  )}
                  <Flex direction="column" gap="4">
                    <Text as="h3" size={{ "@mxlg": "3" }}>{`Quarto ${roomType}`}</Text>
                  </Flex>
                  <Flex direction="column" gap="6">
                    <Flex>
                      <Text>
                        <Text variant="black" size="3">
                          {item?.cityName}
                        </Text>
                      </Text>
                    </Flex>

                    {!!optionsFiltered && (
                      <Flex direction="column" gap="5">
                        <Text
                          css={{
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          <Text
                            variant="black"
                            size="3"
                            css={{
                              flex: "0 0 50%",
                            }}
                          >
                            Contato(s): <Text variant="darkest"> {option.phone}</Text>
                          </Text>
                          <Text
                            variant="black"
                            size="3"
                            css={{
                              "@mxlg": {
                                wordBreak: "break-all",
                              },
                            }}
                          >
                            Email: <Text variant="darkest"> {option.email}</Text>
                          </Text>
                        </Text>

                        {option.obsAgency && (
                          <Text css={{ width: "100%", display: "flex" }}>
                            <Text
                              variant="black"
                              size="3"
                              css={{
                                flex: "0 0 50%",
                                "@mxlg": {
                                  flex: "100%",
                                },
                              }}
                            >
                              Observações: <Text variant="darkest">{option.obsAgency}</Text>
                            </Text>
                          </Text>
                        )}
                      </Flex>
                    )}
                    {option?.note && (
                      <Flex gap="1" direction="column">
                        <Text size="3" fw="700">
                          Justificativa de alteração{" "}
                        </Text>{" "}
                        <Text size="3" variant="warning-dark">
                          {option?.note}
                        </Text>
                      </Flex>
                    )}

                    <Flex>
                      <Text
                        variant="black"
                        size="3"
                        css={{
                          "@mxlg": {
                            wordBreak: "break-all",
                          },
                          flex: "1",
                        }}
                      >
                        Check-in:{" "}
                        <Text variant="darkest">
                          {" "}
                          {displayDate(option.checkIn as Date, DateFormats.TIMELY_MONTH)}
                        </Text>
                      </Text>
                      <Text
                        variant="black"
                        size="3"
                        css={{
                          "@mxlg": {
                            wordBreak: "break-all",
                          },
                          flex: "1",
                        }}
                      >
                        Check-out:{" "}
                        <Text variant="darkest">
                          {" "}
                          {displayDate(option.checkOut as Date, DateFormats.TIMELY_MONTH)}
                        </Text>
                      </Text>
                    </Flex>
                    <Flex gap="2">
                      {itemOffline?.amenities?.map((amenity) => (
                        <Tag
                          key={amenity}
                          variant="info-light"
                          css={{
                            "@mxlg": {
                              border: "0",
                            },
                          }}
                        >
                          {amenity}
                        </Tag>
                      ))}
                    </Flex>
                  </Flex>
                </Flex>

                <Flex
                  justify="between"
                  align={{ "@initial": "center", "@mxlg": "start" }}
                  direction="column"
                  css={{
                    width: "35%",
                    border: "1px $neutrals-light",
                    borderStyle: "none none none dotted",
                    p: "$6",
                    "@mxlg": {
                      width: "100%",
                    },
                  }}
                >
                  <Flex
                    direction="column"
                    gap="4"
                    align={{ "@initial": "center", "@mxlg": "start" }}
                    css={{ mb: "$5" }}
                  >
                    <Text
                      size="4"
                      css={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text variant="darkest">{`${
                        diffBetweenDates(option.checkOut, option.checkIn) + 1
                      } diária${
                        diffBetweenDates(option.checkOut, option.checkIn) + 1 > 1 ? "s," : ","
                      }`}</Text>

                      <Text variant="darkest" css={{ alignSelf: "center" }}>
                        {` ${adultNumber} Adulto${adultNumber > 1 ? "s" : ""}`}
                      </Text>
                    </Text>

                    <Text fw="600">
                      <Text>{asCurrency(option.customerValue)}</Text>
                    </Text>
                  </Flex>

                  {isQuotingStatus &&
                    (optionsFiltered?.length ? (
                      <Flex
                        css={{
                          "@mxlg": {
                            width: "100%",
                            mt: "$5",
                          },
                        }}
                        justify={{ "@mxlg": "between" }}
                        direction={{ "@initial": "column", "@mxlg": "row" }}
                        gap="3"
                      >
                        <Button
                          onClick={() => {
                            onDeleteQuoteOffline?.(option.uuid as string);
                          }}
                          variant="error"
                          css={{
                            "@mxlg": {
                              height: "$8",
                              p: "$2",
                            },
                          }}
                        >
                          <SvgTrash style={{ display: isMobile ? "none" : "block" }} />
                          <Text css={{ ml: "$2" }}>Excluir</Text>
                        </Button>

                        <Button
                          onClick={() => {
                            onOpenQuoteOffline!(item as OrderHotelItem, [optionsFiltered[index]]);
                          }}
                          variant="secondary"
                          css={{
                            "@mxlg": {
                              height: "$8",
                              p: "$2",
                            },
                          }}
                        >
                          <SvgEdit style={{ display: isMobile ? "none" : "block" }} />
                          <Text css={{ ml: "$2" }}>Editar</Text>
                        </Button>

                        <Button
                          onClick={() => {
                            onCopyQuoteOffline!(item as OrderHotelItem, [optionsFiltered[index]]);
                          }}
                          variant="primary"
                          css={{
                            "@mxlg": {
                              height: "$8",
                              p: "$2",
                            },
                          }}
                        >
                          <SvgCopy style={{ display: isMobile ? "none" : "block" }} />
                          <Text css={{ ml: "$2" }}>Replicar</Text>
                        </Button>
                      </Flex>
                    ) : (
                      <Button
                        onClick={() => {
                          onOpenQuoteOffline?.(item as OrderHotelItem, []);
                        }}
                        css={{
                          "@mxlg": {
                            height: "$10",
                            p: "$4",
                          },
                        }}
                      >
                        <SvgExpenses />
                        <Text css={{ ml: "$2" }}>Cotizar Item</Text>
                      </Button>
                    ))}

                  {!isQuotingStatus && (
                    <Button
                      variant="tertiary"
                      onClick={() => {
                        onOpenOptionDetails(item as OrderHotelItem, option);
                      }}
                      css={{
                        "@mxlg": {
                          mt: "$5",
                          height: "$5",
                          fontSize: "$sm",
                          display: "none",
                        },
                      }}
                    >
                      <Icon as={SvgEdit} />
                      Ver / Editar dados
                    </Button>
                  )}
                </Flex>
              </Flex>
            </CardBody>
          </Card>
        ))
      )}
    </Flex>
  );
}
