import { useNavigate, useParams } from "react-router-dom";

import { FlightSegment, OrderAirwaySegment } from "~/application/types";
import { Alert } from "~/components/Alert";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { CompanyLogo } from "~/components/CompanyLogo";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgCheck, SvgRemove } from "~/components/Icon/icons";
import { Text } from "~/components/Text";
import { H5, Label } from "~/components/Typography";
import { useCopyTracker } from "~/presentation/shared/hooks/useCopyTracker";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { getAirlineUrl } from "~/presentation/shared/utils";
import * as DateUtils from "~/utils/date.utils";

export interface OrderAirwayCardProps {
  data: OrderAirwaySegment;
  onOpenDetails?: () => void;
}

export function OrderAirwayCard({ data, onOpenDetails }: OrderAirwayCardProps) {
  const navigate = useNavigate();
  const { orderId } = useParams() as { orderId: string };

  const goFlight = data.segments[0].departureFlightInfo;
  const returnFlight = data.segments?.at(data.segments?.length - 1)?.arrivalFlightInfo;
  const airline = data.segments[0].airline;

  const isMobile = useMobile();
  const canShowTracker = data.tracker;

  const goFlightSeparator = goFlight?.airport.split("-");
  const returnFlightSeparator = returnFlight?.airport.split("-");

  const { renderCopyTracker } = useCopyTracker();

  function hasAirportChangeInConnections(segments: FlightSegment[]): boolean {
    return segments.some(
      (segment, index) =>
        index < segments.length - 1 &&
        segment.arrivalFlightInfo.airportIata !==
          segments[index + 1].departureFlightInfo.airportIata
    );
  }

  function navigateToCheapestFlights() {
    navigate(
      `/pedidos/${orderId}/voos-mais-baratos?${
        data.flightType === "departure" ? "departureId" : "arrivalId"
      }=${data.uuid}`
    );
  }

  const showAirportChangeAlert =
    data.segments.length > 1 && hasAirportChangeInConnections(data.segments);

  return (
    <>
      {showAirportChangeAlert && (
        <Alert variant="warning">
          <Flex direction="column" gap="3">
            <Text variant="warning-dark">Atenção: Troca de aeroporto necessária</Text>
            <Text css={{ lineHeight: "$6" }}>
              Você precisará se deslocar entre diferentes aeroportos durante sua conexão. Planeje
              tempo suficiente para o trajeto.
            </Text>
          </Flex>
        </Alert>
      )}

      <Col>
        <Card>
          <Flex css={{ p: "$4" }} gap="4" align="center">
            <Col>
              <CompanyLogo src={getAirlineUrl(airline)} />
            </Col>

            {data.expiresAt.toString().toLowerCase() !== "invalid date" && (
              <Text size="2" variant="darkest" css={{ fw: "600" }}>
                Expira em{" "}
                {DateUtils.displayDate(data.expiresAt, DateUtils.DateFormats.SMALL_DATE_TIME)}
              </Text>
            )}

            {canShowTracker && (
              <>
                <Text variant="darkest" css={{ fw: "600" }}>
                  <Flex align="center">{data.tracker && data.tracker}</Flex>
                </Text>

                {renderCopyTracker(data.tracker as string)}
              </>
            )}
          </Flex>
        </Card>
      </Col>

      <Col>
        <Card>
          <Flex direction="column" as={CardBody} gap="6">
            <Flex align="start" justify="between" gap="6">
              <CompanyLogo src={getAirlineUrl(airline)} />

              <Flex align="center" gap="2" wrap="wrap">
                {data.isTheCheapestFlight ? (
                  <Button variant="success-light" onClick={navigateToCheapestFlights}>
                    <Icon as={SvgCheck} />
                    <Text>Fez a melhor escolha</Text>
                  </Button>
                ) : (
                  <Button variant="error-light" onClick={navigateToCheapestFlights}>
                    <Icon as={SvgRemove} />
                    <Text>Melhor opção disponível</Text>
                  </Button>
                )}

                <Button
                  variant="tertiary"
                  css={{
                    "@mxlg": {
                      height: "$5",
                      p: "$2",
                      fontSize: "$sm",
                    },
                  }}
                  onClick={onOpenDetails}
                >
                  <Text>Ver detalhes</Text>
                </Button>
              </Flex>
            </Flex>

            <Flex direction={{ "@mxlg": "column" }}>
              <Flex gap="6" justify={{ "@mxlg": "between" }}>
                <Flex align="start" direction="column" gap="4">
                  <Label>Saída</Label>

                  {!isMobile ? (
                    <H5>{goFlight?.airport}</H5>
                  ) : (
                    <H5 size="3">{goFlightSeparator[1]}</H5>
                  )}

                  <Text size="3">{DateUtils.displayDate(goFlight?.dateTime as Date)}</Text>

                  <Label>{DateUtils.toHour12(goFlight?.dateTime as Date)}</Label>
                </Flex>

                {returnFlight && (
                  <Flex
                    align="end"
                    direction="column"
                    gap="4"
                    css={{
                      ml: "$20",
                      "@mxlg": {
                        ta: "end",
                        ml: "0",
                      },
                    }}
                  >
                    <Label>Chegada</Label>

                    {!isMobile ? (
                      <H5>{returnFlight?.airport}</H5>
                    ) : (
                      <H5 size="3">{returnFlightSeparator?.[1]}</H5>
                    )}

                    <Text size="3" css={{ "@mxlg": { ta: "end" } }}>
                      {DateUtils.displayDate(returnFlight?.dateTime)}
                    </Text>

                    <Label css={{ "@mxlg": { ta: "end", width: "$10" } }}>
                      {DateUtils.toHour12(returnFlight.dateTime)}
                    </Label>
                  </Flex>
                )}
              </Flex>
              <Flex
                justify={{ "@mxlg": "between" }}
                css={{
                  ml: "$20",
                  "@mxlg": {
                    mt: "$5",
                    ml: "0",
                  },
                }}
              >
                <Flex align="start" direction="column" gap="4">
                  <Label>Duração</Label>

                  <Label>
                    {DateUtils.timeInterval(goFlight.dateTime, returnFlight?.dateTime as Date)}
                  </Label>
                </Flex>
                <Flex
                  align="start"
                  direction="column"
                  gap="4"
                  css={{
                    ml: "$16",
                  }}
                >
                  <Label>Tarifa</Label>
                  <Label>{data.familyFare}</Label>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Card>
      </Col>
    </>
  );
}

OrderAirwayCard.displayName = "OrderAirwayCard";
