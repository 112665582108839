import { Button } from "~/components/Button";
import { Card } from "~/components/Card";
import { EmptyState } from "~/components/EmptyState";
import { Col, Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgPlus } from "~/components/Icon/icons";
import { LazyList } from "~/components/LazyList";
import { Text } from "~/components/Text";
import { H4 } from "~/components/Typography";
import { Fragment, useCallback } from "react";
import { BindCredentialListItem } from "./components/CredentialListItem";
import { Box } from "~/components/Box";
import { Agreement } from "~/application/types/entities/BindCredential.type";
export interface TabCredentialProps {
  data: Agreement[] | undefined;
  isLoadingAgreement: boolean;
  onToggleAgreement: (params: { credentialId: string; isActive: boolean }) => void;
  onOpenDialog: () => void;
  openEditDialog: (item: Agreement) => void;
}

export function TabCredential({
  data,
  onOpenDialog,
  openEditDialog,
  isLoadingAgreement,
  onToggleAgreement,
}: TabCredentialProps) {
  const listRenderer = useCallback(
    (item: Agreement) => {
      return (
        <BindCredentialListItem
          key={item.uuid}
          data={item}
          onToggleState={onToggleAgreement}
          onEditClick={openEditDialog}
        />
      );
    },
    [onToggleAgreement]
  );

  return (
    <Fragment>
      <Card css={{ p: "$6" }}>
        <Row align="center">
          <Col>
            <H4>Credenciais</H4>
          </Col>

          <Col sz="auto">
            <Button title="Vincular credencial" color="primary" onClick={onOpenDialog}>
              <Icon as={SvgPlus} />
              <Text>Vincular credencial</Text>
            </Button>
          </Col>
        </Row>
      </Card>

      <Box css={{ my: "$5" }}>
        <LazyList
          gap="6"
          items={data}
          isLoading={isLoadingAgreement}
          render={listRenderer}
          skeletonQuantity={8}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              <Text>Você ainda não possui credenciais vinculadas</Text>
            </EmptyState>
          }
        />
      </Box>
    </Fragment>
  );
}
