import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { CostCenter, DeepPartial } from "~/application/types";
import { costCenterService } from "~/application/usecases";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants/queryKeys";
import { queryClient } from "~/services/queryClient";
import { log } from "~/utils/log";
import { CostCenterDialog } from "../views/CostCenter/components/CostCenterDialog";
import { useDebounce } from "use-debounce";
import { QueryTimes } from "~/constants";
import { logError } from "~/presentation/shared/utils/errors";
import { LoadingDialog } from "~/presentation/shared/views/LoadingDialog";
import { ImportCostCenterProps } from "~/application/usecases/ImportCostCenter/ImportCostCenterInterface";
import { importCostService } from "~/application/usecases/ImportCostCenter";
import { FileUpload } from "~/presentation/shared/components/FileUpload";
import { LinkingCostCenterPage } from "~/presentation/CustomerSettings/pages/CostCentersPage/components/LinkingCostCenter/LinkingCostCenterPage";
import { useApprovalModels } from "./useApprovalModels";

export interface UseCostCentersResult {
  data?: CostCenter[];
  isLoading: boolean;
  lastPage: number;
  currentPage: number;
  searchText: string;
  hasUploadedFromSpreadsheet: boolean;
  setSearchText: (text: string) => void;
  onGoToPage: (page: number) => void;
  onCreateCostCenter: () => void;
  onEditCostCenter: (item: CostCenter) => void;
  onToggleCostCenter: (item: CostCenter) => void;
  onCreateCostCentersBySpreadsheet: (customerId: string) => void;
}

export interface UseCostCentersOptions {
  customerId: string;
  enabled: boolean;
}

const LOG_TAG = "Customer/CustomerPage/useCostCenters";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar centros de custos",
  CREATE_SUCCESS_MESSAGE: "Novo centro de custo adicionado",
  CREATE_ERROR_MESSAGE: "Falha ao criar centro de custo",
  UPDATE_SUCCESS_MESSAGE: "Centro de custo atualizado",
  UPDATE_ERROR_MESSAGE: "Falha ao atualizar centro de custo",
  DELETE_ERROR_MESSAGE: "Falha ao excluir centro de custo",
  DELETE_SUCCESS_MESSAGE: "Centro de custo excluído",
  UPLOAD_COST_CENTERS_BY_SPREADSHEET_SUCCESS_MESSAGE: "Planilha carregada com sucesso",
  UPLOAD_COST_CENTERS_BY_SPREADSHEET_ERROR_MESSAGE: "Falha ao carregar planilha",
} as const;

const DEFAULT_FORM_DATA: DeepPartial<CostCenter> = {
  name: "",
  costCenterCode: "",
  credit: 0,
};

export function useCostCenters({
  customerId,
  enabled,
}: UseCostCentersOptions): UseCostCentersResult {
  const [searchText, setSearchText] = useState("");
  const [search] = useDebounce<string>(searchText, 700);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hasUploadedFromSpreadsheet, setHasUploadedFromSpreadsheet] = useState(false);

  const { data, isFetching } = useQuery(
    [QueryKeys.CUSTOMER_COST_CENTERS, customerId, search, currentPage],
    () => {
      return costCenterService.findPaginated({
        customerId,
        name: search,
        page: currentPage,
      });
    },
    {
      staleTime: QueryTimes.NORMAL,
      refetchOnWindowFocus: false,
      enabled: enabled,
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
        });
      },
    }
  );

  const approvalModelsData = useApprovalModels({ customerId, enabled: true });

  const { mutate: mutateCreateCostCenter } = useMutation(
    (item: CostCenter) => costCenterService.create({ ...item, customerId }),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Creating CostCenter(${item.uuid})`);

        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Criando novo centro de custo" />);
      },
      onSuccess: (item) => {
        log.i(LOG_TAG, `Successfully created CostCenter(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_COST_CENTERS]);
        dialogService.popAll();

        if (approvalModelsData.data.length) {
          dialogService.showDialog(
            <LinkingCostCenterPage
              customerId={customerId}
              data={item}
              onCloseClick={() => dialogService.popDialog()}
              approvalModelsData={approvalModelsData}
            />
          );
        }

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CREATE_SUCCESS_MESSAGE, "success");
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE, "error");
      },
    }
  );

  const { mutate: mutateUpdateCostCenter } = useMutation(
    (item: CostCenter) => {
      if (!item.credit) {
        return costCenterService.updateById({ ...item, credit: 0 });
      }
      return costCenterService.updateById(item);
    },
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating CostCenter(${item.uuid})`);

        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Atualizando centro de custo" />);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated CostCenter(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_COST_CENTERS]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE, "success");

        dialogService.popDialog();
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE, "error");
      },
    }
  );

  const { mutate: mutateToggleActiveCostCenter } = useMutation(
    (item: CostCenter) => costCenterService.toggleActive(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Deleting CostCenter(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully deleted CostCenter(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_COST_CENTERS]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE, "success");
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE, "error");
      },
    }
  );

  const handleOpenCreateModal = useCallback(() => {
    dialogService.showDialog(
      <CostCenterDialog isNew onSubmit={mutateCreateCostCenter} defaultData={DEFAULT_FORM_DATA} />
    );
  }, [customerId]);

  const handleOpenEditModal = useCallback((item: CostCenter) => {
    dialogService.showDialog(
      <CostCenterDialog onSubmit={mutateUpdateCostCenter} defaultData={item} />
    );
  }, []);

  const handleChangeSearch = useCallback((text: string) => {
    setSearchText(text);
    setCurrentPage(1);
  }, []);

  const { mutateAsync: onCreateCostCentersBySpreadsheet } = useMutation(
    async (data: ImportCostCenterProps) => await importCostService.import(data),
    {
      onMutate: () => {
        dialogService.popDialog();

        dialogService.showDialog(<LoadingDialog message="Carregando planilha" />);
      },
      onSuccess: (_, item) => {
        setHasUploadedFromSpreadsheet(true);
        queryClient.invalidateQueries([QueryKeys.CUSTOMER_COST_CENTERS]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.UPLOAD_COST_CENTERS_BY_SPREADSHEET_SUCCESS_MESSAGE,
          "success"
        );
      },
      onError: (error: { message?: string }) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.UPLOAD_COST_CENTERS_BY_SPREADSHEET_ERROR_MESSAGE,
        });
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const handleOnCreateCostCentersBySpreadsheet = useCallback(
    (customerId: string) => {
      dialogService.showDialog(
        <FileUpload
          title="Arraste seus arquivos para adicioná-los"
          allowedExtensions={["xls", "xlsx"]}
          onUpload={(file: File) => {
            onCreateCostCentersBySpreadsheet({ customerId, file });
          }}
        />
      );
    },
    [onCreateCostCentersBySpreadsheet]
  );
  return {
    data: data?.data,
    lastPage: data?.meta.last_page || 0,
    isLoading: isFetching,
    currentPage,
    searchText,
    hasUploadedFromSpreadsheet,
    setSearchText: handleChangeSearch,
    onGoToPage: setCurrentPage,
    onCreateCostCenter: handleOpenCreateModal,
    onEditCostCenter: handleOpenEditModal,
    onToggleCostCenter: mutateToggleActiveCostCenter,
    onCreateCostCentersBySpreadsheet: handleOnCreateCostCentersBySpreadsheet,
  };
}
