import { FC } from "react";
import { OrderItems } from "~/application/types";
import { Box } from "~/components/Box";
import { BookingMenuLayout } from "~/core/shared/BookingMenuLayout";
import { FlightQueryPageUIProps } from "./types";
import { AirwayQueryContainer } from "~/presentation/shared/components/AirwayQueryContainer/AirwayQueryContainer";

export const FlightQueryPageUI: FC<FlightQueryPageUIProps> = ({ defaultQuery, onQuery, order }) => {
  return (
    <BookingMenuLayout activeTab={OrderItems.AIRWAY} title="Encontre passagens para sua equipe.">
      <AirwayQueryContainer defaultData={defaultQuery} onSubmit={onQuery} order={order} />
    </BookingMenuLayout>
  );
};
