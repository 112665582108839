import { ComponentProps } from "react";
import { keyframes, styled } from "~/application/theme";

export type SpinnerProps = ComponentProps<typeof Spinner>;

const animSpin = keyframes({
  "0%": { transform: "rotate(0deg)" },
  "100%": { transform: "rotate(360deg)" },
});

export const Spinner = styled("span", {
  // Reset
  boxSizing: "border-box",
  display: "inline-block",

  // Custom
  position: "relative",
  textIndent: "-9999em",
  fontSize: "0.5rem",
  borderStyle: "solid",
  borderColor: "transparent",
  borderLeftColor: "$primary-base",
  transform: "translateZ(0)",
  animation: `${animSpin} 1.1s infinite linear`,
  borderRadius: "$circular",

  variants: {
    size: {
      sm: {
        width: "$8",
        height: "$8",
        borderWidth: "4px",
      },
      md: {
        width: "$11",
        height: "$11",
        borderWidth: "6px",
      },
      lg: {
        width: "$40",
        height: "$40",
        borderWidth: "10px",
      },
    },
  },

  defaultVariants: {
    size: "md",
  },
});

Spinner.displayName = "Spinner";
