export enum SettingParameterTypeValue {
  INTEGER = "integer",
  BOOLEAN = "boolean",
  FLOAT = "float",
  PERCENTAGE = "percentage",
}

export enum SettingParameterType {
  CUSTOMER = "customer",
  AGENCY = "agency",
  BOTH = "both",
}

export enum SettingParameterSlug {
  HOTEL_PRICE_CHANGE = "hotel-price-change-parameter",
  VOUCHER_WITHOUT_VALUE = "voucher-without-value",
  SELECT_APPROVERS = "select-approvers",
  POLICY_BY_REASON_TRIP = "policy-by-reason-trip",
}

export type SettingParameter = {
  uuid: string;
  slug: SettingParameterSlug;
  description: string;
  type: SettingParameterType;
  typeValue: SettingParameterTypeValue;
  value: string;
};
