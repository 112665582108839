import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PolicyParamsSlug, Traveler } from "~/application/types";
import { orderHotelService, orderService, travelerService } from "~/application/usecases";
import { dialogService } from "~/components/DialogStack";
import { QueryKeys } from "~/constants";
import { LoadingDialog } from "~/core/shared/components/LoadingDialog";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { useCustomerEmployeeDialog } from "~/presentation/shared/hooks/useCustomerEmployeeDialog";
import { queryClient } from "~/services/queryClient";
import { log } from "~/utils/log";
import { useBooking } from "../../../../../contexts/BookingContext";
import { useBookingHotel } from "../../../contexts/BookingHotelContext";
import { HotelRoomSelected } from "../../../types";
import { getHotelDataFromUrl } from "../../../utils";

export interface UseSelectGuestsResult {
  travelers?: Traveler[];
  roomsSelected?: HotelRoomSelected[];
  fetchGuests: (name: string) => Promise<Traveler[]>;
  onCreateBooking: () => void;
  onCreateCustomerEmployee: () => void;
  onSelectGuest: (guest: Traveler, roomIndex: number, position: number) => void;
}

export interface UseSelectGuestsOptions {
  enabled: boolean;
  issuerId: string;
  setIsShowCart: Dispatch<SetStateAction<boolean>>;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG_TAG = "Booking/BookingHotel/HotelDetailsPage/useSelectGuests";

export function useSelectGuests({
  issuerId,
  setIsShowCart,
}: UseSelectGuestsOptions): UseSelectGuestsResult {
  const { user, contexts } = useUser();
  const customerId = contexts.customer?.uuid;
  const { order } = useBooking();
  const { hotelQuery, hotelReducer } = useBookingHotel();

  const navigate = useNavigate();

  const emptySelectedRooms = useMemo<HotelRoomSelected[] | undefined>(
    () =>
      hotelReducer.bookingState.roomsSelected?.map((room, roomIndex) => ({
        room,
        guests: Array(
          hotelQuery!.accommodations[roomIndex]?.adultQuantity +
            hotelQuery!.accommodations[roomIndex].guestsChildren.length
        ).fill(null),
      })),
    [hotelQuery, hotelReducer]
  );

  const [roomsSelected, setRoomsSelected] = useState<HotelRoomSelected[]>(emptySelectedRooms ?? []);

  const fetchGuestsByName = useCallback(
    async (name: string) => {
      return (
        order?.travelers ??
        (await travelerService.find(user.customer ?? contexts.customer, {
          name,
        }))
      );
    },
    [order, user, contexts]
  );
  const { handleOpenCreateModal } = useCustomerEmployeeDialog({});

  const handleSelectGuest = useCallback((guest: Traveler, room: number, position: number) => {
    setRoomsSelected((old) => {
      old[room].guests[position] = guest;
      return [...old];
    });
  }, []);

  useEffect(() => {
    let totalGuests = 0;

    hotelQuery?.accommodations.forEach((accommodation) => {
      totalGuests += accommodation.adultQuantity;

      if (accommodation.guestsChildren && accommodation.guestsChildren.length > 0) {
        totalGuests += accommodation.guestsChildren.length;
      }
    });

    const totalGuestsSelected = roomsSelected?.reduce((total, quarto) => {
      return total + quarto.guests.filter((guest) => guest !== null).length;
    }, 0);

    if (totalGuestsSelected === totalGuests) {
      setIsShowCart(true);
    }
  }, [roomsSelected, setIsShowCart]);

  const handleCreateBooking = useCallback(async () => {
    let newOrder = order;

    if (!newOrder) {
      try {
        const travelersSummary = roomsSelected.reduce<Traveler[]>(
          (p, c) => [...p, ...(c.guests as Traveler[])],
          []
        );

        dialogService.showDialog(<LoadingDialog message="Aguarde, estamos criando seu pedido" />);

        newOrder = await orderService.create({
          customerId,
          issuerId,
          travelersIds: travelersSummary.map((t) => t.uuid),
          reasonTripId: hotelQuery?.reasonTrip?.uuid,
        });
      } catch (error) {
        log.e(LOG_TAG, error);
      } finally {
        dialogService.popDialog();
      }
    }

    try {
      dialogService.showDialog(<LoadingDialog message="Aguarde, estamos criando seu pedido" />);

      const hotel = hotelReducer.bookingState.hotelSelected! || getHotelDataFromUrl();

      await orderHotelService.create({
        orderId: newOrder!.uuid,
        hotelQuery: hotelQuery!,
        hotel,
        rooms: roomsSelected.map(({ room, guests }) => ({
          lowerFareViolated: room.violatedPolicies.some(
            (policy) => policy.policyType === PolicyParamsSlug.LOWER_FARE
          ),
          room: room,
          guests: guests as Traveler[],
        })),
      });

      queryClient.invalidateQueries([QueryKeys.ORDERS, newOrder!.uuid]);
      navigate(`/pedidos/${newOrder!.uuid}`);
    } catch (error) {
      log.e(LOG_TAG, error);
    } finally {
      dialogService.popDialog();
    }
  }, [order, hotelQuery, hotelReducer, roomsSelected, issuerId]);

  useEffect(() => {
    if (emptySelectedRooms) {
      setRoomsSelected(emptySelectedRooms);
    }
  }, [emptySelectedRooms]);

  return {
    roomsSelected,
    travelers: order?.travelers,
    fetchGuests: fetchGuestsByName,
    onSelectGuest: handleSelectGuest,
    onCreateCustomerEmployee: handleOpenCreateModal,
    onCreateBooking: handleCreateBooking,
  };
}
