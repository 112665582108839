import { Actions, Profile, UserContexts } from "~/application/types";

export type UseVerifyActionsProps = {
  actions: Actions[];
  profile?: Profile;
  contexts: UserContexts;
};

export const useVerifyActions = ({ actions, contexts, profile }: UseVerifyActionsProps) => {
  return (
    !!profile?.actions?.some((a) => actions.some((action) => a?.slug.includes(action))) ||
    !!contexts?.agency?.uuid
  );
};
