import { useCallback, useEffect } from "react";
import { Actions, Stop } from "~/application/types";
import { stopService } from "~/application/usecases";
import { Button } from "~/components/Button";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgCalendar, SvgGps, SvgSearch, SvgSwap, SvgTrash } from "~/components/Icon/icons";
import { TextInput } from "~/components/Input";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { Card, CardBody, CardFooter } from "~/core/modules/DeprecatedBooking/components/Card";
import { Chip, ChipText, createControlledChipInput } from "~/core/shared/components/ChipInput";
import { DoubleCalendar } from "~/core/shared/components/DoubleCalendar";
import {
  FormControl,
  FormControlContent,
  FormControlLabel,
} from "~/core/shared/components/FormControl";
import {
  Popover,
  PopoverAnchor,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from "~/core/shared/components/Popover";
import { displayDate } from "~/utils/date.utils";
import { ItemsRenderer } from "./components/ItemsRenderer/components";
import { RoadQueryFormProps, TravelRoadQueryForm } from "./types";
import { RoadBudgetActionType } from "~/presentation/Booking/BookingRoad/pages/RoadsPage/hooks/useRoadBudget/type";
import { useVerifyActions } from "../../hooks/useVerifyActions";
import { useUser } from "~/presentation/core/contexts/UserContext";
import useMobile from "../../hooks/useMobile";

const CityInputChip = createControlledChipInput<Stop>();

const ChipRenderer = ({ value }: { value: Stop }) => {
  return (
    <Chip css={{ backgroundColor: "$primary-light" }}>
      <ChipText>{value.city.name || value.stops[0].name}</ChipText>

      <Text>{value.city.state}</Text>
    </Chip>
  );
};

export const RoadQueryForm = ({
  roadBudget,
  index,
  formState,
  isManyStretch,
  onRemoveField,
}: RoadQueryFormProps) => {
  const {
    setValue,
    watch,
    register,
    formState: { errors },
  } = formState;

  const data = watch();
  const { contexts, profile } = useUser();

  const currentStretch = data.stretches?.at(index) as TravelRoadQueryForm;
  const currentErrosStretch = errors?.stretches?.at?.(index);
  const canCreateOrder = useVerifyActions({ actions: [Actions.CreateOrder], profile, contexts });
  const isMobile = useMobile();

  const handleSwapCities = useCallback(() => {
    const { stretches } = data;
    const { originSelected, destinationSelected } = stretches?.at(index) as TravelRoadQueryForm;

    setValue(`stretches.${index}.destinationSelected`, originSelected as Stop);
    setValue(`stretches.${index}.originSelected`, destinationSelected);
  }, [data, watch, setValue, index]);

  // Calendar form
  const excludeDates = useCallback(() => {
    setValue(`stretches.${index}.dateGoSelected`, null as any);
    setValue(`stretches.${index}.dateReturnSelected`, null);
  }, [index]);

  useEffect(() => {
    const nextOrigin = data.stretches?.at(index)?.destinationSelected;
    const validNewOrigin =
      !data.stretches?.at(index + 1)?.originSelected && !!data.stretches?.at(index + 1);

    if ((data.stretches?.length || 0) >= index + 2 && nextOrigin && validNewOrigin) {
      setValue(`stretches.${index + 1}.originSelected`, nextOrigin);
    }
  }, [data.stretches?.at(index)?.destinationSelected, index]);

  const handleDateClick = useCallback(
    (date: Date) => {
      const { stretches } = data;
      const { dateGoSelected, dateReturnSelected, originSelected, destinationSelected } =
        stretches?.at(index) as TravelRoadQueryForm;

      if (isManyStretch) {
        setValue(`stretches.${index}.dateGoSelected`, date);
        return;
      }
      setValue(`stretches.${index}.destinationSelected`, destinationSelected);
      setValue(`stretches.${index}.originSelected`, originSelected);

      if (!dateGoSelected || (dateGoSelected && dateReturnSelected)) {
        setValue(`stretches.${index}.dateReturnSelected`, null);
        setValue(`stretches.${index}.dateGoSelected`, date);
        return;
      }

      const [newStart, newEnd] = [dateGoSelected, dateReturnSelected!, date].sort((a, b) => {
        if (a === null) {
          return 1;
        }

        if (b === null) {
          return -1;
        }

        if (a === b) {
          return 0;
        }

        return a < b ? -1 : 1;
      });

      setValue(`stretches.${index}.dateReturnSelected`, newStart);
      setValue(`stretches.${index}.dateReturnSelected`, newEnd);
    },
    [data, setValue, index]
  );

  const lastMinDateSelectIndex =
    data.stretches?.findLastIndex(
      (stretch, indexStretch) => stretch.dateGoSelected && index > indexStretch
    ) || 0;
  const lastMinDateSelect = data.stretches?.at(lastMinDateSelectIndex)?.dateGoSelected;

  const firstMaxDateSelect = data.stretches?.find(
    (stretch, indexStretch) => stretch.dateGoSelected && index < indexStretch
  )?.dateGoSelected as Date;

  const queryFn = useCallback((name: string) => stopService.find({ name }), []);
  return (
    <Flex align="center" direction={{ "@mxlg": "column" }} gap={{ "@initial": "2", "@mxlg": "6" }}>
      {isMobile && isManyStretch && (
        <Text css={{ pt: "$3", ta: "start", width: "100%", color: "#fff" }}>
          Trecho {index + 1}
        </Text>
      )}

      <FormControl aria-required="true">
        <FormControlLabel css={{ "@mxlg": { color: "White" } }}>Origem</FormControlLabel>

        <Tooltip
          open={!!currentErrosStretch?.originSelected}
          variant="error"
          content={<TooltipLabel>Obrigatório</TooltipLabel>}
          side="bottom"
        >
          <CityInputChip
            {...register(`stretches.${index}.originSelected`, {
              required: true,
            })}
            placeholder="Encontrar origem"
            value={currentStretch?.originSelected}
            leftIcon={SvgGps}
            typingDelayMs={500}
            size="md"
            minChars={3}
            queryFunc={queryFn}
            chipRenderer={(value: Stop) => <ChipRenderer value={value} />}
            emptyText="Nenhuma cidade encontrada"
            loadingText="Carregando cidades..."
            itemsRenderer={({ items, setActiveItem }) => (
              <ItemsRenderer items={items} setActiveItem={setActiveItem} />
            )}
          />
        </Tooltip>
      </FormControl>

      <Button
        size="sm"
        tabIndex={-1}
        css={{ "@mxlg": { display: "none" } }}
        type="button"
        variant="secondary"
        onClick={handleSwapCities}
      >
        <Icon as={SvgSwap} />
      </Button>

      <FormControl aria-required="true">
        <FormControlLabel
          css={{
            "@mxlg": {
              color: "White",
            },
          }}
        >
          Destino
        </FormControlLabel>

        <Tooltip
          open={!!currentErrosStretch?.destinationSelected}
          variant="error"
          content={<TooltipLabel>Obrigatório</TooltipLabel>}
          side="bottom"
        >
          <CityInputChip
            {...register(`stretches.${index}.destinationSelected`, {
              required: true,
            })}
            placeholder="Encontrar destinos"
            value={currentStretch.destinationSelected}
            leftIcon={SvgGps}
            typingDelayMs={500}
            size="md"
            minChars={3}
            queryFunc={queryFn}
            chipRenderer={(value) => <ChipRenderer value={value} />}
            emptyText="Nenhuma cidade encontrada"
            loadingText="Carregando cidades..."
            itemsRenderer={({ items, setActiveItem }) => (
              <ItemsRenderer items={items} setActiveItem={setActiveItem} />
            )}
          />
        </Tooltip>
      </FormControl>

      <Popover>
        <FormControl aria-required="true">
          <FormControlLabel
            css={{
              "@mxlg": {
                color: "White",
              },
            }}
          >
            Data da ida
          </FormControlLabel>

          <PopoverTrigger asChild>
            <FormControlContent>
              <Tooltip
                open={!!currentErrosStretch?.dateGoSelected}
                variant="error"
                content={<TooltipLabel>Obrigatório</TooltipLabel>}
                side="bottom"
              >
                <TextInput
                  {...register(`stretches.${index}.dateGoSelected`, {
                    required: true,
                  })}
                  size="md"
                  onBlur={(e) => e.preventDefault()}
                  placeholder="Data de ida"
                  style={{ width: "100%" }}
                  leftIcon={SvgCalendar}
                  value={
                    currentStretch.dateGoSelected ? displayDate(currentStretch.dateGoSelected) : ""
                  }
                  readOnly
                />
              </Tooltip>
            </FormControlContent>
          </PopoverTrigger>

          {/* Anchor */}
          <PopoverAnchor />
        </FormControl>

        {!isManyStretch && (
          <FormControl>
            <FormControlLabel
              css={{
                "@mxlg": {
                  color: "White",
                },
              }}
            >
              Data da volta
            </FormControlLabel>

            <PopoverTrigger asChild>
              <FormControlContent>
                <TextInput
                  {...register(`stretches.${index}.dateReturnSelected`)}
                  data-size="sm"
                  onBlur={(e) => e.preventDefault()}
                  size="md"
                  style={{ width: "100%" }}
                  placeholder="Data da volta"
                  leftIcon={SvgCalendar}
                  value={
                    currentStretch.dateReturnSelected
                      ? displayDate(currentStretch.dateReturnSelected)
                      : ""
                  }
                  readOnly
                />
              </FormControlContent>
            </PopoverTrigger>
          </FormControl>
        )}

        <PopoverContent>
          <Card
            elevated
            css={{
              "@mxlg": {
                top: -150,
                position: "absolute",
                width: "70vw",
              },
            }}
          >
            <DoubleCalendar
              date={currentStretch.dateGoSelected || new Date()}
              activeMinDate={currentStretch.dateGoSelected as Date}
              activeMaxDate={currentStretch.dateReturnSelected}
              minDate={lastMinDateSelect && index ? lastMinDateSelect : new Date()}
              maxDate={firstMaxDateSelect}
              onDateClick={handleDateClick}
            />

            <CardFooter>
              <CardBody
                css={{
                  "@mxlg": {
                    p: "$2",
                  },
                }}
              >
                <Flex
                  gap="4"
                  css={{
                    justifyContent: "flex-end",
                    "@mxlg": {
                      justifyContent: "space-around",
                    },
                  }}
                >
                  <PopoverClose asChild>
                    <Button
                      css={{
                        "@mxlg": {
                          fontSize: "$sm",
                          height: "$5",
                        },
                      }}
                      variant="tertiary"
                      onClick={excludeDates}
                    >
                      Excluir
                    </Button>
                  </PopoverClose>

                  <PopoverClose asChild>
                    <Button
                      css={{
                        "@mxlg": {
                          fontSize: "$sm",
                          height: "$5",
                        },
                      }}
                      variant="secondary"
                    >
                      Aplicar
                    </Button>
                  </PopoverClose>
                </Flex>
              </CardBody>
            </CardFooter>
          </Card>
        </PopoverContent>
      </Popover>

      {isManyStretch && (
        <Button
          variant="secondary"
          size="mdl"
          onClick={onRemoveField}
          css={{
            alignSelf: "flex-end",
            visibility: index < 1 ? "hidden" : "",
            display: index < 1 && isMobile ? "none" : "flex",
          }}
        >
          <Icon as={SvgTrash} />
        </Button>
      )}

      {!isManyStretch && canCreateOrder && (
        <Button
          size="sm"
          type="submit"
          css={{
            size: "$16",
            alignSelf: "flex-end",
            "@mxlg": {
              margin: "$4 auto",
              width: "$50",
              height: "$11",
              backgroundColor: "#F0F2F5",
              color: "$primary-base",
            },
          }}
          onClick={() => {
            roadBudget?.dispatch?.({ type: RoadBudgetActionType.CLEAR });
          }}
        >
          <Icon as={SvgSearch} variant={{ "@mxlg": "primary" }} />
          <Text
            css={{
              "@lg": {
                display: "none",
              },
            }}
          >
            Buscar
          </Text>
        </Button>
      )}
    </Flex>
  );
};

RoadQueryForm.displayName = "RoadQueryForm";
