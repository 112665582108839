import { Fragment, useCallback, useState } from "react";
import { PendingOrder } from "~/application/types";
import { AppBar, AppBarContainer, AppBarHeading } from "~/components/AppBar";
import { Container } from "~/components/Container";
import { EmptyState } from "~/components/EmptyState";
import { Flex } from "~/components/Flex";
import { LazyList } from "~/components/LazyList";
import { Link } from "~/components/Link";
import { Skeleton } from "~/components/Skeleton";
import { CounterTab, TabBar, Tabs } from "~/components/Tabs";
import { Text } from "~/components/Text";
import { PendingOrderListItem } from "./components/PendingOrderListItem";
import { PENDING_ORDERS_MENU_TABS, PendingOrdersTab } from "./utils";
import { PendingOrderFilter } from "./components/PendingOrderFilter";
import { Card } from "~/components/Card";
import { Icon } from "~/components/Icon";
import { SvgClose, SvgFilter } from "~/components/Icon/icons";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { H5 } from "~/components/Typography";

type ISelectOption = {
  value: string;
  label: string;
};

export interface IPendingOrdersFilters {
  customers: ISelectOption[];
  status: ISelectOption[];
  travelers: ISelectOption[];
  consultants: ISelectOption[];
  issuers: ISelectOption[];
  orderItem: ISelectOption | null;
  orderNumber: string;
}

export interface PendingOrdersContainerProps {
  isLoading: boolean;
  pendingOrdersList?: PendingOrder[];
  filters?: IPendingOrdersFilters;
  activeTab: PendingOrdersTab;
  selectedFilters: IPendingOrdersFilters;
  onFilterCustomer: (data: ISelectOption[]) => void;
  onFilterTravelers: (data: ISelectOption[]) => void;
  onFilterOrderItem: (item: ISelectOption) => void;
  onFilterConsultant: (data: ISelectOption[]) => void;
  onFilterStatus: (data: ISelectOption[]) => void;
  onCleanFilters: () => void;
  onOrderNumber: (search: string) => void;
  onIssuer: (data: ISelectOption[]) => void;
}

export function PendingOrdersContainer({
  isLoading,
  pendingOrdersList,
  activeTab,
  filters,
  selectedFilters,
  onFilterOrderItem,
  onCleanFilters,
  onFilterConsultant,
  onFilterCustomer,
  onFilterStatus,
  onFilterTravelers,
  onOrderNumber,
  onIssuer,
}: PendingOrdersContainerProps) {
  const isMobile = useMobile();

  const listRenderer = useCallback(
    (item: PendingOrder) => (
      <PendingOrderListItem data={item} key={item.uuid} />
    ),
    []
  );
 
  const [sideBarIsOpen, setSideBarIsOpen] = useState(false);

  return (
    <Fragment>
      <Tabs value={activeTab}>
        <AppBar>
          <AppBarContainer>
            <AppBarHeading title="Emissões" />
          </AppBarContainer>

          <Container>
            <TabBar>
              {isLoading ? (
                <Flex gap="8" css={{ p: "$4" }}>
                  <Skeleton variant="text-4" style={{ width: 96 }} />
                  <Skeleton variant="text-4" style={{ width: 96 }} />
                </Flex>
              ) : (
                PENDING_ORDERS_MENU_TABS.map(({ title, id }) => (
                  <Link to={`#${id}`} title={title} key={id}>
                    <CounterTab id={id} value={id}>
                      <Text>{title}</Text>
                    </CounterTab>
                  </Link>
                ))
              )}
            </TabBar>
          </Container>

          <Flex justify="end" gap="4" css={{ p: "$3" }}>
            {!isMobile ? (
              <PendingOrderFilter
                selectedFilters={selectedFilters}
                onFilterOrderItem={onFilterOrderItem}
                onFilterTravelers={onFilterTravelers}
                onFilterStatus={onFilterStatus}
                onFilterCustomer={onFilterCustomer}
                isLoading={isLoading}
                filters={filters}
                onFilterConsultant={onFilterConsultant}
                onCleanFilters={onCleanFilters}
                onOrderNumber={onOrderNumber}
                onIssuer={onIssuer}
              />
            ) : (
              <Card
                css={{
                  width: "$10",
                  height: "$10",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => setSideBarIsOpen((old) => !old)}
              >
                <Icon as={SvgFilter} />
              </Card>
            )}
          </Flex>
        </AppBar>

        <Flex
          direction="column"
          align="center"
          css={{
            background: "#FFF",
            height: "100vh",
            top: "0",
            display: sideBarIsOpen ? "block" : "none",
            width: "80%",
            borderRadius: "$md",
            zIndex: "999",
            transition: "$slow",
            position: "fixed",
            left: "0",
          }}
        >
          <Flex
            justify="between"
            align="center"
            css={{ height: "0", mt: "$10", width: "80%", margin: "$10 auto" }}
          >
            <H5>Empresas</H5>
            <Icon as={SvgClose} onClick={() => setSideBarIsOpen(false)} />
          </Flex>

          <Flex
            direction="column"
            justify="center"
            css={{
              overflow: "scroll",
              height: "auto",
              mt: "$10",
              mx: "$4",
              boxShadow: "$sm",
            }}
          >
            <PendingOrderFilter
              selectedFilters={selectedFilters}
              onFilterTravelers={onFilterTravelers}
              onFilterStatus={onFilterStatus}
              onFilterCustomer={onFilterCustomer}
              isLoading={isLoading}
              onFilterOrderItem={onFilterOrderItem}
              filters={filters}
              onFilterConsultant={onFilterConsultant}
              onCleanFilters={onCleanFilters}
              onOrderNumber={onOrderNumber}
              onIssuer={onIssuer}
            />
          </Flex>
        </Flex>

        <Container
          css={{
            pt: "$10",
            pb: "$20",
            "@mxlg": {
              p: "$5",
            },
          }}
        >
          <LazyList
            gap="6"
            items={pendingOrdersList}
            render={listRenderer}
            skeletonHeight={108}
            EmptyComponent={
              <EmptyState>
                <Text>Não existem pedidos pendentes</Text>
              </EmptyState>
            }
          />
        </Container>
      </Tabs>
    </Fragment>
  );
}
