import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { ApiError } from "./ApiError.class";
import { ApiResponse } from "./ApiResponse.class";
import { AUTH_TOKEN } from "~/application/constants/storageKeys";

type RequestHeaders = Record<string, string | number | boolean>;

type HeadersDefaults = {
  common: RequestHeaders;
  delete: RequestHeaders;
  get: RequestHeaders;
  head: RequestHeaders;
  post: RequestHeaders;
  put: RequestHeaders;
  patch: RequestHeaders;
  options?: RequestHeaders;
  purge?: RequestHeaders;
  link?: RequestHeaders;
  unlink?: RequestHeaders;
};

type ApiClientParams = {
  baseUrl: string;
  timeoutMs: number;
};

export class ApiClient {
  baseUrl: string;
  timeoutMs: number;
  headers: HeadersDefaults = {
    common: { Accept: "application/json, text/plain, */*" },
    delete: {},
    get: {},
    head: {},
    patch: { "Content-Type": "application/json" },
    post: { "Content-Type": "application/json" },
    put: { "Content-Type": "application/json" },
  };

  instance: AxiosInstance;

  constructor({ baseUrl, timeoutMs }: ApiClientParams) {
    this.baseUrl = baseUrl;
    this.timeoutMs = timeoutMs;

    this.instance = axios.create({
      timeout: this.timeoutMs,
    });
  }

  get = async <T = any, D = any>(
    url: string,
    data?: D,
    headers?: object
  ): Promise<ApiResponse<T>> => {
    return await this.request({
      method: "GET",
      url: url,
      data: data,
      headers,
    });
  };

  post = async <T = any, D = any>(
    url: string,
    data?: D,
    headers?: object
  ): Promise<ApiResponse<T>> => {
    return await this.request({
      method: "POST",
      url: url,
      data: data,
      headers,
    });
  };

  put = async <T = any, D = any>(
    url: string,
    data?: D,
    headers?: object
  ): Promise<ApiResponse<T>> => {
    return await this.request({
      method: "PUT",
      url: url,
      data: data,
      headers,
    });
  };

  patch = async <T = any, D = any>(
    url: string,
    data?: D,
    headers?: object
  ): Promise<ApiResponse<T>> => {
    return await this.request({
      method: "PATCH",
      url: url,
      data: data,
      headers,
    });
  };

  delete = async <T = any>(
    url: string,
    headers?: object
  ): Promise<ApiResponse<T>> => {
    return await this.request({
      method: "DELETE",
      url,
      headers,
    });
  };

  head = async <T = any>(
    url: string,
    headers?: object
  ): Promise<ApiResponse<T>> => {
    return await this.request({
      method: "HEAD",
      url,
      headers,
    });
  };

  options = async <T = any>(
    url: string,
    headers?: object
  ): Promise<ApiResponse<T>> => {
    return await this.request({
      method: "OPTIONS",
      url: url,
      headers,
    });
  };

  private request = async <T = any, D = any>(config: {
    method: string;
    url: string;
    data?: D;
    headers?: object;
  }) => {
    const token =
      sessionStorage.getItem(AUTH_TOKEN) || localStorage.getItem(AUTH_TOKEN);

    return this.instance
      .request<T, AxiosResponse<T>, D>({
        method: config.method,
        baseURL: this.baseUrl,
        data: config.data,
        url: config.url,
        headers: Object.assign({}, this.headers.common, config.headers, {
          Authorization: `Bearer ${token}`,
        }),
      })
      .then(
        ({ data, status: statusCode }) => new ApiResponse({ data, statusCode })
      )
      .catch((error: AxiosError<ApiError>) => {
        const { response } = error;

        if (!response) {
          throw error;
        }

        throw new ApiError({
          data: response.data,
          statusCode: response.status,
          code: response.data?.code,
          message: response.data?.message,
        });
      });
  };
}
