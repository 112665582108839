// The ControlledChipInput is a variant of ChipInput that contains a default search logic

import {
  Fragment,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useOnClickOutside } from "usehooks-ts";
import { Box } from "~/components/Box";
import { Card, CardBody } from "~/components/Card";
import { Container } from "~/components/Container";
import { Text } from "~/components/Text";
import {
  Popover,
  PopoverAnchor,
  PopoverContent,
  PopoverTrigger,
} from "~/core/shared/components/Popover";
import { ChipInput, ChipInputProps } from "./ChipInput";
import useMobile from "~/presentation/shared/hooks/useMobile";

export type ControlledChipInputProps<TItem = any> = Omit<
  ChipInputProps,
  "defaultValue" | "value"
> & {
  value?: TItem;
  defaultValue?: TItem;
  onChange?: (event: { target: any; type?: any }) => Promise<void | boolean>;
  queryFunc: (text: string) => Promise<TItem[]>;
  itemsRenderer: (data: {
    items: TItem[];
    setActiveItem: (item: TItem) => void;
  }) => React.ReactNode;
  typingDelayMs?: number;
  minChars?: number;
  loadingText?: string;
  emptyText?: string;
  chipRenderer?: (item: TItem) => JSX.Element;
};

/**
 * @deprecated this component will be removed soon. Don't used it unless necessary.
 */
export const ControlledChipInput = forwardRef(
  <TItem,>(
    {
      value: valueProp,
      typingDelayMs = 600,
      minChars = 0,
      loadingText,
      emptyText,
      onChange,
      chipRenderer,
      queryFunc,
      itemsRenderer,
      name,
      defaultValue,
      onBlur,
      ...props
    }: ControlledChipInputProps<TItem>,
    forwardedRef: any
  ) => {
    const ref = useRef(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const [activeItem, setActiveItem] = useState<TItem | null>(
      valueProp ?? defaultValue ?? null
    );
    const isMobile = useMobile();

    const [open, setOpen] = useState(false);
    const [items, setItems] = useState<TItem[]>();
    const [isFetching, setFetching] = useState<boolean>(false);
    const [text, setText] = useState<string>("");

    const handleActiveItem = useCallback((item: TItem | null) => {
      setActiveItem(item);
      setOpen(false);
    }, []);

    const handleChange = useCallback((value?: TItem | null) => {
      onChange?.({
        type: "change",
        target: {
          name: name,
          value: value,
        },
      });
    }, []);

    const handleSearchItems = useCallback(() => {
      queryFunc(text)
        .then((items) => setItems(items))
        .finally(() => setFetching(false));
    }, [queryFunc, text, setItems, setFetching]);

    useEffect(() => {
      if (text.length >= minChars) {
        setFetching(true);
        const timeOutId = setTimeout(handleSearchItems, typingDelayMs);
        return () => clearTimeout(timeOutId);
      }
    }, [text, handleSearchItems]);

    const clearItem = useCallback(() => {
      setActiveItem(null);
      setText("");
      if (inputRef.current)
        inputRef.current.placeholder = props.placeholder ?? "";
      handleChange(null);
    }, [handleChange, setText, setActiveItem]);

    const handleClickOutside = () => {
      setOpen(false);
    };

    useOnClickOutside(ref, handleClickOutside);

    const value = valueProp ?? activeItem;

    useEffect(() => {
      if (value) {
        setText("");
        if (inputRef.current) inputRef.current.placeholder = "";
      }
    }, [value]);

    useEffect(() => {
      handleChange(value);
    }, [value]);

    return (
      <Popover open={open}>
        <PopoverTrigger asChild>
          <Box>
            <ChipInput
              ref={inputRef}
              {...props}
              onChange={(e) => {
                setOpen(true);
                setText(e.target.value);
              }}
              onClick={() => setOpen((old) => !old)}
              name={name}
              value={text}
              chipValue={value}
              onClearChip={clearItem}
              chipRender={chipRenderer}
              autoComplete="off"
            />
          </Box>
        </PopoverTrigger>

        <PopoverAnchor />

        <PopoverContent
          onOpenAutoFocus={(e) => e.preventDefault()}
          align={isMobile ? "center" : "start"}
          sideOffset={8}
        >
          <Container size="5" css={{ "@mxmd": { width: "90%" } }}>
            <Card css={{ boxShadow: "$lg" }} ref={ref}>
              {isFetching ? (
                <CardBody css={{ "@mxmd": { padding: "$4 $3", width: "$50" } }}>
                  <Text size="3" variant="dark">
                    {loadingText}
                  </Text>
                </CardBody>
              ) : text.length < minChars ? (
                <CardBody css={{ "@mxmd": { padding: "$6" } }}>
                  <Text size="3" variant="dark">
                    Digite ao menos {minChars} letras e aguarde os resultados
                  </Text>
                </CardBody>
              ) : !items || items.length === 0 ? (
                <CardBody>
                  <Text size="3" variant="dark">
                    {emptyText}
                  </Text>
                </CardBody>
              ) : (
                <Fragment>
                  {itemsRenderer({ items, setActiveItem: handleActiveItem })}
                </Fragment>
              )}
            </Card>
          </Container>
        </PopoverContent>
      </Popover>
    );
  }
);

ControlledChipInput.displayName = "ControlledChipInput";

/**
 * @deprecated this component will be removed soon. Don't used it unless necessary.
 */
export const createControlledChipInput = <Titem,>() =>
  ControlledChipInput as (
    props: ControlledChipInputProps<Titem>
  ) => JSX.Element;
