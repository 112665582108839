import { ExpensePolicyTypeValue } from "~/application/types";

export const EXPENSE_POLICY_RULE_TYPES = [
  {
    value: ExpensePolicyTypeValue.UNTOUCHABLE,
    name: "Inalterável",
    ruleType: "price",
    description:
      "Não admite variações de valor para cima ou para baixo da política",
  },
  {
    value: ExpensePolicyTypeValue.TOUCHABLE_LESS,
    name: "Alterável para menos",
    ruleType: "price",
    description: "Admite valores iguais ou menores do que a política",
  },
  {
    value: ExpensePolicyTypeValue.TOUCHABLE_MORE_LESS,
    name: "Alterável para mais e menos",
    ruleType: "price",
    description: "Admite valores maiores ou menores do que a regra",
  },
  {
    value: ExpensePolicyTypeValue.AMOUNT_MAXIMUM,
    name: "Quantidade máxima",
    ruleType: "amount",
    description: "Admite valores até, no máximo, o estabelecido na regra",
  },
];