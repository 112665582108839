import ReactSelect, { StylesConfig } from "react-select";
import { Spinner } from "../../../Spinner";
import { SelectControl } from "../SelectWrapBase/SelectControl";
import { SelectDropdownIndicator } from "../SelectWrapBase/SelectDropdownIndicator";
import { SelectOption } from "../SelectWrapBase/SelectOption";
import { SelectValueContainer } from "../SelectWrapBase/SelectValueContainer";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { useCallback } from "react";

export interface SelectBaseProps<TOption = unknown> {
  value?: any;
  defaultValue?: any;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  isFieldError?: boolean;
  size?: "xs" | "sm" | "md";
  options?: TOption[];
  isLoading?: boolean;
  isDirty?: boolean;
  isMulti?: boolean;
  components?: any;
  portalled?: boolean;
  isClearable?: boolean;
  closeMenuOnSelect?: boolean;
  hideSelectedOptions?: boolean;
  onChange?: (value: any) => void;
  onInputChange?: (value: any) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  loadingMessage?: (obj: { inputValue: string }) => React.ReactNode;
  getOptionLabel?: (option: TOption) => string;
  getOptionValue?: (option: TOption) => string;
}

const SelectLoadingIndicator = () => <Spinner size="sm" />;

export const DEFAULT_COMPONENTS = {
  IndicatorSeparator: null,
  LoadingIndicator: SelectLoadingIndicator,
  DropdownIndicator: SelectDropdownIndicator,
  Control: SelectControl,
  Option: SelectOption,
  ValueContainer: SelectValueContainer,
};

export const SelectBaseWrap = <TOption,>({
  disabled,
  isDirty,
  portalled = false,
  components = DEFAULT_COMPONENTS,
  ...props
}: SelectBaseProps<TOption>) => {
  const isMobile = useMobile();

  const stylesConfig: StylesConfig<any> = {
    menuPortal: (base) => ({
      ...base,
      zIndex: 99999,
    }),
    menuList: () => ({
      maxHeight: "220px",
      overflow: "auto",
    }),
    dropdownIndicator: () => ({}),
    control: (base, state) => ({
      ...base,
      border: "0",
      boxShadow: "none",
      overflowY: "scroll",
      display: "flex",
      justifyContent: "start",
      gap: "5px",
      scrollbarWidth: "none",
      msOverflowStyle: "none",
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    }),
    multiValue: (base, state) => ({ 
      border: state.isFocused ? "2px solid #0064c5" : "",
      padding: "0px",
      borderRadius: state.isFocused ? "6px" : "" 
    }),
    multiValueRemove: (base, state) => ({
      background: state.isFocused ? "" : ""
    })
  };

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
    const selectedItems = document.querySelectorAll(".css-1h4c4le");
  
    const activeElement = document.activeElement;
  
    if (selectedItems.length > 0 && activeElement) {
      let currentIndex = Array.from(selectedItems).indexOf(activeElement as HTMLElement);
      if (currentIndex === -1) {
        currentIndex = selectedItems.length - 1;
        (selectedItems[currentIndex] as HTMLElement).focus();
        (selectedItems[currentIndex] as HTMLElement).scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
        return;
      }

      if (event.key === "ArrowLeft" && currentIndex > 0) {
        currentIndex -= 1;
        (selectedItems[currentIndex] as HTMLElement).focus();
        (selectedItems[currentIndex] as HTMLElement).scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
        return;
      }
  
      if (event.key === "ArrowRight" && currentIndex < selectedItems.length - 1) {
        currentIndex += 1;
        (selectedItems[currentIndex] as HTMLElement).focus();
        (selectedItems[currentIndex] as HTMLElement).scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
        return;
      }
    }
  }, []);
  
  return (
    <ReactSelect
      {...props}
      isDisabled={disabled}
      data-dirty={isDirty}
      styles={stylesConfig}
      onKeyDown={handleKeyDown}
      components={components}
      menuPosition="absolute"
      menuPortalTarget={portalled ? document.body : undefined}
      closeMenuOnSelect={false}
      menuShouldBlockScroll
    />
  );
};

SelectBaseWrap.displayName = "SelectBaseWrap";