import { useQuery } from "@tanstack/react-query";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { QueryTimes } from "~/application/constants";
import { CustomerEmployee, Traveler } from "~/application/types";
import { orderService, travelerService } from "~/application/usecases";
import { employeeToTraveler } from "~/application/usecases/Traveler/TravelerService";
import { rangedArray } from "~/application/utils/array-functions";
import { QueryKeys } from "~/constants";
import { SNACKBAR_MESSAGES } from "~/core/modules/Agency/pages/AgenciesOrdersPage/types";
import { useLogTag } from "~/presentation/core/contexts/LogTagContext";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { useCreateCustomerEmployee } from "~/presentation/shared/hooks/useCreateCustomerEmployee";
import { useCustomerEmployeeDialog } from "~/presentation/shared/hooks/useCustomerEmployeeDialog";
import { logError } from "~/presentation/shared/utils/errors";
import { useFlightsPage } from "../../../contexts/FlightsPageContext";
import { TravelersSectionUI } from "./TravelersSectionUI";
import { AirwayTravelerPlaceholder, TravelersSectionProps } from "./types";
import { useRemakeSearch } from "~/core/shared/contexts/remakeSearchContext";

export const TravelersSection: FC<TravelersSectionProps> = ({ setIsShowingCart }) => {
  const { LOG_TAG } = useLogTag();

  const { contexts } = useUser();
  const [searchParams] = useSearchParams();

  const orderId = searchParams.get("orderId");

  const { airwayReducer, flightQuery } = useFlightsPage();
  const { travelers: travelersRemakeSearch } = useRemakeSearch();

  const { bookingState } = airwayReducer;

  const travelersPlaceholder = useMemo<AirwayTravelerPlaceholder[]>(() => {
    if (!flightQuery.data) return [];

    const { adultQuantity, childrenQuantity } = flightQuery.data;

    return [
      ...rangedArray(adultQuantity).map<AirwayTravelerPlaceholder>((i) => ({
        index: i,
        type: "ADT",
      })),
      ...rangedArray(childrenQuantity).map<AirwayTravelerPlaceholder>((i) => ({
        index: i,
        type: "CHD",
      })),
    ];
  }, [flightQuery]);

  const { data: order } = useQuery(
    [QueryKeys.ORDERS, orderId],
    () => orderService.findById(orderId!),
    {
      staleTime: QueryTimes.LONGEST,
      refetchOnWindowFocus: false,
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
        });
      },
      enabled: !!orderId,
    }
  );

  const fetchTravelersByName = useCallback(
    async (name: string) => {
      return order?.travelers ?? travelerService.find(contexts.customer, { name });
    },
    [order, contexts]
  );

  const onSelectTraveler = useCallback(
    (item: Traveler, optionIndex: number) => {
      bookingState.travelers[optionIndex] = {
        ...travelersPlaceholder[optionIndex],
        traveler: item,
      };

      if (bookingState.travelers.length === bookingState.adultQuantity) {
        setIsShowingCart(false);
      }

      airwayReducer.dispatch({
        type: "SET_TRAVELERS",
        payload: [...bookingState.travelers],
      });
    },
    [bookingState, setIsShowingCart]
  );

  useEffect(() => {
    if (travelersRemakeSearch) {
      airwayReducer.dispatch({
        type: "SET_TRAVELERS",
        payload: travelersRemakeSearch.map((traveler) => ({ traveler })) as any,
      });
    } else {
      airwayReducer.dispatch({
        type: "SET_TRAVELERS",
        payload: [],
      });
    }
  }, [travelersRemakeSearch]);

  useEffect(() => {
    if (!bookingState.travelers.length && order) {
      if (order.travelers.length === 1) {
        airwayReducer.dispatch({
          type: "SET_TRAVELERS",
          payload: order?.travelers.map((traveler) => ({ traveler })) as any,
        });
      }
    }
  }, [order, bookingState]);

  const { create: createCustomerEmployee } = useCreateCustomerEmployee({
    customerId: contexts.customer.uuid,
  });

  const onSubmitCustomerEmployee = useCallback(
    async (data: CustomerEmployee) => {
      try {
        const customerEmployee = await createCustomerEmployee(data);

        for (let i = 0; i < travelersPlaceholder.length; i++) {
          if (!bookingState.travelers[i]) {
            const traveler = employeeToTraveler(contexts.customer, customerEmployee);

            onSelectTraveler(traveler, i);
            break;
          }
        }
      } catch (error) {
        throw new Error();
      }
    },
    [contexts, travelersPlaceholder, onSelectTraveler, createCustomerEmployee]
  );

  const { handleOpenCreateModal: onCreateCustomerEmployee } = useCustomerEmployeeDialog({
    onSubmit: onSubmitCustomerEmployee,
  });

  const travelers = useMemo<Traveler[]>(() => {
    return bookingState.travelers?.map((traveler) => traveler?.traveler) ?? travelersRemakeSearch;
  }, [bookingState.travelers]);

  const selectedTravelers = bookingState.travelers.map((t) => t?.traveler);

  return (
    <TravelersSectionUI
      data={order?.travelers ?? travelers}
      selectedTravelers={selectedTravelers}
      travelersPlaceholder={travelersPlaceholder}
      onCreateCustomerEmployee={onCreateCustomerEmployee}
      fetchTravelers={fetchTravelersByName}
      onSelectTraveler={onSelectTraveler}
    />
  );
};
