import { Fee } from "~/application/types";
import {
  AppBarActions,
  AppBarContainer,
  AppBarHeading,
} from "~/components/AppBar";
import { AppBar } from "~/components/AppBar/AppBar";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { Footer } from "~/components/Document";
import { EmptyState } from "~/components/EmptyState";
import { Icon } from "~/components/Icon";
import { SvgPlus } from "~/components/Icon/icons";
import { SearchBar } from "~/components/Input";
import { LazyList } from "~/components/LazyList";
import { Pagination } from "~/components/Pagination";
import { Text } from "~/components/Text";
import { Fragment, useCallback } from "react";
import { FeeListItem } from "./components/FeeListItem";

export interface FeesContainerProps {
  data?: Fee[];
  isLoading: boolean;
  currentPage: number;
  lastPage: number;
  searchText: string;
  setSearchText: (searchO: string) => void;
  onGoToPage: (page: number) => void;
  onCreateFee: () => void;
  onEditFee: (item: Fee) => void;
  onToggleState: (data: Fee) => void;
}

export function FeesContainer({
  data,
  currentPage,
  lastPage,
  searchText,
  setSearchText,
  onGoToPage,
  onCreateFee,
  onEditFee,
  onToggleState,
}: FeesContainerProps) {
  const listRenderer = useCallback(
    (item: Fee) => (
      <FeeListItem
        data={item}
        onToggleState={onToggleState}
        onEditClick={onEditFee}
        key={item.uuid}
      />
    ),
    [onToggleState, onEditFee]
  );

  return (
    <Fragment>
      <AppBar>
        <AppBarContainer>
          <AppBarHeading
            title="Fees"
            description="Fees cadastrados para empresas"
          />

          <AppBarActions>
            <SearchBar
              search={searchText}
              onSearchChange={setSearchText}
              placeholder="Encontrar taxa"
            />

            <Button title="Nova taxa" onClick={onCreateFee}>
              <Icon as={SvgPlus} />
              <Text>Nova taxa</Text>
            </Button>
          </AppBarActions>
        </AppBarContainer>
      </AppBar>

      <Container css={{ pt: "$10", pb: "$20" }}>
        <LazyList
          gap="6"
          items={data}
          render={listRenderer}
          skeletonQuantity={10}
          skeletonHeight={100}
          EmptyComponent={
            <EmptyState>
              {searchText.length > 0 ? (
                <Text>Nenhuma taxa corresponde aos dados informados</Text>
              ) : (
                <Text>Você ainda não possui taxas cadastradas</Text>
              )}
            </EmptyState>
          }
        />
      </Container>

      <Footer>
        <Container>
          <Pagination
            activePage={currentPage}
            pagesCount={lastPage}
            setActivePage={onGoToPage}
          />
        </Container>
      </Footer>
    </Fragment>
  );
}
