import { Assessment } from "~/application/types";
import { toDate } from "~/application/utils/date-functions";
import { AssessmentDTO } from "../dtos";

export function mapAssessmentDTO(data: AssessmentDTO): Assessment {
  return {
    uuid: data.uuid,
    status: data.status,
    createdAt: toDate(data.created_at),
    approvalRequest: {
      uuid: data.approval_request.uuid,
      orderId: data.approval_request.order_uuid,
      approvalModelId: data.approval_request.approval_model_uuid,
      isApproved: data.approval_request.approved,
    },
  };
}
