import { FC } from "react";
import { HotelIssuanceFormProps } from "./types";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { NumberInput, Select, TextInput } from "~/components/Input";
import { Flex } from "~/components/Flex";
import { Card, CardBody } from "~/components/Card";
import { Col } from "~/components/Grid";
import { SvgExpenses, SvgInfo } from "~/components/Icon/icons";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { IconButton } from "~/components/IconButton";
import { Icon } from "~/components/Icon";

export const HotelIssuanceForm: FC<HotelIssuanceFormProps> = ({
  control,
  providers,
  isLoading,
  totalValue,
  value,
  isOffline,
  taxes,
}) => {
  const { fee, markup } = taxes;

  return (
    <Flex
      align="center"
      direction="column"
      css={{
        py: "$10",
        backgroundColor: "$neutrals-lightest",
      }}
      gap="4"
    >
      <Flex
        as={Card}
        direction="column"
        css={{
          overflow: "visible",
        }}
      >
        <Flex as={CardBody} direction="column" gap="4">
          <Col>
            <FormControl control={control} name="tracker" required>
              <FieldLabel>Código da reserva</FieldLabel>
              <TextInput placeholder="Digite o código da reserva" />
            </FormControl>
          </Col>

          {!isOffline && (
            <>
              <Col>
                <FormControl control={control} name="provider" required>
                  <FieldLabel>Fornecedor</FieldLabel>
                  <Select
                    isLoading={isLoading}
                    placeholder="Selecione o fornecedor"
                    options={providers}
                    getOptionValue={(provider) => provider.uuid}
                    getOptionLabel={(provider) => provider.name}
                  />
                </FormControl>
              </Col>

              <Flex direction="column" gap="4">
                <Col>
                  <FormControl name="value" control={control} required>
                    <FieldLabel>Cobrado do cliente</FieldLabel>
                    <NumberInput
                      placeholder="0,00 R$"
                      allowNegative={false}
                      leftIcon={SvgExpenses}
                    />
                  </FormControl>
                </Col>

                <Col>
                  <FormControl name="totalValue" control={control} required>
                    <Flex align="center" css={{ mb: 2 }}>
                      <FieldLabel css={{ mb: 0 }}>Valor do fornecedor</FieldLabel>
                      <Tooltip
                        content={
                          <TooltipLabel>Incluso fee, markup e taxas do fornecedor</TooltipLabel>
                        }
                      >
                        <IconButton size="sm">
                          <Icon as={SvgInfo} />
                        </IconButton>
                      </Tooltip>
                    </Flex>
                    <NumberInput
                      prefix="R$ "
                      leftIcon={SvgExpenses}
                      defaultValue={(value || 0) - fee - markup}
                    />
                  </FormControl>
                </Col>
              </Flex>
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

HotelIssuanceForm.displayName = "HotelIssuanceForm";
