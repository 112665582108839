import { FC, useMemo } from "react";
import { usePDF } from "react-to-pdf";
import {
  AirlineCode,
  Customer,
  Flight,
  FlightServicesIncluded,
  User,
} from "~/application/types";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { CompanyLogo } from "~/components/CompanyLogo";
import { Divider } from "~/components/Divider";
import { Flex } from "~/components/Flex";
import { FormDialog } from "~/components/FormDialog";
import { Icon } from "~/components/Icon";
import { SvgFavicon } from "~/components/Icon/icons";
import { Text } from "~/components/Text";
import { FlightOptionBenefit } from "~/presentation/shared/components/FlightListItem";
import { createFlightBenefits } from "~/presentation/shared/components/FlightListItem/FlightOptionItem/utils";
import { formatFlightInfo, getAirlineUrl } from "~/presentation/shared/utils";
import { DateFormats, displayDate } from "~/utils/date.utils";
import { asCurrency } from "~/utils/mask.utils";
import { AirwayBudgetState } from "../type";
import { AirwayQueryFormData } from "~/presentation/shared/components/AirwayQueryForm";
import { Grid } from "~/components/Grid";

export type AirwayBudgetDialogProps = {
  data: AirwayBudgetState;
  user?: User;
  customer?: Customer;
  airwayParams: AirwayQueryFormData | null;
};

export const AirwayBudgetDialog: FC<AirwayBudgetDialogProps> = ({
  data,
  user,
  customer,
  airwayParams,
}) => {
  const { toPDF, targetRef } = usePDF({ filename: "Orçamento.pdf" });

  const currentData = Object.values(data);

  return (
    <Card
      css={{
        width: "55vw",
      }}
    >
      <FormDialog
        title={"PDF"}
        css={{
          width: "100%",
          overflow: "hidden",
        }}
        positiveButton={
          <Flex justify="end">
            <Button onClick={() => toPDF()}>Baixar PDF</Button>
          </Flex>
        }
      >
        <Flex
          css={{
            width: "100%",
            overflow: "auto",
          }}
          direction="column"
        >
          <CardBody ref={targetRef} css={{}}>
            <Flex gap="2" direction="column">
              <Flex
                css={{
                  width: "100%",
                  p: "$3 $6",
                  borderRadius: "$md $md $none $none",
                  backgroundColor: "$primary-base",
                }}
                align="center"
                justify="between"
              >
                <Icon as={SvgFavicon} size="lg" />
                <Flex direction="column" gap="1">
                  <Text size="1" variant="white">
                    Empresa
                  </Text>
                  <Text size="3" variant="white" fw="600">
                    {customer?.companyName}
                  </Text>
                </Flex>
              </Flex>
              <Flex gap="2" direction="column">
                <Text fw="700">
                  Agente: <Text fw="400">{`${user?.name}`}</Text>
                </Text>
                <Text fw="700">
                  Telefone: <Text fw="400">{user?.phone}</Text>
                </Text>
                <Text fw="700">
                  E-mail: <Text fw="400">{user?.email}</Text>
                </Text>
              </Flex>
              <Divider
                css={{
                  borderColor: "$neutrals-dark",
                  width: "100%",
                }}
              />
              <Flex
                css={{
                  width: "100%",
                }}
                align="center"
                justify="between"
              >
                <Flex
                  css={{
                    p: "$3",
                    borderRadius: "$pill",
                    backgroundColor: "$primary-base",
                  }}
                >
                  <Text variant="white" fw="700" size="4">
                    Cotação Eletrônica
                  </Text>
                </Flex>
                <Flex direction="column" gap="1">
                  <Text size="1" fw="400">
                    Data da cotação
                  </Text>
                  <Text size="3" fw="600">
                    {displayDate(Date(), DateFormats.SMALL_DATE)}
                  </Text>
                </Flex>
              </Flex>
              <Divider
                css={{
                  width: "100%",
                  borderColor: "$neutrals-dark",
                }}
              />
              {currentData.map((newData, index) => {
                if (!newData.length) return;
                return (
                  <Flex direction="column" gap="6">
                    <Text fw="700" variant="primary-dark">
                      Data da {index > 0 ? "volta" : "ida"}:{" "}
                      <Text fw="500" variant="primary-dark">
                        {displayDate(
                          index > 0
                            ? (airwayParams?.stretch?.at(0)?.returnDate as Date)
                            : (airwayParams?.stretch.at(0)?.outboundDate as Date),
                          DateFormats.SMALL_COMPLETE_WEEK_DATE
                        )}
                      </Text>
                    </Text>

                    {newData.map((fli, index) => {
                      const flightInfo = useMemo(() => formatFlightInfo(fli as Flight), [fli]);

                      return (
                        <Flex key={fli?.id} direction="column" gap="2">
                          <Text fw="700" size="5" variant="primary">
                            {`Opção ${index + 1}`}
                          </Text>
                          <Flex
                            css={{
                              width: "100%",
                            }}
                            direction="column"
                          >
                            <Flex
                              css={{
                                p: "$3 $6",
                                borderRadius: "$md $md $none $none",
                                border: "1px solid $neutrals-dark",
                                width: "100%",
                              }}
                              align="center"
                              justify="between"
                            >
                              <CompanyLogo
                                size="md"
                                src={getAirlineUrl(fli?.airline || AirlineCode.LATAM)}
                              />
                              <Text size="2" variant="darkest" fw="500">
                                Voo Nº {fli?.segments?.at(0)?.flightNumber}
                              </Text>
                            </Flex>
                            <Flex
                              css={{
                                p: "$6 $10",
                                borderRadius: "$none $none $md $md",
                                borderTop: "none",
                                border: "1px solid $neutrals-dark",
                                width: "100%",
                              
                              }}
                              align="center"
                              justify="center"
                              direction="column"
                              gap="6"
                            >
                              <Flex justify="between" align="center" css={{ width: "100%" }}>
                                <Flex
                                  direction="column"
                                  gap="2"
                                  align="center"
                                  css={{
                                    flex: "1",
                                  }}
                                >
                                  <Text fw="700">
                                    {flightInfo.departureFlightInfo.dateTimeHour12}
                                  </Text>
                                  <Text size="1">Origem</Text>
                                  <Text variant="darkest" size="3" fw="600">
                                    {flightInfo.departureFlightInfo.airportIata}
                                    {" - "}
                                    {flightInfo.departureFlightInfo.airport}
                                  </Text>
                                </Flex>
                                <Flex
                                  direction="column"
                                  gap="2"
                                  align="center"
                                  css={{
                                    flex: "1",
                                  }}
                                >
                                  <Text fw="600" variant="darkest" size="2">
                                    Duração
                                  </Text>
                                  <Flex
                                    css={{
                                      width: "100%",
                                    }}
                                    align="center"
                                    gap="1"
                                  >
                                    <Divider
                                      css={{
                                        width: "80%",
                                        borderColor: "$neutrals-dark",
                                      }}
                                    />
                                    <Text
                                      size="2"
                                      fw="700"
                                      css={{
                                        minWidth: "max-content",
                                      }}
                                    >
                                      {flightInfo.flightDuration}
                                    </Text>
                                    <Divider
                                      css={{
                                        width: "80%",
                                        borderColor: "$neutrals-dark",
                                      }}
                                    />
                                  </Flex>
                                  <Text variant="darkest" size="2" fw="600">
                                    {`${fli?.scales ? fli?.scales : "Sem"} ${
                                      Number(fli?.scales) > 1 ? "conexões" : "conexão"
                                    }`}
                                  </Text>
                                </Flex>
                                <Flex
                                  direction="column"
                                  gap="2"
                                  align="center"
                                  css={{
                                    flex: "1",
                                  }}
                                >
                                  <Text fw="700">{flightInfo.arrivalInfo.dateTimeHour12}</Text>
                                  <Text size="1">Origem</Text>
                                  <Text variant="darkest" size="3" fw="600">
                                    {flightInfo.arrivalInfo.airportIata}
                                    {" - "}
                                    {flightInfo.arrivalInfo.airport}
                                  </Text>
                                </Flex>
                              </Flex>
                              <Grid columns={2} justify="center" gap="6">
                                {fli?.options?.map((option) => {
                                  const flightBenefits = useMemo(
                                    () => createFlightBenefits(option?.servicesIncluded as FlightServicesIncluded),
                                    [option]
                                  );
                                  return (
                                    <Flex
                                      key={option?.id}
                                      direction="column"
                                      css={{
                                        minWidth: "$40",
                                        maxWidth: "max-content",
                                      }}
                                    >
                                      <Flex
                                        css={{
                                          p: "$4",
                                          borderRadius: "$md $md $none $none",
                                          backgroundColor: "$neutrals-lightest",
                                          border: "1px solid $neutrals-dark",
                                          width: "100%",
                                        }}
                                      >
                                        <Text size="4" fw="600">
                                          {option?.familyFare}
                                        </Text>
                                      </Flex>
                                      <Flex
                                        css={{
                                          p: "$4",
                                          borderRadius: "$none  $none $md $md",
                                          backgroundColor: "$neutrals-white",
                                          border: "1px solid $neutrals-dark",
                                          width: "100%",
                                        }}
                                        justify="center"
                                        align="end"
                                        direction="column"
                                      >
                                        <Flex direction="column">
                                          {flightBenefits.map((item) => (
                                            <Flex key={item.name} direction="column">
                                              <FlightOptionBenefit
                                                name={item.name}
                                                isExists={item.isExists}
                                              />
                                              <Divider />
                                            </Flex>
                                          ))}
                                        </Flex>

                                        <Text size="4" fw="600">
                                          {asCurrency(option?.pricingInfo?.total?.amount || 0)}
                                        </Text>
                                      </Flex>
                                    </Flex>
                                  );
                                })}
                              </Grid>
                            </Flex>
                          </Flex>
                        </Flex>
                      );
                    })}
                    <Divider
                      css={{
                        borderStyle: "groove",
                        borderColor: "$neutrals-dark",
                      }}
                    />
                  </Flex>
                );
              })}
            </Flex>
          </CardBody>
        </Flex>
      </FormDialog>
    </Card>
  );
};
