import type { DeepPartial, HotelEmission, OrderHotelItem, Provider } from "~/application/types";

export type CreateFormDataProps = {
  data: OrderHotelItem;
  providers?: Provider[];
};

export function createFormData({
  data,
  providers,
}: CreateFormDataProps): DeepPartial<HotelEmission> {
  const { uuid, tracker, provider, value } = data;

  return {
    uuid,
    tracker,
    provider: {
      name: provider,
      uuid: providers?.find(({ name }) => name === provider)?.uuid,
    },
    value: Number(value),
  };
}
