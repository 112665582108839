import { useQuery } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import type {
  Hotel,
  HotelDetails,
  HotelDetailsLinks,
  HotelQuery,
  HotelRoom,
} from "~/application/types";
import { ApiError } from "~/application/types";
import { hotelDetailsService } from "~/application/usecases";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants/queryKeys";
import { QueryTimes } from "~/constants/queryTimes";
import { log } from "~/utils/log";
import { MapViewDialog } from "../../../components/MapViewDialog";
import { useBookingHotel } from "../../../contexts/BookingHotelContext";
import { HOTEL_ACCOMMODATIONS_SECTION_ID } from "../views/SelectRooms/TabSelectRooms";
import { AccommodationDetailsDialog } from "../views/SelectRooms/components/AccommodationPoliciesDialog";
import { AsyncAccommodationRulesDialog } from "../views/SelectRooms/components/AccommodationRulesDialog/async";
import { HotelGalleryDialog } from "../views/SelectRooms/components/HotelGalleryDialog";
import { getHotelDataFromUrl } from "../../../utils";

export interface UseSelectRoomsResult {
  isLoading: boolean;
  hotel: Hotel;
  hotelDetails?: HotelDetails;
  hotelQuery: HotelQuery;
  hotelRooms?: HotelRoom[];
  selectedAccommodations: Array<HotelRoom | null>;
  currentRoomTab: number;
  setCurrentRoomTab: (index: number) => void;
  onOpenAccommodationDetails: (room: HotelRoom) => void;
  onOpenAccommodationPolicies: (room: HotelRoom) => void;
  onSelectAccommodation: (
    position: number,
    room: HotelRoom,
    element: HTMLDivElement | null
  ) => void;
  onOpenGallery: () => void;
  onScrollToAccommodations: () => void;
  onGoNextStep: () => void;
  onMapView: () => void;
}

export interface UseSelectRoomsOptions {
  enabled: boolean;
  onGoNextStep: () => void;
  setIsShowCart: Dispatch<SetStateAction<boolean>>;
}

const LOG_TAG = "Booking/BookingHotel/HotelDetailsPage/useSelectRooms";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar hotel",
} as const;

export function useSelectRooms({
  enabled,
  onGoNextStep,
  setIsShowCart,
}: UseSelectRoomsOptions): UseSelectRoomsResult {
  const { hotelQuery, hotelReducer } = useBookingHotel();

  const [currentRoomTab, setCurrentRoomTab] = useState<number>(0);

  const currentAccommodation = hotelQuery?.accommodations?.at(currentRoomTab);
  const quantityGuest =
    (currentAccommodation?.adultQuantity || 0) +
    (currentAccommodation?.guestsChildren?.length || 0);

  const [selectedAccommodations, setSelectedAccommodations] = useState<Array<HotelRoom>>(
    Array(hotelQuery?.accommodations.length).fill(null)
  );

  const hotel = hotelReducer.bookingState.hotelSelected! || getHotelDataFromUrl();

  const { data: hotelDetailLink, isFetching: isFetchingLink } = useQuery<
    HotelDetailsLinks,
    ApiError
  >(
    [QueryKeys.QUERY_HOTEL_DETAILS, hotel],
    () => hotelDetailsService.query(hotel?.searchKey, hotel?.uuid),
    {
      cacheTime: QueryTimes.NONE,
      enabled: enabled,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE, "error");
      },
    }
  );

  const { data: hotelDetails, isFetching: isFetchingHotelDetails } = useQuery<
    HotelDetails,
    ApiError
  >([QueryKeys.HOTEL_DETAILS, hotel], () => hotelDetailsService.find(hotelDetailLink!.link), {
    staleTime: 1000 * 60 * 15, // 15 minutes
    enabled: !isFetchingLink,
    retry: true,
    retryDelay: hotelDetailLink?.waitTime,
    onError: (error) => {
      log.e(LOG_TAG, error);

      snackbarService.showSnackbar(SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE, "error");
    },
  });

  const hotelRoomsList = useMemo(
    () =>
      hotel?.provider?.slug === "iterpec" &&
      hotelQuery &&
      selectedAccommodations.some(
        (ac) =>
          ac?.adultAmount + ac?.childAmount === quantityGuest &&
          ac.accommodationIndex !== currentRoomTab + 1
      )
        ? selectedAccommodations.filter(
            (acc) => acc?.adultAmount + acc?.childAmount === quantityGuest
          )
        : hotelDetails?.rooms
            .filter((r) => r.accommodationIndex === 1 + currentRoomTab)
            .sort((a, b) => a.totalPriceWithFee - b.totalPriceWithFee),
    [hotelDetails, currentRoomTab, selectedAccommodations, hotelQuery, hotel, quantityGuest]
  );

  const handleSelectAccommodation = useCallback(
    (position: number, room: HotelRoom, element: HTMLDivElement | null) => {
      setTimeout(
        () =>
          element?.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "end",
          }),
        100
      );
      // Check for iterpec provider
      setIsShowCart(true);

      setCurrentRoomTab(
        position + 1 === hotelQuery?.accommodations.length ? position : position + 1
      );

      setSelectedAccommodations((old) => {
        old[position] = room;
        return [...old];
      });
    },
    [hotel, hotelQuery, setIsShowCart, setCurrentRoomTab]
  );

  const handleScrollToAccommodations = useCallback(() => {
    const sectionElement = document.getElementById(HOTEL_ACCOMMODATIONS_SECTION_ID);

    if (!sectionElement) return;

    const elementPosition = sectionElement.getBoundingClientRect().top;

    window.scrollTo({
      top: elementPosition - 84,
      behavior: "smooth",
    });
  }, []);

  const handleMapView = useCallback(() => {
    dialogService.showDialog(<MapViewDialog height={600} data={hotel} hotelsList={[hotel]} />);
  }, [hotel]);

  const handleOpenGallery = useCallback(() => {
    dialogService.showDialog(<HotelGalleryDialog images={hotelDetails?.images ?? []} />);
  }, [hotel, hotelDetails]);

  const handleOpenAccommodationDetails = useCallback(
    (room: HotelRoom) => {
      dialogService.showDialog(
        <AccommodationDetailsDialog data={room} hotelDetails={hotelDetails!} />
      );
    },
    [hotelDetails]
  );

  const handleOpenAccommodationPolicies = useCallback(
    (room: HotelRoom) => {
      dialogService.showDialog(<AsyncAccommodationRulesDialog hotel={hotel} room={room} />);
    },
    [hotel]
  );

  const handleGoNextStep = useCallback(() => {
    hotelReducer.dispatch({
      type: "SET_HOTEL_ROOMS",
      payload: selectedAccommodations as HotelRoom[],
    });

    onGoNextStep();
  }, [onGoNextStep]);

  return {
    hotel,
    hotelDetails,
    currentRoomTab,
    selectedAccommodations,
    hotelQuery: hotelQuery!,
    isLoading: isFetchingHotelDetails,
    hotelRooms: hotelRoomsList,
    onGoNextStep: handleGoNextStep,
    setCurrentRoomTab,
    onOpenGallery: handleOpenGallery,
    onOpenAccommodationDetails: handleOpenAccommodationDetails,
    onOpenAccommodationPolicies: handleOpenAccommodationPolicies,
    onSelectAccommodation: handleSelectAccommodation,
    onScrollToAccommodations: handleScrollToAccommodations,
    onMapView: handleMapView,
  };
}
