import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Flex } from "~/components/Flex";
import { Grid } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import {
  SvgAlertTriangle,
  SvgCall,
  SvgCarDoor,
  SvgClock,
  SvgGps,
  SvgInfo,
  SvgManual,
  SvgSnowflake,
} from "~/components/Icon/icons";
import { Image } from "~/components/Image";
import { snackbarService } from "~/components/SnackbarStack";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { QueryKeys } from "~/constants";
import { queryClient as queryClientService } from "~/services/queryClient";
import { DateFormats, displayDate } from "~/utils/date.utils";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody } from "../../../components/Card";
import { InputPerson } from "../../../components/InputPerson";
import { VehicleBookingCart } from "../components/VehicleBookingCart";
import { VehicleBookingContext } from "../contexts/VehicleBookingContext";
import { VehicleConfirmationDialog } from "./ConfirmationDetailsDialog";
import { dialogService } from "~/components/DialogStack";
import { useMutation, useQuery } from "@tanstack/react-query";
import { customerEmployeeService, vehicleQueryService } from "~/application/usecases";
import { LoadingDialog } from "~/presentation/shared/views/LoadingDialog";
import { AsyncSelect } from "~/presentation/shared/components/AsyncSelect";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { vehicleBookingReducer } from "../utils/vehicle.utils";
import { DEFAULT_VEHICLE_BOOKING_STATE } from "../VehicleBookingContainer";
import { Alert } from "~/components/Alert";
import { asCurrency } from "~/utils/mask.utils";
import { ViolatedPoliciesButton } from "~/presentation/shared/components/ViolatedPoliciesButton/ViolatedPoliciesButton";

type VehicleReservationSectionProps = {
  activeStep: string;
  cartIsOpen: boolean;
  setCartIsOpen: Dispatch<SetStateAction<boolean>>;
};

export function VehicleReservationSection({
  activeStep,
  cartIsOpen,
  setCartIsOpen,
}: VehicleReservationSectionProps) {
  const { actions, bookingInfo, queryData, order } = useContext(VehicleBookingContext);

  const { user } = useUser();
  const [state, dispatch] = useReducer(vehicleBookingReducer, DEFAULT_VEHICLE_BOOKING_STATE);

  const [link, setLink] = useState({
    link: "",
    waitTime: 0,
  });

  const { mutate: getLinkDetail } = useMutation(
    [bookingInfo],
    async () => {
      const detail = await vehicleQueryService.linkDetail({
        id: bookingInfo.vehicleSelected?.id || 0,
        searchKey: bookingInfo.vehicleSelected?.searchKey || "",
      });
      setLink({
        link: detail.link,
        waitTime: detail.wait_time,
      });
    },
    {}
  );

  const { data } = useQuery(
    [QueryKeys.QUERY_VEHICLE_DETAILS, link],
    async () => await vehicleQueryService.findDetail(link.link),
    {
      enabled: !!link,
      retry: 10,
      retryDelay: link.waitTime,
      onSuccess: () => {
        dialogService.popAll();
      },
    }
  );

  useEffect(() => {
    getLinkDetail();
  }, []);

  useEffect(() => {
    if (!data) {
      dialogService.showDialog(<LoadingDialog message="Carregando detalhes" />);
    }
  }, [data]);

  const navigate = useNavigate();

  async function handleCreateBooking() {
    const issuerId = user.profiles.customer?.uuid || state.issuerId;

    actions
      .createBooking(issuerId)
      .then((orderUuid) => {
        queryClientService.invalidateQueries([QueryKeys.ORDERS, orderUuid]);
        navigate(`/pedidos/${orderUuid}`);
      })
      .catch((error) => {
        snackbarService.showSnackbar(error.message, "error");
      });
  }

  const handleConfirmationDetails = useCallback(() => {
    dialogService.showDialog(<VehicleConfirmationDialog queryData={queryData} />);
  }, []);

  const returnFeeAnotherCity = bookingInfo.vehicleSelected?.returnFeeAnotherCity || 0;

  const { contexts } = useUser();
  const [issuerName, setIssuerName] = useState("");

  const customerId = contexts?.customer?.uuid || "";

  const fetchIssuers = useCallback(async () => {
    const response = await customerEmployeeService.find({
      customerId,
      name: issuerName,
      onlyUser: true,
    });
    return response.data;
  }, [customerId, issuerName]);

  return (
    <Grid
      gap="6"
      css={{
        gridTemplateColumns: "2fr 1fr",
        "@mxlg": {
          gridTemplateColumns: "1fr",
        },
      }}
    >
      <Box>
        <Flex direction="column" align="stretch" css={{ gap: "$12" }}>
          <Text
            as="h4"
            size="6"
            css={{
              fw: "600",
              "@mxlg": {
                textAlign: "center",
                fontSize: "$md",
              },
            }}
          >
            Confirme as informações da sua reserva
          </Text>

          <Flex direction="column" gap="6">
            <Text css={{ fw: "700" }}>Veículo</Text>
            <Card
              spacing="6"
              css={{
                overflow: "visible",
              }}
            >
              <Flex
                direction={{ "@mxlg": "columnReverse" }}
                css={{
                  p: "$5",
                  "@mxlg": {
                    p: 15,
                  },
                }}
                gap="6"
              >
                <Flex direction="column" gap="6">
                  <Flex direction="column" gap="3">
                    <Flex align={{ "@mxlg": "center" }} justify={{ "@mxlg": "between" }}>
                      <Flex
                        align="center"
                        justify="between"
                        css={{
                          width: "100%",
                        }}
                      >
                        <Text
                          size="6"
                          css={{
                            fw: "600",
                            "@mxlg": {
                              fontSize: "$md",
                            },
                          }}
                        >
                          {bookingInfo.vehicleSelected?.vehicle.group}
                        </Text>
                        {bookingInfo.vehicleSelected?.violatedPolicies && (
                          <ViolatedPoliciesButton
                            data={bookingInfo?.vehicleSelected?.violatedPolicies}
                          />
                        )}
                      </Flex>
                      <Button
                        onClick={handleConfirmationDetails}
                        variant="tertiary"
                        css={{
                          display: "none",
                          "@mxlg": {
                            height: "$5",
                            fontSize: "$sm",
                            display: "flex",
                          },
                        }}
                      >
                        Ver detalhes
                      </Button>
                    </Flex>
                    <Text
                      variant="dark"
                      css={{
                        "@mxlg": {
                          fontSize: "$sm",
                        },
                      }}
                    >
                      {bookingInfo.vehicleSelected?.vehicle.description}
                    </Text>
                  </Flex>

                  <Flex gap="3">
                    {bookingInfo.vehicleSelected?.vehicle.airCondition && (
                      <Tag variant="neutral-light">
                        <Icon as={SvgSnowflake} />
                        Ar condicionado
                      </Tag>
                    )}

                    <Tag variant="neutral-light">
                      <Icon as={SvgManual} />
                      {bookingInfo.vehicleSelected?.vehicle.transmissionType}
                    </Tag>

                    <Tag variant="neutral-light">
                      <Icon as={SvgCarDoor} />
                      {bookingInfo.vehicleSelected?.vehicle.numberDoors}
                    </Tag>
                  </Flex>
                </Flex>
                <Flex justify={{ "@mxlg": "center" }}>
                  <Image
                    src={bookingInfo.vehicleSelected?.vehicle.image}
                    css={{ height: "138px", width: "240px" }}
                  />
                </Flex>
              </Flex>
            </Card>
          </Flex>

          <Flex
            direction="column"
            gap="6"
            css={{
              "@mxlg": {
                display: "none",
              },
            }}
          >
            <Text css={{ fw: "700" }}>Datas e localização</Text>
            <Grid columns="2" gap="6">
              <Card spacing="6">
                <Flex as={CardBody} direction="column" gap="6">
                  <Text as="p" size="3" css={{ fw: "600" }}>
                    Informações sobre retirada
                  </Text>

                  <Flex gap="3" align="center">
                    <Icon variant="primary" as={SvgClock} />
                    <Text size="2" css={{ fw: "500" }}>
                      Data e hora
                    </Text>
                    <Text size="2" variant="dark">
                      {displayDate(
                        queryData.dateGetSelected as Date,
                        DateFormats.LONG_BRAZILIAN_DATE
                      )}{" "}
                      às {queryData.timeGetSelected}
                    </Text>
                  </Flex>

                  <Flex gap="3" align="center">
                    <Icon variant="primary" as={SvgGps} />
                    <Text size="2" css={{ fw: "500" }}>
                      Local
                    </Text>
                    <Text size="2" variant="dark">
                      {`${bookingInfo.vehicleSelected?.destination} - ${queryData?.destinationSelected.state}, ${queryData?.destinationSelected.country}`}
                    </Text>
                  </Flex>
                  {data && (
                    <>
                      <Flex gap="3" align="center">
                        <Icon variant="primary" size="sm" as={SvgCall} />
                        <Text size="2" css={{ fw: "500" }}>
                          Contato
                        </Text>
                        <Text size="2" variant="dark">
                          {data?.phone}
                        </Text>
                      </Flex>
                      <Flex gap="3" align="center">
                        <Icon variant="primary" as={SvgInfo} />
                        <Text size="2" css={{ fw: "500" }}>
                          Detalhes
                        </Text>
                        <Text size="2" variant="dark">
                          {data.deadLineDescription}
                        </Text>
                      </Flex>
                    </>
                  )}
                </Flex>
              </Card>
              <Card spacing="6">
                <Flex as={CardBody} direction="column" gap="6">
                  <Text as="p" size="3" css={{ fw: "600" }}>
                    Informações sobre devolução
                  </Text>

                  <Flex gap="3" align="center">
                    <Icon variant="primary" as={SvgClock} />
                    <Text size="2" css={{ fw: "500" }}>
                      Data e hora
                    </Text>
                    <Text size="2" variant="dark">
                      {displayDate(
                        queryData.dateReturnSelected as Date,
                        DateFormats.LONG_BRAZILIAN_DATE
                      )}{" "}
                      às {queryData.timeReturnSelected}
                    </Text>
                  </Flex>
                  <Flex gap="3" align="center">
                    <Icon variant="primary" as={SvgGps} />
                    <Text size="2" css={{ fw: "500" }}>
                      Local
                    </Text>
                    <Text size="2" variant="dark">
                      {`${bookingInfo.vehicleSelected?.destination} - ${queryData?.destinationSelected.state}, ${queryData?.destinationSelected.country}`}
                    </Text>
                  </Flex>

                  {data && (
                    <>
                      <Flex gap="3" align="center">
                        <Icon variant="primary" size="sm" as={SvgCall} />
                        <Text size="2" css={{ fw: "500" }}>
                          Contato
                        </Text>
                        <Text size="2" variant="dark">
                          {data?.phone}
                        </Text>
                      </Flex>
                      <Flex gap="3" align="center">
                        <Icon variant="primary" as={SvgInfo} />
                        <Text size="2" css={{ fw: "500" }}>
                          Detalhes
                        </Text>
                        <Text size="2" variant="dark">
                          {data.deadLineDescription}
                        </Text>
                      </Flex>
                    </>
                  )}
                </Flex>
              </Card>
            </Grid>
          </Flex>
          {!!returnFeeAnotherCity && (
            <Alert variant="warning">
              <Icon as={SvgAlertTriangle} size="xl" />
              <Text size="3" css={{ lineHeight: "$4" }}>
                Para devolução em outra loja, será cobrado uma taxa de{" "}
                <Text fw="700" variant="warning-dark">
                  {asCurrency(returnFeeAnotherCity)}{"."}
                </Text>{" "}
              </Text>
            </Alert>
          )}
          <Flex direction="column" gap="6">
            <Text css={{ fw: "700" }}>Condutor</Text>
            <InputPerson selectedPerson={bookingInfo.traveler} readOnly />
          </Flex>

          {contexts.agency && !order?.issuer?.uuid && (
            <Flex direction="column" gap="6">
              <Text css={{ fw: "700" }}>Solicitante</Text>

              <AsyncSelect
                onInputChange={(value) => setIssuerName(value)}
                onChange={(issuer) => dispatch({ type: "SET_ISSUER", payload: issuer })}
                placeholder="Digite o solicitante"
                fetchOptions={fetchIssuers}
                getOptionLabel={(c) => `${c.name} ${c.lastName || ""}`}
                getOptionValue={(c) => c.uuid}
              />
            </Flex>
          )}
        </Flex>
      </Box>

      <Box>
        <Box
          css={{
            top: "116px",
            "@mxlg": {
              top: "0",
            },
          }}
        >
          <VehicleBookingCart
            currentActiveStep={activeStep}
            cartIsOpen={cartIsOpen}
            setCarIsOpen={setCartIsOpen}
            setNextStep={handleCreateBooking}
            bookingInfo={bookingInfo}
            details={data}
          />
        </Box>
      </Box>
    </Grid>
  );
}
