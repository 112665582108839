import { Traveler } from "~/application/types";
import { DateUtils } from "~/application/utils";
import { Avatar } from "~/components/Avatar";
import { Card, CardBody } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { DataColItem } from "~/components/List";
import { Text } from "~/components/Text";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { asCPF } from "~/utils/mask.utils";

export interface TravelerListItemProps {
  data: Traveler;
}

export function TravelerListItem({ data, ...props }: TravelerListItemProps) {
  const isMobile = useMobile();
  const customerDateOfBirth = DateUtils.smallDateFormat(data.dateBirth)
  return (
    <Card {...props}>
      <Flex direction={{ "@mxlg": "column" }} css={{
        "@mxlg": {
          p: 15
        }
      }} as={CardBody} gap="4">
        <Avatar css={{
          "@mxlg": {
            display: "none",
          }
        }} src={data.avatarUrl} size="lg" />

        {isMobile ? (
          <DataColItem
            headerTitle=""
            data={
              <Text size="3" css={{ fw: "600" }}>
                {data.fullName}
              </Text>
            }
          />
        ) : (
          <DataColItem
            headerTitle="Nome"
            data={
              <Text size="2" css={{
                fw: "600", "@mxlg": {
                  ml: "$40"
                }
              }}>
                {data.fullName}
              </Text>
            }
          />
        )}

        <DataColItem
          headerTitle="CPF"
          data={
            <Text size="2" css={{ fw: "600" }}>
              {asCPF(data.cpf)}
            </Text>
          }
        />

        <DataColItem
          headerTitle="RG"
          data={
            <Text size="2" css={{ fw: "600" }}>
              {data.rg}
            </Text>
          }
        />

        <DataColItem
          headerTitle="Data de nascimento"
          data={
            <Text size="2" css={{ fw: "600" }}>
              {DateUtils.smallDateFormat(data.dateBirth)}
            </Text>
          }
        />
      </Flex>
    </Card>
  );
}

TravelerListItem.displayName = "TravelerListItem";
