import { Button } from "~/components/Button";
import { Icon } from "~/components/Icon";
import { SvgPlus } from "~/components/Icon/icons";
import { Text } from "~/components/Text";
import { Flex } from "~/components/Flex";
import { AppBar, AppBarActions, AppBarContainer, AppBarHeading } from "~/components/AppBar";
import { Footer } from "~/components/Document";
import { SearchBar } from "~/components/Input";
import { Credential, CredentialCustomer } from "~/application/types/entities/Credential.type";
import { Container } from "~/components/Container";
import { LazyList } from "~/components/LazyList";
import { useCallback, useState } from "react";
import { EmptyState } from "~/components/EmptyState";
import { CredentialListItem } from "./components/CredentialListItem";
import { Pagination } from "~/components/Pagination";
import { PaginatedResource } from "~/application/types";
import { UseMutateFunction, UseQueryResult } from "@tanstack/react-query";

export interface CredentialsContainerProps {
  searchText: string;
  isLoading: boolean;
  lastPage: number;
  credentials?: PaginatedResource<Credential[]>;
  currentPage: number;

  handleToggleCustomer: UseMutateFunction<
    void,
    unknown,
    {
      agreementId: string;
      refetch: () => void;
      isActive: boolean;
    },
    void
  >;

  fetchCredentialCustomerOnClick: ({
    credentialId,
    currentPage,
    searchText,
  }: {
    credentialId: string;
    currentPage: number;
    searchText: string;
  }) => UseQueryResult<PaginatedResource<CredentialCustomer[]>, unknown>;

  setSearchText: (text: string) => void;
  onActive: (id: string) => void;
  onInactive: (id: string) => void;
  onGoToPage: (page: number) => void;
  onOpenCreateDialog: () => void;
  onOpenEditDialog: (item: any) => void;
}

export function CredentialsContainer({
  credentials,
  isLoading,
  lastPage,
  currentPage,
  searchText,
  handleToggleCustomer,
  fetchCredentialCustomerOnClick,
  onActive,
  onGoToPage,
  onOpenEditDialog,
  onOpenCreateDialog,
  onInactive,
  setSearchText,
}: CredentialsContainerProps) {
  const [collapsedItems, setCollapsedItems] = useState<Record<string, boolean>>({});

  const toggleCollapse = (id: string) => {
    setCollapsedItems((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const renderCredentialItem = useCallback(
    (credential: Credential) => {
      const onToggleState = credential.active ? onInactive : onActive;
      return (
        <CredentialListItem
          data={credential}
          isCollapsed={collapsedItems[credential.uuid]}
          currentPage={currentPage}
          handleToggleCustomer={handleToggleCustomer}
          fetchCredentialCustomerOnClick={fetchCredentialCustomerOnClick}
          onToggleState={onToggleState}
          onEditClick={onOpenEditDialog}
          onToggleCollapse={() => toggleCollapse(credential.uuid)}
        />
      );
    },
    [
      collapsedItems,
      currentPage,
      onActive,
      onInactive,
      setCollapsedItems,
      fetchCredentialCustomerOnClick,
    ]
  );

  return (
    <Flex direction="column" gap="4">
      <AppBar>
        <AppBarContainer>
          <AppBarHeading title="Credenciais" />

          <AppBarActions>
            <SearchBar
              search={searchText}
              placeholder="Buscar credencial"
              onSearchChange={setSearchText}
            />

            <Button title="Nova credencial" color="primary" onClick={onOpenCreateDialog}>
              <Icon as={SvgPlus} />
              <Text>Nova credencial</Text>
            </Button>
          </AppBarActions>
        </AppBarContainer>
      </AppBar>

      <Container css={{ pt: "$10", pb: "$20" }}>
        <LazyList
          gap="6"
          items={credentials?.data}
          isLoading={isLoading}
          render={renderCredentialItem}
          skeletonQuantity={8}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              <Text>Você ainda não tem credenciais cadastradas</Text>
            </EmptyState>
          }
        />
      </Container>

      <Footer>
        <Container>
          <Pagination activePage={currentPage} pagesCount={lastPage} setActivePage={onGoToPage} />
        </Container>
      </Footer>
    </Flex>
  );
}
