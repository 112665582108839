import type { Customer, CustomerEmployee, Traveler } from "~/application/types";
import { toDate } from "~/application/utils/date-functions";
import { customerEmployeeService } from "../CustomerEmployee";
import type { IFindTravelerParams, ITravelerService } from "./ITravelerService";

export function employeeToTraveler(customer: Customer, data: CustomerEmployee): Traveler {
  return {
    uuid: data.uuid,
    fullName: `${data.name} ${data.lastName}`,
    cpf: data.cpf,
    email: data.email,
    rg: data.rg,
    dateBirth: toDate(data.birthDate),
    phone: data.phone,
    company: {
      tradingName: customer.tradingName,
    },
  };
}

export class TravelerService implements ITravelerService {
  async find(
    customer: Customer,
    { page = 1, name, onlyUser }: IFindTravelerParams
  ): Promise<Traveler[]> {
    return await customerEmployeeService
      .find({
        customerId: customer.uuid,
        page: page,
        name: name,
        onlyUser,
      })
      .then(({ data }) => data.map((item) => employeeToTraveler(customer, item)));
  }
}
