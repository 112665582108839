import { useCallback } from "react";
import { OrderVehicle } from "~/application/types";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { dialogService } from "~/components/DialogStack";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgCarDoor, SvgManual, SvgSnowflake } from "~/components/Icon/icons";
import { Image } from "~/components/Image";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { Label } from "~/components/Typography";
import { OrderVehicleDialogDetails } from "../OrderVehicleDialog";
import { ViolatedPoliciesButton } from "../ViolatedPoliciesButton/ViolatedPoliciesButton";

export interface OrderVehicleCardProps {
  data: OrderVehicle;
}

export function OrderVehicleCard({ data }: OrderVehicleCardProps) {
  const handleOpenOrderDetail = useCallback(() => {
    dialogService.showDialog(<OrderVehicleDialogDetails data={data} />);
  }, []);

  return (
    <Card
      css={{
        overflow: "visible",
        "@mxlg": {
          border: "0",
        },
      }}
    >
      <Flex direction={{ "@mxlg": "columnReverse" }} as={CardBody} justify="between" gap="6">
        <Flex direction="column" gap="6" justify="between">
          <Flex direction="column" gap="4">
            <Flex align="center" justify="between" gap="2">
              <Flex align="center" gap="2" justify="between" css={{ width: "100%" }}>
                <Text size="3" css={{ fw: "600" }}>
                  {data.vehicleGroup}
                </Text>
                {!!data.violatedPolicies?.length && (
                  <ViolatedPoliciesButton data={data.violatedPolicies} />
                )}
              </Flex>
              <Button
                variant="tertiary"
                onClick={handleOpenOrderDetail}
                css={{
                  height: "$4",
                  fontSize: "$sm",
                  p: 10,
                  "@lg": {
                    display: "none",
                  },
                }}
              >
                Ver detalhes
              </Button>
            </Flex>
            <Label style={{ lineHeight: 1.5 }}>{data.vehicleDescription}</Label>
          </Flex>

          <Flex gap="3" wrap="wrap">
            {data.vehicleAirConditioner && (
              <Tag
                variant="neutral-light"
                css={{
                  "@mxlg": {
                    height: "$7",
                  },
                }}
              >
                <Icon as={SvgSnowflake} />
                <Text>Ar condicionado</Text>
              </Tag>
            )}

            <Tag
              variant="neutral-light"
              css={{
                "@mxlg": {
                  height: "$7",
                },
              }}
            >
              <Icon as={SvgManual} />
              <Text>{data.vehicleTransmissionType}</Text>
            </Tag>

            <Tag
              variant="neutral-light"
              css={{
                "@mxlg": {
                  height: "$7",
                },
              }}
            >
              <Icon as={SvgCarDoor} />
              <Text>{data.vehicleDoorsNumbers}</Text>
            </Tag>
          </Flex>
        </Flex>

        <Flex justify={{ "@mxlg": "center" }}>
          <Image src={data.vehicleImage} style={{ height: "138px", width: "240px" }} />
        </Flex>
      </Flex>
    </Card>
  );
}

OrderVehicleCard.displayName = "OrderVehicleCard";
