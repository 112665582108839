import { useCallback, useContext } from "react";
import { OrderItems, OrderRoadSegment, OrderStatus } from "~/application/types";
import { Box } from "~/components/Box";
import { Card, CardBody } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { LazyList } from "~/components/LazyList";
import { Text } from "~/components/Text";
import { AlertViolatedPoliciesPerProducts } from "~/presentation/shared/components/AlertViolatedPoliciePerProducts/AlertViolatedPoliciesPerProducts";
import { OrderRoadItemSkeleton } from "~/presentation/shared/components/OrderRoadItemSkeleton";
import { ReasonRejectedItem } from "~/presentation/shared/components/ReasonRejectedItem";
import { RoadTravelerListItem } from "~/presentation/shared/components/RoadTravelerListItem";
import { useOrder } from "~/presentation/shared/hooks/useOrder/useOrder";
import { OrderApprovalItem, canMarkAsRejected } from "~/presentation/shared/utils";
import { ApprovalOrderContext } from "../../hooks/ApprovalOrderContext";
import { ApprovalButtons } from "../ApprovalButtons";
import { ReprovalMessage } from "../ApprovalMessage";
import { RemoveItemButton } from "../RemoveItemButton";
import { OrderRoadCard } from "./OrderRoadCard";

export interface OrderRoadItemProps {
  isLoading: boolean;
  data?: OrderRoadSegment[];
  canShowApprovalButtons?: boolean;
  onDeleteItem?: (itemId: string) => void;
  onOpenDetails: (data: OrderRoadSegment) => void;
  itemIndex: number;
}

export function OrderRoadItem({
  data,
  canShowApprovalButtons = false,
  onOpenDetails,
  onDeleteItem,
  itemIndex
}: OrderRoadItemProps) {
  const { order } = useOrder();
  const { items, approveItem, reproveItem } = useContext(ApprovalOrderContext);

  const listRenderer = useCallback(
    (item: OrderRoadSegment, index: number) => {
      const canMarkItemAsRejected = canMarkAsRejected({
        reasonRejected: item.reasonRejected,
        order,
      });
      const orderStatus = order?.status as OrderStatus;
      const canRemoveItem =
        ![
          OrderStatus.APPROVED,
          OrderStatus.ON_APPROVAL,
          OrderStatus.ISSUED,
          OrderStatus.CANCELED,
          OrderStatus.CANCELING,
          OrderStatus.PENDING_ISSUE,
        ].includes(orderStatus) && onDeleteItem;

      const itemData = {
        item: OrderApprovalItem.ROAD_ORDERS,
        itemOrderId: item.uuid,
      };

      const isRejectedItem = items?.rejected.some(
        ({ itemOrderId }) => itemOrderId === itemData.itemOrderId
      );

      const RoadTravel = () => {
        return (
          <Flex direction="column" gap="3">
            <Flex>
              <Col>
                <Flex
                  justify="between"
                  align="center"
                  css={{
                    "@mxmd": {
                      p: "$3",
                    },
                  }}
                >
                  <Text size={{ "@initial": "5", "@mxlg": "4" }}>Passagem</Text>

                  <Flex
                    css={{
                      mb: "$2",
                      "@mxlg": {
                        display: "none",
                      },
                    }}
                  >
                    <ApprovalButtons
                      itemIndex={itemIndex}
                      item={itemData}
                      approveItem={approveItem}
                      reproveItem={reproveItem}
                    />
                  </Flex>
                </Flex>
              </Col>
            </Flex>

            <OrderRoadCard
              data={item}
              onOpenDetails={onOpenDetails}
            />

            {canMarkItemAsRejected && <ReasonRejectedItem reasonRejected={item.reasonRejected} />}
          </Flex>
        );
      };

      return (
        <Flex direction="column" gap="8">
          <Flex
            justify="between"
            css={{
              display: "none",
              "@mxlg": {
                display: "flex",
                width: "100%",
              },
            }}
          >
            <ApprovalButtons 
              itemIndex={itemIndex}
              item={itemData} 
              approveItem={approveItem} 
              reproveItem={reproveItem} 
            />
          </Flex>

          <Card
            css={{
              backgroundColor: "transparent",
              border: "none",
            }}
          >
            <CardBody
              css={{
                p: "0",
              }}
            >
              <Flex direction="column" gap="6" key={index}>
                {canMarkItemAsRejected ? (
                  <Card css={{ border: "1px solid red" }}>
                    <CardBody>
                      <RoadTravel />
                    </CardBody>
                  </Card>
                ) : (
                  <Box>
                    <RoadTravel />
                    {isRejectedItem && <ReprovalMessage itemIndex={itemIndex} item={itemData} />}
                  </Box>
                )}
              </Flex>
            </CardBody>
          </Card>

          <Flex css={{ width: "100%" }} justify="end">
            {canRemoveItem && <RemoveItemButton onRemove={() => onDeleteItem(item.uuid)} />}
          </Flex>

          <Text size={{ "@initial": "5", "@mxlg": "4" }}>Passageiros</Text>

          <LazyList
            items={item.travelers}
            render={(traveler) => (
              <RoadTravelerListItem
                item={item}
                orderStatus={orderStatus}
                traveler={traveler}
                key={traveler.uuid}
              />
            )}
            skeletonHeight={88}
            skeletonQuantity={2}
          />
        </Flex>
      );
    },
    [items]
  );

  return (
    <Box>
      <Flex direction="column" gap="2">
        <AlertViolatedPoliciesPerProducts itemType={OrderItems.ROAD} order={order} />
        <LazyList
          items={data}
          gap="8"
          render={listRenderer}
          skeletonQuantity={1}
          SkeletonComponent={<OrderRoadItemSkeleton />}
        />
      </Flex>
    </Box>
  );
}

OrderRoadItem.displayName = "OrderRoadItem";
