import { CardBody } from "~/components/Card";
import { CartHeader } from "~/components/Cart";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgChevronDown, SvgChevronUp, SvgTrash } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Image } from "~/components/Image";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { Caption } from "~/components/Typography";
import { daysBetween } from "~/utils/date.utils";
import { asCurrency } from "~/utils/mask.utils";
import { formatSentence } from "~/utils/string.utils";
import { Dispatch, Fragment, SetStateAction, useEffect } from "react";
import { Card } from "../../../components/Card";
import { VehicleBookingInfo } from "../utils/vehicle.utils";
import { Button } from "~/components/Button";
import { VehicleQuery, VehicleQueryDetail } from "~/application/types";
import { useVehicleBudgetResult } from "../hooks/useVehicleBudget/type";
import { dialogService } from "~/components/DialogStack";
import { VehicleBudgetDialog } from "../hooks/useVehicleBudget/components/VehicleBudgetDialog";
import { useUser } from "~/presentation/core/contexts/UserContext";

export type VehicleBookingCartProps = {
  bookingInfo: VehicleBookingInfo;
  cartIsOpen: boolean;
  vehicleEditable?: boolean;
  setCarIsOpen: Dispatch<SetStateAction<boolean>>;
  onRemoveVehicle?: VoidFunction;
  setNextStep: VoidFunction;
  currentActiveStep?: string;
  details?: VehicleQueryDetail;
  vehicleBudget?: useVehicleBudgetResult;
  vehicleQuery?: VehicleQuery;
};

export const VehicleBookingCart = ({
  bookingInfo,
  cartIsOpen,
  vehicleEditable,
  currentActiveStep,
  details,
  vehicleBudget,
  setNextStep,
  setCarIsOpen,
  onRemoveVehicle,
  vehicleQuery,
}: VehicleBookingCartProps) => {
  const { contexts, user } = useUser();
  useEffect(() => {
    if (!cartIsOpen) {
      setCarIsOpen(true);
    }
  }, [bookingInfo.price.totalValue]);

  const isButtonDisabled = () => {
    if (currentActiveStep === "travelers") {
      return !bookingInfo.traveler;
    }

    return !bookingInfo.vehicleSelected;
  };
  const returnFeeAnotherCity = bookingInfo.vehicleSelected?.returnFeeAnotherCity || 0;

  return (
    <Card
      elevated
      css={{
        "@mxlg": {
          position: "fixed",
          bottom: 0,
          left: 0,
          display: bookingInfo.price.totalValue > 0 ? "flex" : "none",
          borderRadius: "0",
          height: cartIsOpen ? "40%" : "5%",
          transition: "$slow",
          background: "#003161",
          width: "100%",
        },
      }}
    >
      <CartHeader
        css={{
          "@mxlg": {
            display: "flex",
            py: "$1",
            justifyContent: "center",
          },
        }}
      >
        <Text
          css={{
            "@mxlg": {
              display: "none",
            },
          }}
        >
          Sua reserva
        </Text>
        <Icon
          css={{
            "@lg": {
              display: "none",
            },
            width: "$80",
          }}
          as={cartIsOpen ? SvgChevronDown : SvgChevronUp}
          onClick={() => setCarIsOpen((prev) => !prev)}
        />
      </CartHeader>

      <CardBody
        css={{
          gap: "$4",
          "@mxlg": {
            border: "0",
            mt: "$1",
            px: "$3",
            color: "#FFF",
            height: "100vh",
          },
        }}
      >
        <Flex justify="between" align="center">
          <Caption
            css={{
              "@mxlg": {
                color: "#FFF",
              },
            }}
          >
            Valor Total
          </Caption>
          <Text size="6" css={{ fw: "600" }}>
            {asCurrency(
              bookingInfo.price.totalValue +
                bookingInfo.price.totalTaxesValue +
                returnFeeAnotherCity
            )}
          </Text>
        </Flex>

        <Flex
          justify="between"
          align="center"
          css={{
            "@mxlg": {
              mt: "$8",
            },
          }}
        >
          <Caption
            css={{
              "@mxlg": {
                color: "#FFF",
              },
            }}
          >
            Preço da diária
          </Caption>
          <Text
            css={{
              "@mxlg": {
                color: "#FFF",
              },
            }}
            size="2"
            variant="darkest"
          >
            {asCurrency(
              bookingInfo.price.totalValue /
                daysBetween(
                  bookingInfo.query.dateReturnSelected as Date,
                  bookingInfo.query.dateGetSelected as Date
                )
            )}
          </Text>
        </Flex>

        <Flex justify="between" align="center">
          <Caption
            css={{
              "@mxlg": {
                color: "#FFF",
              },
            }}
          >
            Taxas e encargos
          </Caption>
          <Text
            css={{
              "@mxlg": {
                color: "#FFF",
              },
            }}
            size="2"
            variant="darkest"
          >
            {asCurrency(bookingInfo.price.totalTaxesValue)}
          </Text>
        </Flex>
        {!!returnFeeAnotherCity && (
          <Flex justify="between" align="center">
            <Caption
              css={{
                "@mxlg": {
                  color: "#FFF",
                },
              }}
            >
              Taxa de devolução em outra loja
            </Caption>
            <Text
              css={{
                "@mxlg": {
                  color: "#FFF",
                },
              }}
              size="2"
              variant="darkest"
            >
              {asCurrency(returnFeeAnotherCity)}
            </Text>
          </Flex>
        )}
      </CardBody>

      <Flex css={{ pb: "$5", mt: "$5" }}>
        {vehicleBudget?.activeBudget ? (
          <Button
            css={{ width: "90%", margin: "0 auto" }}
            disabled={!vehicleBudget?.state.length}
            onClick={() =>
              dialogService.showDialog(
                <VehicleBudgetDialog
                  data={vehicleBudget?.state}
                  customer={contexts?.customer}
                  user={user}
                  vehicleParams={vehicleQuery}
                />
              )
            }
          >
            Gerar PDF
          </Button>
        ) : (
          <Button
            css={{ width: "90%", margin: "0 auto" }}
            disabled={isButtonDisabled()}
            onClick={setNextStep}
          >
            {currentActiveStep === "bookingConfirmation" ? "Efetuar reserva" : "Avançar"}
          </Button>
        )}
      </Flex>

      {bookingInfo.vehicleSelected && (
        <Fragment>
          <Flex
            css={{
              "@mxlg": {
                display: "none",
              },
            }}
            as={CardBody}
            gap="4"
            align="center"
          >
            <Image
              src={bookingInfo.vehicleSelected.vehicle.image}
              css={{ width: "48px", height: "32px" }}
            />

            <Col>
              <Flex direction="column" gap="2">
                <Text size="3" css={{ fw: "600" }}>
                  {bookingInfo.vehicleSelected.vehicle.group}
                </Text>
                <Text size="2" variant="darkest">
                  {formatSentence([
                    daysBetween(
                      bookingInfo.query.dateReturnSelected as Date,
                      bookingInfo.query.dateGetSelected as Date
                    ),
                    "diária",
                    "diárias",
                  ])}
                </Text>
              </Flex>
            </Col>

            <Text css={{ fw: "600" }}>{asCurrency(bookingInfo.vehicleSelected.totalPrice)}</Text>

            {vehicleEditable && (
              <Tooltip content={<TooltipLabel>Remover</TooltipLabel>}>
                <IconButton onClick={onRemoveVehicle}>
                  <Icon as={SvgTrash} />
                </IconButton>
              </Tooltip>
            )}
          </Flex>

          {details && (
            <Flex
              css={{
                "@mxlg": {
                  display: "none",
                },
              }}
              as={CardBody}
              gap="4"
              align="center"
            >
              <Flex direction="column">
                <Text fw="500">Esta tarifa inclui:</Text>
                <ul>
                  <Flex direction="column" gap="2">
                    {details?.rentalPolicy.map((rp) => (
                      <li key={rp} style={{}}>
                        <Text size="2" variant="dark">
                          {rp}
                        </Text>
                      </li>
                    ))}
                  </Flex>
                </ul>
              </Flex>
            </Flex>
          )}
        </Fragment>
      )}
    </Card>
  );
};

VehicleBookingCart.displayName = "VehicleBookingCart";
