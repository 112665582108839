import { Fragment, useCallback } from "react";
import { ApprovalModel, CustomerEmployee } from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { EmptyState } from "~/components/EmptyState";
import { Icon } from "~/components/Icon";
import { SvgPlus } from "~/components/Icon/icons";
import { SearchBar } from "~/components/Input";
import { LazyList } from "~/components/LazyList";
import { Pagination } from "~/components/Pagination";
import { Text } from "~/components/Text";
import { ApprovalModelListItem } from "./components/ApprovalModelListItem";
import { Col, Row } from "~/components/Grid";
import { H4 } from "~/components/Typography";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { MultiSelect } from "~/components/Input/MultiSelect";
import { IApproverFilters, ISelectOption } from "../../hooks/useApprovalModels";
import { CheckMarkMultiSelect } from "~/components/Input/CheckMarkMultiSelect";

export type TabApprovalModelProps = {
  currentPage: number;
  lastPage: number;
  data: ApprovalModel[];
  searchText: string;
  isLoading: boolean;
  approverOptions?: IApproverFilters;
  isLoadingApprovers: boolean;
  selectedApprover: IApproverFilters;
  setSearchText: (search: string) => void;
  handleChangeSearchApprover: (text: string) => void;
  onCreateApprovalModel: () => void;
  onChangeFilterApprover: (data: ISelectOption[]) => void;
  onEditApprovalModel: (ApprovalModel: ApprovalModel) => void;
  onToggleState: (ApprovalModel: ApprovalModel) => void;
  onGoToPage: (value: number) => void;
};

export function TabApprovalModels({
  data,
  isLoading,
  searchText,
  approverOptions,
  isLoadingApprovers,
  selectedApprover,
  onChangeFilterApprover,
  handleChangeSearchApprover,
  setSearchText,
  onGoToPage,
  onEditApprovalModel,
  onToggleState,
  onCreateApprovalModel,
  currentPage,
  lastPage,
}: TabApprovalModelProps) {
 
  const isMobile = useMobile();
  const listRenderer = useCallback(
    (item: ApprovalModel) => (
      <ApprovalModelListItem
        data={item}
        onEditClick={onEditApprovalModel}
        onToggleState={onToggleState}
        key={item.uuid}
      />
    ),
    [onEditApprovalModel, onToggleState]
  );
  
  return (
    <Fragment>
      <Card css={{ p: "$6", overflow: "visible" }}>
        <Row align="center">
          <Col>
            <H4>Modelos de aprovação</H4>
          </Col>

          <Col sz="3">
            <CheckMarkMultiSelect
              value={selectedApprover.approvers}
              options={approverOptions?.approvers}
              isLoading={isLoadingApprovers}
              onInputChange={handleChangeSearchApprover}
              onChange={onChangeFilterApprover}
              placeholder="Aprovadores"
            />
          </Col>

          <Col
            sz="auto"
            css={{
              "@mxlg": {
                margin: "$5 auto",
                width: "100%",
              },
            }}
          >
            <SearchBar
              style={isMobile ? { width: "100%" } : undefined}
              search={searchText}
              onSearchChange={setSearchText}
              placeholder="Encontrar"
            />
          </Col>

          <Col
            sz={{ "@lg": "auto" }}
            css={{
              "@mxlg": {
                width: "100%",
              },
            }}
          >
            <Button
              title="Novo modelo de aprovação"
              color="primary"
              onClick={onCreateApprovalModel}
              css={{ "@mxlg": { width: "100%" } }}
            >
              <Icon as={SvgPlus} />
              <Text>Novo modelo de aprovação</Text>
            </Button>
          </Col>

        </Row>
      </Card>

      <Box css={{ my: "$6" }}>
        <LazyList
          gap="6"
          items={data}
          isLoading={isLoading}
          render={listRenderer}
          skeletonQuantity={8}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              <Text>Você ainda não possui modelos de aprovação cadastradas</Text>
            </EmptyState>
          }
        />
      </Box>

      <Card>
        <CardBody>
          <Pagination activePage={currentPage} pagesCount={lastPage} setActivePage={onGoToPage} />
        </CardBody>
      </Card>
    </Fragment>
  );
}
